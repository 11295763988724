<template>
    <div class="marginTrade">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterOne()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="tool_filter_one">
                <h3>类型</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in typeArr"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="tool_filter_one">
                <h3>分类</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changeFilterThree()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterObj[tool_filter_two]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool: {},
            typeArr: [
                {
                    label: '涨跌幅',
                    value: '涨跌幅',
                },
                {
                    label: '主力资金',
                    value: '主力资金',
                },
            ],
            filterObj: {
                '涨跌幅': [
                    {
                        label: '涨幅',
                        value: '涨幅',
                    },
                    {
                        label: '跌幅',
                        value: '跌幅',
                    }
                ],
                '主力资金': [
                    {
                        label: '流入',
                        value: '涨幅',
                    },
                    {
                        label: '流出',
                        value: '跌幅',
                    }
                ],
            },
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
       changeFilterOne() {
        this.tool_filter_two = '涨跌幅'
        this.tool_filter_three = '涨幅'
        this.changeFilterThree()
       },
       changeFilterTwo() {
        this.changeFilterThree()
       },
       changeFilterThree() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    capital: this.tool_filter_two,
                    limit: this.tool_filter_three,
                })
            }, 'show')
       },
       addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}-${this.tool_filter_two}-${this.tool_filter_three}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            type: this.tool_filter_one,
                            capital: this.tool_filter_two,
                            limit: this.tool_filter_three,
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            margin-right: 30px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>