<template>
    <div class="marginTrade">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock
                :data="activeWindowCustomChartMsg"
                @selectStock="changeFilterOne"
            />
            <div class="filter_two" v-show="tool_filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilter()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['排名', '走势详情'].indexOf(tool_filter_two) !== -1" class="filter_three">
                <h3>类型</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changeFilter()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterThreeArr"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool: {},
            filterThreeArr: [
                {
                    label: '流动比',
                    value: 'part1',
                },
                {
                    label: '速动比',
                    value: 'part2',
                },
                {
                    label: '利息保障倍数',
                    value: 'part3',
                },
                {
                    label: '资产负债率',
                    value: 'part4',
                },
                {
                    label: '权益乘数',
                    value: 'part5',
                },
                {
                    label: '应收账款周转率',
                    value: 'part6',
                },
                {
                    label: '存货周转率',
                    value: 'part7',
                },
                {
                    label: '流动资产周转率',
                    value: 'part8',
                },
                {
                    label: '非流动资产周转率',
                    value: 'part9',
                },
                {
                    label: '资产周转率',
                    value: 'part10',
                },
                {
                    label: '营业利润率',
                    value: 'part11',
                },
                {
                    label: '毛利率',
                    value: 'part12',
                },
                {
                    label: '净利率',
                    value: 'part13',
                },
                {
                    label: 'ROE',
                    value: 'part14',
                },
            ],
        }
    },
    components: {
        SelectStock
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeFilterOne(value) {
            this.tool_filter_one = value
            this.tool_filter_two = 'ROE相关历史数据'
            this.tool_filter_three = ''
            this.changeFilter()
        },
        changeFilter() {
            if (['ROE相关历史数据', 'ROE详情'].indexOf(this.tool_filter_two) !== -1) {
                this.tool_filter_three = ''
            }
            if (['排名', '走势详情'].indexOf(this.tool_filter_two) !== -1 && !this.tool_filter_three) {
                this.tool_filter_three = 'part1'
            }
             this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_two,
                    seccode: this.tool_filter_one.value,
                    secname: this.tool_filter_one.label,
                    subType: this.tool_filter_three || undefined
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}${this.tool_filter_three ? `-${this.filterThreeArr.filter(item => item.value === this.tool_filter_three)[0].label}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_two,
                        seccode: this.tool_filter_one.value,
                        secname: this.tool_filter_one.label,
                        subType: this.tool_filter_three || undefined
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        .filter_two {
            width: 20%;
            padding-right: 30px;
            box-sizing: border-box;
        }
        .filter_three {
            width: 50%;
        }
        .select_stock {
            margin-right: 30px;
        }
        > div {
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>