import axios from "axios";

let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getFinanceCompareData(options) {
  return new Promise(async (resolve, reject) => {
    try {
      //收集股票seccode
      let seccodeArr = [];
      options.compareStocks.map((item) => {
        seccodeArr.push(item.seccode);
      });
      let finalData = []; //最终数据
      //首先尝试从临时存储中获得数据
      let fullData = seccodeArr.length == 1 ? tempHistory[seccodeArr[0]] : tempHistory[seccodeArr[0] + seccodeArr[1]];
      //如果没得到，则开始发请求
      if (!fullData) {
        let seasonData = await ajaxFinReportDateList(seccodeArr);
        const latestSeason = JSON.parse(seasonData.data.content).vReportDate.slice(-1)[0]; //{eSeasonType: 1, sYear: '2022'}
        //如果一个股票走一个逻辑，如果两个股票走两个逻辑
        if (seccodeArr.length == 1) {
          fullData = await handleOneStock(seccodeArr, latestSeason);
          tempHistory[seccodeArr[0]] = fullData;
        } else {
          fullData = await handleTwoStock(seccodeArr, latestSeason);
          tempHistory[seccodeArr[0] + seccodeArr[1]] = fullData;
        }
      }
      finalData = getFinalData(fullData, options);
      const compareSeason = getCompareSeason(fullData, options);

      const result = {
        pageTitle: getPageTitle(options),
        data: finalData,
        date: compareSeason,
        titleRows: getTitleRows(options),
        summaryColors: getSummaryColors(options),
      };
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}
function getCompare(fullData, options) {
  let compare1, compare2;
  if (options.compareType == "同比" || options.compareType == "最新报") {
    compare1 = fullData[0];
    compare2 = fullData[1];
  } else if (options.compareType == "环比") {
    compare1 = fullData[0];
    compare2 = fullData[2];
  } else if (options.compareType == "历史一季报") {
    compare1 = fullData[3];
    compare2 = fullData[4];
  } else if (options.compareType == "历史中报") {
    compare1 = fullData[5];
    compare2 = fullData[6];
  } else if (options.compareType == "历史三季报") {
    compare1 = fullData[7];
    compare2 = fullData[8];
  } else if (options.compareType == "历史年报") {
    compare1 = fullData[9];
    compare2 = fullData[10];
  }
  return {
    compare1,
    compare2,
  };
}
function getCompareSeason(fullData, options) {
  if (options.compareStocks.length == 1) {
    return "";
  } else {
    const compareObj = getCompare(fullData, options);
    let compare1 = compareObj.compare1;
    return getTitle(compare1);
  }
}
function getFinalData(fullData, options) {
  const compareObj = getCompare(fullData, options);
  let compare1 = compareObj.compare1;
  let compare2 = compareObj.compare2;

  let result = [];
  if (options.compareStocks.length == 1) {
    result.push(["关键指标", getTitle(compare1), getTitle(compare2)]);
  } else {
    result.push(["关键指标", options.compareStocks[0].secname, options.compareStocks[1].secname]);
  }
  if (options.compareKey == "每股指标") {
    result.push(["每股收益EPS", getValue(compare1, 1, 1), getValue(compare2, 1, 1)]);
    result.push(["每股净资产BPS", getValue(compare1, 1, 2), getValue(compare2, 1, 2)]);
    result.push(["每股资本公积", getValue(compare1, 1, 4), getValue(compare2, 1, 4)]);
    result.push(["每股未分配利润", getValue(compare1, 1, 5), getValue(compare2, 1, 5)]);
    result.push(["每股现金流量净额", getValue(compare1, 1, 6), getValue(compare2, 1, 6)]);
  } else if (options.compareKey == "盈利能力") {
    result.push(["净资产收益率(ROE)", getValue(compare1, 1, 7), getValue(compare2, 1, 7)]);
    result.push(["净资产回报率(ROA)", getValue(compare1, 1, 8), getValue(compare2, 1, 8)]);
    result.push(["总资产净利率", getValue(compare1, 1, 9), getValue(compare2, 1, 9)]);
    result.push(["销售净利率", getValue(compare1, 1, 10), getValue(compare2, 1, 10)]);
    result.push(["销售毛利率", getValue(compare1, 1, 11), getValue(compare2, 1, 11)]);
  } else if (options.compareKey == "资本结构") {
    result.push(["资产负债率", getValue(compare1, 1, 12), getValue(compare2, 1, 12)]);
    result.push(["长期资本负债率", getValue(compare1, 1, 13), getValue(compare2, 1, 13)]);
    result.push(["权益乘数", getValue(compare1, 1, 14), getValue(compare2, 1, 14)]);
    result.push(["流动资产/总资产", getValue(compare1, 1, 15), getValue(compare2, 1, 15)]);
    result.push(["非流动资产/总资产", getValue(compare1, 1, 16), getValue(compare2, 1, 16)]);
    result.push(["非流动负债权益比", getValue(compare1, 1, 17), getValue(compare2, 1, 17)]);
  } else if (options.compareKey == "偿债能力") {
    result.push(["流动比率", getValue(compare1, 1, 18), getValue(compare2, 1, 18)]);
    result.push(["速动比率", getValue(compare1, 1, 19), getValue(compare2, 1, 19)]);
    result.push(["现金比率", getValue(compare1, 1, 20), getValue(compare2, 1, 20)]);
  } else if (options.compareKey == "营运能力") {
    result.push(["营业周期", getValue(compare1, 1, 21), getValue(compare2, 1, 21)]);
    result.push(["存货周转天数", getValue(compare1, 1, 22), getValue(compare2, 1, 22)]);
    result.push(["应收账款周转天数", getValue(compare1, 1, 23), getValue(compare2, 1, 23)]);
    result.push(["存货周转率", getValue(compare1, 1, 24), getValue(compare2, 1, 24)]);
    result.push(["流动资产周转率", getValue(compare1, 1, 25), getValue(compare2, 1, 25)]);
    result.push(["固定资产周转率", getValue(compare1, 1, 26), getValue(compare2, 1, 26)]);
    result.push(["总资产周转率", getValue(compare1, 1, 27), getValue(compare2, 1, 27)]);
  } else if (options.compareKey == "成长能力") {
    result.push(["营业总收入增长率", getValue(compare1, 1, 28), getValue(compare2, 1, 28)]);
    result.push(["营业收入在增长率", getValue(compare1, 1, 29), getValue(compare2, 1, 29)]);
    result.push(["营业利润增长率", getValue(compare1, 1, 30), getValue(compare2, 1, 30)]);
    result.push(["净利润增长率", getValue(compare1, 1, 31), getValue(compare2, 1, 31)]);
    result.push(["利润总额增长率", getValue(compare1, 1, 32), getValue(compare2, 1, 32)]);
    result.push(["经营性现金流增长率", getValue(compare1, 1, 33), getValue(compare2, 1, 33)]);
    result.push(["净资产增长率", getValue(compare1, 1, 34), getValue(compare2, 1, 34)]);
    result.push(["总资产增长率", getValue(compare1, 1, 35), getValue(compare2, 1, 35)]);
  } else if (options.compareKey == "营业情况") {
    result.push(["营业总收入", getValue(compare1, 2, 1001), getValue(compare2, 2, 1001)]);
    result.push(["营业收入", getValue(compare1, 2, 1002), getValue(compare2, 2, 1002)]);
    result.push(["营业总成本", getValue(compare1, 2, 1020), getValue(compare2, 2, 1020)]);
    result.push(["营业成本", getValue(compare1, 2, 1033), getValue(compare2, 2, 1033)]);
    result.push(["营业税金及附加", getValue(compare1, 2, 1034), getValue(compare2, 2, 1034)]);
    result.push(["销售费用", getValue(compare1, 2, 1035), getValue(compare2, 2, 1035)]);
    result.push(["管理费用", getValue(compare1, 2, 1036), getValue(compare2, 2, 1036)]);
    result.push(["财务费用", getValue(compare1, 2, 1037), getValue(compare2, 2, 1037)]);
    result.push(["资产减值损失", getValue(compare1, 2, 1038), getValue(compare2, 2, 1038)]);
  } else if (options.compareKey == "其他经营收益情况") {
    result.push(["加:公允价值变动收益", getValue(compare1, 2, 1042), getValue(compare2, 2, 1042)]);
    result.push(["加:投资收益", getValue(compare1, 2, 1043), getValue(compare2, 2, 1043)]);
    result.push(["其中:对联营企业和合营企业的投资收益", getValue(compare1, 2, 1044), getValue(compare2, 2, 1044)]);
    result.push(["资产处置收益", getValue(compare1, 2, 1085), getValue(compare2, 2, 1085)]);
    result.push(["其他收益", getValue(compare1, 2, 1090), getValue(compare2, 2, 1090)]);
  } else if (options.compareKey == "利润情况") {
    result.push(["营业利润", getValue(compare1, 2, 1048), getValue(compare2, 2, 1048)]);
    result.push(["加:营业外收入", getValue(compare1, 2, 1049), getValue(compare2, 2, 1049)]);
    result.push(["减:营业外支出", getValue(compare1, 2, 1050), getValue(compare2, 2, 1050)]);
    result.push(["加:影响利润总额的其他项", getValue(compare1, 2, 1051), getValue(compare2, 2, 1051)]);
    result.push(["利润总额", getValue(compare1, 2, 1054), getValue(compare2, 2, 1054)]);
    result.push(["减:所得税费用", getValue(compare1, 2, 1055), getValue(compare2, 2, 1055)]);
    result.push(["加:影响净利润的其他项", getValue(compare1, 2, 1056), getValue(compare2, 2, 1056)]);
  } else if (options.compareKey == "净利润详情") {
    result.push(["净利润", getValue(compare1, 2, 1059), getValue(compare2, 2, 1059)]);
    result.push(["(一)按经营持续性分类", "", ""]);
    result.push(["持续经营净利润", getValue(compare1, 2, 1059), getValue(compare2, 2, 1059)]);
    result.push(["(二)按所有权归属分类", "", ""]);
    result.push(["归属于母公司股东的净利润", getValue(compare1, 2, 1060), getValue(compare2, 2, 1060)]);
    result.push(["少数股东损益", getValue(compare1, 2, 1061), getValue(compare2, 2, 1061)]);
  } else if (options.compareKey == "收益详情") {
    result.push(["每股收益", "", ""]);
    result.push(["基本每股收益", getValue(compare1, 2, 1070), getValue(compare2, 2, 1070)]);
    result.push(["稀释每股收益", getValue(compare1, 2, 1071), getValue(compare2, 2, 1071)]);
    result.push(["其他综合收益", getValue(compare1, 2, 1072), getValue(compare2, 2, 1072)]);
    result.push(["综合收益总额", getValue(compare1, 2, 1066), getValue(compare2, 2, 1066)]);
    result.push(["归属于母公司所有者的总和收益总额", getValue(compare1, 2, 1067), getValue(compare2, 2, 1067)]);
    result.push(["归属于少数股东的综合收益总额", getValue(compare1, 2, 1068), getValue(compare2, 2, 1068)]);
  } else if (options.compareKey == "资产概况") {
    result.push(["流动资产", getValue(compare1, 3, 2017), getValue(compare2, 3, 2017)]);
    result.push(["非流动资产", getValue(compare1, 3, 2038), getValue(compare2, 3, 2038)]);
    result.push(["资产总计", getValue(compare1, 3, 2063), getValue(compare2, 3, 2063)]);
  } else if (options.compareKey == "流动资产") {
    result.push(["货币资金", getValue(compare1, 3, 2001), getValue(compare2, 3, 2001)]);
    result.push(["衍生金融资产", getValue(compare1, 3, 2045), getValue(compare2, 3, 2045)]);
    result.push(["应收票据", getValue(compare1, 3, 2004), getValue(compare2, 3, 2004)]);
    result.push(["应收账款", getValue(compare1, 3, 2007), getValue(compare2, 3, 2007)]);
    result.push(["预付款项", getValue(compare1, 3, 2009), getValue(compare2, 3, 2009)]);
    result.push(["应收利息", getValue(compare1, 3, 2006), getValue(compare2, 3, 2006)]);
    result.push(["应收股利", getValue(compare1, 3, 2005), getValue(compare2, 3, 2005)]);
    result.push(["其他应收款", getValue(compare1, 3, 2008), getValue(compare2, 3, 2008)]);
    result.push(["存货", getValue(compare1, 3, 2010), getValue(compare2, 3, 2010)]);
    result.push(["一年内到期的非流动资产", getValue(compare1, 3, 2013), getValue(compare2, 3, 2013)]);
    result.push(["其他流动资产", getValue(compare1, 3, 2014), getValue(compare2, 3, 2014)]);
  } else if (options.compareKey == "非流动资产") {
    result.push(["发放委托贷款和垫款", getValue(compare1, 3, 2047), getValue(compare2, 3, 2047)]);
    result.push(["可供出售金融资产", getValue(compare1, 3, 2018), getValue(compare2, 3, 2018)]);
    result.push(["持有至到期投资", getValue(compare1, 3, 2019), getValue(compare2, 3, 2019)]);
    result.push(["长期应收款", getValue(compare1, 3, 2022), getValue(compare2, 3, 2022)]);
    result.push(["长期股权投资", getValue(compare1, 3, 2021), getValue(compare2, 3, 2021)]);
    result.push(["投资性房地产", getValue(compare1, 3, 2020), getValue(compare2, 3, 2020)]);
    result.push(["固定资产", getValue(compare1, 3, 2023), getValue(compare2, 3, 2023)]);
    result.push(["在建工程", getValue(compare1, 3, 2025), getValue(compare2, 3, 2025)]);
    result.push(["工程物资", getValue(compare1, 3, 2024), getValue(compare2, 3, 2024)]);
    result.push(["固定资产清理", getValue(compare1, 3, 2026), getValue(compare2, 3, 2026)]);
  } else if (options.compareKey == "负债概况") {
    result.push(["流动负债", getValue(compare1, 3, 2083), getValue(compare2, 3, 2083)]);
    result.push(["非流动负债", getValue(compare1, 3, 2095), getValue(compare2, 3, 2095)]);
    result.push(["负债合计", getValue(compare1, 3, 2119), getValue(compare2, 3, 2119)]);
  } else if (options.compareKey == "流动负债") {
    result.push(["短期借款", getValue(compare1, 3, 2064), getValue(compare2, 3, 2064)]);
    result.push(["衍生金融负债", getValue(compare1, 3, 2099), getValue(compare2, 3, 2099)]);
    result.push(["应付票据", getValue(compare1, 3, 2067), getValue(compare2, 3, 2067)]);
    result.push(["应付账款", getValue(compare1, 3, 2068), getValue(compare2, 3, 2068)]);
    result.push(["预收款项", getValue(compare1, 3, 2070), getValue(compare2, 3, 2070)]);
    result.push(["应付职工薪酬", getValue(compare1, 3, 2071), getValue(compare2, 3, 2071)]);
    result.push(["应交税费", getValue(compare1, 3, 2073), getValue(compare2, 3, 2073)]);
    result.push(["应付利息", getValue(compare1, 3, 2074), getValue(compare2, 3, 2074)]);
    result.push(["应付股利", getValue(compare1, 3, 2072), getValue(compare2, 3, 2072)]);
    result.push(["其他应付款", getValue(compare1, 3, 2075), getValue(compare2, 3, 2075)]);
    result.push(["划分为持有待售的负债", getValue(compare1, 3, 2078), getValue(compare2, 3, 2078)]);
    result.push(["一年内到期的非流动负债", getValue(compare1, 3, 2079), getValue(compare2, 3, 2079)]);
    result.push(["其他流动负债", getValue(compare1, 3, 2080), getValue(compare2, 3, 2080)]);
  } else if (options.compareKey == "非流动负债") {
    result.push(["长期借款", getValue(compare1, 3, 2084), getValue(compare2, 3, 2084)]);
    result.push(["应付债券", getValue(compare1, 3, 2085), getValue(compare2, 3, 2085)]);
    result.push(["长期应付款", getValue(compare1, 3, 2086), getValue(compare2, 3, 2086)]);
    result.push(["长期应付职工薪酬", getValue(compare1, 3, 2087), getValue(compare2, 3, 2087)]);
    result.push(["专项应付款", getValue(compare1, 3, 2088), getValue(compare2, 3, 2088)]);
    result.push(["预计负债", getValue(compare1, 3, 2089), getValue(compare2, 3, 2089)]);
    result.push(["长期递延收益", getValue(compare1, 3, 2091), getValue(compare2, 3, 2091)]);
    result.push(["递延所得税负债", getValue(compare1, 3, 2090), getValue(compare2, 3, 2090)]);
    result.push(["其他非流动负债", getValue(compare1, 3, 2092), getValue(compare2, 3, 2092)]);
  } else if (options.compareKey == "股东收益详情") {
    result.push(["实收资本(或股本)", getValue(compare1, 3, 2120), getValue(compare2, 3, 2120)]);
    result.push(["其他权益工具", getValue(compare1, 3, 2121), getValue(compare2, 3, 2121)]);
    result.push(["资本公积", getValue(compare1, 3, 2124), getValue(compare2, 3, 2124)]);
    result.push(["减:库存股", getValue(compare1, 3, 2127), getValue(compare2, 3, 2127)]);
    result.push(["其他综合收益", getValue(compare1, 3, 2128), getValue(compare2, 3, 2128)]);
    result.push(["专项储备", getValue(compare1, 3, 2133), getValue(compare2, 3, 2133)]);
    result.push(["盈余公积", getValue(compare1, 3, 2125), getValue(compare2, 3, 2125)]);
    result.push(["未分配利润", getValue(compare1, 3, 2126), getValue(compare2, 3, 2126)]);
    result.push(["所有者权益其他项", getValue(compare1, 3, 2147), getValue(compare2, 3, 2147)]);
    result.push(["归属于母公司股东权益合计", getValue(compare1, 3, 2136), getValue(compare2, 3, 2136)]);
    result.push(["少数股东权益", getValue(compare1, 3, 2137), getValue(compare2, 3, 2137)]);
  } else if (options.compareKey == "经营活动现金流入详情") {
    result.push(["销售商品、提供劳务收到的现金", getValue(compare1, 4, 3001), getValue(compare2, 4, 3001)]);
    result.push(["收到的税费返还", getValue(compare1, 4, 3002), getValue(compare2, 4, 3002)]);
    result.push(["收到的其他与经营活动有关的现金", getValue(compare1, 4, 3013), getValue(compare2, 4, 3013)]);
  } else if (options.compareKey == "经营活动现金流出详情") {
    result.push(["购买商品和劳务所支付的现金", getValue(compare1, 4, 3017), getValue(compare2, 4, 3017)]);
    result.push(["支付给职工以及为职工支付的现金", getValue(compare1, 4, 3018), getValue(compare2, 4, 3018)]);
    result.push(["支付的各项税费", getValue(compare1, 4, 3019), getValue(compare2, 4, 3019)]);
    result.push(["支付其他与经营活动有关的现金", getValue(compare1, 4, 3027), getValue(compare2, 4, 3027)]);
  } else if (options.compareKey == "投资活动现金流入详情") {
    result.push(["收回投资收到的现金", getValue(compare1, 4, 3033), getValue(compare2, 4, 3033)]);
    result.push(["取得投资收益收到的现金", getValue(compare1, 4, 3034), getValue(compare2, 4, 3034)]);
    result.push([
      "处置固定资产、无形资产和其他长期资产而收回的现金净额",
      getValue(compare1, 4, 3035),
      getValue(compare2, 4, 3035),
    ]);
    result.push(["处置子公司及其他营业单位收到的现金净额", getValue(compare1, 4, 3036), getValue(compare2, 4, 3036)]);
    result.push(["收到的其他与投资活动有关的现金", getValue(compare1, 4, 3037), getValue(compare2, 4, 3037)]);
  } else if (options.compareKey == "投资活动现金流出详情") {
    result.push(["投资支付的现金", getValue(compare1, 4, 3042), getValue(compare2, 4, 3042)]);
    result.push(["质押贷款净增加额", getValue(compare1, 4, 3044), getValue(compare2, 4, 3044)]);
    result.push([
      "购建固定资产、无形资产和其他长期资产所支付的现金",
      getValue(compare1, 4, 3041),
      getValue(compare2, 4, 3041),
    ]);
    result.push(["支付其他与投资活动有关的现金", getValue(compare1, 4, 3045), getValue(compare2, 4, 3045)]);
  } else if (options.compareKey == "筹资活动现金流入详情") {
    result.push(["吸收投资收到的现金", getValue(compare1, 4, 3051), getValue(compare2, 4, 3051)]);
    result.push(["其中:子公司吸收少数股东投资收到的现金", getValue(compare1, 4, 3052), getValue(compare2, 4, 3052)]);
    result.push(["取得借款收到的现金", getValue(compare1, 4, 3054), getValue(compare2, 4, 3054)]);
    result.push(["发行债券收到的现金", getValue(compare1, 4, 3053), getValue(compare2, 4, 3053)]);
    result.push(["收到其他与筹资活动有关的现金", getValue(compare1, 4, 3055), getValue(compare2, 4, 3055)]);
  } else if (options.compareKey == "筹资活动现金流出详情") {
    result.push(["偿还债务所支付的现金", getValue(compare1, 4, 3059), getValue(compare2, 4, 3059)]);
    result.push(["分配股利、利润或偿付利息支付的现金", getValue(compare1, 4, 3060), getValue(compare2, 4, 3060)]);
    result.push(["支付的其他与筹资活动有关的现金", getValue(compare1, 4, 3062), getValue(compare2, 4, 3062)]);
  } else if (options.compareKey == "其他现金流变化") {
    result.push(["期初现金及现金等价物余额", getValue(compare1, 4, 3072), getValue(compare2, 4, 3072)]);
    result.push(["汇率变动对现金及现金等价物的影响额", getValue(compare1, 4, 3068), getValue(compare2, 4, 3068)]);
    result.push(["现金及现金等价物净增加额", getValue(compare1, 4, 3071), getValue(compare2, 4, 3071)]);
    result.push(["现金及现金等价物净余额", getValue(compare1, 4, 3072), getValue(compare2, 4, 3072)]);
  } else if (options.compareKey == "负债和股东权益概况") {
    result.push(["负债合计", getValue(compare1, 3, 2119), getValue(compare2, 3, 2119)]);
    result.push(["股东权益合计", getValue(compare1, 3, 2139), getValue(compare2, 3, 2139)]);
    result.push(["负债及股东权益总计", getValue(compare1, 3, 2142), getValue(compare2, 3, 2142)]);
  } else if (options.compareKey == "经营活动中产生的现金概况") {
    result.push(["经营活动现金流入合计", getValue(compare1, 4, 3016), getValue(compare2, 4, 3016)]);
    result.push(["经营活动现金流出合计", getValue(compare1, 4, 3030), getValue(compare2, 4, 3030)]);
    result.push(["经营活动产生的现金净额", getValue(compare1, 4, 3032), getValue(compare2, 4, 3032)]);
  } else if (options.compareKey == "投资活动中产生的现金概况") {
    result.push(["投资活动现金流入合计", getValue(compare1, 4, 3040), getValue(compare2, 4, 3040)]);
    result.push(["投资活动现金流出合计", getValue(compare1, 4, 3048), getValue(compare2, 4, 3048)]);
    result.push(["投资活动产生的现金净额", getValue(compare1, 4, 3050), getValue(compare2, 4, 3050)]);
  } else if (options.compareKey == "筹资活动中产生的现金概况") {
    result.push(["筹资活动现金流入合计", getValue(compare1, 4, 3058), getValue(compare2, 4, 3058)]);
    result.push(["筹资活动现金流出合计", getValue(compare1, 4, 3065), getValue(compare2, 4, 3065)]);
    result.push(["筹资活动产生的现金净额", getValue(compare1, 4, 3067), getValue(compare2, 4, 3067)]);
  }
  return result;
}

function getTitleRows(options) {
  const titleRowsObj = {
    利润情况: [1, 5],
    营业情况: [1, 3],
    净利润详情: [2, 4],
    收益详情: [1, 4, 5],
    其他现金流变化: [2, 3],
  };
  return titleRowsObj[options.compareKey];
}
function getSummaryColors(options) {
  const colosObj = {
    资产概况: ["#FF7700", "#EBA90F"],
    负债概况: ["#178CEA", "#62A9E2"],
    负债和股东权益概况: ["#178CEA", "#62A9E2"],
    经营活动中产生的现金概况: ["#FF7700", "#EBA90F"],
    投资活动中产生的现金概况: ["#FF7700", "#EBA90F"],
    筹资活动中产生的现金概况: ["#FF7700", "#EBA90F"],
  };
  return colosObj[options.compareKey];
}
function getValue(data, index1, index2) {
  return data.mItems[index1][index2].sDisplayValue;
}
function getTitle(data) {
  const eSeasonTypeObj = {
    1: "一季报",
    2: "中报",
    3: "三季报",
    4: "年报",
  };
  return `${data.sYear}${eSeasonTypeObj[data.eSeasonType]}`;
}
function getPageTitle(options) {
  let beginTxt;
  if (options.compareStocks.length == 1) {
    beginTxt = options.compareStocks[0].secname;
    return `${beginTxt}【${options.compareKey}】${options.compareType}`;
  } else {
    return `${options.compareKey}对比`;
  }
}
async function handleOneStock(seccodeArr, latestSeason) {
  return new Promise(async (resolve) => {
    const yoySeason = getYoySeason(latestSeason); //同比
    const circleSeason = getCircleSeason(latestSeason); //环比
    const historySeason1 = getHistorySeason(latestSeason, 1); //历史一季报
    const historySeason2 = getHistorySeason(latestSeason, 2); //历史中季报
    const historySeason3 = getHistorySeason(latestSeason, 3); //历史三季报
    const historySeason4 = getHistorySeason(latestSeason, 4); //历史年报
    const allSeason = [
      {
        sDtSecCode: seccodeArr[0],
        sYear: latestSeason.sYear,
        eSeasonType: latestSeason.eSeasonType, //最新
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: yoySeason.sYear,
        eSeasonType: yoySeason.eSeasonType, //同比
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: circleSeason.sYear,
        eSeasonType: circleSeason.eSeasonType, //环比
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason1[0].sYear,
        eSeasonType: historySeason1[0].eSeasonType, //历史一季报1
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason1[1].sYear,
        eSeasonType: historySeason1[1].eSeasonType, //历史一季报2
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason2[0].sYear,
        eSeasonType: historySeason2[0].eSeasonType, //历史中季报1
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason2[1].sYear,
        eSeasonType: historySeason2[1].eSeasonType, //历史中季报2
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason3[0].sYear,
        eSeasonType: historySeason3[0].eSeasonType, //历史三季报1
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason3[1].sYear,
        eSeasonType: historySeason3[1].eSeasonType, //历史三季报2
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason4[0].sYear,
        eSeasonType: historySeason4[0].eSeasonType, //历史年报1
      },
      {
        sDtSecCode: seccodeArr[0],
        sYear: historySeason4[1].sYear,
        eSeasonType: historySeason4[1].eSeasonType, //历史年报2
      },
    ];
    let financeData = await ajaxFinancialData(allSeason);
    financeData = JSON.parse(financeData.data.content).vFinancialData;
    resolve(financeData);
  });
}
async function handleTwoStock(seccodeArr, latestSeason) {
  return new Promise(async (resolve) => {
    const allSeason = [
      {
        sDtSecCode: seccodeArr[0],
        sYear: latestSeason.sYear,
        eSeasonType: latestSeason.eSeasonType,
      },
      {
        sDtSecCode: seccodeArr[1],
        sYear: latestSeason.sYear,
        eSeasonType: latestSeason.eSeasonType,
      },
    ];
    let financeData = await ajaxFinancialData(allSeason);
    financeData = JSON.parse(financeData.data.content).vFinancialData;
    resolve(financeData);
  });
}
//获取同比season
function getYoySeason(latestSeason) {
  if (latestSeason.eSeasonType == 1) {
    return { eSeasonType: 4, sYear: latestSeason.sYear - 1 + "" };
  } else {
    return { eSeasonType: latestSeason.eSeasonType - 1, sYear: latestSeason.sYear };
  }
}
//获取环比season
function getCircleSeason(latestSeason) {
  return { eSeasonType: latestSeason.eSeasonType, sYear: latestSeason.sYear - 1 + "" };
}
//获取历史一季度，历史中报，历史三季度，历史年报season，返回一个数组
function getHistorySeason(latestSeason, season) {
  let result = [];
  if (latestSeason.eSeasonType >= season) {
    return [
      { eSeasonType: season, sYear: latestSeason.sYear },
      { eSeasonType: season, sYear: latestSeason.sYear - 1 + "" },
    ];
  } else {
    return [
      { eSeasonType: season, sYear: latestSeason.sYear - 1 + "" },
      { eSeasonType: season, sYear: latestSeason.sYear - 2 + "" },
    ];
  }
}

//获取财报对比
async function ajaxFinancialData(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinancialData&req=GetFinancialDataReq&rsp=GetFinancialDataRsp",
    JSON.stringify({
      vReqItem: options,
    })
  );
}
//获取有财报数据的日期列表
async function ajaxFinReportDateList(seccodeArr) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinReportDateList&req=GetFinReportDateListReq&rsp=GetFinReportDateListRsp",
    JSON.stringify({
      vDtSecCode: seccodeArr,
    })
  );
}
