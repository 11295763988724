<template>
    <div class="liveBackground">
        <div class="bg_filter">
            <ul>
                <li
                    v-for="item in filterArr"
                    :key="item.value"
                    @click="changeType(item.value)"
                    :class="[type === item.value ? 'active' : '']"
                >
                    {{ item.title }}
                </li>
            </ul>
            <el-select v-model="category" clearable placeholder="背景类别" @change="changeCategory">
                <el-option
                v-for="(item, index) in typeArr"
                :key="index"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
        </div>
        <div class="img_list">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <ResourceItem
                    :itemData="item"
                    :active="active"
                    :markerVisible="type === 'all' ? true : false"
                    @change-active="changeActive"
                    materialSource="背景"
                    :groupVisible="false"
                    v-for="(item, index) in data"
                    :key="index"
                    :roomSizeScale="detail.roomSizeScale"
                />
            </div>
            <span>{{ loading ? '正在加载更多' : '暂无更多'}}</span>
        </div>
    </div>
</template>
<script>
import ResourceItem from '../../components/resourceItem/index.vue'

export default {
    data: function() {
        return {
            type: 'all',
            category: '',
            active: '',
            filterArr: [
                {
                   title: '全部',
                   value: 'all', 
                },
                {
                   title: '图片',
                   value: '0', 
                },
                {
                   title: '视频',
                   value: '2', 
                },
            ],
            typeArr: ['金融数字','粒子空间','平面抽象','室内场景','室外风景'],
            data: [],
            pageNum: 1,
            pageSize: 21,
            endPageNum: 1,
            loading: false,
            detail: {}
        }
    },
    components: {
        ResourceItem
    },
    computed: {
        disabled() {
            return this.pageNum >= this.endPageNum;
        }
    },
    created() {
        this.detail = JSON.parse(localStorage.getItem('room_detail'))
        this.getList()
    },
     mounted() {
        this.$bus.$on('clearBackgroundActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeActive(value) {
            this.active = String(value)
        },
        changeType(value) {
            this.type = value
            this.update()
        },
        changeCategory() {
            this.update()
        },
        getList(params) {
            this.loading = true;
            this.API.material.getList({
                materialClass: 1,
                materialType: isNaN(Number(this.type)) ? undefined : Number(this.type),
                searchKeyword: this.detail.roomSizeScale === '16:9' ? this.category ? `横屏 ${this.category}` : '横屏' : this.category ? `竖屏 ${this.category}` : '竖屏',
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                range: 0,
                ...params
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.data = this.data.concat(res.data.list);
                    this.endPageNum = res.data.pages
                } else {
                    this.$message.error(res.message)
                }
                this.loading = false;
            })
        },
        // 滚动加载
        load() {
            if (!this.disabled) {
                this.pageNum += 1;
                this.getList();
            }
        },
        // 切换类型
        update() {
            this.data = [];
            this.pageNum = 1;
            this.endPageNum = 1;
            this.load = false;
            this.getList();
        },
    },
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>