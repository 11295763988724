import axios from "axios"

let tempHistory = {};//某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getCompanyIntroData(options){
    const typeObj = {
        "公司介绍":"getCompanyIntroduction",
        "参股控股公司": "getCompanyRelation",
        // "参股控股公司":"getCompanyShareHolder",
        // "关系":"getRelationship",
        // "高管":"getExecutiveInfo",
        // "董事和高管":"getExecutive",
        "董事和高管": "getExecutiveMsg",
        "主营业务":"getMainBusiness",
        "分红配送":"getDividendPayingPlacing",
        "净利润行业对比":"getNetProfitCompare",
        "营业收入行业对比":"getOperatingRevenueCompare",
        "每股收益行业对比":"getEps",
        "市盈率行业对比":"getPe",
        "股本结构":"getCapitalStructure",
        "十大流通股东":"getTopShareholder",
        "十大股东":"getTopholder",
        "主力持仓":"getMainHolder",
        "经营状况概述":"getOperateDesc",
        "收入和利润情况":"getIncomeProfit",
        "资产负债情况":"getAssetsDebt",
        "现金净流量":"getCashNetFlow",
        "行业规模":"getIndustryScale",
        "员工人数和营业收入":"getStaffCountAndIncome",
        "员工分析":"getStaffAnalyze",
        "人均营业收入":"getIncomeAve",
        "普通员工薪酬概况":"getCommStaffSalaryAve",
        "高管薪酬概况":"getSeniorStaffSalaryAve",
        "员工增长情况":"getStaffGrowthRatio",
        "人均营业利润":"getProfitAve",
        "员工学历构成":"getStaffEducation",
        "员工职业构成":"getStaffJob",
        "本科员工比例":"getBechelorData",
        "博士员工人数":"getDoctorData",
        "硕士员工人数":"getMasterData",
        "生产岗员工占比":"getProductRatio",
        "销售岗员工占比":"getSaleRatio",
        "技术岗员工占比":"getTechRatio",
        "财务岗员工占比":"getTinancialRatio",
        "行政岗员工占比":"getAdministratorRatio",
        "其他岗员工占比":"getOtherRatio",
        "大专以下员工学历占比":"getBelowEducationRatio",
        "大专员工学历占比":"getCollegeEducationRatio",
        "本科员工学历占比":"getBechelorEducationRatio",
        "硕士员工学历占比":"getMasterEducationRatio",
        "博士员工学历占比":"getDoctorEducationRatio",
    };
    const functions ={
        getCompanyIntroduction,
        getCompanyRelation,
        // getCompanyShareHolder,
        // getRelationship,
        getExecutiveMsg,
        // getExecutiveInfo,
        // getExecutive,
        getMainBusiness,
        getDividendPayingPlacing,
        getNetProfitCompare,
        getOperatingRevenueCompare,
        getEps,
        getPe,
        getCapitalStructure,
        getTopShareholder,
        getTopholder,
        getMainHolder,
        getOperateDesc,
        getIncomeProfit,
        getAssetsDebt,
        getCashNetFlow,
        getIndustryScale,
        getStaffCountAndIncome,
        getStaffAnalyze,
        getIncomeAve,
        getCommStaffSalaryAve,
        getSeniorStaffSalaryAve,
        getStaffGrowthRatio,
        getProfitAve,
        getStaffEducation,
        getStaffJob,
        getBechelorData,
        getDoctorData,
        getMasterData,
        getProductRatio,
        getSaleRatio,
        getTechRatio,
        getTinancialRatio,
        getAdministratorRatio,
        getOtherRatio,
        getBelowEducationRatio,
        getCollegeEducationRatio,
        getBechelorEducationRatio,
        getMasterEducationRatio,
        getDoctorEducationRatio,
    };
    return functions[typeObj[options.type]](options)
}

/*=====================个股百科-公司介绍=========================*/
export function getCompanyIntroduction(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handleCompanyIntroduction(fullData),
            date:'上市日期：'+fullData.stCompanyIntro.stCompanyIntroduction.sListingDate.replace(/\-/g,''),          
        }
        resolve(finalData);
    });
}
function getTitle(options){
    return options.secname+options.type
}

function handleCompanyIntroduction(data){
    let chartData = [];
    chartData.push({a:"公司名称",b:data.stCompanyIntro.stCompanyIntroduction.sName});
    chartData.push({a:"发行价格",b:data.stCompanyIntro.stCompanyIntroduction.sIssuePrice});
    chartData.push({a:"实际控制人",b:data.stCompanyIntro.stCompanyIntroduction.sController});
    chartData.push({a:"所属区域",b:data.stCompanyIntro.stCompanyIntroduction.sCity});
    chartData.push({a:"所属行业",b:data.stCompanyIntro.stCompanyIntroduction.sIndustry});
    chartData.push({a:"注册地址",b:data.stCompanyIntro.stCompanyIntroduction.sRegistryLocate});
    chartData.push({a:"控股或参股公司",b:data.stCompanyIntro.stCompanyIntroduction.sShareholderNum+'家'});
    chartData.push({a:"主营业务",b:data.stCompanyIntro.stCompanyIntroduction.sMainBusiness});
    return  chartData
}

async function ajaxCompanyIntro(options){
    return await axios.post("https://comm.wedengta.com/?s=base&f=getCompanyIntro&req=GetCompanyIntroReq&rsp=GetCompanyIntroRsp",
    JSON.stringify({
        sDtSecCode: options.seccode,
    }))
    
}
export function getCompanyRelation(options) {
    return new Promise(async (resolve) => {
        let fullData = []
        if (options.name) {
            fullData = await getRelationship(options)
        } else {
            fullData = await getCompanyShareHolder(options)
        }
        resolve(fullData)
    })
}
/*=====================个股百科-参股控股公司=========================*/
export function getCompanyShareHolder(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyShareholder(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleCompanyShareHolder(fullData,options)
        resolve(finalData);
    });
}

function handleCompanyShareHolder(data,options){
    let eSeasonType
    if (data.eSeasonType ==1 ){
        eSeasonType='一季报'
    } else if (data.eSeasonType ==2){
        eSeasonType='中报'
    } else if (data.eSeasonType ==3){
        eSeasonType='三季报'
    } else if (data.eSeasonType ==4){
        eSeasonType='年报'
    }
    let seasonDate = data.sYear+eSeasonType
    let chartData = [];
    data.vShareholderItem.slice(0,6).map((item,index)=>{
        chartData.push({
            sShaHolCom:item.sShaHolCom,
            stShaRatio:item.stShaRatio.dOrgValue,
            sListed:item.sListed,
            sMaiBus:item.sMaiBus
        })
    })
    for(var i=1;i<chartData.length;i++){
        if(chartData[i].stShaRatio ==0 ){
            chartData[i].stShaRatio ="-----"
        }
        if(chartData[i].sMaiBus == "" ){
            chartData[i].sMaiBus ="-----"
        }
    }
    const titleArr = ["被参股公司","持股比例","上市/挂牌", "主营业务"];
    chartData.unshift(titleArr);
    const result =  {
        pageTitle:getTitle(options),
        data:chartData,
        date:seasonDate,
    }
    return result
}

async function ajaxCompanyShareholder(options){
    return await axios.post("https://comm.wedengta.com/?s=base&f=getCompanyShareholder&req=GetCompanyShareholderReq&rsp=GetCompanyShareholderRsp",
    JSON.stringify({
        sDtSecCode: options.seccode,
    }))
    
}

export function getExecutiveMsg(options) {
    return new Promise(async (resolve) => {
        let fullData = []
        if (options.name) {
            fullData = await getExecutiveInfo(options)
        } else {
            fullData = await getExecutive(options)
        }
        resolve(fullData)
    })
}

/*=====================个股百科-董事和高管=========================*/
export function getExecutive(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleExecutive(fullData,options)
        resolve(finalData);
    });
}

function handleExecutive(data,options){    
    let eSeasonType
    if (data.stMainIndex.eSeasonType ==1 ){
        eSeasonType='一季报'
    } else if (data.stMainIndex.eSeasonType ==2){
        eSeasonType='中报'
    } else if (data.stMainIndex.eSeasonType ==3){
        eSeasonType='三季报'
    } else if (data.stMainIndex.eSeasonType ==4){
        eSeasonType='年报'
    }
    let seasonDate = data.stMainIndex.sYear+eSeasonType
    
    let chartData = [];
    data.vtExecutive.slice(0,11).map((item,index)=>{
        chartData.push({
            sName:item.sName,
            sAge:item.sAge,
            sEdu:item.sEdu,
            sBusiness:item.sBusiness
        })
    })

    const titleArr = ["姓名","年龄","学历", "职位"];
    chartData.unshift(titleArr);
    const result =  {
        pageTitle:getTitle(options),
        data:chartData.slice(0,10),
        date:seasonDate,
    }
    return result
}

/*=====================个股百科-高管=========================*/
export function getExecutiveInfo(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleExecutiveInfo(fullData,options)
        resolve(finalData);
    });
}
function getExecutiveTitle(options){
    return options.secname+options.type+options.name
}

function handleExecutiveInfo(data,options){
    let eSeasonType
    if (data.stMainIndex.eSeasonType ==1 ){
        eSeasonType='一季报'
    } else if (data.stMainIndex.eSeasonType ==2){
        eSeasonType='中报'
    } else if (data.stMainIndex.eSeasonType ==3){
        eSeasonType='三季报'
    } else if (data.stMainIndex.eSeasonType ==4){
        eSeasonType='年报'
    }
    let seasonDate = data.stMainIndex.sYear+eSeasonType
    var Person=new Object();
    data.vtExecutive.map((item,index)=>{
        let chartData = [];
        chartData.push({a:"名称",b:item.sName});
        chartData.push({a:"年龄",b:item.sAge});
        chartData.push({a:"学历",b:item.sEdu});
        chartData.push({a:"角色",b:item.sBusiness});
        chartData.push({a:"任职时间",b:item.sTimeofOffice});
        chartData.push({a:"持股数量",b:item.sHoldNum});
        chartData.push({a:"薪酬",b:item.sPay});
        chartData.push({a:"简介",b:item.sIntroduce});
        Person[item.sName]=chartData
    })
    const result =  {
        pageTitle:getExecutiveTitle(options),
        data:Person[options.name],
        date:seasonDate,
    }
    return result
}

/*=====================个股百科-主营业务=========================*/
export function getMainBusiness(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleMainBusiness(fullData,options)
        resolve(finalData);
    });
}

function handleMainBusiness(data,options){
    let eSeasonType
    if (data.vMainBusinessByProduction[0].eSeasonType ==1 ){
        eSeasonType='一季报'
    } else if (data.vMainBusinessByProduction[0].eSeasonType ==2){
        eSeasonType='中报'
    } else if (data.vMainBusinessByProduction[0].eSeasonType ==3){
        eSeasonType='三季报'
    } else if (data.vMainBusinessByProduction[0].eSeasonType ==4){
        eSeasonType='年报'
    }
    let seasonDate = data.vMainBusinessByProduction[0].sYear+eSeasonType
    let chartData = [];
    data.vMainBusinessByProduction[0].vItem.map((item,index)=>{
        chartData.push({
            sProductName:item.sProductName,
            stOpeRevOccu:item.stOpeRevOccu.sDisplayValue,
            stOpeRev:item.stOpeRev.sDisplayValue,
            stOpCost:item.stOpCost.sDisplayValue
        })
    })
    const titleArr = ["名称","业务占比","营业收入", "营业成本"];
    chartData.unshift(titleArr);
    const result =  {
        pageTitle:getTitle(options),
        data:chartData,
        date:seasonDate,
    }
    return result
}

/*=====================个股百科-分红配送=========================*/
export function getDividendPayingPlacing(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handleDividendPayingPlacing(fullData),         
        }
        resolve(finalData);
    });
}

function handleDividendPayingPlacing(data){
    let chartData = [];
    data.vDividendPayingPlacing.map((item,index)=>{
        chartData.push({
            sYear:item.sYear,
            sDetail:item.sDetail,
            sDate:item.sDate,
        })
    })
    const titleArr = ["年度","方案","除权日"];
    chartData.unshift(titleArr);
    return  chartData.slice(0,9)
}


/*=====================个股百科-净利润行业对比=========================*/
export function getNetProfitCompare(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handleNetProfitCompare(fullData.vtIndustryCompare,options),   
            legend:["行业平均值"]       
        }
        resolve(finalData);
    });
}

function handleNetProfitCompare(data,options){
    let chartData = [];
    data[1].vtCompItem.map((item,index)=>{
        if (item.sName==""){
            chartData.push({
                label:options.secname,
                value1:item.dValue,
                value2:item.sValue,
            })           

        }
        else{
            chartData.push({
                label:item.iOrder+' '+item.sName,
                value1:item.dValue,
                value2:item.sValue,
            })
        }
    })
    return  chartData
}


/*=====================个股百科-营业收入行业对比=========================*/
export function getOperatingRevenueCompare(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handleOperatingRevenueCompare(fullData.vtIndustryCompare,options),   
            legend:["行业平均值"]   
        }
        resolve(finalData);
    });
}

function handleOperatingRevenueCompare(data,options){
    let chartData = [];
    data[0].vtCompItem.map((item,index)=>{
        if (item.sName==""){
            chartData.push({
                label:options.secname,
                value1:item.dValue,
                value2:(item.dValue/100000000).toFixed(2)+'亿元',
            })           

        }
        else{
            chartData.push({
                label:item.iOrder+' '+item.sName,
                value1:item.dValue,
                value2:(item.dValue/100000000).toFixed(2)+'亿元',
            })
        }

    })
    return  chartData
}

/*=====================个股百科-每股收益行业对比=========================*/
export function getEps(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handleEps(fullData.vtIndustryCompare,options),   
            legend:["行业平均值"]    
        }
        resolve(finalData);
    });
}

function handleEps(data,options){
    let chartData = [];
    data[2].vtCompItem.map((item,index)=>{
        if (item.sName==""){
            chartData.push({
                label:options.secname,
                value1:item.dValue,
                value2:(item.dValue).toFixed(2),
            })           

        }
        else{
            chartData.push({
                label:item.iOrder+' '+item.sName,
                value1:item.dValue,
                value2:(item.dValue).toFixed(2),
            })
        }
    })
    return  chartData
}

/*=====================个股百科-市盈率行业对比=========================*/
export function getPe(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyIntro(options);
            fullData = JSON.parse(fullData.data.content).stCompanyIntro;
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle:getTitle(options),
            data:handlePe(fullData.vtIndustryCompare,options),   
            legend:["行业平均值"]       
        }
        resolve(finalData);
    });
}

function handlePe(data,options){
    let chartData = [];
    data[3].vtCompItem.map((item,index)=>{
        if (item.sName==""){
            chartData.push({
                label:options.secname,
                value1:item.dValue,
                value2:(item.dValue).toFixed(2),
            })           

        }
        else{
            chartData.push({
                label:item.iOrder+' '+item.sName,
                value1:item.dValue,
                value2:(item.dValue).toFixed(2),
            })
        }
    })
    return  chartData
}

/*=====================个股百科-关系=========================*/
export function getRelationship(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxCompanyShareholder(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleRelationship(fullData,options)
        resolve(finalData);
    });
}
function getRelationshipTitle(options){
    return options.name+'和'+options.secname+options.type
}

function handleRelationship(data,options){
    let eSeasonType
    if (data.eSeasonType ==1 ){
        eSeasonType='一季报'
    } else if (data.eSeasonType ==2){
        eSeasonType='中报'
    } else if (data.eSeasonType ==3){
        eSeasonType='三季报'
    } else if (data.eSeasonType ==4){
        eSeasonType='年报'
    }
    let seasonDate = data.sYear+eSeasonType
    var Person=new Object();
    data.vShareholderItem.map((item,index)=>{
        let chartData = [];
        chartData.push({a:"投资额",b:item.stInvAmount.sDisplayValue});
        chartData.push({a:"持股",b:item.stShaRatio.sDisplayValue});
        chartData.push({a:"注册资本",b:item.stRegCap.sDisplayValue,c:"参股关系",d:item.sRelation});
        chartData.push({a:"上市/挂牌",b:item.sListed,c:"所属行业",d:item.sMaiBus});
        chartData.push({a:"营业收入",b:item.stOpeInc.sDisplayValue,c:"净利润",d:item.stNetPro.sDisplayValue});
        Person[item.sShaHolCom]=chartData
    })
    const result =  {
        pageTitle:getRelationshipTitle(options),
        secname:options.secname,
        companyname:options.name,
        ship:Person[options.name].slice(0,2),
        data:Person[options.name].slice(2),
        date:seasonDate,
    }
    return result
}


/*=====================个股百科-股本结构=========================*/
export function getCapitalStructure(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxGetSecInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleCapitalStructure(fullData,options)
        resolve(finalData);
    });
}

function handleCapitalStructure(data,options){
    let eSeasonType
    if (data.sTopHolderDate.slice(5) =='03-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'一季报'
    } else if (data.sTopHolderDate.slice(5) =='06-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'中报'
    } else if (data.sTopHolderDate.slice(5) =='09-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'三季报'
    } else if (data.sTopHolderDate.slice(5) =='12-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'年报'
    }
    let chartData = [];
    data.stCapitalStructure.vCapitalStructureDetail.reverse().map((item,index)=>{
        chartData.push({
            label:item.sDate,
            line1:1*(item.iShareholderNumber).toFixed(2),
            line2:1*(item.fPerCapitaHoldings/10000).toFixed(2)
        });
    })
    let tableData = [];
    tableData.push({a:"总股本",b:data.stCapitalStructure.sFloatShare});
    tableData.push({a:"流通A股",b:data.stCapitalStructure.sCirculationStock});
    tableData.push({a:"股东人数",b:data.stCapitalStructure.sShareholderNumber});
    tableData.push({a:"人均持股",b:data.stCapitalStructure.sPerCapitaHoldings});
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,
        date:eSeasonType,
        tableData:tableData,    
        legend:['股东人数','人均持股']
    }
    return result
}

async function ajaxGetSecInfo(options){
    return await axios.get("https://sec.wedengta.com/getSecInfo?action=company&seccode="+options.seccode)
    
}
/*=====================个股百科-十大流通股东=========================*/
export function getTopShareholder(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxGetSecInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        
        const finalData = handleTopShareholder(fullData,options)
        resolve(finalData);
    });
}

function handleTopShareholder(data,options){
    let eSeasonType
    if (data.sTopHolderDate.slice(5) =='03-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'一季报'
    } else if (data.sTopHolderDate.slice(5) =='06-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'中报'
    } else if (data.sTopHolderDate.slice(5) =='09-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'三季报'
    } else if (data.sTopHolderDate.slice(5) =='12-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'年报'
    }
    let chartData = [];
    data.vTopShareholder.map((item,index)=>{
        chartData.push({
            sName:item.sName,
            sRatio:item.sRatio,
            sChangeDetail:item.sChangeDetail
        });
    })
    const titleArr = ["股东","占比","异动"];
    chartData.unshift(titleArr);   
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,
        date:eSeasonType
    }
    return result
}

/*=====================个股百科-十大股东=========================*/
export function getTopholder(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxGetSecInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleTopholder(fullData,options)
        resolve(finalData);
    });
}

function handleTopholder(data,options){
    let eSeasonType
    if (data.sTopHolderDate.slice(5) =='03-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'一季报'
    } else if (data.sTopHolderDate.slice(5) =='06-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'中报'
    } else if (data.sTopHolderDate.slice(5) =='09-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'三季报'
    } else if (data.sTopHolderDate.slice(5) =='12-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'年报'
    }
    let chartData = [];
    data.vTopHolder.map((item,index)=>{
        chartData.push({
            sName:item.sName,
            sRatio:item.sRatio,
            sChangeDetail:item.sChangeDetail
        });
    })
    const titleArr = ["股东","占比","异动"];
    chartData.unshift(titleArr);  
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,
        date:eSeasonType
    }
    return result
}

/*=====================个股百科-主力持仓=========================*/
export function getMainHolder(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxGetSecInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleMainHolder(fullData,options)
        resolve(finalData);
    });
}

function handleMainHolder(data,options){
    let eSeasonType
    if (data.sTopHolderDate.slice(5) =='03-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'一季报'
    } else if (data.sTopHolderDate.slice(5) =='06-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'中报'
    } else if (data.sTopHolderDate.slice(5) =='09-30'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'三季报'
    } else if (data.sTopHolderDate.slice(5) =='12-31'){
        eSeasonType=data.sTopHolderDate.slice(0,4)+'年报'
    }
    let chartData = [];
    data.stMainHolder.vtMainHolderDetail.reverse().map((item,index)=>{
        chartData.push({
            label:item.sDateDesc,
            bar:item.fPrice.toFixed(1),
            line:item.fRate.toFixed(1)
        });
    })
    let tableData = [];
    tableData.push({a:"机构数量",b:data.stMainHolder.iHolders});
    tableData.push({a:"累计持股",b:data.stMainHolder.fTotalCount.toFixed(2)});
    tableData.push({a:"较上期变化",b:data.stMainHolder.fChange.toFixed(2)});
    tableData.push({a:"占总股本比例",b:data.stMainHolder.fRate.toFixed(2)+'%'});
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,
        date:eSeasonType,
        tableData:tableData,  
        legend:['股价(元)','机构持股比例']
    }
    return result
}


/*=====================个股百科-经营状况概述=========================*/
export function getOperateDesc(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxOperateAnalyze(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleOperateDesc(fullData,options)
        resolve(finalData);
    });
}

function handleOperateDesc(data,options){
    let chartData = [];
    data.vOperateDesc.map((item,index)=>{
        chartData.push({
            re:item
        });
    })
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData.slice(0,5)
    }
    return result
}


async function ajaxOperateAnalyze(options){
    return await axios.post("https://comm.wedengta.com/?s=StockEncyclopedia&f=getOperateAnalyze&req=OperateAnalyzeReq&rsp=OperateAnalyzeRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}

/*=====================个股百科-收入和利润情况=========================*/
export function getIncomeProfit(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxOperateAnalyze(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleIncomeProfit(fullData,options)
        resolve(finalData);
    });
}

function handleIncomeProfit(data,options){
    let chartData = [];
    for(var obj in data.mIncomeProfitData){
        chartData.push({
            value1:1*(data.mIncomeProfitData[obj].fIncome/100000000).toFixed(2),
            value2:1*(data.mIncomeProfitData[obj].fProfit/100000000).toFixed(2),
            label:obj.slice(0,4)
        })
    }
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,        
        legend:['收入','归母净利润'],
        desc:"单位:亿元",
    }
    return result
}
/*=====================个股百科-资产负债情况=========================*/
export function getAssetsDebt(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxOperateAnalyze(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleAssetsDebt(fullData,options)
        resolve(finalData);
    });
}

function handleAssetsDebt(data,options){
    let chartData = [];
    for(var obj in data.mAssetsDebtData){
        chartData.push({
            value1:1*(data.mAssetsDebtData[obj].fAssets/100000000).toFixed(1),
            value2:1*(data.mAssetsDebtData[obj].fDebt/100000000).toFixed(1),
            value3:1*(data.mAssetsDebtData[obj].fDebtRatio).toFixed(1),
            label:obj.slice(0,4)
        })
    }
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,        
        legend:['总资产(亿元)','总负债(亿元)','资产负债率(%)'],
    }
    return result
}
/*=====================个股百科-现金净流量=========================*/
export function getCashNetFlow(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxOperateAnalyze(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleCashNetFlow(fullData,options)
        resolve(finalData);
    });
}

function handleCashNetFlow(data,options){
    let chartData = [];
    for(var obj in data.mCashNetFlowData){
        chartData.push({
            value4:1*(data.mCashNetFlowData[obj].fCashEquivalent/100000000).toFixed(2),
            value3:1*(data.mCashNetFlowData[obj].fFinancingCashFlow/100000000).toFixed(2),
            value2:1*(data.mCashNetFlowData[obj].fInvestCashFlow/100000000).toFixed(2),
            value1:1*(data.mCashNetFlowData[obj].fOperateCashFlow/100000000).toFixed(2),
            label:obj.slice(0,4)
        })
    }
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,        
        legend:['现金净流量','投资现金流','融资现金流','期末现金及等价物'],
        desc:"单位:亿元",
    }
    return result
}
/*=====================个股百科-行业规模=========================*/
export function getIndustryScale(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxOperateAnalyze(options);
            fullData = JSON.parse(fullData.data.content);

            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleIndustryScale(fullData,options)
        resolve(finalData);
    });
}

function handleIndustryScale(data,options){
    let chartData = [];
    for(var obj in data.mIndustryScaleData){
        chartData.push({
            value:1*(data.mIndustryScaleData[obj]/100000000).toFixed(2),
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options),        
        data:chartData,
        desc:'单位:亿元'
    }
    return result
}


/*=====================个股百科-员工人数和营业收入=========================*/
export function getStaffCountAndIncome(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxWorkEfficiency(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleStaffCountAndIncome(fullData,options)
        resolve(finalData);
    });
}

function handleStaffCountAndIncome(data,options){
    let chartData = [];
    for(var obj in data.mStaffCountData){
        chartData.push({
            line1:data.mStaffCountData[obj],
            line2:1*(data.mIncomeData[obj]/100000000).toFixed(2),
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['在职员工人数','主营收入(亿元)'],      
        data:chartData
    }
    return result
}

async function ajaxWorkEfficiency(options){
    return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getWorkEfficiencyData&req=WorkEfficiencyReq&rsp=WorkEfficiencyRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}


/*=====================个股百科-员工分析=========================*/
export function getStaffAnalyze(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffAnalyze(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleStaffAnalyze(fullData,options)
        resolve(finalData);
    });
}

function handleStaffAnalyze(data,options){
    let workEffTxt;
    let staffStabTxt;
    let talentIntroTxt;
    let integrityTxt;
    if(data.fScore_WorkEff>=80){
        workEffTxt='高';
    }else if(data.fScore_WorkEff<80 && data.fScore_WorkEff>=70){
        workEffTxt='较高';
    }else if(data.fScore_WorkEff<70 && data.fScore_WorkEff>=60){
        workEffTxt='一般';
    }else if(data.fScore_WorkEff<60){
        workEffTxt='低';
    }
    if(data.fScore_StaffStab>=80){
        staffStabTxt='强';
    }else if(data.fScore_StaffStab<80 && data.fScore_StaffStab>=70){
        staffStabTxt='较强';
    }else if(data.fScore_StaffStab<70 && data.fScore_StaffStab>=60){
        staffStabTxt='一般';
    }else if(data.fScore_StaffStab<60){
        staffStabTxt='低';
    }
    if(data.fScore_TalentIntro>=75){
        talentIntroTxt='持续优化';
    }else if(data.fScore_TalentIntro<75 && data.fScore_TalentIntro>=60){
        talentIntroTxt='有一定优化';
    }else if(data.fScore_TalentIntro<60){
        talentIntroTxt='没有得到优化';
    }   
    if(data.fScore_Integrate>=80){
        integrityTxt='好';
    }else if(data.fScore_Integrate<80 && data.fScore_Integrate>=70){
        integrityTxt='较好';
    }else if(data.fScore_Integrate<70 && data.fScore_Integrate>=60){
        integrityTxt='一般';
    }else if(data.fScore_Integrate<60){
        integrityTxt='差';
    }

    var descArr=[
        workEffTxt,
        staffStabTxt,
        talentIntroTxt,
        integrityTxt,
        data.fBetterThan.toFixed(0),
    ]
    var descScore=[
        data.fScore_WorkEff,
        data.fScore_StaffStab,
        data.fScore_TalentIntro,
    ]
    let chartData=[];

    chartData.push({ label: "", value1: data.fScore_WorkEff})
    chartData.push({ label: "", value1: data.fScore_StaffStab })
    chartData.push({ label: "", value1: data.fScore_TalentIntro})

    const result =  {
        pageTitle:getTitle(options), 
        desc1:descArr,
        desc:"综合评分："+data.fScore_Integrate,
        data:chartData,
        descScore:descScore
    }
    return result
}

async function ajaxStaffAnalyze(options){
    return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffAnalyzeScore&req=StaffAnalyzeScoreReq&rsp=StaffAnalyzeScoreRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}


/*=====================个股百科-人均营业收入=========================*/
export function getIncomeAve(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxWorkEfficiency(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        
        const finalData = handleIncomeAve(fullData,options)
        resolve(finalData);
    });
}

function handleIncomeAve(data,options){
    let chartData = [];
    for(var obj in data.mIncomeAveData){
        chartData.push({
            value1:1*(data.mIncomeAveData[obj].fIncomeAve/10000).toFixed(2),
            value2:1*(data.mIncomeAveData[obj].fIncomeIndustryAVe/10000).toFixed(2),
            iIndustryRank:data.mIncomeAveData[obj].iIndustryRank,
            iCompanyTotalCount:data.mIncomeAveData[obj].iCompanyTotalCount,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['人均营业收入','行业均值'],  
        desc1:'人均营业收入：', 
        desc2:'行业排名：',
        desc3:'万',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,
        value1:chartData[chartData.length-1].value1, 
        data:chartData
    }
    return result
}

/*=====================个股百科-人均营业利润=========================*/
export function getProfitAve(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxWorkEfficiency(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleProfitAve(fullData,options)
        resolve(finalData);
    });
}

function handleProfitAve(data,options){
    let chartData = [];
    for(var obj in data.mProfitAveData){
        chartData.push({
            value1:1*(data.mProfitAveData[obj].fProfitAve/10000).toFixed(2),
            value2:1*(data.mProfitAveData[obj].fProfitIndustryAVe/10000).toFixed(2),
            iIndustryRank:data.mProfitAveData[obj].iIndustryRank,
            iCompanyTotalCount:data.mProfitAveData[obj].iCompanyTotalCount,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['人均营业利润','行业均值'],   
        desc1:'人均营业利润：', 
        desc2:'行业排名：',
        desc3:'万',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,
        value1:chartData[chartData.length-1].value1, 
        data:chartData
    }
    return result
}


/*=====================个股百科-普通员工薪酬概况=========================*/
export function getCommStaffSalaryAve(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStability(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleCommStaffSalaryAve(fullData,options)
        resolve(finalData);
    });
}

function handleCommStaffSalaryAve(data,options){
    let chartData = [];
    for(var obj in data.mCommStaffSalaryAveData){
        chartData.push({
            value1:1*(data.mCommStaffSalaryAveData[obj].fSalaryAve/10000).toFixed(2),
            value2:1*(data.mCommStaffSalaryAveData[obj].fSalaryIndustryAVe/10000).toFixed(2),
            iIndustryRank:data.mCommStaffSalaryAveData[obj].iIndustryRank,
            iCompanyTotalCount:data.mCommStaffSalaryAveData[obj].iCompanyTotalCount, 
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['普通员工人均收入','行业均值'],  
        desc1:'普通员工人均收入：', 
        desc2:'行业排名：',
        desc3:'万',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,  
        value1:chartData[chartData.length-1].value1,  
        data:chartData
    }
    return result
}

async function ajaxStaffStability(options){
    return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStabilityData&req=StaffStabilityReq&rsp=StaffStabilityRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}

/*=====================个股百科-高管薪酬概况=========================*/
export function getSeniorStaffSalaryAve(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStability(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleSeniorStaffSalaryAve(fullData,options)
        resolve(finalData);
    });
}

function handleSeniorStaffSalaryAve(data,options){
    let chartData = [];
    for(var obj in data.mSeniorStaffSalaryAveData){
        chartData.push({
            value1:1*(data.mSeniorStaffSalaryAveData[obj].fSalaryAve/10000).toFixed(2),
            value2:1*(data.mSeniorStaffSalaryAveData[obj].fSalaryIndustryAVe/10000).toFixed(2),
            iIndustryRank:data.mSeniorStaffSalaryAveData[obj].iIndustryRank,
            iCompanyTotalCount:data.mSeniorStaffSalaryAveData[obj].iCompanyTotalCount, 
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['高管人均收入','行业均值'],
        desc1:'高管人均收入：', 
        desc2:'行业排名：', 
        desc3:'万',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,  
        value1:chartData[chartData.length-1].value1,  
        data:chartData
    }
    return result
}

/*=====================个股百科-员工增长情况=========================*/
export function getStaffGrowthRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStability(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleStaffGrowthRatio(fullData,options)
        resolve(finalData);
    });
}

function handleStaffGrowthRatio(data,options){
    let chartData = [];
    for(var obj in data.mStaffGrowthRatioData){
        chartData.push({
            value1:1*(data.mStaffGrowthRatioData[obj].fGrowthRatio).toFixed(2),
            value2:data.mStaffGrowthRatioData[obj].fIndustryAve,
            iIndustryRank:data.mStaffGrowthRatioData[obj].iIndustryRank,
            iCompanyTotalCount:data.mStaffGrowthRatioData[obj].iCompanyTotalCount, 
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['员工人数增长率','行业均值'], 
        desc1:'员工人数增长率：', 
        desc2:'行业排名：', 
        desc3:'%',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,  
        value1:chartData[chartData.length-1].value1,  
        data:chartData
    }
    return result
}


/*=====================个股百科-员工学历构成========================*/
export function getStaffEducation(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleStaffEducation(fullData,options)
        resolve(finalData);
    });
}

function handleStaffEducation(data,options){
    let chartData = [];    
    let typeData ={1:'博士',2:'硕士',3:'本科',4:'大专',5:'大专以下'}
    for(var obj in data.mStaffEducationData){
        chartData.push({
            value1:data.mStaffEducationData[obj][1],
            value2:data.mStaffEducationData[obj][2],
            value3:data.mStaffEducationData[obj][3],
            value4:data.mStaffEducationData[obj][4],
            value5:data.mStaffEducationData[obj][5],
            label:obj.slice(0,7)
        })
    }
    for(var i=0;i<chartData.length;i++){
        if(chartData[i].value1==undefined){
            chartData[i].value1=0
        }
        if(chartData[i].value2==undefined){
            chartData[i].value2=0
        }       
        if(chartData[i].value3==undefined){
            chartData[i].value3=0
        }
        if(chartData[i].value4==undefined){
            chartData[i].value4=0
        }
        if(chartData[i].value5==undefined){
            chartData[i].value5=0
        }
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['博士','硕士','本科','大专','大专以下'],  
        data:chartData
    }
    return result
}

async function ajaxStaffStruct(options){
    return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStructData&req=StaffStructReq&rsp=StaffStructRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}

/*=====================个股百科-员工职业构成========================*/
export function getStaffJob(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleStaffJob(fullData,options)
        resolve(finalData);
    });
}

function handleStaffJob(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobData){
        chartData.push({
            value1:data.mStaffJobData[obj][1],
            value2:data.mStaffJobData[obj][2],
            value3:data.mStaffJobData[obj][3],
            value4:data.mStaffJobData[obj][4],
            value5:data.mStaffJobData[obj][5],
            value6:data.mStaffJobData[obj][6],
            label:obj.slice(0,7)
        })
    }
    
    for(var i=0;i<chartData.length;i++){
        if(chartData[i].value1==undefined){
            chartData[i].value1=0
        }
        if(chartData[i].value2==undefined){
            chartData[i].value2=0
        }       
        if(chartData[i].value3==undefined){
            chartData[i].value3=0
        }
        if(chartData[i].value4==undefined){
            chartData[i].value4=0
        }
        if(chartData[i].value5==undefined){
            chartData[i].value5=0
        }
        if(chartData[i].value6==undefined){
            chartData[i].value6=0
        }
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['生产人员','销售人员','技术人员','财务人员','行政人员','其他人员'],  
        data:chartData
    }
    return result
}


/*=====================个股百科-本科员工比例=======================*/
export function getBechelorData(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxTalentIntroduce(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleBechelorData(fullData,options)

        resolve(finalData);
    });
}

function handleBechelorData(data,options){
    let chartData = [];    
    for(var obj in data.mBechelorData){
        chartData.push({
            value1:1*(data.mBechelorData[obj].fRatio).toFixed(2),
            value2:data.mBechelorData[obj].fIndustryRatioAve,
            iIndustryRank:data.mBechelorData[obj].iIndustryRank,
            iCompanyTotalCount:data.mBechelorData[obj].iCompanyTotalCount,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['本科学历员工占比','行业均值'],  
        desc1:'本科学历员工占比：', 
        desc2:'行业排名：', 
        desc3:'%',
        data:chartData,
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,
        value1:chartData[chartData.length-1].value1
    }

    return result
}

async function ajaxTalentIntroduce(options){
    return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getTalentIntroduceData&req=TalentIntroduceReq&rsp=TalentIntroduceRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
    }))
    
}


/*=====================个股百科-博士员工人数=======================*/
export function getDoctorData(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxTalentIntroduce(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleDoctorData(fullData,options)

        resolve(finalData);
    });
}

function handleDoctorData(data,options){
    let chartData = [];    
    for(var obj in data.mDoctorData){
        chartData.push({
            value:data.mDoctorData[obj].iSatffCount,
            iIndustryRank:data.mMasterData[obj].iIndustryRank,
            iCompanyTotalCount:data.mMasterData[obj].iCompanyTotalCount,
            label:obj.slice(0,4)
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        data:chartData,
        desc1:'博士人员人数：', 
        desc2:'行业排名：', 
        desc3:'人',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,
        iSatffCount:chartData[chartData.length-1].value
    }
    return result
}

/*=====================个股百科-硕士员工人数=======================*/
export function getMasterData(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxTalentIntroduce(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleMasterData(fullData,options)
        resolve(finalData);
    });
}

function handleMasterData(data,options){
    let chartData = [];    
    for(var obj in data.mMasterData){
        chartData.push({
            value:data.mMasterData[obj].iSatffCount,
            iIndustryRank:data.mMasterData[obj].iIndustryRank,
            iCompanyTotalCount:data.mMasterData[obj].iCompanyTotalCount,
            label:obj.slice(0,4)
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        data:chartData,
        desc1:'硕士人员人数：', 
        desc2:'行业排名：', 
        desc3:'人',
        iIndustryRank:chartData[chartData.length-1].iIndustryRank,
        iCompanyTotalCount:chartData[chartData.length-1].iCompanyTotalCount,
        iSatffCount:chartData[chartData.length-1].value
    }
    return result
}

/*=====================个股百科-生产岗员工占比=======================*/
export function getProductRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleProductRatio(fullData,options)
        resolve(finalData);
    });
}

function handleProductRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][1].fRatio,
            value2:data.mStaffJobRatioData[obj][1].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['生产占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-销售岗员工占比=======================*/
export function getSaleRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleSaleRatio(fullData,options)
        resolve(finalData);
    });
}

function handleSaleRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][2].fRatio,
            value2:data.mStaffJobRatioData[obj][2].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['销售占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-技术岗员工占比=======================*/
export function getTechRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleTechRatio(fullData,options)
        resolve(finalData);
    });
}

function handleTechRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][3].fRatio,
            value2:data.mStaffJobRatioData[obj][3].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['技术占比','行业均值'],  
        data:chartData
    }
    return result
}


/*=====================个股百科-财务岗员工占比=======================*/
export function getTinancialRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleTinancialRatio(fullData,options)
        resolve(finalData);
    });
}

function handleTinancialRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][4].fRatio,
            value2:data.mStaffJobRatioData[obj][4].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['财务占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-行政岗员工占比=======================*/
export function getAdministratorRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleAdministratorRatio(fullData,options)
        resolve(finalData);
    });
}

function handleAdministratorRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][5].fRatio,
            value2:data.mStaffJobRatioData[obj][5].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['行政占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-其他岗员工占比=======================*/
export function getOtherRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleOtherRatio(fullData,options)
        resolve(finalData);
    });
}

function handleOtherRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffJobRatioData){
        chartData.push({
            value1:data.mStaffJobRatioData[obj][6].fRatio,
            value2:data.mStaffJobRatioData[obj][6].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['其他占比','行业均值'],  
        data:chartData
    }
    return result
}


/*=====================个股百科-博士员工学历占比=======================*/
export function getDoctorEducationRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleDoctorEducationRatio(fullData,options)
        resolve(finalData);
    });
}

function handleDoctorEducationRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffEducationRatioData){
        chartData.push({
            value1:data.mStaffEducationRatioData[obj][1].fRatio,
            value2:data.mStaffEducationRatioData[obj][1].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['博士员工学历占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-硕士员工学历占比=======================*/
export function getMasterEducationRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleMasterEducationRatio(fullData,options)
        resolve(finalData);
    });
}

function handleMasterEducationRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffEducationRatioData){
        chartData.push({
            value1:data.mStaffEducationRatioData[obj][2].fRatio,
            value2:data.mStaffEducationRatioData[obj][2].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['硕士员工学历占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-本科员工学历占比=======================*/
export function getBechelorEducationRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleBechelorEducationRatio(fullData,options)
        resolve(finalData);
    });
}

function handleBechelorEducationRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffEducationRatioData){
        chartData.push({
            value1:data.mStaffEducationRatioData[obj][3].fRatio,
            value2:data.mStaffEducationRatioData[obj][3].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['本科员工学历占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-大专员工学历占比=======================*/
export function getCollegeEducationRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleCollegeEducationRatio(fullData,options)
        resolve(finalData);
    });
}

function handleCollegeEducationRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffEducationRatioData){
        chartData.push({
            value1:data.mStaffEducationRatioData[obj][4].fRatio,
            value2:data.mStaffEducationRatioData[obj][4].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['大专员工学历占比','行业均值'],  
        data:chartData
    }
    return result
}

/*=====================个股百科-大专以下员工学历占比=======================*/
export function getBelowEducationRatio(options){
    return new Promise(async (resolve)=>{
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxStaffStruct(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = handleBelowEducationRatio(fullData,options)
        resolve(finalData);
    });
}

function handleBelowEducationRatio(data,options){
    let chartData = [];    
    for(var obj in data.mStaffEducationRatioData){
        chartData.push({
            value1:data.mStaffEducationRatioData[obj][5].fRatio,
            value2:data.mStaffEducationRatioData[obj][5].fIndustryAve,
            label:obj
        })
    }
    const result =  {
        pageTitle:getTitle(options), 
        legend:['大专以下员工学历占比','行业均值'],  
        data:chartData
    }
    return result
}