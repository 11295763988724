<template>
    <div class="tool_power">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <div class="tool_three_stock">
                <PowerStock :data="activeWindowCustomChartMsg" @changePowerStock="changePowerStock" />
            </div>
            <div class="tool_three_key" v-show="tool_filter_one">
                <h3>名称</h3>
                <el-select v-model="tool_filter_two" filterable size="medium" placeholder="请选择" @change="changePowerKey()">
                    <el-option
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="tool_three_type" v-show="this.tool_filter_one.length === 1">
                <h3>筛选条件</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changePower()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterArr"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import PowerStock from '../components/PowerStock/index.vue';
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            filterArr: [{
                label: '同比',
                value: '同比',
            },{
                label: '环比',
                value: '环比',
            },{
                label: '历史一季报',
                value: '历史一季报',
            },{
                label: '历史三季报',
                value: '历史三季报',
            },{
                label: '历史中报',
                value: '历史中报',
            },{
                label: '历史年报',
                value: '历史年报',
            }],
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    components: {
        PowerStock
    },
    methods: {
        changePowerStock(value) {
            this.tool_filter_one = value
            switch (this.activeWindowCustomChartMsg.materialName) {
                case '个股财报':
                    this.tool_filter_two = '每股指标'
                    this.tool_filter_three = '同比'
                    break;
                 case '财报比对':
                    this.tool_filter_two = '每股指标'
                    this.tool_filter_three = '最新报'
                    break;
                default:
                    this.tool_filter_two = undefined
                    this.tool_filter_three = undefined
                    break;
            }
            this.changePower()
        },
        changePowerKey() {
            switch (this.activeWindowCustomChartMsg.materialName) {
                case '个股财报':
                    this.tool_filter_three = '同比'
                    break;
                 case '财报比对':
                    this.tool_filter_three = '最新报'
                    break;
                default:
                    this.tool_filter_three = undefined
                    break;
            }
            this.changePower()
        },
        changePower() {
            this.$emit('setCustomWindowChart', {
                    ...this.activeWindowCustomChartMsg,
                    remark: JSON.stringify({
                        compareKey: this.tool_filter_two,
                        compareStocks: this.tool_filter_one,
                        compareType: this.tool_filter_three,
                    })
                }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.map(item => item.secname).join('与')}-${this.tool_filter_two}-${this.tool_filter_three}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            compareKey: this.tool_filter_two,
                            compareStocks: this.tool_filter_one,
                            compareType: this.tool_filter_three,
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    },
}
</script>
<style lang="less" scoped>
.tool_power {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            margin-right: 30px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
        }
        /deep/ .el-radio-group {
            display: flex;
            flex-wrap: wrap;
            .el-radio-button__inner {
                background: #2E2F30;
                color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                border: #2E2F30;
                margin-bottom: 4px;
                margin-right: 4px;
            }
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background: #767676;
            border: #767676;
            color: #fff;
            box-shadow: unset
        }
        /deep/ .el-select .el-input.is-focus .el-input__inner {
            border-color: rgba(255, 255, 255, 0.08);
        }
        /deep/ .el-input__inner {
            background: #202020;
            border-color: rgba(255, 255, 255, 0.08);
            color: #fff;
        }
    }
}
</style>