<template>
    <el-dialog
        :visible.sync="visible"
        title="OBS直播操作流程"
        :close-on-click-modal="false"
        custom-class="obs_dialog"
        width="30vw"
        @close="close"
        append-to-body
    >
        <div class="obs_content">
            <div>
                <el-button type="primary" @click="copy">复制地址</el-button>
                <el-button type="primary" @click="previewObs">连接OBS</el-button>
            </div>
            <ul>
                <li>第一步：点击“复制地址”并在obs中打开（添加浏览器来源） </li>
                <li>第二步：点击“连接OBS”</li>
            </ul>
            <p>点击 <i class="el-icon-question" @click="useHelp" /> 查看直播使用帮助</p>
        </div>
    </el-dialog>
</template>
<script>
import { delCookie, getCookie, copyText, GET_URL_PARAMS } from "@/utils/utils.js";
export default {
    data: function() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            Object.assign(this.$data, this.$options.data())
        },
        copy() {
            const { obsCode } = GET_URL_PARAMS(JSON.parse(getCookie('user')).obsUrl)
            let url = `${location.origin}/formal/receive?obsCode=${obsCode}`
            copyText(url).then(() => {
                this.$message({ showClose: true, type: 'success', message: `复制成功`})
            })
        },
        previewObs() {
            this.$emit('previewObs')
        },
        useHelp() {
            window.open('https://tanzhiying.feishu.cn/docs/doccnFMPlcbDIn7dq21Gj3smBGh')
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .obs_dialog {
    background: rgb(32,32,32);
    .el-dialog__header {
        border-bottom: 1px solid #262626;
        .el-dialog__title {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .el-dialog__body {
        padding: 8px 80px 24px;
        .obs_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            > div {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin: 20px 0;
            }
            > ul {
                > li {
                    margin-bottom: 6px;
                }
            }
            > p {
                margin-top: 24px;
                i {
                    cursor: pointer;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>