import axios from "axios"
import { options } from "less";

let tempHistory = {};//某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getDuPonData(options){
    console.log(options)
    const typeObj = {
        "ROE详情": "getAnalysisPic",
        "ROE相关历史数据": "getRoeRelationHistory",
        "排名": "getIndustryScatter",
        "走势详情": "getStockYearChange"
      };
      const functions = {
        getAnalysisPic,
        getRoeRelationHistory,
        getIndustryScatter,
        getStockYearChange,
      };
      return functions[typeObj[options.type]](options);
}

/*=====================ROE详情=========================*/
export function getAnalysisPic(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxAnalysisPic(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleAnalysisPic(fullData),
        };
        resolve(finalData);
    });
  }

function getTitle(options){
    return options.secname+options.type
}

function handleAnalysisPic(data){
    let chartData = {};
    chartData ={
        name: `<div>ROE</div><div>${data.stStockDuPonAnalysisPic.fRoe.toFixed(2)+'%'}</div>`,
        children: [
            {   
                name: `<div>权益乘数</div><div>${data.stStockDuPonAnalysisPic.fEquityMultipler.toFixed(2)+'%'}</div>`,
                children: [
                    {   name: `<div>资产负债率</div><div>${data.stStockDuPonAnalysisPic.fDebtAssetsRatio.toFixed(2)+'%'}</div>`,
                        children: [
                            {   name: `<div>流动比</div><div>${data.stStockDuPonAnalysisPic.fCurrentRatio.toFixed(2)}</div>`                                  
                            },
                            {   name: `<div>速动比</div><div>${data.stStockDuPonAnalysisPic.fQuickRatio.toFixed(2)}</div>`                                   
                            },                                    
                            {   name: `<div>利息保障倍数</div><div>${data.stStockDuPonAnalysisPic.fARTRate.toFixed(2)+'%'}</div>`                                 
                            },
                        ]
                    },
                ]
            },
            { 
                name: `<div>资产周转率</div><div>${data.stStockDuPonAnalysisPic.fTotalAssetTRate.toFixed(2)+'%'}</div>`,
                children: [
                    {   name: `<div>流动资产周转率</div><div>${data.stStockDuPonAnalysisPic.fCurrentAssetsToTA.toFixed(2)+'%'}</div>`,
                        children: [
                            {   name: `<div>应收账款周转率</div><div>${data.stStockDuPonAnalysisPic.fInterestCover.toFixed(2)}</div>`
                            },
                            {   name: `<div>存货周转率</div><div>${data.stStockDuPonAnalysisPic.fInventoryTRate.toFixed(2)+'%'}</div>`
                            }
                        ]
                    },
                    {   name: `<div>非流动资产周转率</div><div>${data.stStockDuPonAnalysisPic.fNonCurrentAssetsToTA.toFixed(2)+'%'}</div>`
                    },
                ]
            },
            { 
                name: `<div>净利率</div><div>${data.stStockDuPonAnalysisPic.fNetProfitRatio.toFixed(2)+'%'}</div>`,
                children: [
                    {   name: `<div>毛利率</div><div>${data.stStockDuPonAnalysisPic.fOperatingProfitRatio.toFixed(2)+'%'}</div>`
                    },
                    {   name: `<div>营业利润率</div><div>${data.stStockDuPonAnalysisPic.fGrossIncomeRatio.toFixed(2)+'%'}</div>`
                    },
                ]
            }
        ]
    }

    
    return chartData
}


/*=====================ROE 相关历史数据=========================*/
export function getRoeRelationHistory(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.seccode+options.type]
        if (!fullData){
            fullData = await ajaxAnalysisPic(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type] = fullData;
        }

        const finalData = {
            pageTitle: getTitle(options),
            data:handleRoeRelationHistory(fullData),
        };
        resolve(finalData);
    });
  }

function handleRoeRelationHistory(data){
    let chartData = [];
    data.stRoeRelationHistoryHome.vDtDate.map((item,index)=>{
        chartData.push({
            vDtDate:item.replace(/\-/g,''),
            vRoe:data.stRoeRelationHistoryHome.vRoe[index].toFixed(2)+'%',
            vRoa:data.stRoeRelationHistoryHome.vRoa[index].toFixed(2)+'%',
            vNetProfitRatio:data.stRoeRelationHistoryHome.vNetProfitRatio[index].toFixed(2)+'%',
            vEquityMultipler:data.stRoeRelationHistoryHome.vEquityMultipler[index].toFixed(2),
            vTotalAssetTRate:data.stRoeRelationHistoryHome.vTotalAssetTRate[index].toFixed(2)+'%',
        })
    })
    const titleArr = ["时间","ROE","ROA", "净利率","权益乘数", "总资产周转率"];
    chartData.unshift(titleArr);
    return chartData
}

/*=====================排名=========================*/
export function getIndustryScatter(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.seccode+options.type+options.subType]
        let IndustryCodeData = JSON.parse((await ajaxStockYearChange(options)).data.content).vIndustryScatter[0];
        if (!fullData){            
            fullData = await ajaxIndustryScatter({IndustryCode:IndustryCodeData.sStockCode,IndustryName:IndustryCodeData.sStockName},options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getIndustryTitle(options,IndustryCodeData),
            data:handleIndustryScatter(fullData,options),
        };
        resolve(finalData);
    });
  }

  function getIndustryTitle(options,IndustryCodeData){
    const range2Obj = {
        part1: "流动比",
        part2: "速动比",
        part3: "利息保障倍数",
        part4: "资产负债率",
        part5: "权益乘数",
        part6: "应收账款周转率",
        part7: "存货周转率",
        part8: "流动资产周转率",
        part9: "非流动资产周转率",
        part10: "资产周转率",
        part11: "营业利润率",
        part12: "毛利率",
        part13: "净利率",
        part14: "ROE",
      };
    return IndustryCodeData.sStockName+"行业"+range2Obj[options.subType]+options.type
}


function handleIndustryScatter(data,options){
    let chartData = [];
    data.vIndustryScatter.map((item,index)=>{
        chartData.push({
            iIndex:item.iIndex,
            sStockName:item.sStockName,
            fValue: item.fValue.toFixed(2)+'%'
        })
    })
    const range2Obj = {
        part1: "流动比",
        part2: "速动比",
        part3: "利息保障倍数",
        part4: "资产负债率",
        part5: "权益乘数",
        part6: "应收账款周转率",
        part7: "存货周转率",
        part8: "流动资产周转率",
        part9: "非流动资产周转率",
        part10: "资产周转率",
        part11: "营业利润率",
        part12: "毛利率",
        part13: "净利率",
        part14: "ROE",
      };
    const titleArr = ["排名","公司名称",range2Obj[options.subType]];
    chartData.unshift(titleArr);
    return chartData.slice(0,11)
}

/*=====================走势详情=========================*/
export function getStockYearChange(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.seccode+options.type+options.subType]
        if (!fullData){
            fullData = await ajaxStockYearChange(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.seccode+options.type+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getChangeTitle(options),
            data:handleStockYearChange(fullData,options)
        };
        resolve(finalData);
    });
  }

function getChangeTitle(options){
    const range2Obj = {
        part1: "流动比",
        part2: "速动比",
        part3: "利息保障倍数",
        part4: "资产负债率",
        part5: "权益乘数",
        part6: "应收账款周转率",
        part7: "存货周转率",
        part8: "流动资产周转率",
        part9: "非流动资产周转率",
        part10: "资产周转率",
        part11: "营业利润率",
        part12: "毛利率",
        part13: "净利率",
        part14: "ROE",
      };
    return options.secname+range2Obj[options.subType]+options.type
}

function handleStockYearChange(data,options){
    let chartData = [];
    let tableData = [];
    tableData.push({
        fValueTtm:data.fValueTtm.toFixed(2),
        fAvgThree:data.fAvgThree.toFixed(2),        
        fAvgFive:data.fAvgFive.toFixed(2),
    })
    const titleArr = ["最新值","3年均值","5年均值"];
    tableData.unshift(titleArr);
    data.vStockYearChange.map((item,index)=>{
        chartData.push({
            label:item.sDate,
            value2:item.fSelfValue,
            value1:item.fIndustryValue
        })
    })
    const range2Obj = {
        part1: "流动比",
        part2: "速动比",
        part3: "利息保障倍数",
        part4: "资产负债率",
        part5: "权益乘数",
        part6: "应收账款周转率",
        part7: "存货周转率",
        part8: "流动资产周转率",
        part9: "非流动资产周转率",
        part10: "资产周转率",
        part11: "营业利润率",
        part12: "毛利率",
        part13: "净利率",
        part14: "ROE",
      };
    let legend=[range2Obj[options.subType],"行业均值"]
    return {chartData:chartData,tableData:tableData,legend:legend,type:range2Obj[options.subType]}
}


async function ajaxAnalysisPic(options){
    return await axios.post("https://comm.wedengta.com/?s=dupontanalysis&f=getStockDuPonAnalysisPic&req=GetStockDuPonAnalysisPicReq&rsp=GetStockDuPonAnalysisPicRsp",
    JSON.stringify({
        sStockCode: options.seccode,
    }))
    
}

async function ajaxStockYearChange(options){
    const rangeObj = {
        part1: 1,
        part2: 2,
        part3: 3,
        part4: 4,
        part5: 5,
        part6: 6,
        part7: 7,
        part8: 8,
        part9: 9,
        part10: 10,
        part11: 11,
        part12: 12,
        part13: 13,
        part14: 14,
      };
    return await axios.post("https://comm.wedengta.com/?s=dupontanalysis&f=getStockIndexDetail&req=GetStockIndexDetailReq&rsp=GetStockIndexDetailRsp",
    JSON.stringify({
        sStockCode: options.seccode,
        sStockName:options.secname,
        eDpIndexType:rangeObj[options.subType],
    }))
    
}

async function ajaxIndustryScatter(Industry,options){
    const rangeObj = {
        part1: 1,
        part2: 2,
        part3: 3,
        part4: 4,
        part5: 5,
        part6: 6,
        part7: 7,
        part8: 8,
        part9: 9,
        part10: 10,
        part11: 11,
        part12: 12,
        part13: 13,
        part14: 14,
      };
    return await axios.post("https://tx-comm.wedengta.com/?s=dupontanalysis&f=getIndustryEquityMultiplerPage&req=GetIndustryEquityMultiplerPageReq&rsp=GetIndustryEquityMultiplerPageRsp",
    JSON.stringify({
        sIndustryCode: Industry.IndustryCode,
        sIndustryName:Industry.IndustryName,
        eDpIndexType:rangeObj[options.subType],
    }))
    
}

