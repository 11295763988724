import axios from "axios";
import moment from "moment";
import { BigNumber } from "bignumber.js";
import * as d3 from "d3";

//拉取K线数据的常量
const DAY = 4;
const WEEK = 5;
const MONTH = 6;
const ONE= 7;
const FIVE = 0;
const FIFTEEN = 1;
const THIRTY = 2;
const SIXTY = 3;
//计算macd的常量
const K_MACD_1 = 12;
const K_MACD_2 = 26;
const K_MACD_3 = 9;
// 计算kdj的常量
const K_KDJ_1 = 9;
const K_KDJ_2 = 3;
const K_KDJ_3 = 3;
// 计算RSI的常量
const K_RSI_1 = 6;
const K_RSI_2 = 12;
const K_RSI_3 = 24;
// 计算BOLL的常量
const DEFAULT_BOLL_MA_COUNT = 20;
const DEFAULT_BOLL_FACTOR = 2;
//资金博弈/主力资金
const CAPITAL_MIN = 1;
const CAPITAL_DAY = 2;

let volumeRatio = 0;

//行情数据
export function getMarketChartData(options) {
  return new Promise(async (resolve) => {
    let finalData;
    if (options.chartType == "分时") {
      const dataArr = await Promise.all([
        ajaxTime(options), //分时K线
        ajaxShortLineSignal(options), //短线起爆数据
        ajaxCapitalDDZ(options, CAPITAL_MIN), //资金博弈数据，分钟级
        ajaxSecQuote(options), //获取volumeRatio和最高最低
      ]);
      finalData = handleTimeSignal(dataArr, options.seccode);
    } else if (options.chartType == "日K") {
      const dataArr = await Promise.all([
        ajaxK(options, DAY),
        ajaxBsSignal(options),
        ajaxCapitalDDZ(options, CAPITAL_DAY), // 主力资金
        ajaxSecMultiWaveInfo(options, DAY), // 神奇海波
        ajaxSecHisValByNum(options), // PB|PE
      ]);
      finalData = handleDayK(dataArr);
    } else if (options.chartType == "五日") {
      const dataArr = await Promise.all([
        await ajax5DaysTime(options),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handle5DaysTime(dataArr);
      console.log(finalData,'finalDatafinalDatafinalData');
    } else if (options.chartType == "周K") {
      const dataArr = await Promise.all([
        await ajaxK(options, WEEK),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType == "月K") {
      const dataArr = await Promise.all([
        await ajaxK(options, MONTH),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType === '1分') {
      const dataArr = await Promise.all([
        await ajaxK(options, ONE),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType === '5分') {
      const dataArr = await Promise.all([
        await ajaxK(options, FIVE),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType === '15分') {
      const dataArr = await Promise.all([
        await ajaxK(options, FIFTEEN),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType === '30分') {
      const dataArr = await Promise.all([
        await ajaxK(options, THIRTY),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    } else if (options.chartType === '60分') {
      const dataArr = await Promise.all([
        await ajaxK(options, SIXTY),
        await ajaxHisChipDistDetail(options)
      ])
      finalData = handleNormalK(dataArr, options.chartType);
    }
    const pageTitle = getPageTitle(options);
    const stockInfo = await getStockInfo(options);
    const result = {
      pageTitle: pageTitle,
      finalData: finalData,
      stockInfo: stockInfo,
    };
    resolve(result);
  });
}

async function getStockInfo(options) {
  const data = await ajaxQuote(options);
  const stockInfo = JSON.parse(data.data.content).vSecQuote[0];
  return {
    fMax: stockInfo.fMax.toFixed(2),
    fMaxClass: getClass(stockInfo.fMax, stockInfo.fClose),
    fMin: stockInfo.fMin.toFixed(2),
    fMinClass: getClass(stockInfo.fMin, stockInfo.fClose),
    fOpen: stockInfo.fOpen.toFixed(2),
    fOpenClass: getClass(stockInfo.fOpen, stockInfo.fClose),
    fNow: stockInfo.fNow.toFixed(2),
    fClose: stockInfo.fClose.toFixed(2),
    fNowClass: getClass(stockInfo.fNow, stockInfo.fClose),
    fNowPercent: ((100 * (stockInfo.fNow - stockInfo.fClose)) / stockInfo.fClose).toFixed(2),
    fFhsl: (100 * stockInfo.fFhsl).toFixed(2),
  };
}
function getClass(a, b) {
  if (a == b) {
    return "num_same";
  } else if (a > b) {
    return "num_rise";
  } else if (a < b) {
    return "num_fall";
  }
}
function getPageTitle(options) {
  return options.secname + options.chartType + (options.chartType == "分时" ? "图" : "线");
}

//处理分时，叠加短线起爆数据，量比，macd
function handleTimeSignal(dataArr, seccode) {
  let combinedData = [];
  let timeK = JSON.parse(dataArr[0].data.content);
  const shortSignal = JSON.parse(dataArr[1].data.content).vShortLineSignal;
  const capitalDDZ = JSON.parse(dataArr[2].data.content).vCapitalDDZDesc;
  const quote = JSON.parse(dataArr[3].data.content).vSecQuote[0];


  volumeRatio = quote.fVolumeRatio;
  const fMax = quote.fMax;
  const fMin = quote.fMin;



  //昨收价
  const fPreClose = timeK.fPreClosePrice;
  timeK = timeK.vTrendDesc;

  //处理量比
  // 1. 首先得到一个aveVolume数组，第一天的量除以1，第一天+第二天的量除以2... 以此类推
  // 2. 通过secbaseInfo接口得到volumeRatio的定值
  // 3. aveVolume的最后一个值除以volumeRatio得到day5AverageVolume
  // 4. 遍历aveVolume，拿它数组里的每个值除以day5AverageVolume得到一条volumeRatio曲线
  let volumeAve = [];
  let volumeSum = 0;
  timeK.map((item, index) => {
    volumeSum += item.lNowvol;
    volumeAve.push(volumeSum / (index + 1));
  });
  const day5AverageVolume = volumeAve[volumeAve.length - 1] / volumeRatio;

  //开始计算macd数组
  const macdArr = getMACD(timeK, "fNow");

  //开始计算资金博弈
  const fundFight = getFundFight(capitalDDZ);

  //找到短线起爆
  let lockTime, lockValue;
  shortSignal.map((item) => {
    if (item.sSecDtCode == seccode) {
      lockTime = convertTimeFormat(item.sLockTime);
      lockValue = item.fLockPrice;
    }
  });

  //每一项的数据
  let tempItem;
  timeK.map((item, index) => {
    tempItem = {
      label: item.iMinute, // 分钟-分时图label
      now: item.fNow, // 当前价-分时图
      average: item.fAverage, // 均价-分时图
      volume: getTimeSignedVolume(timeK, index, fPreClose), // 成交量和分时能量都是用的同一个数据，只是画法不一样
      volumeRatio: (volumeAve[index] / day5AverageVolume).toFixed(2) * 1, // 量比
      macd: macdArr[index], // MACD
      fundFight: fundFight[index], //fundFight // 资金博弈
    };
    if (lockTime != undefined && lockTime == item.iMinute) {
      tempItem.lockValue = lockValue;
    }
    combinedData.push(tempItem);
  });

  return {
    fPreClose: fPreClose,
    fMax: fMax,
    fMin: fMin,
    data: combinedData,
  };
}

//通过原始数据计算资金博弈
function getFundFight(capitalDDZ) {
  let result = [];
  let ssups = []
  let sbigs = []
  let smids = []
  let ssmalls = []
  capitalDDZ.reverse().forEach((item) => {
    let sup = item.fSuperInAmt - item.fSuperOutAmt;
    let big = item.fBigInAmt - item.fBigOutAmt;
    let mid = item.fMidInAmt - item.fMidOutAmt;
    let small = item.fSmallInAmt - item.fSmallOutAmt;
    ssups.push(sup)
    sbigs.push(big)
    smids.push(mid)
    ssmalls.push(small)
    result.push({
      label: item.lTime,
      super: ssups.reduce((a, b) => a+b) / 100000000,
      big: sbigs.reduce((a, b) => a+b) / 100000000,
      mid: smids.reduce((a, b) => a+b) / 100000000,
      small: ssmalls.reduce((a, b) => a+b) / 100000000,
    });
  });
  return result;
}

//通过原始数据计算macd
function getMACD(timeK, theField) {
  let macdArr = [];
  let dea9 = 0,
    ema12 = 0,
    ema26 = 0,
    diff = 0;
  timeK.map((item, index) => {
    const close = item[theField];
    if (index == 0) {
      ema12 = close;
      ema26 = close;
      macdArr.push({
        macd: 0,
        diff: 0,
        dea: 0,
      });
    } else {
      ema12 = computeEma(ema12, close, K_MACD_1);
      ema26 = computeEma(ema26, close, K_MACD_2);
      diff = ema12 - ema26;
      dea9 = (dea9 * (K_MACD_3 - 1) + diff * 2) / (K_MACD_3 + 1);
      const macd = 2 * (diff - dea9);
      macdArr.push({
        macd: macd,
        diff: diff,
        dea: dea9,
      });
    }
  });
  return macdArr;
}
// 通过原始数据计算kdj
function getKDJ(timeK, theField='fClose') {
  let kdjArr = []
  let kList = []
  let dList = []
  let kValue = 0
  let dValue = 0
  let jValue = 0
  timeK.forEach((item, i) => {
    let close = item[theField];
    if (i === 0) {
      kList.push(50)
      dList.push(50)
      kdjArr.push({
        kValue: 0,
        dValue: 0,
        jValue: 0,
      })
    } else {
      let subentitys = timeK.slice((i - K_KDJ_1 + 1) < 0 ? 0 : (i - K_KDJ_1 + 1), (i + 1) > timeK.length ? timeK.length : (i + 1))
      let ln = d3.min(subentitys, (d) => d.fLow)
      let hn = d3.max(subentitys, (d) => d.fHigh)
      if (ln !== hn) {
        let rsv = (close - ln) / (hn - ln) * 100;
        kValue = SMA(rsv, K_KDJ_2, 1, kList[i - 1]);
        dValue = SMA(kValue, K_KDJ_3, 1, dList[i - 1]);
        jValue = 3 * kValue - 2 * dValue;
        kList.push(kValue)
        dList.push(dValue)
        kdjArr.push({
          kValue,
          dValue,
          jValue,
        })
      } else {
        kList.push(100)
        dList.push(100)
        kdjArr.push({
          kValue: 100,
          dValue: 100,
          jValue: 100,
        })
      }
    }
  })
  return kdjArr
}
// 通过原始数据计算RSI
function getRSI(timeK, theField='fClose') {
  let rsi1 = 100;
  let rsi2 = 100;
  let rsi3 = 100;
  let mRsiList = []
  let rsi1Maxlist = []
  let rsi1Abslist = []
  let rsi2Maxlist = []
  let rsi2Abslist = []
  let rsi3Maxlist = []
  let rsi3Abslist = []
  timeK.forEach((item, i) => {
    let close = item[theField];
    let perclose = timeK[i - 1 < 0 ? 0 : i - 1][theField]
    let range = close - perclose
    let rsi1max = 0;
    let rsi1abs = 0;
    let rsi2max = 0;
    let rsi2abs = 0;
    let rsi3max = 0;
    let rsi3abs = 0;
    if (i > 0) {
      rsi1max = SMA(range > 0 ? range : 0, K_RSI_1, 1, rsi1Maxlist[i - 1]);
      rsi1abs = SMA(Math.abs(range), K_RSI_1, 1, rsi1Abslist[i - 1]);
      rsi2max = SMA(range > 0 ? range : 0, K_RSI_2, 1, rsi2Maxlist[i - 1]);
      rsi2abs = SMA(Math.abs(range), K_RSI_2, 1, rsi2Abslist[i - 1]);
      rsi3max = SMA(range > 0 ? range : 0, K_RSI_3, 1, rsi3Maxlist[i - 1]);
      rsi3abs = SMA(Math.abs(range), K_RSI_3, 1, rsi3Abslist[i - 1]);
      rsi1 = rsi1max / rsi1abs * 100;
      rsi2 = rsi2max / rsi2abs * 100;
      rsi3 = rsi3max / rsi3abs * 100;
    }
    rsi1Maxlist.push(rsi1max);
    rsi1Abslist.push(rsi1abs);
    rsi2Maxlist.push(rsi2max);
    rsi2Abslist.push(rsi2abs);
    rsi3Maxlist.push(rsi3max);
    rsi3Abslist.push(rsi3abs);
    mRsiList.push({
      rsi1, rsi2, rsi3
    });
  })
  return mRsiList
}
// 通过原始数据计算BOLL
function getBOLL(timeK, theField='fClose') {
  let mBollEntities = []
  timeK.forEach((item, i) => {
    let bollval = 0;
    let upper = 0;
    let lower = 0;
    let sum = 0;
    let count = 0;
    for (let j = 0; j < DEFAULT_BOLL_MA_COUNT && i - j >= 0; j++) {
        sum += timeK[i - j][theField];
        count++;
    }
    if (count > 0) {
        bollval = sum / count;
    }
    let devition = getStandardDevition(i, bollval, timeK, theField)
    upper = bollval + DEFAULT_BOLL_FACTOR * devition
    lower = bollval - DEFAULT_BOLL_FACTOR * devition
    mBollEntities.push({
      bollval, upper, lower
    })
  })
  return mBollEntities
}
function getStandardDevition(i, bollval, timeK, theField) {
  let sum = 0;
  let count = 0;
  for (let j = i - DEFAULT_BOLL_MA_COUNT + 1; j <= i; j++) {
      if (j < 0) {
          continue;
      }

      let close = timeK[j][theField];
      sum += (close - bollval) * (close - bollval);
      count++;
  }
  if (count > 0) {
      return Math.sqrt(sum / count) ;
  }
  return 0;
}
//计算ema，我也不懂这个算法是啥，照着安卓端的代码同步过来的，也用到了精确计算库bignumber.js
function computeEma(ema, close, K_MACD) {
  let temp1 = new BigNumber(ema);
  let temp2 = new BigNumber(K_MACD - 1);
  temp1 = temp1.multipliedBy(temp2);
  temp2 = new BigNumber(close * 2);
  temp1 = temp1.plus(temp2);
  const result = temp1.dividedBy(K_MACD + 1);
  return result;
}
function SMA(X, N, M, perValue) {
  return (X * M + perValue * (N - M)) / N;
}

// 量和分时能量都是用的同一个数据，只是画法不一样
function getTimeSignedVolume(timeK, index, fPreClose) {
  console.log(index,timeK[index].lNowvol,'index');
  const perMinute = timeK[index].fAmount / timeK[index].lNowvol / 100;
  let sign;
  if (index == 0) {
    sign = perMinute > fPreClose ? 1 : -1;
  } else {
    sign = perMinute > timeK[index - 1].fNow ? 1 : -1;
  }
  return timeK[index].lNowvol * sign;
}

// K线的量获取正负号
function getKSignedVolume(kData, index, fPreClose) {
  let sign;
  if (index == 0) {
    sign = kData[index].fClose >= fPreClose ? 1 : -1;
  } else {
    sign = kData[index].fClose > kData[index - 1].fClose ? 1 : -1;
  }
  return kData[index].lVolume * sign;
}

//把 11:30 转换成 580分钟 格式
function convertTimeFormat(time) {
  const timeArr = time.split(":");
  return timeArr[0] * 60 + timeArr[1] * 1;
}

//处理日K，叠加多空数据
function handleDayK(dataArr) {
  let combinedData = [];
  const dayK = JSON.parse(dataArr[0].data.content).vKLineDesc;
  const bs = JSON.parse(dataArr[1].data.content).vSecBsInfo;
  const capitalDDZ = JSON.parse(dataArr[2].data.content).vCapitalDDZDesc;
  const wave = JSON.parse(dataArr[3].data.content).vSecWaveInfo;
  const PB_PE = JSON.parse(dataArr[4].data.content).vHisVal;
  let tempItem;

  const capitalDDZArr = getCapitalDDZArr(capitalDDZ);

  //K线均线
  const kAve5Arr = getAve(dayK, 5, "fClose");
  const kAve10Arr = getAve(dayK, 10, "fClose");
  const kAve20Arr = getAve(dayK, 20, "fClose");

  //成交量均线
  const volumeAve5Arr = getAve(dayK, 5, "lVolume");
  const volumeAve10Arr = getAve(dayK, 10, "lVolume");

  //获取macd
  const macdArr = getMACD(dayK, "fClose");
  const kdjArr = getKDJ(dayK, "fClose")
  const rsiArr = getRSI(dayK, "fClose")
  const bollArr = getBOLL(dayK, "fClose")
  // 获取神奇海波
  let waveObj = {}
  wave.forEach((item) => {
    waveObj[item.sDate.split('-').join('')] = item.iWave
  })
  // 获取PB PE
  let PbPeObj = {}
  PB_PE.forEach((item) => {
    let key = item.sDate.substring(0, 10).split('-').join('')
    PbPeObj[key] = {
      fPB: item.fPB,
      fPE: item.fPE
    }
  })
  //获取60根K线前一天的fClose，用来计算量的正负号
  let fPreClose = dayK.slice(-61)[0].fClose;
  if (fPreClose == undefined) {
    fPreClose = dayK.slice(-60)[0].fClose;
  }
  const dayK60 = dayK.slice(-60);

  dayK60.map((_item, index) => {
    const item = dayK60[dayK60.length - 1 - index];
    const date = item.lYmd + "";
    const label = `${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(6, 8)}`;
    tempItem = {
      label: label,
      k: [item.fOpen, item.fClose, item.fLow, item.fHigh], //open close low high
      kAve: [
        kAve5Arr[kAve5Arr.length - 1 - index],
        kAve10Arr[kAve10Arr.length - 1 - index],
        kAve20Arr[kAve20Arr.length - 1 - index],
      ], // k线均线
      volume: getKSignedVolume(dayK60, dayK60.length - 1 - index, fPreClose), // 成交量
      volumeAve: [volumeAve5Arr[volumeAve5Arr.length - 1 - index], volumeAve10Arr[volumeAve10Arr.length - 1 - index]], // 成交量均线
      iBs: -1,
      macd: macdArr[macdArr.length - 1 - index],
      kdj: kdjArr[kdjArr.length - 1 - index],
      rsi: rsiArr[rsiArr.length - 1 - index],
      boll: bollArr[bollArr.length - 1 - index],
      wave: waveObj[label.split('.').join('')] || 0,
      PB: PbPeObj[label.split('.').join('')]?.fPB || 0,
      PE: PbPeObj[label.split('.').join('')]?.fPE || 0,
      capital: capitalDDZArr.length > 1 ? capitalDDZArr[capitalDDZArr.length - 1 - index]?.bar || 0 : 0, // 主力资金
      capitalAve: [
        capitalDDZArr.length > 1 ? capitalDDZArr[capitalDDZArr.length - 1 - index]?.ave5 || 0 : 0,
        capitalDDZArr.length > 1 ? capitalDDZArr[capitalDDZArr.length - 1 - index]?.ave10 || 0 : 0,
      ], // 主力资金均线
    };
    if (bs.length > 1) {
      bs.map((cell) => {
        if (item.lYmd == moment(cell.sDate).format("YYYYMMDD")) {
          tempItem.iBs = cell.iBs; //iBs  0为卖出，1为买入
        }
      });
    }
    combinedData.push(tempItem);
  });
  return {
    data: combinedData.reverse(),
  };
}

//通过原始数据算柱子，和柱子的5日，10日平均线。原始数据的时间是从近到远的
function getCapitalDDZArr(capitalDDZ) {
  let capital = [];
  capitalDDZ.map((item) => {
    capital.unshift(item.fSuperInAmt + item.fBigInAmt - item.fSuperOutAmt - item.fBigOutAmt);
  });

  // capital的时间是由远到近的，获得的均线也是由远到近的
  const fiveDaysAverages = getAve(capital, 5);
  const tenDaysAverages = getAve(capital, 10);

  capital = capital.slice(-60);

  //result的通过unshift，实现了时间从远到近
  let result = [];
  capital.map((_item, index) => {
    const item = capital[capital.length - 1 - index];
    result.unshift({
      bar: item,
      ave5: fiveDaysAverages[fiveDaysAverages.length - 1 - index],
      ave10: tenDaysAverages[tenDaysAverages.length - 1 - index],
    });
  });
  return result;
}

//计算n日均线，aveNum指算几日均线,theField是指哪个字段，如果未指定则直接使用
function getAve(data, aveNum, theField) {
  let averageArr = [];
  for (let i = aveNum; i < data.length; i++) {
    let tempSum = 0;
    for (let j = 0; j < aveNum && i - j > 0; j++) {
      tempSum += theField == undefined ? data[i - j] : data[i - j][theField];
    }
    averageArr.push(tempSum / aveNum);
  }
  return averageArr;
}

//处理五日
function handle5DaysTime(dataArr) {
  const k5Days = JSON.parse(dataArr[0].data.content).vRtMinDesc.reverse(); //取的80天数据，为了算出20日均线
  const fPreClose = k5Days[0].fPreClose;


  //先收集原始数据，为计算volume的正负号，和macd做准备
  let roughCollection = [];
  k5Days.map((item) => {
    item.vTrendDesc.map((cell, i) => {
      roughCollection.push(cell);
    });
  });

  let startDate = k5Days[0].sDate;
  startDate = `${startDate.substring(0, 4)}.${startDate.substring(4, 6)}.${startDate.substring(6, 8)}`;
  let endDate = k5Days[k5Days.length - 1].sDate;
  endDate = `${endDate.substring(0, 4)}.${endDate.substring(4, 6)}.${endDate.substring(6, 8)}`;

  //开始计算macd数组
  const macdArr = getMACD(roughCollection, "fNow");

  //再收集最终数据，此时就可以计算出volume和macd了
  let finalCollection = [];
  let nowArr = [];
  k5Days.map((item, index) => {
    item.vTrendDesc.map((cell, i) => {
      finalCollection.push({
        label: `day${index + 1}min${cell.iMinute}`,
        now: cell.fNow,
        average: cell.fAverage,
        volume: getTimeSignedVolume(roughCollection, index * 241 + i, fPreClose),
        macd: macdArr[index * 241 + i],
      });
      nowArr.push(cell.fNow);
    });
  });
  console.log(finalCollection,'finalCollectionfinalCollection');
  const ChipDistData = JSON.parse(dataArr[1].data.content)
  let fAvgCost = ChipDistData.vtChipDistRsp[0]?.fAvgCost
  let fMainAvgCost = ChipDistData.vtChipDistRsp.filter(item => item.vtMainChipDist.length > 0)[0]?.fMainAvgCost
  return {
    fPreClose: fPreClose,
    fMax: d3.max(nowArr),
    fMin: d3.min(nowArr),
    startDate: startDate,
    endDate: endDate,
    data: finalCollection,
    capitalAvg: fAvgCost ? `平均成本：${fAvgCost}，${ChipDistData.sChangeDesc}` : '',
    capitalMainAvg: fMainAvgCost ? `主力成本：${fMainAvgCost}，${ChipDistData.sMainChgDesc}` : ''
  };
}

//处理周K，月K
function handleNormalK(dataArr, type) {
  let convertedData = [];
  const kData = JSON.parse(dataArr[0].data.content).vKLineDesc; //取的80天数据，为了算出20日均线

  //从旧到新算出均线
  let kAve5Arr = getAve(kData, 5, "fClose");
  let kAve10Arr = getAve(kData, 10, "fClose");
  let kAve20Arr = getAve(kData, 20, "fClose");

  //成交量均线
  let volumeAve5Arr = getAve(kData, 5, "lVolume");
  let volumeAve10Arr = getAve(kData, 10, "lVolume");


  const macdArr = getMACD(kData, "fClose");
  const kdjArr = getKDJ(kData, "fClose")
  const rsiArr = getRSI(kData, "fClose")
  const bollArr = getBOLL(kData, "fClose")
  //获取60根K线前一天的fClose，用来计算量的正负号
  let fPreClose = kData.slice(-61)[0].fClose;
  if (fPreClose == undefined) {
    fPreClose = kData.slice(-60)[0].fClose;
  }

  const kData60 = kData.slice(-60);
  //由于所有的数据都是从旧到新，并且长度并不相等，所以统一各数组从后往前记录
  
  kData60.forEach((_item, index) => {
    const item = kData60[kData60.length - 1 - index];
    const date = item.lYmd + "";
    const label = `${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(6, 8)}`;
    convertedData.push({
      label: ['周K', '月K'].indexOf(type) !== -1 ? label : index,
      k: [item.fOpen, item.fClose, item.fLow, item.fHigh], //open close low fHigh
      kAve: [
        kAve5Arr[kAve5Arr.length - 1 - index],
        kAve10Arr[kAve10Arr.length - 1 - index],
        kAve20Arr[kAve20Arr.length - 1 - index],
      ],
      macd: macdArr[macdArr.length - 1 - index],
      kdj: kdjArr[kdjArr.length - 1 - index],
      rsi: rsiArr[rsiArr.length - 1 - index],
      boll: bollArr[bollArr.length - 1 - index],
      volume: getKSignedVolume(kData60, kData60.length - 1 - index, fPreClose),
      volumeAve: [volumeAve5Arr[volumeAve5Arr.length - 1 - index], volumeAve10Arr[volumeAve10Arr.length - 1 - index]],
    });
  });
  const ChipDistData = JSON.parse(dataArr[1].data.content)
  let fAvgCost = ChipDistData.vtChipDistRsp[0]?.fAvgCost
  let fMainAvgCost = ChipDistData.vtChipDistRsp.filter(item => item.vtMainChipDist.length > 0)[0]?.fMainAvgCost
  return {
    data: convertedData.reverse(),
    capitalAvg: fAvgCost ? `平均成本：${fAvgCost}，${ChipDistData.sChangeDesc}` : '',
    capitalMainAvg: fMainAvgCost ? `主力成本：${fMainAvgCost}，${ChipDistData.sMainChgDesc}` : ''
  };
}

//获取K线数据  eKLineType  4日线 5周线 6月线
async function ajaxK(options, eKLineType) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getKLine&req=KLineReq&rsp=KLineRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      eKLineType: eKLineType,
      iWantnum: 80,
    })
  );
}
//获取5日分时数据
async function ajax5DaysTime(options, eKLineType) {
  return await axios.post(
    "https://comm.wedengta.com/?s=kline&f=getRtMinEx&req=RtMinReq&rsp=RtMinRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      iNum: 5,
    })
  );
}
//获取短线起爆数据
async function ajaxShortLineSignal(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=ShortLineAssault&f=getShortLineSignal&req=GetShortLineSignalReq&rsp=GetShortLineSignalRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
    })
  );
}

//获取多空信号
async function ajaxBsSignal(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=singal&f=getSecBsInfo&req=GetSecBsInfoReq&rsp=GetSecBsInfoRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      sDate: moment().format("YYYYMMDD"),
      iSize: 60,
    })
  );
}

//获取分时数据
async function ajaxTime(options) {
  return await axios.get("https://sec.wedengta.com/getSecKline", {
    params: {
      seccode: options.seccode,
      type: 1,
    },
  });
}

//获取资金博弈
async function ajaxCapitalDDZ(options, type) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getCapitalDDZ&req=CapitalDDZReq&rsp=CapitalDDZRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      eCapitalDDZType: type, //1 分钟级别 2 日级别
      iNum: type == 1 ? 800 : 70, //分钟取800个足够了，日k取70条，因为有10条是为了算平均值
    })
  );
}

//获取quote
async function ajaxSecQuote(options) {
  return await axios.get("https://sec.wedengta.com/getSecInfo", {
    params: {
      action: "quote",
      seccode: options.seccode,
    },
  });
}

//获取个股详情
async function ajaxQuote(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getQuote&req=QuoteReq&rsp=QuoteRsp",
    JSON.stringify({
      vDtSecCode: [options.seccode],
    })
  );
}
// 个股筹码分布
async function ajaxHisChipDistDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=chipDist&f=getHisChipDistDetail&req=HisChipDistReq&rsp=HisChipDistRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      iStartxh: 0,
      iWantnum: 5
    })
  );
}
// 神奇海波
async function ajaxSecMultiWaveInfo(options, type = 4) {
  return await axios.post(
    "https://comm.wedengta.com/?s=singal&f=getSecMultiWaveInfo&req=GetSecMultiWaveInfoReq&rsp=GetSecMultiWaveInfoRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      sWaveType: '4', // 日
    })
  );
}
// PB PE
async function ajaxSecHisValByNum(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=PortfolioSecNews&f=getSecHisValByNum&req=GetSecHisValReq&rsp=GetSecHisValRsp",
    JSON.stringify({
      sDtCode: options.seccode,
      iStart: 0,
      iWantNum: 60,
    })
  );
}
