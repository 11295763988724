<template>
    <el-dialog
        :visible.sync="visible"
        title="行情滚动图"
        :close-on-click-modal="false"
        custom-class="live_market"
        width="40vw"
        @close="close"
    >
        <div class="create_content">
            <marketMarquee ref="marketMarquee" :defaultData="form" />
            <el-form :model="form" ref="form" label-width="94px">
                <el-form-item
                    prop="theme"
                    label="选择配色"
                >
                    <el-radio-group v-model="form.theme">
                        <el-radio label="theme1">配色一</el-radio>
                        <el-radio label="theme2">配色二</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    prop="barTitle"
                    label="直播标题"
                >
                    <el-input v-model="form.barTitle" placeholder="请输入直播标题" maxlength="22" show-word-limit></el-input>
                </el-form-item>
                <div class="time">
                    <el-form-item
                        prop="time1"
                        label="时间预告"
                    >
                        <el-input v-model="form.time1" placeholder="例： 每天"></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="time2"
                    >
                        <el-input v-model="form.time2" placeholder="例： 10:00"></el-input>
                    </el-form-item>
                </div>
                <el-form-item
                    prop="barCareerNo"
                    label="执业信息"
                >
                    <el-input
                        type="textarea"
                        placeholder="请输入执业信息"
                        v-model="form.barCareerNo"
                        :rows="3"
                        show-word-limit
                        maxlength="22"
                    />
                </el-form-item>
                <el-form-item
                    prop="barRiskFree"
                    label="风险提示"
                >
                    <el-input
                        type="textarea"
                        placeholder="请输入风险提示"
                        v-model="form.barRiskFree"
                        :rows="3"
                        show-word-limit
                        maxlength="22"
                    />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="medium">取 消</el-button>
            <el-button @click="submit" type="primary" size="medium">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import marketMarquee from "../../../components/marketMarquee/index.vue";
export default {
    props: {
        switch_visible: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            visible: false,
            form: {
                theme: 'theme1',
                barTitle: '',
                barCareerNo: '',
                barRiskFree: '',
                time1: '',
                time2: '',
            },
        }
    },
    components: {
        marketMarquee
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                let options = {
                   theme: newVal.theme,
                    barTitle: newVal.barTitle,
                    barCareerNo: newVal.barCareerNo,
                    barRiskFree: newVal.barRiskFree,
                    time: `${newVal.time1}|${newVal.time2}`
                }
                this.$refs.marketMarquee?.show(options)
            },
            deep: true
        }
    },
    methods: {
        open(value) {
            this.visible = true
            if (value) {
                let newValue = {
                    theme: value.theme,
                    barTitle: value.barTitle,
                    barCareerNo: value.barCareerNo,
                    barRiskFree: value.barRiskFree,
                    time1: value.time.split('|')[0],
                    time2: value.time.split('|')[1]
                }
                this.form = newValue
            }
        },
        close() {
            this.$refs.form.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        async submit() {
            // if (!(this.form.time1 && this.form.time2)) {
            //     return this.$message.warning('请输入时间预告')
            // }
            let newForm = {
                theme: this.form.theme,
                barTitle: this.form.barTitle,
                barCareerNo: this.form.barCareerNo,
                barRiskFree: this.form.barRiskFree,
                time: `${this.form.time1}|${this.form.time2}`
            }
            this.API.live.addMaterial({
                id: Number(this.$route.query.id),
                liveRoomTools: {
                    roomId: Number(this.$route.query.id),
                    toolName: '行情滚动图',
                    toolCode: 'liveMarket',
                    toolContent: JSON.stringify(newForm),
                    showStatus: this.switch_visible ? 1 : 0,
                },
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('编辑成功')
                    this.$emit('downScreen')
                    this.$emit('upDate')
                    this.close()
                } else {
                    this.$message.error('编辑失败')
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .live_market {
    background: rgb(32,32,32);
    .el-dialog__header {
        display: flex;
        justify-Content: center;
        align-items: center;
        .el-dialog__title {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .el-dialog__body {
        padding: 8px 20px 0px;
        .create_content {
            display: flex;
            flex-direction: column;
            background: #121212;
            padding: 20px 20px 0;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .time {
                display: flex;
                > div {
                    flex: 1;
                    &:last-child {
                        > div {
                            margin-left: 20px !important;
                        }
                    }
                }
            }
            .el-form {
                margin-top: 24px;
            }
        }
        .el-form-item {
            margin-bottom: 24px;
        }
        .el-form-item__label {
            color: #fff;
            font-size: 16px;
            text-align: left;
        }
        .el-input__inner, .el-textarea__inner {
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.21);
            color: #fff;
        }
        .el-form-item.is-error .el-input__inner {
            border-color: #F56C6C
        }
        .el-input .el-input__count .el-input__count-inner, .el-input__count {
            background: unset;
        }
    }
    .el-dialog__footer {
        padding: 0 20px 20px;
        .dialog-footer {
            padding: 0 20px 20px;
            background: #121212;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .el-button--default {
                border-radius: 4px;
                border: 1px solid #4E8CFF;
                background: #252525;
                color: #4E8CFF;
                margin-right: 60px;
            }
            .el-button--primary {
                border: 1px solid #4E8CFF;
                background: #4E8CFF;
            }
        }
    }
}
</style>