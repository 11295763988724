<template>
    <div class="marginTrade">
        <button v-show="visible" @click="addGroup">添加到常用</button>
        <div>
            <div class="filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterOne()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <SelectStock
                :data="activeWindowCustomChartMsg"
                @selectStock="changeFilterThree"
                v-if="['个股筹码分布', '个股主力筹码分布'].indexOf(tool_filter_one) !== -1"
            />
            <div v-if="['全市场盈利比例分布', '筹码形态分析'].indexOf(tool_filter_one) !== -1 || Object.keys(tool_filter_three).length > 0" class="filter_two">
                <h3>内容</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in currentFilter()"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: {},
            tool: {},
            visible: false
        }
    },
    components: {
        SelectStock
    },
    created() {
        this.tool = tool
    },
    methods: {
        currentFilter() {
            switch (this.tool_filter_one) {
                case "全市场盈利比例分布":
                    return [
                        {
                            label: "统计",
                            value: "all",
                        },
                        {
                            label: "0-20%",
                            value: "part1",
                        },
                        {
                            label: "20-50%",
                            value: "part2",
                        },
                        {
                            label: "50-80%",
                            value: "part3",
                        },
                        {
                            label: "80-100%",
                            value: "part4",
                        },
                    ]
                case "筹码形态分析":
                    return [
                        {
                            label: "底部单峰密集型股票",
                            value: "底部单峰密集型股票",
                        },
                        {
                            label: "底部单峰密集型筹码形态",
                            value: "底部单峰密集型筹码形态",
                        },
                        {
                            label: "中部单峰密集型股票",
                            value: "中部单峰密集型股票",
                        },
                        {
                            label: "中部单峰密集型筹码形态",
                            value: "中部单峰密集型筹码形态",
                        },
                        {
                            label: "顶部单峰密集型股票",
                            value: "顶部单峰密集型股票",
                        },
                        {
                            label: "顶部单峰密集型筹码形态",
                            value: "顶部单峰密集型筹码形态",
                        },
                        {
                            label: "双峰密集型股票",
                            value: "双峰密集型股票",
                        },
                        {
                            label: "双峰密集型筹码形态",
                            value: "双峰密集型筹码形态",
                        },
                        {
                            label: "发散型股票",
                            value: "发散型股票",
                        },
                        {
                            label: "发散型筹码形态",
                            value: "发散型筹码形态",
                        },
                    ]
                case "个股筹码分布":
                    return [
                        {
                            label: '解析',
                            value: '解析'
                        },
                        {
                            label: '趋势',
                            value: '趋势'
                        },
                    ]
                case "个股主力筹码分布":
                    return [
                        {
                            label: '解析',
                            value: '解析'
                        },
                        {
                            label: '趋势',
                            value: '趋势'
                        },
                    ]
                default:  
            }
        },
        changeFilterOne() {
            if (this.tool_filter_one === '全市场盈利比例分布') {
                this.tool_filter_three = {}
                this.tool_filter_two = 'all'
                this.visible = true
                this.changeFilterTwo()
            } else if (this.tool_filter_one === '筹码形态分析') {
                this.tool_filter_three = {}
                this.tool_filter_two = '底部单峰密集型股票'
                this.visible = true
                this.changeFilterTwo()
            } else if (['个股筹码分布', '个股主力筹码分布'].indexOf(this.tool_filter_one) !== -1&& Object.keys(this.tool_filter_three).length > 0) {
                this.changeFilterTwo()
            } else {
                this.visible = false
            }
        },
        changeFilterThree(value) {
            this.tool_filter_three = value
            this.tool_filter_two = "解析"
            this.visible = true
            this.changeFilterTwo()
        },
        changeFilterTwo() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType: this.tool_filter_two,
                    seccode: this.tool_filter_three.value,
                    secname: this.tool_filter_three.label,
                })
            }, 'show')
        },
        addGroup() {
            const obj = {
                all: '统计',
                part1: '0~20%',
                part2: '20~50%',
                part3: '50~80%',
                part4: '80~100%'
            }
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}${this.tool_filter_three.label ? `-${this.tool_filter_three.label}` : ''}-${obj[this.tool_filter_two] || this.tool_filter_two}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        subType: this.tool_filter_two,
                        seccode: this.tool_filter_three.value,
                        secname: this.tool_filter_three.label,
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        .filter_one {
            width: 21%;
            padding-right: 30px;
            box-sizing: border-box;
        }
        .filter_two {
            flex: 1;
            box-sizing: border-box;
        }
        .select_stock {
            margin-right: 30px;
        }
        > div {
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
        }
    }
}
</style>