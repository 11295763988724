<template>
    <div class="screen_components">
        <CardItem name="直播间LOGO" :editVisible="true" :authVisible="authVisible" />
        <CardItem name="直播间信息" :editVisible="true" :authVisible="authVisible" />
        <CardItem name="行情滚动图" :editVisible="true" :authVisible="authVisible" />
    </div>
</template>
<script>
import CardItem from './cardItem/index.vue'
export default {
    props: {
        authVisible: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        CardItem
    },
}
</script>
<style lang="less" scoped>
.screen_components {
    display: flex;
    flex-wrap: wrap;
    > div {
        margin: 0 15px 20px;
    }
}
</style>