<template>
    <div class="publishMaterial">
        <div class="material_content" :style="{ height: isActive === 'materialBackground' ? '100%' : 'calc(100% - 3rem)'}">
            <keep-alive>
                <component v-bind:is="currentComponent()" :authVisible="authVisible"></component>
            </keep-alive>
        </div>
        <el-button type="danger" v-show="isActive != 'materialBackground'" size="medium" @click="downScreen">下屏</el-button>
    </div>
</template>
<script>
import MaterialPhoto from './materialPhoto'
import MaterialNumPic from './materialNumPic'
import MaterialMessage from './materialMessage'
import MaterialTool from './materialTool'
export default {
    props: {
        authVisible: {
            type: Boolean,
            default: true,
        },
        isActive: {
            type: String,
        }
    },
    components: {
        MaterialPhoto,
        MaterialNumPic,
        MaterialMessage,
        MaterialTool,
    },
    methods: {
        downScreen() {
            this.$bus.$emit('downScreen')
        },
        currentComponent() {
            switch (this.isActive) {
                case 'materialPhoto':
                    return 'MaterialPhoto'
                    break;
                case 'materialNumPic':
                    return 'MaterialNumPic'
                    break;
                case 'materialMessage':
                    return 'MaterialMessage'
                    break;
                case 'materialTool':
                    return 'MaterialTool'
                    break;
            }
        }
    },
}
</script>
<style lang="less" scoped>
 .publishMaterial {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .material_content {
        padding: 20px 0 0px 0;
        box-sizing: border-box;
    }
    .el-button--danger {
        background-color: #FF3939;
        border-color: #FF3939;
        margin-right: 24px;
        align-self: flex-end;
        margin-bottom: 3px;
    }
    .el-button--danger:hover {
        background-color: #F56C6C;
        border-color: #F56C6C;
    }
 }
</style>