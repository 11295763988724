<template>
    <div class="intelliChooseStock">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <h3>名称</h3>
            <el-radio-group v-model="tool_filter_one" size="medium" @change="changeType()">
                <el-radio-button
                    :label="item.value"
                    v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                    :key="index"
                >
                    {{ item.label }}
                </el-radio-button>
            </el-radio-group>
        </div>
        <div v-show="this.tool_filter_one">
            <h3>类型</h3>
            <el-radio-group v-model="tool_filter_two" size="medium" @change="changeType()">
                <el-radio-button
                    :label="item.value"
                    v-for="(item, index) in typeArr"
                    :key="index"
                    :value="item.value"
                > {{ item.label }}
                </el-radio-button>
            </el-radio-group>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";
export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two:'选股策略',
            tool: {},
            typeArr:[{                
                label: '选股策略',
                value: '选股策略',
            },{    
                label: '入选个股',
                value: '入选个股',
            }]
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType:this.tool_filter_two
                })
            }, 'show')
        },
        addGroup() {
            cutLive(document.getElementById('preview'), `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}-${this.tool_filter_two}`).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}-${this.tool_filter_two}`,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        subType:this.tool_filter_two
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.intelliChooseStock {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        > h3 {
            color: #fff;
            margin-bottom: 8px;
        }
        /deep/ .el-radio-group {
            display: flex;
            flex-wrap: wrap;
            .el-radio-button__inner {
                background: #2E2F30;
                color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                border: #2E2F30;
                margin-bottom: 8px;
                margin-right: 8px;
            }
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background: #767676;
            border: #767676;
            color: #fff;
            box-shadow: unset
        }
    }
}
</style>