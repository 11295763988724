import axios from "axios";
import moment from "moment";

//行情数据
export function getPlateChartData(options) {
  return new Promise(async (resolve) => {
    let result;
    if (options.chartType == "涨幅top10") {
      const dataArr = await Promise.all([ajaxPlateStocks(options), ajaxSecQuote(options)]);
      result = handlePlateChartData(dataArr, true);
    } else if (options.chartType == "跌幅top10") {
      const dataArr = await Promise.all([ajaxPlateStocks(options), ajaxSecQuote(options)]);
      result = handlePlateChartData(dataArr, false);
    } else if (options.chartType == "板块潜力") {
      const data = await ajaxSecPortrait(options);
      result = handleSecPortrait(data);
    }
    resolve(result);
  });
}

function handlePlateChartData(dataArr, isDescending) {
  const plateStocksData = JSON.parse(dataArr[0].data.content).vSecSimpleQuote;
  let plateQuote = JSON.parse(dataArr[1].data.content).vSecSimpleQuote[0];

  let newPlateQuote = {
    sSecName: plateQuote.sSecName,
    sDtSecCode: plateQuote.sDtSecCode,
    absRate: (Math.abs((plateQuote.fNow - plateQuote.fClose) / plateQuote.fClose) * 100).toFixed(2),
    isRatePositive: plateQuote.fNow > plateQuote.fClose,
  };

  let plateArr = [];
  plateStocksData.map((item) => {

    //不停牌的股票才能进来
    if (item.eSecStatus == 0) {
      plateArr.push({
        sSecName: item.sSecName,
        sDtSecCode: item.sDtSecCode,
        fNow: item.fNow,
        absRate: (Math.abs((item.fNow - item.fClose) / item.fClose) * 100).toFixed(2),
        isRatePositive: item.fNow > item.fClose,
      });
    }
  });
  return {
    plateStocks: isDescending ? plateArr.slice(0, 10) : plateArr.slice(-10).reverse(),
    plateQuote: newPlateQuote,
  };
}

function handleSecPortrait(data) {
  const portraitData = JSON.parse(data.data.content);

  //数据收集
  let chartData = [];
  portraitData.stIndexUpdowns.vtUpdowns.map((item, index) => {
    chartData.push({
      label: moment(item.iTradeDate * 1000).format("YYYY-MM-DD"),
      value1: portraitData.stStockUpdowns.vtUpdowns[index].sUpDowns * 1,
      value2: item.sUpDowns * 1,
    });
  });
  //legned收集
  let legends = [portraitData.stStockUpdowns.sName, portraitData.stIndexUpdowns.sName];
  const result = {
    data: chartData,
    legend: legends,
    suffix: "%",
  };

  return result;
}

//获取板块下股票列表
async function ajaxPlateStocks(options) {
  return await axios.get("https://sec.wedengta.com/getMarketQuotation", {
    params: {
      seccode: options.seccode,
      action: "PlateStockList",
    },
  });
}
//获取quote
async function ajaxSecQuote(options) {
  return await axios.get("https://sec.wedengta.com/getSecInfo", {
    params: {
      action: "squote",
      seccode: options.seccode,
    },
  });
}
//获取板块趋势
async function ajaxSecPortrait(options) {
  return await axios.get("https://sec.wedengta.com/getSecPortrait", {
    params: {
      action: "MarketTrend",
      seccode: options.seccode,
    },
  });
}
