<template>
    <div
        class="like_component"
        @click="likeResource"
        :style="{ width: size == 'medium' ? '32px' : '', height: size == 'medium' ? '32px' : ''}"
    >
        <img :src="showImg()" alt="" :style="{ height: size == 'medium' ? '24px' : ''}">
    </div>
</template>
<script>
import ToCollect from '../../../../../../assets/images/detail/to_collect.svg'
import Collect from '../../../../../../assets/images/detail/collect.svg'
export default {
    props: {
        status: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data: function() {
        return {
            visible: false
        }
    },
    created() {
        if (this.status) {
            this.visible = true
        }
    },
    methods: {
        likeResource() {
            if (!this.visible) {
                this.$emit('addMaterial', (value) => {
                    if (value) {
                        this.visible = true
                    }
                })
            } else {
                this.$emit('deleteMaterial', (value) => {
                    if (value) {
                        this.visible = false
                    }
                })
            }        
        },
        showImg() {
            return this.visible ? Collect : ToCollect
        }
    },
}
</script>
<style lang="less" scoped>
.like_component {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    > img {
        height: 14px;
    }
}
</style>