import axios from "axios";
import moment from "moment";

import { getUpdateDate } from "@/utils/utils.js";


export function getAuctionChartData(options) {
  const typeObj = {
    auctionSummary: "getAuctionSummaryData", //集合竞价summary
    auctionUpLimit: "getAuctionLimit", //涨停，跌停试盘
    auctionDownLimit: "getAuctionLimit",
    auctionUpTrend: "getAuctionTrend", //看多，看空详情
    auctionDownTrend: "getAuctionTrend",
  };
  const functions = {
    getAuctionSummaryData,
    getAuctionLimit,
    getAuctionTrend,
  };
  return functions[typeObj[options.type]](options);
}

/*=====================集合竞价=========================*/
export function getAuctionSummaryData(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    const dataArr = await Promise.all([ajaxAuctionTryData(), ajaxAuctionTrendData()]);
    const data1 = JSON.parse(dataArr[0].data.content);
    const data2 = JSON.parse(dataArr[1].data.content);
    const finalData = [
      {
        label: "涨停试盘",
        value: data1.iLimitUpCount,
      },
      {
        label: "跌停试盘",
        value: data1.iLimitDownCount,
      },
      {
        label: "竞价看多",
        value: data2.iBullishCount,
      },
      {
        label: "竞价看空",
        value: data2.iBearlishCount,
      },
    ];
    const result = {
      pageTitle: "集合竞价",
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}

export function getAuctionLimit(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    const data = await ajaxAuctionTryData();
    let _data = JSON.parse(data.data.content);
    _data = options.type == "auctionUpLimit" ? _data.mAuctionTryData["1"] : _data.mAuctionTryData["2"];

    const titleArr = ["股票名称", options.type == "auctionUpLimit" ? "涨幅" : "跌幅", "试盘时间"];
    let finalData = [];
    finalData.push(titleArr);
    _data.map((item) => {
      finalData.push([item.sSecName, item.fRealTimeGain, item.sTime]);
    });
    const result = {
      pageTitle: options.type == "auctionUpLimit" ? "涨停试盘详情" : "跌停试盘详情",
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}
export function getAuctionTrend(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    const data = await ajaxAuctionTrendData();
    let _data = JSON.parse(data.data.content);
    _data = options.type == "auctionUpTrend" ? _data.mAuctionTrendData["1"] : _data.mAuctionTrendData["2"];
    const titleArr = ["股票名称", "实时涨幅", "竞价涨幅"];
    let finalData = [];
    finalData.push(titleArr);
    _data.map((item) => {
      finalData.push([item.sSecName, item.fRealTimeGain, item.fAuctionGain]);
    });
    const result = {
      pageTitle: options.type == "auctionUpTrend" ? "竞价看多详情" : "竞价看空详情",
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}

//获取集合竞价试盘
async function ajaxAuctionTryData() {
  return await axios.post(
    "https://comm.wedengta.com/?s=AuctionStat&f=getAuctionTryData&req=AuctionTryReq&rsp=AuctionTryRsp",
    JSON.stringify({
      iAuctionTryType: 3, // 竞价试盘类型, 1:竞价涨停  2:竞价跌停  3:所有数据
      iDisplayCount: 10, // 展示的数量，默认值为0表示展示所有的
    })
  );
}
//获取集合竞价趋势
async function ajaxAuctionTrendData() {
  return await axios.post(
    "https://comm.wedengta.com/?s=AuctionStat&f=getAuctionTrendData&req=AuctionTrendReq&rsp=AuctionTrendRsp",
    JSON.stringify({
      iAuctionTrendType: 3, // 竞价趋势数据类型，1:竞价看多  2：竞价看空  3：全部数据
      iDisplayCount: 10, // 展示的数量，默认值为0表示展示所有的
    })
  );
}
