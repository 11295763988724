import axios from "axios";
import { getUpdateDate } from "@/utils/utils.js";

const plateQuoteTypeObj = {
  '行业': 3,
  '概念': 4,
  '地域': 2,
}
const sortTypeObj = {
  '涨幅': 1,
  '跌幅': 2,
}
const typeObj = {
  '行业': 32,
  '概念': 33,
  '地域': 31,
}
export function getPlateAreaData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            let date = await getUpdateDate()
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
                date,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
  if (options.capital === '涨跌幅') {
    return `${options.type}${options.limit}榜`
  }
  if (options.capital === '主力资金') {
    return `${options.type}资金${options.limit === '涨幅' ? '流入' : '流出'}榜`
  }
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data = []
        if (options.limit && options.capital === '涨跌幅') {
          data = await ajaxPlateQuote({
            plateQuoteType: plateQuoteTypeObj[options.type],
            sortType: sortTypeObj[options.limit],
          })
          data = JSON.parse(data.data.content).vPlateQuoteDesc
          handleAjaxPlateArise(data, sortTypeObj[options.limit])
        }
        if (options.limit && options.capital === '主力资金') {
          data = await ajaxMarketQuotation({
            setType: typeObj[options.type],
            sortType: sortTypeObj[options.limit],
          })
          data = JSON.parse(data.data.content).vCapitalDetailDesc
          let data1 = await ajaxSimpleQuote(data.map(item => item.sDtSecCode))
          data1 = JSON.parse(data1.data.content).vSecSimpleQuote
          handleAjaxPlateFund(data, data1, plateQuoteTypeObj[options.type])
        }
        resolve(data)
    })
}
function getSecNameTxt(name) {
  var nameTxt = name;
  if (name.length >= 6) {
      nameTxt = '<i>' + name + '</i>';
  }
  return nameTxt;
}
function getFundNumTxt(num) {
  var txt;
  if (Math.abs(num) > 100000000) {
      txt = (num / 100000000).toFixed(2) + '<i>亿</i>';
  } else if (Math.abs(num) > 10000) {
      txt = (num / 10000).toFixed(2) + '<i>万</i>';
  } else {
      txt = (num).toFixed(2) + '<i>元</i>';
  }
  return txt;
}
function getRateByData(liData) {
  var sRate = ''; //涨跌幅，带+ -符号和百分比
  var sRateNum = ''; //sRate的值，不带+ -符号和百分比，排序时用，如果停牌就给''
  var className = '';
  var eveClassName = ''; //热点里边框式涨跌样式名  eve_fall,eve_stop,eve_rise
  var classNameNum = ''; //表示没有涨跌幅num和num_stop
  var fNow = liData.fNow;
  var fClose = liData.fClose;
  var eSecStatus = liData.eSecStatus;
  var iTpFlag = liData.iTpFlag < 2 ? 2 : liData.iTpFlag;
  //有停牌状态
  if (eSecStatus == "1") {
      sRate = '停牌';
      sRateNum = '';
      className = 'num_stop';
      eveClassName = 'eve_stop';
      classNameNum = 'num';
      fNow = fClose;
      //没有停牌状态，但fnow为0，我们认为是集合竞价，显示--，fnow如不为0则正常显示
  } else {
      classNameNum = 'num';
      if (fNow / 1 === 0) {
          sRate = '--';
          sRateNum = '';
          className = 'num_stop2';
          eveClassName = 'eve_stop';
          fNow = '--';
      } else {
          var rate = (Math.abs((fNow - fClose) / fClose) * 100).toFixed(2);
          sRateNum = ((fNow - fClose) / fClose) * 100;
          if (fClose > fNow) {
              sRate = '-' + rate + '%';
              className = 'num_fall';
              eveClassName = 'eve_fall';
          } else if (fClose == fNow) {
              sRate = rate + '%';
              className = 'num_stop2';
              eveClassName = 'eve_stop';
          } else {
              sRate = '+' + rate + '%';
              className = 'num_rise';
              eveClassName = 'eve_rise';
          }
          fNow = fNow.toFixed(iTpFlag);
      }
  }
  return {
      fNow: fNow,
      sRate: sRate,
      sRateNum: sRateNum,
      className: className,
      eveClassName: eveClassName,
      classNameNum: classNameNum
  };
}
function getAriseClass(num) {
  var ariseClass;
  var ariseClassArr = [3, 2, 1, 0, -1, -2, -3];

  if (num >= ariseClassArr[0]) {
      ariseClass = 'rise_lev4';
  } else if (num < ariseClassArr[0] && num >= ariseClassArr[1]) {
      ariseClass = 'rise_lev3';
  } else if (num < ariseClassArr[1] && num >= ariseClassArr[2]) {
      ariseClass = 'rise_lev2';
  } else if (num < ariseClassArr[2] && num > ariseClassArr[3]) {
      ariseClass = 'rise_lev1';
  } else if (num == ariseClassArr[3]) {
      ariseClass = 'rise_lev0';
  } else if (num > ariseClassArr[4] && num < ariseClassArr[3]) {
      ariseClass = 'fall_lev1';
  } else if (num > ariseClassArr[5] && num <= ariseClassArr[4]) {
      ariseClass = 'fall_lev2';
  } else if (num > ariseClassArr[6] && num <= ariseClassArr[5]) {
      ariseClass = 'fall_lev3';
  } else {
      ariseClass = 'fall_lev4';
  }
  return ariseClass;
}
function getFundClass(num) {
  var ariseClass;
  if (num > 0) {
      ariseClass = 'rise_lev4';
  } else if (num == 0) {
      ariseClass = 'rise_lev0';
  } else if (num < 0) {
      ariseClass = 'fall_lev4';
  }
  return ariseClass;
}
function handleAjaxPlateArise(data, sortType) {
  for (var i = 0; i < data.length; i++) {
      data[i].arise = 1 * ((100 * (data[i].fNow - data[i].fClose) / data[i].fClose).toFixed(2));
      data[i].ariseTxt = (data[i].arise).toFixed(2) + '%';
      data[i].sSecNameTxt = getSecNameTxt(data[i].sSecName);
      data[i].class = getAriseClass(1 * (data[i].arise).toFixed(2));
      data[i].headArise = (data[i].fHeadNow - data[i].fHeadClose) / data[i].fHeadClose;

      //兼容处理板块下股票名
      if (data[i].sHeadName == '') {
          data[i].sHeadName = '--';
      }
      //兼容处理板块下股票涨跌幅
      if (isNaN(data[i].headArise)) {
          data[i].headAriseTxt = '--';
      } else {
          data[i].headAriseTxt = (100 * data[i].headArise).toFixed(2) + '%';
      }
  }
//   if (sortType == 1) {
//       data = data.sort(function (a, b) {
//           return b.arise - a.arise;
//       });
//   } else if (sortType == 2) {
//       data = data.sort(function (a, b) {
//           return a.arise - b.arise;
//       });
//   }
}
function handleAjaxPlateFund(data, quoteData, finalType) {
  if (data.length === 0) {
      return;
  }

  for (var i = 0; i < data.length; i++) {
      data[i].fundNumTxt = getFundNumTxt(data[i].fZljlr);
      data[i].class = getFundClass(1 * (data[i].fZljlr).toFixed(2));
      data[i].ariseTxt = '--';
      data[i].sSecNameTxt = getSecNameTxt(data[i].sSecName);

      for (var j = 0; j < quoteData.length; j++) {
          if (data[i].sDtSecCode == quoteData[j].sDtSecCode) {
              data[i].ariseTxt = getRateByData(quoteData[j]).sRate;
          }
      }
  }
//   if (finalType == 3) {
//       data = data.sort(function (a, b) {
//           return b.fZljlr - a.fZljlr;
//       });
//   } else if (finalType == 4) {
//       data = data.sort(function (a, b) {
//           return a.fZljlr - b.fZljlr;
//       });
//   }
}
async function ajaxPlateQuote(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getPlateQuote&req=PlateQuoteReq&rsp=PlateQuoteRsp",
        JSON.stringify({
          ePlateQuoteReqType: options.plateQuoteType,  //榜单类型,2地域 3行业 4概念
          iWantnum: 20,                       //要多少个
          ePlateQuoteSortType: options.sortType        //1涨幅，2跌幅
        })
      );
}
async function ajaxSimpleQuote(options) {
  return await axios.post(
      "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
      JSON.stringify({
        vDtSecCode: options
      })
    );
}
async function ajaxPlateHisQuote(options) {
  return await axios.post(
      "https://comm.wedengta.com/?s=singal&f=getPlateHisQuote&req=GetPlateHisQuoteReq&rsp=GetPlateHisQuoteRsp",
      JSON.stringify({
        iPlateType: options.plateType,      // 板块类型：3-行业板块；4-概念板块
        iCycleType: options.CycleType       //0周  1月  2半年  3年
      })
    );
}
async function ajaxMarketQuotation(options) {
  return await axios.get("https://sec.wedengta.com/getMarketQuotation", {
    params: {
      action: 'CapitalFlow',
      marketType: 0,
      start: 0,
      count: 20,
      setType: options.setType, // 32行业 33概念 31地区
      sortType: options.sortType, // 1 涨幅 2跌幅
      dataType: 1,
      colType: 44
    },
  });
}