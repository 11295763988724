<template>
    <div class="image">
        <div class="img_bg" ref="imgBg">
            <img :src="dataSource.url" alt="">
        </div>
        <div class="img_msg">
            <p>{{ dataSource.materialName }}</p>
            <div>
                <span>属性：</span>
                <span>图片</span>
            </div>
            <div class="oneRow">
                <div>
                    <span>尺寸：</span>
                    <span>{{ `${imgMsg.width}*${imgMsg.height}`}}</span>
                </div>
                <div>
                    <span>大小：</span>
                    <span>{{ imgMsg.size }}MB</span>
                </div>
            </div>
            <div>
                <span>标签：</span>
                <span>{{ dataSource.materialTag}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            imgMsg: {
                width: '',
                height: '',
                size: '',
            },
        }
    },
    created() {
        this.getImg(this.dataSource.url)
    },
    methods: {
        getImg(url) {
            this.API.search.getOssImageInfo({
                content: `${url}?x-oss-process=image/info`
            }).then((res) => {
                if (res?.data) {
                    this.imgMsg = {
                        width: res.data.ImageWidth.value,
                        height: res.data.ImageHeight.value,
                        size: Number(res.data.FileSize.value / 1024 / 1024).toFixed(2)
                    }
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.image {
    width: 100%;
    display: flex;
    flex-direction: column;
    .img_bg {
        background: #1B1B26;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            max-height: 100%;
            max-width: 100%;
        }
    }
    .img_msg {
        flex: 1;
        color: rgba(255, 255, 255, 0.5);
        > p {
            font-size: 14px;
            margin-top: 10px;
        }
        > div {
            font-size: 14px;
            margin-top: 10px;
        }
        .oneRow {
            display: flex;
            > div {
                margin-right: 14px;
            }
        }
    }
}
</style>