<template>
    <el-dialog
        :visible.sync="visible"
        title="添加素材"
        :close-on-click-modal="false"
        width="420px"
        @close="close"
    >
        <div class="create_content">
            <el-radio-group v-model="radio" v-if="groupList.length">
                <div v-for="(item, index) in groupList" :key="index" class="group_radio">
                    <el-radio :label="index">{{ item.categoryId ? item.categoryName : ''}}</el-radio>
                    <EditName :value="item" @getGroupList="getGroupList" ref="reName" v-if="!item.categoryId" />
                </div>
            </el-radio-group>
            <div class="noData" v-else>
                暂无分组
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <span @click="addGroup">
                <i class="el-icon-circle-plus-outline"></i>
                创建分组
            </span>
            <div>
                <el-button @click="close" size="small">取消</el-button>
                <el-button @click="submit" type="primary" size="small">确定</el-button>
            </div>
        </span>
    </el-dialog>
</template>
<script>
import EditName from './EditName.vue'
export default {
    data: function() {
        return {
            visible: false,
            radio: 0,
            groupList: [],
            materialDetail: {}
        }
    },
    components: {
        EditName
    },
    methods: {
        open(value) {
            this.getGroupList()
            this.visible = true
            this.materialDetail = value
        },
        close() {
            Object.assign(this.$data, this.$options.data())
        },
        getGroupList(callback, isApi=true) {
            if (isApi) {
                this.API.category.getList({
                    roomId: Number(this.$route.query.id)
                }).then((res) => {
                    if (res?.code === 200) {
                        this.groupList = res.data.reverse()
                        if (callback) {
                            callback()
                        }
                    } else {
                        this.$message.error('获取分组失败')
                    }
                })
            } else {
                this.groupList = this.groupList.filter(item => item.categoryName)
                if (callback) {
                    callback()
                }
            }
        },
        addGroup() {
            this.groupList = [{
                categoryId: undefined,
                categoryName: '',
            }, ...this.groupList]
            this.$nextTick(() => {
                this.radio = 0
                this.$refs.reName[0].beginReName()
            })
        },
        submit() {
            this.API.category.addMaterial2Category({
                categoryId: this.groupList[this.radio].categoryId,
                // materialId: `${this.materialDetail.materialType}_${this.materialDetail.materialId}`,
                materialType: this.materialDetail.materialType,
                materialShowJson: JSON.stringify(this.materialDetail),
            }).then((res) => {
                if (res?.code === 200) {
                    this.$message.success('添加分组成功！')
                    this.close()
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
  padding: 8px 20px 0px;
  .el-radio-group {
    width: 100%;
  }
  .create_content {
        background: #121212;
        
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        color: #fff;
        .el-radio-group {
            margin: 10px 0;
            padding: 0 20px;
            box-sizing: border-box;
            max-height: 300px;
            min-height: 120px;
            overflow-y: scroll;
            overflow-x: hidden;
            // &::-webkit-scrollbar { display: none; }
            &::-webkit-scrollbar {
                width: 0.2rem;
            }
        }
        .noData {
            box-sizing: border-box;
            min-height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .group_radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > label {
                margin-right: 0;
                display: flex;
                align-items: center;
                height: 40px;
                .el-radio__inner {
                    width: 20px;
                    height: 20px;
                    margin-top: 2px;
                    &::after {
                        box-sizing: content-box;
                        content: "";
                        border: 2px solid #fff;
                        border-left: 0;
                        border-top: 0;
                        height: 9px;
                        left: 6px;
                        position: absolute;
                        top: 2px;
                        transform: rotate(45deg) scaleY(0);
                        width: 4px;
                        transition: transform .15s ease-in .05s;
                        transform-origin: center;
                        border-radius: unset;
                        background-color: unset
                    }
                }
                .el-radio__input.is-checked .el-radio__inner {
                    border-color: #4E8CFF;
                    background: #4E8CFF;
                }
                .el-radio__input.is-checked .el-radio__inner::after {
                    transform: rotate(45deg) scaleY(1);
                }
                .el-radio__label {
                    color: #fff;
                    font-size: 16px;
                }
            }
            > div {
                flex: 1;
            }
        }
  }
}
/deep/ .el-dialog__footer {
    padding: 0px 20px 20px;
    .dialog-footer {
        padding: 0 20px 20px;
        background: #121212;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        > span {
            color: #4E8CFF;
            cursor: pointer;
        }
        .el-button--default {
            border-radius: 4px;
            border: 1px solid #4E8CFF;
            background: #252525;
            color: #4E8CFF;
            margin-right: 16px;
        }
        .el-button--primary {
            border: 1px solid #4E8CFF;
            background: #4E8CFF;
        }
    }
}
</style>