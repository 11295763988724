<template>
    <div class="tool_power">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <div class="tool_three_stock">
                <PowerStock :data="activeWindowCustomChartMsg" @changePowerStock="changePowerStock" />
            </div>
            <div class="tool_three_key">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="small" @change="changePowerKey()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="getPowerArr()" class="tool_three_type">
                <h3>筛选条件</h3>
                <el-radio-group v-model="tool_filter_three" size="small" @change="changePower()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool[getPowerArr()]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import PowerStock from '../components/PowerStock/index.vue';
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    components: {
        PowerStock
    },
    methods: {
        // 个股财报&&财报比对
        getPowerArr() {
            const obj = this.tool.tooObj[this.activeWindowCustomChartMsg.materialName].filter(item => item.value === this.tool_filter_two)[0]
            return obj?.key
        },
        changePowerStock(value) {
            this.tool_filter_one = value
            this.tool_filter_two = '综合对比'
            this.tool_filter_three = ''
            this.changePower()
        },
        changePowerKey() {
            if (!this.tool_filter_one) {
                this.tool_filter_two = ''
                return this.$message.info('请先选择股票！')
            } 
            switch (this.tool_filter_two) {
                case '盈利能力对比':
                    this.tool_filter_three = '净资产收益率(ROE)'
                    break;
                case '偿债能力对比':
                    this.tool_filter_three = '流动比率'
                    break;
                case '成长能力对比':
                    this.tool_filter_three = '营业总收入增长率'
                break;
                case '运营能力对比':
                    this.tool_filter_three = '营业周期'
                break;
                default:
                    this.tool_filter_three = ''
                    break;
            }
            this.changePower()
        },
        changePower() {
            this.$emit('setCustomWindowChart', {
                    ...this.activeWindowCustomChartMsg,
                    remark: JSON.stringify({
                        type: this.tool_filter_two,
                        compareStocks: this.tool_filter_one,
                        base: this.tool_filter_three || undefined,
                    })
                }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.map(item => item.secname).join('与')}-${this.tool_filter_two}${this.tool_filter_three ? `-${this.tool_filter_three}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            type: this.tool_filter_two,
                            compareStocks: this.tool_filter_one,
                            base: this.tool_filter_three || undefined,
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    },
}
</script>
<style lang="less" scoped>
.tool_power {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        .tool_three_stock {
            width: 160px;
            margin-right: 30px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
        }
        .tool_three_key {
            width: 400px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
        }
        .tool_three_type {
            flex: 1;
            margin-left: 20px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
        }
        /deep/ .el-radio-group {
            display: flex;
            flex-wrap: wrap;
            .el-radio-button__inner {
                background: #2E2F30;
                color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                border: #2E2F30;
                margin-bottom: 4px;
                margin-right: 4px;
            }
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background: #767676;
            border: #767676;
            color: #fff;
            box-shadow: unset
        }
    }
}
</style>