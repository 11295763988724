<template>
    <div class="myMaterial">
        <div class="operation">
            <div class="search">
                <el-input
                    v-model="keyword"
                    placeholder="搜索素材"
                    @change="getList"
                    suffix-icon="el-icon-search"
                    clearable
                    :disabled="allowMove"
                >
                </el-input>
                <!-- <el-button type="primary" :disabled="keyword.length > 0" @click="goMove">{{ allowMove ? '保存' : '调整顺序'}}</el-button> -->
            </div>
            <el-button type="primary" icon="el-icon-plus" @click="uploadImg" :disabled="allowMove">本地上传</el-button>
        </div>
        <div class="list" v-loading="loading">
            <cardDragger :data="data" :colNum="3" @finishDrag="finishDrag" :allowMove="allowMove" >
                <template v-slot:content="slotProps">
                    <ResourceItem
                        :itemData="slotProps.item"
                        :markerVisible="true"
                        :popoverVisible="!allowMove"
                        :screenVisible="!allowMove"
                        materialSource="本地上传"
                        :numVisible="allowMove"
                        :active="active"
                        @get-list="getList"
                        @change-active="changeActive"
                    />
                </template>
            </cardDragger>
        </div>
        <div class="operation_screen">
            <!-- <el-popover
                placement="top"
                trigger="hover"
                content="点击可上屏上一个素材"
                popper-class="screenTip"
            >
                <el-button type="info" size="medium" slot="reference" @click="prevScreen" :disabled="allowMove">上一个</el-button>
            </el-popover>
            <span>当前应用第{{ activeIndex === undefined ? 0 : activeIndex + 1}}个素材</span>
            <el-popover
                placement="top"
                trigger="hover"
                content="点击可上屏下一个素材"
                popper-class="screenTip"
            >
                <el-button type="info" size="medium" slot="reference" @click="nextScreen" :disabled="allowMove">下一个</el-button>
            </el-popover> -->
            <el-button type="danger" size="medium" @click="downScreen" :disabled="allowMove">下屏</el-button>
        </div>
        <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
        <CropperDialog ref="cropper" :fixed="true" @receiveData="receiveData" />
    </div>
</template>
<script>
import ResourceItem from '../components/resourceItem/index.vue'
import cardDragger from '@/components/cardDragger'
import CropperDialog from '@/components/cropperDialog'
import debounce from 'lodash.debounce'
export default {
    data: function() {
        return {
            keyword: '',
            active: '',
            activeIndex: undefined,
            allowMove: false,
            data: [],
            loading: false,
            pageNum: 1,
            pageSize: 1000,
            sortArr: []
        }
    },
    components: {
        ResourceItem,
        cardDragger,
        CropperDialog
    },
    created() {
        this.getList()
        this.getList = debounce(this.getList, 500)
    },
    mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
            this.activeIndex = undefined
        })
    },
    methods: {
        downScreen() {
            this.$bus.$emit('downScreen')
        },
        uploadImg() {
            this.$refs.fileInput.click()
        },
        batchImport(e) {
            const [file] = e.target.files;
            const checkSize = this.imgBeforeUpload(file)
            if (checkSize) {
                this.$refs.cropper.openDialog(file)
            }
             // 清空input的值
            this.$refs.fileInput.value = ''
        },
        //图片上传前，检查大小是否超出
        imgBeforeUpload(file) {
            const isFileSizeFit = file.size / 1024 / 1024 < 5;
            const isLtM = file.type.indexOf('image/') !== -1
            if (!isLtM) {
                this.$message.error("请上传图片");
            } else {
                if (!isFileSizeFit) {
                    this.$message.error("图片大小不能超过 5MB!");
                }
            }
            return isFileSizeFit && isLtM;
        },
        receiveData(value) {
            this.API.myMaterial.addMaterial({
                materialClass: 0,
                materialName: value.name,
                materialType: 0,
                url: value.url,
                remark: '本地上传',
                roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
            }).then((response) => {
                if (response && response instanceof Object && response.code === 200) {
                    this.getList()
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        changeActive(value) {
            this.active = value
            this.data.forEach((item, index) => {
                if (item.id === value) {
                    this.activeIndex = index
                }
            })
        },
        goMove() {
          this.allowMove = !this.allowMove
          // 当取消调整的时候，修改我的中素材顺序
          if (!this.allowMove && this.sortArr.length > 0) {
            this.sort({
                list: this.sortArr.map((item, index) => {
                    return {
                        materialId: item,
                        showIndex: index + 1
                    }
                })
            })
          }
        },
        finishDrag(OldPositon,NewPositon,selectMenuData) {
            let arr = []
            let domArr = [...document.getElementsByClassName('positionNum')]
            domArr.forEach((item) => {
                let id = item.getAttribute('data-id')
                arr[Number(item.innerText) - 1] = id
            })
            this.sortArr = arr
        },
        sort(params) {
            this.API.myMaterial.sort({
                roomId: Number(this.$route.query.id),
                ...params
            })
        },
        getList() {
            this.loading = true
            this.API.myMaterial.getList({
                // materialType: isNaN(Number(this.type)) ? undefined : Number(this.type), type 全部|all 图片|0 数图|1 视频|2 资讯|3 工具|5
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                roomId: Number(this.$route.query.id),
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    if (!this.keyword) {
                        this.data = res.data.list.map((item, index) => {
                            return {
                                ...item,
                                positionNum: index + 1,
                                id: `card${item.id}`
                            }
                        })
                    } else {
                        this.data = res.data.list.filter((value) => {
                            return value.materialName.indexOf(this.keyword) !== -1
                        }).map((item, index) => {
                            return {
                                ...item,
                                positionNum: index + 1,
                                id: `card${item.id}`
                            }
                        })
                    }
                } else {
                    this.data = []
                    this.$message.error('获取失败！')
                }
                this.loading = false
            })
        },
        prevScreen() {
            if (this.activeIndex === undefined || this.activeIndex === 0) {
                this.activeIndex = this.data.length - 1
            } else {
                this.activeIndex -= 1
            }
            let current = this.data.filter((item) => {
                return item.positionNum === this.activeIndex + 1
            })
            this.$bus.$emit('upScreen', current[0])
            this.active = current[0].id
        },
        nextScreen() {
            if (this.activeIndex === undefined) {
                this.activeIndex = 0
            } else if (this.activeIndex === this.data.length - 1) {
                this.activeIndex = 0
            } else {
                this.activeIndex += 1
            }
            let current = this.data.filter((item) => {
                return item.positionNum === this.activeIndex + 1
            })
            this.$bus.$emit('upScreen', current[0])
            this.active = current[0].id
        }
    },
}
</script>

<style lang="less" scoped>
.myMaterial {
    padding: 20px 0 6px 0;
    height: calc(100vh - 61px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    > ul {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-left: 14px;
        > li {
            margin-right: 24px;
            color: #fff;
            cursor: pointer;
            padding: 2px 6px;
            &:hover {
                background: rgba(78, 140, 255, 0.2);
                border-radius: 4px;
            }
        }
        .active {
            background: rgba(78, 140, 255, 0.2);
            border-radius: 4px;
        }
    }
    .operation {
        padding: 0 0 0 14px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .search {
            flex: 1;
            display: flex;
            align-items: center;
            > button {
                margin-left: 24px;
            }
            /deep/ .el-input__inner {
                background: rgb(32,32,32);
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
        > button {
            background: #4E8CFF;
            border: 1px solid #4E8CFF;
            margin-left: 24px;
        }
    }
    .list {
        overflow-y: scroll;
        flex: 1;
    }
    /deep/ .el-loading-mask {
      background: #16181A;
    }
    .operation_screen {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 24px;
        margin-top: 20px;
        > span {
            color: #a6a9ad;
            font-size: 12px;
            margin: 0 20px;
        }
        .el-button--danger {
            background-color: #FF3939;
            border-color: #FF3939;
        }
        .el-button--danger:hover {
            background-color: #F56C6C;
            border-color: #F56C6C;
        }
    }
}
</style>