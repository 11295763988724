<template>
    <div class="materialMessage">
        <div class="input">
            <el-input
                v-model="keyword"
                ref="keywordInput"
                placeholder="搜索资讯素材"
                @change="searchList"
                suffix-icon="el-icon-search"
                clearable
                @clear="handleClear"
            >
            </el-input>
        </div>
        <div class="img_list">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <ResourceItem
                    :itemData="item"
                    :active="active"
                    @change-active="changeActive"
                    :markerVisible="false"
                    materialSource="资讯"
                    :deleteVisible="false"
                    v-for="(item, index) in data"
                    :key="index"
                />
            </div>
            <span>{{ loading ? '正在加载更多' : '暂无更多'}}</span>
        </div>
    </div>
</template>
<script>
import ResourceItem from '../../components/resourceItem/index.vue'

export default {
    data: function() {
        return {
            keyword: '',
            active: '',
            data: [],
            loading: false,
            disabled: false,
            sStartId: '0'
        }
    },
    components: {
        ResourceItem
    },
    mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeActive(value) {
            this.active = String(value)
        },
        handleClear() {
             this.$refs.keywordInput.blur()
        },
        async getList() {
            this.loading = true;
            this.API.news.getList({
               roomId: Number(this.$route.query.id),
               sStartId: this.sStartId
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.data = this.data.concat(res.data.list.map((item) => {
                        return {
                            ...item,
                            id: item.newsId
                        }
                    }));
                    this.sStartId = res.data.nextId
                } else {
                    this.$message.error(res.message)
                }
                this.loading = false;
            })
        },
        // 滚动加载
        load() {
            if (!this.disabled) {
                this.getList();
            }
        },
        searchList() {
            this.data = []
            if (this.keyword) {
                this.loading = true
                this.disabled = true
                this.API.search.searchNews({
                    content: this.keyword
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.data = res.data.map((item) => {
                            return {
                                ...item,
                                id: item.newsId
                            }
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                    this.loading = false;
                })
            } else {
                this.disabled = false
                this.sStartId = '0'
                this.getList()
            }
        }
    },
}
</script>
<style lang="less" scoped>
.materialMessage {
    height: 100%;
    .input {
        padding: 0 24px 0 14px;
        box-sizing: border-box;
        /deep/ .el-input__inner {
            background: rgb(32,32,32);
            border-color: rgba(255, 255, 255, 0.08);
            color: #fff;
        }
    }
    .img_list {
        margin-top: 20px;
        padding-top: 2px;
        height: calc(100% - 60px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 0 15px;
            }
        }
        > span {
            color: #fff;
        }
    }
}
</style>