<template>
    <el-menu
        :default-active="isActive"
        class="detail-menu"
        background-color="#1C1E1F"
        text-color="#fff"
        active-text-color="#4e8cff"
        @select="selectMenu"
    >
        <el-submenu index="live_set">
            <template slot="title">设置</template>
            <el-menu-item index="materialBackground">背景</el-menu-item>
            <el-menu-item index="component">画面组件</el-menu-item>
        </el-submenu>
        <el-submenu index="publish_material">
            <template slot="title">素材</template>
            <el-menu-item index="materialPhoto">图片</el-menu-item>
            <el-menu-item index="materialNumPic">数图</el-menu-item>
            <el-menu-item index="materialMessage">资讯</el-menu-item>
            <el-menu-item index="materialTool">工具</el-menu-item>
        </el-submenu>
        <el-menu-item index="common_material">
            <span slot="title">常用素材</span>
        </el-menu-item>
        <el-menu-item index="my_material">
            <span slot="title">本地上传</span>
        </el-menu-item>
    </el-menu>
</template>
<script>
export default {
    props: {
        isActive: {
            type: String,
            default: 'materialBackground',
        },
        selectMenu: {
            type: Function,
            default: () => {}
        }
    }
}
</script>
<style lang="less" scoped>
.detail-menu {
    border: 0;
    user-select: none;
    width: 7.8vw;
    overflow: hidden;
    .el-submenu .el-menu-item {
        min-width: unset;
    }
    .el-menu-item {
        font-size: 16px;
    }
    /deep/ .el-submenu__title {
        font-size: 16px;
    }
}
</style>