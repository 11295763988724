<template>
    <div class="common_material">
        <div class="material_group">
            <div class="operation_btn">
                <el-button type="primary" size="small" @click="addGroup()">新建分组</el-button>
                <div>
                    <el-button type="primary" size="small" v-if="!isEdit" @click="isEditChange">编辑</el-button>
                    <template v-else>
                        <el-button type="primary" size="small" @click="isEditChange">取消编辑</el-button>
                        <el-button type="primary" size="small" @click="addGroup(activeIndex)">编辑分组</el-button>
                        <el-button type="primary" size="small" @click="checkAll" v-show="materialList.length > 0">
                            {{ materialList.length === materialCheck.length && materialList.length > 0 ? '取消全选' : '全选'}}
                        </el-button>
                        <el-button type="danger" size="small" @click="deleteM" v-show="materialList.length > 0">删除</el-button>
                    </template>
                </div>
            </div>
            <div class="group_list">
                <div class="group_list_name" @mousewheel="mousewheelTitle" id="group" ref="group">
                    <span
                        :class="{ active: index == activeIndex }"
                        class="group_name"
                        v-for="(item, index) in groupList"
                        :key="index"
                        @click="handleCommand(index)"
                    >
                        {{ item.categoryName }}
                    </span>
                </div>
                <el-dropdown @command="handleCommand">
                    <el-button size="small" class="group_more">
                        查看更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(item, index) in groupList"
                            :key="index"
                            :command="index"
                        >
                            {{ item.categoryName }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- :allowDel="groupList.length > 1" -->
            <AddGroupDialog
                ref="addGroup"
                @getGroupList="getGroupList"
            />
        </div>
        <div class="material_list">
            <el-checkbox-group v-model="materialCheck">
                <div v-for="(item, index) in materialList" :key="index">
                    <div @click="isEdit && checkBox(item)">
                        <ResourceItem
                            :itemData="item.materialShowJson"
                            :active="active"
                            @change-active="changeActive"
                            :markerVisible="false"
                            :deleteVisible="false"
                            :groupVisible="false"
                            :popoverVisible="!isEdit"
                            materialSource="常用素材"
                        />
                    </div>
                    <el-checkbox :label="item.id" v-show="isEdit">&nbsp</el-checkbox>
                </div>
            </el-checkbox-group>
            <span>暂无更多</span>
        </div>
        <el-button type="danger" size="medium" @click="downScreen" v-show="materialList.length">下屏</el-button>
    </div>
</template>
<script>
import AddGroupDialog from './AddGroupDialog.vue'
import ResourceItem from '../components/resourceItem'
import $ from "jquery"
export default {
    data: function() {
        return {
            isEdit: false,
            activeIndex: 0,
            groupList: [], // 组集合
            materialCheck: [], // check集合
            materialList: [], // 素材集合
            active: '',

        }
    },
    components: {
        AddGroupDialog,
        ResourceItem
    },
    async created() {
        this.getGroupList().then(() => {
            this.getCategoryMaterialList()
        })
    },
     mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeActive(value) {
            this.active = String(value)
        },
        downScreen() {
            this.$bus.$emit('downScreen')
        },
        // 滚动显示新闻列表
        mousewheelTitle(event) {
            event.stopPropagation();
            event.preventDefault();
            this.$refs["group"].scrollLeft += 0.4 * event.deltaY;
        },
        getGroupList(isDel) {
            return new Promise((resolve) => {
                this.API.category.getList({
                    roomId: Number(this.$route.query.id)
                }).then((res) => {
                    this.groupList = res.data.reverse()
                    if (isDel) {
                        this.handleCommand(this.activeIndex ? this.activeIndex - 1 : 0)
                    } else {
                        if (this.groupList.length < 2) {
                            this.getCategoryMaterialList()
                        }
                    }
                    
                    resolve()
                })
            })
        },
        isEditChange() {
            this.isEdit = !this.isEdit
            this.materialCheck = []
        },
        handleCommand(command) {
            this.activeIndex = command
            let titleSpan = document.querySelectorAll(".group_name");
            $("#group").animate({ scrollLeft: titleSpan[command].offsetLeft }, 500);
            this.getCategoryMaterialList()
        },
        // 新建分组
        addGroup(value) {
            if (value === undefined) {
                this.$refs.addGroup.open(value)
            } else {
                this.$refs.addGroup.open(this.groupList[value])
            }
        },
        // 获取素材
        getCategoryMaterialList() {
            if (this.groupList[this.activeIndex]) {
                this.API.category.getCategoryMaterialList({
                    categoryId: this.groupList[this.activeIndex].categoryId,
                    roomId: Number(this.$route.query.id),
                }).then((res) => {
                    this.materialList = res.data.map((item) => {
                        return {
                            ...item,
                            materialShowJson: {
                                ...JSON.parse(item.materialShowJson),
                                id: item.id,
                            }
                        }
                    })
                })
            } else {
                this.materialList = []
            }
        },
        checkBox(item) {
            // 当是编辑态的时候
            if (this.isEdit) {
                if (this.materialCheck.indexOf(item.id) === -1) {
                    this.materialCheck.push(item.id)
                } else {
                    this.materialCheck = this.materialCheck.filter(value => value !== item.id)
                }
            }
        },
        checkAll() {
            if (this.materialList.length === this.materialCheck.length && this.materialList.length > 0) {
                this.materialCheck = []
            } else {
                this.materialCheck = this.materialList.map(item => item.id)
            }
        },
        deleteM() {
            this.$confirm('确定删除当前选中素材？', '删除素材', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.API.category.deleteMaterialFromCategory({
                    deleteIds: this.materialCheck,
                    roomId: Number(this.$route.query.id)
                }).then((res) => {
                    if (res?.code === 200) {
                        this.materialCheck = []
                        this.getCategoryMaterialList()
                    }
                }) 
            }).catch(() => {
                console.log('取消删除')  
            });
        }
    }
}
</script>
<style lang="less" scoped>
.common_material {
    padding: 8px 0 6px 0;
    // height: calc(100vh - 70px);
    height: calc(100vh - 61px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .material_group {
        padding-left: 14px;
        .operation_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #393D40;
            padding-bottom: 6px;
            margin-bottom: 6px;
            /deep/ .el-button--primary {
                background-color: inherit;
                border-color: #4E8CFF;
                color: #4E8CFF;
            }
            /deep/ .el-button--danger {
                background-color: inherit;
                color: #F56C6C;
            }
        }
        .group_list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            .group_list_name {
                display: flex;
                align-items: center;
                overflow-x: auto;
                overflow-y: hidden;
                flex: 1;
                // width: 100%;
                margin-right: 16px;
                &::-webkit-scrollbar { display: none; }
                position: relative;
                > span {
                    display: flex;
                    align-items: center;
                    white-space:nowrap;
                    margin-right: 12px;
                    cursor: pointer;
                    color: rgba(255, 255, 255, 0.4);
                    padding: 6px 8px;
                    border: 1px solid rgba(255,255,255,0.2);
                    border-radius: 4px;
                    &::before {
                        content: " ";
                        width: 14px;
                        height: 14px;
                        background-image: url('../../../../../assets/images/detail/group.svg');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin-right: 8px;
                    }
                    &:hover {
                        color: #fff;
                        &::before {
                            background-image: url('../../../../../assets/images/detail/group_active.svg');
                        }
                    }
                }
                .active {
                    background: #4E8CFF;
                    border-color: #4E8CFF;
                    color: #fff;
                    &::before {
                        background-image: url('../../../../../assets/images/detail/group_active.svg');
                    }
                }
            }
            .group_more {
                background: #16181A;
                border-color: rgba(255,255,255,0.2);
                color: rgba(255, 255, 255, 0.4);
                &:hover {
                    color: #fff;
                }
            }
        } 
    }
    .material_list {
        margin-top: 20px;
        padding-top: 2px;
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .el-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 0 15px;
                position: relative;
                > label {
                    position: absolute;
                    top: 8px;
                    right: 6px;
                    /deep/ .el-checkbox__label {
                    padding: 0;
                    }
                }
            }
        }
        > span {
            color: #fff;
        }
    }
    > button {
       margin-top: 8px;
       margin-right: 24px;
       align-self: flex-end;
        background-color: #FF3939;
        border-color: #FF3939;
        &:hover {
            background-color: #F56C6C;
            border-color: #F56C6C;
        }
    }
}

</style>
