<template>
    <div class="materialPhoto">
        <el-autocomplete
            v-model="keyword"
            ref="keywordInput"
            placeholder="搜索图片素材"
            :fetch-suggestions="queryLabel"
            @select="handleSelect"
            @change="searchList"
            @clear="handleClear"
            :debounce="500"
            suffix-icon="el-icon-search"
            clearable
        >
            <template slot-scope="{ item }">
                <div class="name">{{ item.value }}</div>
            </template>
        </el-autocomplete>
        <div class="img_list">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <ResourceItem
                    :itemData="item"
                    :active="active"
                    @change-active="changeActive"
                    :markerVisible="false"
                    :deleteVisible="false"
                    materialSource="图片"
                    v-for="(item, index) in data"
                    :key="index"
                />
            </div>
            <span>{{ loading ? '正在加载更多' : '暂无更多'}}</span>
        </div>
    </div>
</template>
<script>
import ResourceItem from '../../components/resourceItem/index.vue'
export default {
    data: function() {
        return {
            keyword: '',
            active: '',
            data: [],
            pageNum: 1,
            pageSize: 21,
            endPageNum: 1,
            loading: false,
        }
    },
    components: {
        ResourceItem
    },
    computed: {
        disabled() {
            return this.pageNum >= this.endPageNum
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeActive(value) {
            this.active = String(value)
        },
       getList(params) {
            this.loading = true;
            this.API.material.getList({
                materialClass: 0,
                materialType: 0,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                roomId: Number(this.$route.query.id),
                searchKeyword: this.keyword || undefined,
                range: 0,
                ...params
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.data = this.data.concat(res.data.list);
                    this.endPageNum = res.data.pages
                } else {
                    this.$message.error(res.message)
                }
                this.loading = false;
            })
        },
        // 滚动加载
        load() {
            if (!this.disabled) {
                this.pageNum += 1;
                this.getList();
            }
        },
        searchList() {
            this.pageNum = 1
            this.data = []
            this.getList()
        },
        handleSelect(item) {
            this.keyword = item.value
            this.searchList()
        },
        handleClear() {
            this.$refs.keywordInput.$el.getElementsByTagName('input')[0].blur()
        },
        queryLabel(queryString, cb) {
            this.API.search.searchTags({
                content: queryString
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    cb(res.data.map((item) => {
                        return {
                            value: item
                        }
                    }))
                } else {
                    cb([])
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.materialPhoto {
    height: 100%;
    .el-autocomplete {
        width: 100%;
        padding: 0 24px 0 14px;
        box-sizing: border-box;
    }
    /deep/ .el-input__inner {
        background: rgb(32,32,32);
        border-color: rgba(255, 255, 255, 0.08);
        color: #fff;
    }
    .img_list {
        margin-top: 20px;
        padding-top: 2px;
        height: calc(100% - 60px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 0 15px;
            }
        }
        > span {
            color: #fff;
        }
    }
}
</style>