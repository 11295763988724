<template>
    <div class="tool">
        <div class="tool_bg" v-loading="loading">
             <iframe :src="url" frameborder="0" ref="refChart"></iframe>
        </div>
        <div class="tool_operation">
            <div>
                <h3>名称</h3>
                <template v-if="['市场强度', '财报比对', '北向资金', '个股财报', '个股百科', '财务业绩'].indexOf(dataSource.materialName) !== -1">
                    <el-select v-model="tool_filter_one" filterable size="medium" placeholder="请选择" @change="oneObj[dataSource.materialName]()">
                        <el-option
                            v-for="(item, index) in tool.tooObj[dataSource.materialName]"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
                <template v-else>
                    <el-radio-group
                        v-model="tool_filter_one"
                        size="medium"
                        @change="oneObj[dataSource.materialName]()"
                    >
                        <el-radio-button
                            :label="item.value"
                            v-for="(item, index) in tool.tooObj[dataSource.materialName]"
                            :key="index"
                        >
                            {{ item.label }}
                        </el-radio-button>
                    </el-radio-group>
                </template>
                <p>注：点击名称可切换预览</p>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../tool.js'
import { handleChart } from '../general.js'
import { sleep } from "@/utils/utils.js";
export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            loading: false,
            url: `${location.origin}/preview/northFundChart`,
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            tool_filter_five: '',
            tool_filter_six: '',
            oneObj: {
                '行情': this.changeScene,
                '板块': this.changeScene,
                '竞价': this.changeFilterType,
                '北向资金': this.changeFilterType,
                '南向资金': this.changeFilterType,
                '融资融券': this.changeFilterType,
                '龙虎榜': this.changeFilterType,
                '大宗交易': this.changeFilterType, 
                '财报比对': this.changePowerType,
                '个股财报': this.changePowerType,
                '估值交易': this.changeEvaluatingDistType,
                '新股中心': this.changeIpoinfoType,
                '市场强度': this.changeCheckMarketType,
                '财报比对(图)': this.changeResultsContrast,
                '板块地域概念': this.changePlateArea,
                '智能诊股': this.changeEvaluatingDistType,
                '个股百科': this.changeEvaluatingDistType,
                '投资图谱': this.changeInvestmentMap,
                '筹码分布': this.changeChipDist,
                '定增掘金': this.changeNuggets,
                '多空信号': this.changeBsSignal,
                '财务业绩': this.financeAnalysis,
                '科创掘金': this.keChuangGraph,
                '扫雷': this.changeEvaluatingDistType,
                '榜单': this.changeFilterType,
                '价值评估': this.changeEvaluatingDistType,
                "融资追击": this.changeStockTool,
                "商誉分析(大盘)": this.changeFilterType,
                "商誉分析(个股)": this.changeStockTool,
                "历史回看": this.changeStockTool,
                "龙虎榜(个股)": this.changeLongHuStock,
                "相似K线": this.changeSimilarK,
                "策略选股": this.intelliChooseStock,
            },
        }
    },
    created() {
        this.tool = tool
        switch (this.dataSource.materialName) {
            case '融资追击':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '融资买入趋势分析'
                this.changeStockTool()
                break;
            case '商誉分析(个股)':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '最新商誉信息'
                this.changeStockTool()
                break;
            case '历史回看':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '月份历史行情回看'
                this.changeStockTool()
                break;
            case '龙虎榜(个股)':
                this.tool_filter_two = {
                    seccode: '0001002498',
                    secname: '汉缆股份',
                }
                this.tool_filter_one = '基本信息'
                this.changeLongHuStock()
                break; 
            case '行情':
                this.tool_filter_two = {
                    value: '0101601318',
                    label: '中国平安',
                }
                this.tool_filter_one = '分时'
                this.changeScene()
                break;
            case '板块':
                this.tool_filter_two = {
                    value: '2020880046',
                    label: '房地产',
                }
                this.tool_filter_one = '涨幅top10'
                this.changeScene()
                break;
            case '竞价':
                this.tool_filter_one = 'auctionSummary'
                this.changeFilterType()
                break;
            case '北向资金':
                this.tool_filter_one = 'flow'
                this.changeFilterType()
                break;
            case '南向资金':
                this.tool_filter_one = 'flow'
                this.changeFilterType()
                break;
            case '商誉分析(大盘)':
                this.tool_filter_one = '商誉净资产比例统计'
                this.changeFilterType()
                break;
            case '个股财报':
                this.tool_filter_two = [{
                    seccode: '0101601318',
                    secname: '中国平安',
                }]
                this.tool_filter_one = '每股指标'
                this.changePowerType()
                break;
            case '财报比对':
                this.tool_filter_two = [
                    {
                        seccode: '0101601318',
                        secname: '中国平安',
                    }, {
                        seccode: '0101601628',
                        secname: '中国人寿',
                    }
                ]
                this.tool_filter_one = '每股指标'
                this.changePowerType()
                break;
            case '融资融券':
                this.tool_filter_one = 'vtSHMarginTrade'
                this.changeFilterType()
                break;
            case '龙虎榜':
                this.tool_filter_one = 'vSecList'
                this.changeFilterType()
                break;
            case '大宗交易':
                this.tool_filter_one = '大宗交易'
                this.changeFilterType()
                break;
            case '新股中心':
                this.tool_filter_one = '新股申购'
                this.changeIpoinfoType()
                break;
            case '估值交易':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '估值详情'
                this.changeEvaluatingDistType()
                break;
            case '智能诊股':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '机构调研'
                this.changeEvaluatingDistType()
                break;
            case '价值评估':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '营收'
                this.changeEvaluatingDistType()
                break;
            case '个股百科':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '公司介绍'
                this.changeEvaluatingDistType()
                break;
            case '扫雷':
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_one = '风险评估'
                this.changeEvaluatingDistType()
                break;
            case '榜单':
                this.tool_filter_one = '涨幅榜'
                this.changeFilterType()
                break;
            case '市场强度':
                this.tool_filter_one = '当日大盘预警'
                this.changeCheckMarketType()
                break;
            case '财报比对(图)':
                this.tool_filter_one = '综合对比'
                this.tool_filter_two = [
                    { seccode: "0001002340", secname: "上海机场" },
                    { seccode: "0001300001", secname: "中国船舶" }
                ]
                this.changeResultsContrast()
                break;
            case '板块地域概念':
                this.tool_filter_one = '行业'
                this.changePlateArea()
                break;
            case '投资图谱':
                this.tool_filter_one = '十大投资股东'
                this.tool_filter_two = {
                    label: '比亚迪',
                    value: '0001002594'
                }
                this.changeInvestmentMap()
                break;
            case '筹码分布':
                this.tool_filter_one = '全市场盈利比例分布'
                this.changeChipDist()
                break;
            case '定增掘金':
                this.tool_filter_one = '定增计划'
                this.changeNuggets()
                break;
            case '多空信号':
                this.tool_filter_one = '盘中多空趋势'
                this.tool_filter_two = {
                    seccode: '0001300059',
                    secname: '东方财富',
                    industrycode: '2020880057',
                    industryname: '互联网信息服务',
                }
                this.changeBsSignal()
                break;
            case '财务业绩':
                this.tool_filter_one = '盈利能力-ROE'
                this.tool_filter_two = {
                    seccode: '0101601318',
                    secname: '中国平安',
                }
                this.tool_filter_three = 1
                this.financeAnalysis()
                break;
            case '科创掘金':
                this.tool_filter_one = '已申报科创板的公司'
                this.keChuangGraph()
                break;
            case '相似K线':
                this.tool_filter_one = '模拟走势'
                this.tool_filter_two = {
                    seccode: '0001300292',
                    secname: '吴通控股',
                }
                this.tool_filter_three = '二维模型'    
                this.tool_filter_four = '30天'  
                this.tool_filter_five = '沪深市场'  
                this.tool_filter_six = '历史以来'  
                this.changeSimilarK()
                break;
            case '策略选股':
                this.tool_filter_one = '缩量涨停板'
                this.tool_filter_two = '选股策略' 
                this.intelliChooseStock()
                break;
            default:
                break;
        }
    },
    methods: {
        // 策略选股
        intelliChooseStock() {
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType:this.tool_filter_two
                })
            })
        }, 
        // 相似K线
        changeSimilarK() {
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    seccode: this.tool_filter_two.seccode,
                    secname: this.tool_filter_two.secname,
                    similarType:this.tool_filter_three,
                    similarPeriod:this.tool_filter_four,
                    scopeType:this.tool_filter_five,
                    timeType:this.tool_filter_six

                })
            })
        }, 
        // 历史回看、商誉分析、融资追击
        changeStockTool() {
            switch (this.tool_filter_one) {
                case '月份历史行情回看':
                    // 历史回看
                    this.tool_filter_three = 9
                    break;
                case '星期历史行情回看':
                    // 历史回看
                    this.tool_filter_three = 5
                    break;
                case '行业最佳表现个股':
                    // 历史回看
                    this.tool_filter_three = '月份'
                    break;
                default:
                    this.tool_filter_three = undefined
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    seccode: this.tool_filter_two.seccode,
                    secname: this.tool_filter_two.secname,
                    subType: this.tool_filter_three || undefined
                })
            })
        },
        // 龙虎榜(个股)
        changeLongHuStock() {
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    seccode: this.tool_filter_two.seccode,
                    secname: this.tool_filter_two.secname,
                    date: '2022-10-27'
                })
            })
        }, 
        currentFlowDirection(value) {
            if (['fundFlowIndustry', 'stockBuildUp', 'stockHoldList', 'capitalDetail', 'capitalMainFlow'].indexOf(this.tool_filter_one) !== -1) {
                return this.tool_filter_two
            }
            if (this.tool_filter_one === 'flow') {
                return value.materialName === '北向资金' ? '北向' : '南向'
            }
            return undefined
        },
        changeFilterType() {
            switch (this.tool_filter_one) {
                case 'flow':
                    this.tool_filter_two = '当日'
                    break;
                case 'shareRate':
                    this.tool_filter_two = 'A股'
                    break;
                case 'fundFlowIndustry':
                    this.tool_filter_two = 'summary'
                    break;
                case 'fundFlowTop10Stock':
                    this.tool_filter_two = this.dataSource.materialName === '北向资金' ? '深股通' : '港股通(深)'
                    break;
                case 'historyDaily':
                    this.tool_filter_two = this.dataSource.materialName === '北向资金' ? '北向资金' : '南向资金'
                    break;
                case 'stockBuildUp':
                    this.tool_filter_two = this.dataSource.materialName === '北向资金' ? '北向' : '南向'
                    break;
                case 'stockHoldList':
                    this.tool_filter_two = this.dataSource.materialName === '北向资金' ? '北向' : '南向'
                    break;
                case 'longHuSummary':
                    this.tool_filter_two = '深股通'
                    break;
                case 'capitalDetail':
                    this.tool_filter_two = '净流入'
                    break;
                case 'capitalMainFlow':
                    this.tool_filter_two = '涨幅'
                    break;
                case 'longHuReal':
                    this.tool_filter_two = 'summary'
                    break;
                default:
                     this.tool_filter_two = undefined
                    break;
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    flowPeriod: this.tool_filter_one === 'flow' ? this.tool_filter_two : undefined,
                    market: ['flow', 'fundFlowIndustry', 'stockBuildUp', 'stockHoldList'].indexOf(this.tool_filter_one) === -1 ? this.tool_filter_two : undefined,
                    flowDirection: this.currentFlowDirection(this.dataSource)
                })
            })
        },
        // 行情板块
        changeScene() {
            try {
                this.setCustomWindowChart({
                    ...this.dataSource,
                    remark: JSON.stringify({
                        stock_code: this.tool_filter_two?.value,
                        stock_name: this.tool_filter_two?.label,
                        stock_scene: this.tool_filter_one
                    })
                })
            } catch (error) {
                console.log(error)
            }
        },
        changePowerType() {
            switch (this.tool_filter_one) {
                case '每股指标':
                    this.tool_filter_three = '同比'
                    break;
                case '盈利能力':
                    this.tool_filter_three = this.dataSource.materialName === '个股财报' ? '环比' : '最新报'
                    break;
                case '资本结构':
                    this.tool_filter_three = '历史中报'
                break;
                case '偿债能力':
                    this.tool_filter_three = '偿债能力'
                break;
                case '营运能力':
                    this.tool_filter_three = '历史年报'
                break;
                case '筹资活动中产生的现金概况' || '负债和股东权益概况' || '股东收益详情' || '':
                    this.tool_filter_three = '最新报'
                break;
                default:
                    this.tool_filter_three = '同比'
                    break;
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    compareKey: this.tool_filter_one,
                    compareStocks: this.tool_filter_two,
                    compareType: this.tool_filter_three,
                })
            }, 'show')
        },
        // 新股中心
        changeIpoinfoType() {
            try {
                this.setCustomWindowChart({
                    ...this.dataSource,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        seccode: ['上市表现', '等待上市', '新股申购'].indexOf(this.tool_filter_one) !== -1 ? undefined : '0001301239',
                        secname: ['上市表现', '等待上市', '新股申购'].indexOf(this.tool_filter_one) !== -1 ? undefined : '普瑞眼科',
                    })
                })
            } catch (error) {
                console.log(error)
            }
        },
        //  估值交易
        changeEvaluatingDistType() {
            if (['估值详情', 'PE-TTM走势图(倍)', 'PB走势图(倍)', '所在行业估值分布'].indexOf(this.tool_filter_one) !== -1) {
                this.tool_filter_three = ''
                this.tool_filter_four = ''
            }
            if (['个股估值分布', '个股估值回测', '行业估值分布'].indexOf(this.tool_filter_one) !== -1) {
                this.tool_filter_three = '3年'
                this.tool_filter_four = 'PB'
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    seccode: this.tool_filter_two.seccode,
                    secname: this.tool_filter_two.secname,
                    type: this.tool_filter_one,
                    period: this.tool_filter_three || undefined,
                    mode: this.tool_filter_four || undefined
                })
            })
        },
        changeCheckMarketType() {
            if (['当日大盘预警','当日市场资金','当日市场趋势','当日涨停强度','当日跌停强度','涨跌停走势'].indexOf(this.tool_filter_one) !== -1) {
                this.tool_filter_two = ''
                this.tool_filter_three = ''
            } else if (['市场强度'].indexOf(this.tool_filter_one) !== -1) {
                this.tool_filter_two = '个股'
                this.tool_filter_three = ''
            } else if (['涨停板', '连续涨停', '涨停打开'].indexOf(this.tool_filter_one) !== -1) {
                this.tool_filter_two = '涨幅'
                this.tool_filter_three = '当日'
            } else if (['跌停板','连续跌停','跌停打开'].indexOf(this.tool_filter_one !== -1)) {
                this.tool_filter_two = '跌幅'
                this.tool_filter_three = '当日'
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    market: this.tool_filter_two || undefined,
                    time: this.tool_filter_three || undefined
                })
            })
        },
        changeResultsContrast() {
            switch (this.tool_filter_one) {
                case '盈利能力对比':
                    this.tool_filter_three = '净资产收益率(ROE)'
                    break;
                case '偿债能力对比':
                    this.tool_filter_three = '流动比率'
                    break;
                case '成长能力对比':
                    this.tool_filter_three = '营业总收入增长率'
                    break;
                case '运营能力对比':
                    this.tool_filter_three = '营业周期'
                    break;
                default:
                    this.tool_filter_three = undefined
                    break;
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    compareStocks: this.tool_filter_two,
                    base: this.tool_filter_three || undefined,
                })
            })
        },
        changePlateArea() {
            switch (this.tool_filter_one) {
                case '行业':
                    this.tool_filter_two = '涨跌幅'
                    this.tool_filter_three = '涨幅'
                    break;
                case '概念':
                    this.tool_filter_two = '主力资金'
                    this.tool_filter_three = '涨幅'
                    break;
                case '地域':
                    this.tool_filter_two = '涨跌幅'
                    this.tool_filter_three = '跌幅'
                    break;
                default:
                    break;
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    capital: this.tool_filter_two,
                    limit: this.tool_filter_three,
                })
            })
        },
        changeInvestmentMap() {
            let compareStocks = []
            if (this.tool_filter_two) {
                compareStocks.push({
                    seccode: this.tool_filter_two.value,
                    secname: this.tool_filter_two.label,
                })
            }
            if (this.tool_filter_one === '投资关系') {
                compareStocks.push({
                    seccode: '0101601390',
                    secname: '中国中铁',
                })
            }
            if (this.tool_filter_one === '任职情况') {
                this.tool_filter_three = "王传福"
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    compareStocks,
                    person: this.tool_filter_one === '任职情况' ? this.tool_filter_three : undefined
                })
            })
        },
        changeChipDist() {
            if (this.tool_filter_one.indexOf('个股') !== -1) {
                this.tool_filter_three = {
                    label: '格林美',
                    value: '0001002340',
                }
                this.tool_filter_two = "趋势"
            }
            if (this.tool_filter_one === '全市场盈利比例分布') {
                this.tool_filter_three = ''
                this.tool_filter_two = 'all'
            }
            if (this.tool_filter_one === '筹码形态分析') {
                this.tool_filter_three = ''
                this.tool_filter_two = '底部单峰密集型股票'
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType: this.tool_filter_two,
                    seccode: this.tool_filter_three instanceof Object ? this.tool_filter_three.value : undefined,
                    secname: this.tool_filter_three instanceof Object ? this.tool_filter_three.label : undefined,
                })
            })
        },
        changeNuggets() {
            switch (this.tool_filter_one) {
                case '定增计划':
                    this.tool_filter_two = '定增预案'
                    break;
                case '定增破发':
                    this.tool_filter_two = 1
                    break;
                case '定增优选':
                    this.tool_filter_two = 2
                    break;
                default:
                    
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType: this.tool_filter_two,
                })
            })
        },
        changeBsSignal() {
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                   seccode: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_one) !== -1 ? this.tool_filter_two.seccode : undefined,
                    secname: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_one) !== -1 ? this.tool_filter_two.secname : undefined,
                    industrycode: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_one) !== -1 ? this.tool_filter_two.industrycode : undefined,
                    industryname: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_one) !== -1 ? this.tool_filter_two.industryname : undefined,
                    type: this.tool_filter_one
                })
            })
        },
        financeAnalysis() {
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    seccode: this.tool_filter_two.seccode,
                    secname: this.tool_filter_two.secname,
                    type: this.tool_filter_one,
                    seasonType: this.tool_filter_three
                })
            })
        },
        keChuangGraph() {
            let currentType = ''
            if (this.tool_filter_one !== '个股') {
                currentType = this.tool_filter_one
            } else {
                currentType = '(申报方)的详细介绍'
            }
            this.setCustomWindowChart({
                ...this.dataSource,
                remark: JSON.stringify({
                    subType: currentType,
                    compareStocks: this.tool_filter_one !== '个股' ? undefined : [{
                        seccode: '0101601318',
                        secname: '中国平安',
                    }]
                })
            })
        },
        async setCustomWindowChart(item) {
            let orderObj = {
                '行情': `${location.origin}/preview/marketChart`,
                '板块': `${location.origin}/preview/plateChart`,
                '北向资金': `${location.origin}/preview/northFundChart`,
                '南向资金': `${location.origin}/preview/northFundChart`,
                '竞价': `${location.origin}/preview/auctionChart`,
                '个股财报': `${location.origin}/preview/financeCompareChart`,
                '财报比对': `${location.origin}/preview/financeCompareChart`,
                '融资融券': `${location.origin}/preview/marginTradeChart`,
                '龙虎榜': `${location.origin}/preview/longHuChart`,
                '估值交易': `${location.origin}/preview/evaluatingDistChart`,
                '新股中心': `${location.origin}/preview/ipoInfoChart`,
                '市场强度': `${location.origin}/preview/checkMarketChart`,
                '大宗交易': `${location.origin}/preview/bigTradeChart`,
                '财报比对(图)': `${location.origin}/preview/resultsContrastChart`,
                '板块地域概念': `${location.origin}/preview/plateAreaChart`,
                '智能诊股': `${location.origin}/preview/intelligentDiagnosisChart`,
                '个股百科': `${location.origin}/preview/companyIntroChart`,
                '投资图谱': `${location.origin}/preview/investmentMapChart`,
                '筹码分布': `${location.origin}/preview/chipDistChart`,
                '定增掘金': `${location.origin}/preview/nuggetsChart`,
                '多空信号': `${location.origin}/preview/bsSignalChart`,
                '财务业绩': `${location.origin}/preview/financeAnalysisChart`,
                '科创掘金': `${location.origin}/preview/keChuangGraphChart`,
                '扫雷': `${location.origin}/preview/stockMineSweepChart`,
                '榜单': `${location.origin}/preview/plateQuoteChart`,
                '价值评估': `${location.origin}/preview/diagnosisValueChart`,
                '商誉分析(大盘)': `${location.origin}/preview/goodwillAnalysisChart`,
                '融资追击': `${location.origin}/preview/financingTrackChart`,
                '商誉分析(个股)': `${location.origin}/preview/goodwillWarnChart`,
                '历史回看': `${location.origin}/preview/historyBackChart`,
                '龙虎榜(个股)': `${location.origin}/preview/longHuStockChart`,
                '相似K线': `${location.origin}/preview/similarKChart`,
                '策略选股': `${location.origin}/preview/intelliChooseStockChart`,
                
            }
            this.url = orderObj[item.materialName]
            this.$nextTick(async () => {
                let commandData = ""
                this.loading = true
                commandData = await handleChart(item).catch(() => {
                        console.error("拉取失败");
                    });
                this.loading = false
                await sleep(500)
                if (this.$refs.refChart?.contentWindow) {
                    if (this.$refs.refChart.contentWindow.hide) {
                        this.$refs.refChart.contentWindow.hide()
                    }
                    await sleep(700)
                    if (this.$refs.refChart.contentWindow.show) {
                        this.$refs.refChart.contentWindow.show(commandData)
                    }
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.tool {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .tool_bg {
        width: 100%;
        height: 380px;
        background: #1B1B26;
        > iframe {
            width: 100%;
            height: 100%;
        }
    }
    /deep/ .tool_operation {
       > div {
           > h3 {
               color: rgba(255, 255, 255, 0.5);
               font-size: 14px;
               margin-top: 8px;
               margin-bottom: 4px;
           }
            .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: rgb(27, 27, 38);
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: rgb(27, 27, 38);
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            > p {
                text-align: center;
                color: rgba(255, 255, 255, 0.5);
            }
       }
    }
    /deep/ .el-loading-mask {
        background: #16181A;
    }
    /deep/ .el-select .el-input.is-focus .el-input__inner {
        border-color:  #767676;
    }
    /deep/ .el-input__inner {
        background: rgb(27, 27, 38);
        border-color: rgb(27, 27, 38);
        color: rgba(255, 255, 255, 0.5);
    }
}
</style>