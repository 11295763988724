<template>
    <div class="power_stock_club">
        <Item v-if="data.materialName === '个股财报'" @individualStock="individualStock" />
        <template v-if="data.materialName.indexOf('财报比对') !== -1">
            <Item title="股票1" @compareStockOne="compareStockOne" />
            <Item title="股票2" @compareStockTwo="compareStockTwo" />
        </template>
    </div>
</template>
<script>
import Item from './item.vue'
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    data: function() {
        return {
            selectArr: []
        }
    },
    components: {
        Item
    },
    methods: {
        individualStock(value) {
            this.selectArr[0] = value
            this.$emit('changePowerStock', this.selectArr)
        },
        compareStockOne(value) {
            this.selectArr[0] = value
            if (this.selectArr.length === 2) {
                this.$emit('changePowerStock', this.selectArr)
            }
        },
        compareStockTwo(value) {
            this.selectArr[1] = value
            if (this.selectArr.filter(item => item).length === 2) {
                this.$emit('changePowerStock', this.selectArr)
            }
        }
    },
}
</script>
