<template>
    <div class="marginTrade">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>股票</h3>
                <el-select v-model="tool_filter_one"  size="medium" placeholder="请选择股票" @change="changeFilterOne()">
                    <el-option
                        v-for="(item, index) in stockArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="tool_filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";
import axios from 'axios'
export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            tool: {},
            stockArr: [],
            date: '',
        }
    },
    created() {
        this.tool = tool
        this.getStockArr()
    },
    methods: {
        async getStockArr() {
            let data1 = await this.ajaxLHBList()
            data1 = JSON.parse(data1.data.content)
            let data = await this.ajaxLHBListDetail({ sDay: data1.sDay })
            data = JSON.parse(data.data.content)
            this.stockArr = data.vSecList.map((item) => {
                return {
                    label: item.sSecName,
                    value: item.sDtSecCode,
                }
            })
            this.date = data1.sDay
        },
        getSecName() {
            return this.stockArr.filter(item => item.value === this.tool_filter_one)[0].label
        },
        async ajaxLHBList() {
            return await axios.post(
                "https://comm.wedengta.com/?s=longhubang&f=getLHBList&req=GetLHBListReq&rsp=GetLHBListRsp",
                JSON.stringify({})
            );
        },
        async ajaxLHBListDetail(options) {
            return await axios.post(
                "https://comm.wedengta.com/?s=longhubang&f=getLHBListDetail&req=GetLHBListDetailReq&rsp=GetLHBListDetailRsp",
                JSON.stringify({
                    iSubList: 1,
                    sDay: options.sDay
                })
            );
        },
        changeFilterOne() {
            this.tool_filter_two = '基本信息'
            this.changeFilterTwo()
        },
        changeFilterTwo() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: this.tool_filter_one,
                    secname: this.getSecName(),
                    type: this.tool_filter_two,
                    date: this.date
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.getSecName()}-${this.tool_filter_two}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        seccode: this.tool_filter_one,
                        secname: this.getSecName(),
                        type: this.tool_filter_two,
                        date: this.date
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                width: 20%;
                box-sizing: border-box;
            }
            &:nth-child(2) {
                flex: 1;
                padding-left: 30px;
                box-sizing: border-box;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>