import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
//大宗交易数据
export function getPlateQuoteData(options) {
    const typeObj = {
        "涨幅榜": "getPlateQuote", 
        "跌幅榜": "getPlateQuote",
        "换手率榜": "getPlateQuote",
        "涨速榜": "getPlateQuote",
        "振幅榜": "getPlateQuote",
        "量比榜": "getPlateQuote",
        "成交额榜": "getPlateQuote",       
        "热门股票(按关注度)": "getSpecial",    
        "热门股票(按多点信号强度)": "getSpecial" ,     
        "上涨潜力排行(按形态预估)": "getSpecial",      
        "上涨潜力排行": "getSpecial" 
      };
      const functions = {
        getPlateQuote,
        getSpecial
      };
      return functions[typeObj[options.type]](options);

}

/*=====================常规榜单========================*/
export function getPlateQuote(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.type]
        if (!fullData){
            fullData = await ajaxPlateQuote(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.type] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handlePlateQuote(fullData,options)
        };
        resolve(finalData);
    });
  }

function handlePlateQuote(data,options){
    
    let chartData = [];
    data.vPlateQuoteDesc.map((item,index)=>{
        let rangeObj={
            "跌幅榜":((item.fNow-item.fClose)/item.fClose*100).toFixed(2),
            "涨幅榜":((item.fNow-item.fClose)/item.fClose*100).toFixed(2),
            "换手率榜":(item.fFhsl*100).toFixed(2)+"%",
            "涨速榜":(item.fQuickRisePct).toFixed(2),
            "振幅榜":(item.fAmplitude).toFixed(2),
            "量比榜":(item.fVolumeRatio).toFixed(2),
            "成交额榜":(item.fAmout/100000000).toFixed(2)+'亿'
        }
        chartData.push([
            `<div>${item.sSecName}</div><div>${item.sDtSecCode.slice(4)}</div>`,
            item.fNow.toFixed(2),
            rangeObj[options.type]
        ])

    })
    const titleArr = ["股票名称","价格", options.type.slice(0,2)];
    chartData.unshift(titleArr);
    return chartData.slice(0,9)
  }

/*=====================特色榜单========================*/
export function getSpecial(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.type]
        if (!fullData){
            let rangeObj={
                "热门股票(按关注度)":await ajaxAccuPoint(options),
                "热门股票(按多点信号强度)":await ajaxSingal(),
                "上涨潜力排行(按形态预估)":await ajaxSimilarKLine(options),
                "上涨潜力排行":await ajaxValueModel()
            }
            fullData = rangeObj[options.type];
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.type] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:await handleSpecial(fullData,options)
        };
        resolve(finalData);
    });
  }

function handleSpecial(data,options){
    return new Promise(async (resolve) => {    
        let chartData = [];
        let titleArr=[];
        let chgData
        if(options.type=="热门股票(按关注度)"){
            for(var i=0;i<data.vItem.length;i++){
                await chg(data.vItem[i]).then((res)=>{
                    chgData=res.toFixed(2)
                });
                chartData.push([
                    `<div>${data.vItem[i].sSecName}</div><div>${data.vItem[i].sDtSecCode.slice(4)}</div>`,
                    chgData,
                    data.vItem[i].iAttenDegree
                ])
            }
            titleArr = ["股票名称","价格", "关注度"]
        }else if(options.type=="热门股票(按多点信号强度)"){
            data.vSecBsInfoBuy.reverse().map((item,index)=>{
                chartData.push([
                    `<div>${item.sName}</div><div>${item.sDtSecCode.slice(4)}</div>`,
                    item.fClosePre,
                    1*(item.fProbability.toFixed(2))
                ])
            })
            titleArr = ["股票名称","价格", "多点信号强度"]
        }else if(options.type=="上涨潜力排行(按形态预估)"){
            data.vtSKLStatisticsInfo.reverse().map((item,index)=>{
                chartData.push([
                    `<div>${item.sSecName}</div><div>${item.sDtSecCode.slice(4)}</div>`,
                    item.fUpDownPct,
                    item.fUpDownRate
                ])
            })
            titleArr = ["股票名称","20天模拟上涨概率", "20天模拟涨跌幅"]
        }else if(options.type=="上涨潜力排行"){
            data.vtIncreasePotentialInfo.map((item,index)=>{
                chartData.push([
                    item.sSecName,
                    item.fInPrice,
                    item.fValueDist,
                    item.fIncPro,
                ])
            })
            titleArr = ["股票名称","入选价格", "估值分布","后续3个月上涨潜力"]
        }    
        chartData.unshift(titleArr);
        resolve(chartData.slice(0,9)) 
    })
  }

function chg(options){
    return new Promise(async (resolve) => {
        let accData = await ajaxSimpleQuote(options.sDtSecCode);
        accData = JSON.parse(accData.data.content).vSecSimpleQuote[0];
        resolve((accData.fNow-accData.fClose)/accData.fClose*100);
    })
}


function getTitle(options){
    return options.type    
}


async function ajaxPlateQuote(options){
    let require = requireInfo(options)
    return await axios.post("https://comm.wedengta.com/?s=quote&f=getPlateQuoteEx&req=PlateQuoteReq&rsp=PlateQuoteRsp",require)    
}

function requireInfo(options){    
    const subObj = {
        "涨幅榜": 1,
        "跌幅榜":1,
        "换手率榜":1,
        "量比榜": 14,
        "成交额榜": 15,
        "振幅榜": 16,
        "涨速榜": 19
      };
    const sortObj = {
        "涨幅榜":2,
        "跌幅榜":1,
        "换手率榜":2,
        "量比榜":2,
        "成交额榜":2
    };
    if(options.type=='换手率榜'){
        return  JSON.stringify({ePlateQuoteReqType: subObj[options.type],ePlateQuoteSortType:sortObj[options.type],iColype:36,iWantnum:10})
    }else if (["跌幅榜","涨幅榜","量比榜","成交额榜"].indexOf(options.type)>-1){
        return JSON.stringify({ePlateQuoteReqType: subObj[options.type],ePlateQuoteSortType:sortObj[options.type],iWantnum:10})
    }else{
        return JSON.stringify({ePlateQuoteReqType: subObj[options.type],iWantnum:10})
    }
}

async function ajaxAccuPoint(options){
    return await axios.post("https://comm.wedengta.com/?s=accuPoint&f=getRealTimeHotStock&req=GetRealTimeHotStockReq&rsp=GetRealTimeHotStockRsp",
    JSON.stringify(
        { 
            "iWantnum":10
        }
    ))   
}

async function ajaxSingal(){
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getSecBsTop&req=GetSecBsTopReq&rsp=GetSecBsTopRsp")   
}

async function ajaxSimilarKLine(options){
    return await axios.post("https://comm.wedengta.com/?s=similarKLine&f=getSimilarKLineStatistics&req=SKLStatisticsReq&rsp=SKLStatisticsRsp",
    JSON.stringify({
        iWantNum:10,
        eStatisticsType:1
    })
    )   
}
async function ajaxSimpleQuote(options){
    return await axios.post("https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
    JSON.stringify({
        vDtSecCode:[options]
    })
    )   
}

async function ajaxValueModel(){
    return await axios.post("https://comm.wedengta.com/?s=valueModel&f=getIncreasePotentialDetail&req=IncreasePotentialReq&rsp=IncreasePotentialRsp",
    JSON.stringify({
        sDtSecCode:"0001000001",
        eType:2,
        ePeriod:2
    })
    )   
}





