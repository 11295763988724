<template>
    <div class="similarK">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeStock" />
            <div v-show="tool_filter_one">
                <h3>名称</h3>
                <el-select v-model="tool_filter_two" filterable size="medium" placeholder="请选择" @change="changeType()">
                    <el-option
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="this.tool_filter_one">
                <h3>周期</h3>
                <el-select v-model="tool_filter_three" filterable size="medium" placeholder="请选择" @change="changeType()">
                    <el-option
                        :label="item.value"
                        v-for="(item, index) in filterArr"
                        :key="index"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="this.tool_filter_one">
                <h3>模型</h3>
                <el-select v-model="tool_filter_four" filterable size="medium" placeholder="请选择" @change="changeType()">
                    <el-option
                        :label="item.value"
                        v-for="(item, index) in filterArr2"
                        :key="index"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="this.tool_filter_one">
                <h3>时间</h3>
                <el-select v-model="tool_filter_six" filterable size="medium" placeholder="请选择" @change="changeType()">
                    <el-option
                        :label="item.value"
                        v-for="(item, index) in filterArr3"
                        :key="index"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="this.tool_filter_one">
                <h3>范围</h3>
                <el-select v-model="tool_filter_five" filterable size="medium" placeholder="请选择" @change="changeType()">
                    <el-option
                        :label="item.value"
                        v-for="(item, index) in filterArr4"
                        :key="index"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>

        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            filterArr: [{
                label: '30天',
                value: '30天',
            },{
                label: '60天',
                value: '60天',
            },{
                label: '50周',
                value: '50周',
            }],
            filterArr2: [{
                label: '二维模型',
                value: '二维模型',
            },{
                label: '四维模型',
                value: '四维模型',
            },{
                label: '六维模型',
                value: '六维模型',
            }],
            filterArr3: [{
                label: '历史以来',
                value: '历史以来',
            },{
                label: '最近5年',
                value: '最近5年',
            },{
                label: '最近3年',
                value: '最近3年',
            }],
            filterArr4: [{
                label: '沪深市场',
                value: '沪深市场',
            },{
                label: '相同行业',
                value: '相同行业',
            },{
                label: '同档市值',
                value: '同档市值',
            }],
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three:'',
            tool_filter_four:'',
            tool_filter_five:'',
            tool_filter_six:'',
            tool: {},
        }
    },
    components: {
        SelectStock
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeStock(value) {
            this.tool_filter_one = value // obj key is label and value
            this.tool_filter_two = tool.tooObj[this.activeWindowCustomChartMsg.materialName][0].value
            this.tool_filter_three = '30天'
            this.tool_filter_four = '二维模型'
            this.tool_filter_five = '沪深市场'
            this.tool_filter_six = '历史以来'
            this.changeType()
        },
        changeType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: this.tool_filter_one.value,
                    secname: this.tool_filter_one.label,
                    type: this.tool_filter_two,
                    similarPeriod: this.tool_filter_three,
                    similarType: this.tool_filter_four,
                    scopeType: this.tool_filter_six,
                    timeType: this.tool_filter_five
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.map(item => item.secname).join('与')}-${this.tool_filter_two}-${this.tool_filter_three}-${this.tool_filter_four}-${this.tool_filter_five}-${this.tool_filter_six}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            seccode: this.tool_filter_one.value,
                            secname: this.tool_filter_one.label,
                            type: this.tool_filter_two,
                            similarType: this.tool_filter_three,
                            similarPeriod: this.tool_filter_four,
                            scopeType: this.tool_filter_five,
                            timeType: this.tool_filter_six
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.similarK {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width:100%;
        > div {
            margin-right: 30px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
        }
        /deep/ .el-radio-group {
            display: flex;
            flex-wrap: wrap;
            .el-radio-button__inner {
                background: #2E2F30;
                color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                border: #2E2F30;
                margin-bottom: 4px;
                margin-right: 4px;
            }
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background: #767676;
            border: #767676;
            color: #fff;
            box-shadow: unset
        }
        /deep/ .el-select .el-input.is-focus .el-input__inner {
            border-color: rgba(255, 255, 255, 0.08);
        }
        /deep/ .el-input__inner {
            background: #202020;
            border-color: rgba(255, 255, 255, 0.08);
            color: #fff;
        }
    }
}
</style>