import axios from "axios";
import moment from 'moment'
const listType={
    "缩量涨停板":"99999_236",
    "连续涨停策略":"99994_337",
    "灯塔金股":"99995_128",
    "公告涨停预测":"99993_121",
    "主题涨停预测":"99996_56",
    "券商高预期股":"99990_82",
    "龙虎榜":"99988_87",
    "超跌反弹":"99998_231",
    "热股推荐":"99985_150",
    "东方金股":"99992_48",
    "庄家牛股":"99936_220",
    "MACD金叉股":"99987_89",
    "黄金KDJ":"99991_232",
    "短期均线金叉":"99997_222",
    "游资携手":"99989_230"
}
export function getIntelliChooseStockData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            // let finalData = await ajaxSearchIntelliStock(options)
            
            let finalData = await ajaxGetIntelliStock(listType[options.type])
            finalData = JSON.parse(finalData.data.content);
            const result = {
                pageTitle: getPageTitle(options) || '',
                data:await handleIntelliStock(finalData,options)
            };
            console.log(result,'result');
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return options.type+options.subType
}
function handleIntelliStock(data,options){    
    return new Promise(async (resolve) => {
        let chartData= data.stIntelliPickStockV2
        let resultData
        if(options.subType =="选股策略"){
            resultData={
                sDescription : chartData.sDescription,
                fMonthSuccPercent : chartData.fMonthSuccPercent==0?"0.00%":(chartData.fMonthSuccPercent*100).toFixed(2)+'%',
                vtTagInfo : chartData.vtTagInfo,
                fFirstIncrease : chartData.fFirstIncrease==0?"0.00%":(chartData.fFirstIncrease*100).toFixed(2)+'%',
                fFirstIncreaseClass:chartData.fFirstIncrease>0?"num_rise":chartData.fFirstIncrease=0?"num_stop":"num_fall",
                fWeekIncrease : chartData.fWeekIncrease==0?"0.00%":(chartData.fWeekIncrease*100).toFixed(2)+'%',
                fWeekIncreaseClass:chartData.fWeekIncrease>0?"num_rise":chartData.fWeekIncrease=0?"num_stop":"num_fall",
                fMonthIncrease : chartData.fMonthIncrease==0?"0.00%":(chartData.fMonthIncrease*100).toFixed(2)+'%',
                fMonthIncreaseClass:chartData.fMonthIncrease>0?"num_rise":chartData.fMonthIncrease=0?"num_stop":"num_fall",
                vIntelliSec : chartData.vIntelliSec,
            }        
            
        }else if (options.subType =="入选个股"){
                let sDate = chartData.sDate
                let vtIntelliStock = chartData.vtIntelliStock
                let seccodeArr=[]
                let stockDataArr=[]
                vtIntelliStock.map((item,index)=>{
                    seccodeArr.push(item.sDtSecCode);
                })
                let stockData = await ajaxSimpleQuote(seccodeArr)
                stockData=JSON.parse(stockData.data.content).vSecSimpleQuote;
                stockData.map((item,index)=>{
                    stockDataArr.push([
                        `<div>${item.sSecName}</div><div>${item.sDtSecCode.slice(4)}</div>`,
                        (item.fClose).toFixed(2)+"",
                        (item.fNow).toFixed(2)+"",
                        1*((item.fNow-item.fClose)/item.fClose*100).toFixed(2)
                    ])
                })
                const titleArr = ["股票","入选价", "最新价", "涨跌幅"];
                stockDataArr.unshift(titleArr);
                resultData={
                    sDate:sDate,
                    stockDataArr:stockDataArr
                }
        }
    
        resolve(resultData)
    })

}


// 6:收益最高,7:胜率最高 获取sId  获取列表
async function ajaxSearchIntelliStock(options) {
    return await axios.get("https://sec.wedengta.com/getIntelliStock?action=searchIntelliPickStockList", {
        params: {
            searchCondition: 6,
            searchStr:""
        },
    });
}


// 获取历史牛股和策略收益  单个sId获取
async function ajaxGetIntelliStock(options) {
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getIntelliPickStockDetailV2&req=IntelliPickStockDetailReq&rsp=IntelliPickStockDetailV2Rsp", 
    JSON.stringify({
        sId: options
        }));
}

// 当前股价 vDtSecCode为数组
async function ajaxSimpleQuote(value) {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
        JSON.stringify({
        vDtSecCode: value
        })
    );
}
