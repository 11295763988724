var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
      position:'relative',
      overflow: 'hidden',
      height:_vm.computeTop(_vm.data.length)+_vm.cardOutsideHeight+'px',
      width:_vm.cardOutsideWidth*_vm.colNum+'px'})},_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"d_cardBorderBox",style:({
        width:_vm.cardOutsideWidth+'px',
        height:_vm.cardOutsideHeight+'px'
    }),attrs:{"id":item.id}},[_c('div',{staticClass:"d_cardInsideBox",style:({ 
          width:_vm.cardInsideWidth+'px',
          height:_vm.cardInsideHeight+'px',
          cursor: _vm.allowMove ? 'move' : 'unset'
          }),on:{"mousedown":function($event){_vm.allowMove && _vm.touchStart($event,item.id)}}},[_vm._t("header",null,{"item":item}),(item.componentData)?_c(item.componentData,{tag:"component",attrs:{"itemData":item}}):_vm._t("content",function(){return [_c('div',{staticClass:"d_emptyContent"},[_vm._v(" 卡片暂无内容 ")])]},{"item":item})],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }