import axios from "axios";

export function getInvestmentMapData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    if (['高管图谱', '十大投资股东', '上游产品', '下游产品','主要产品','上游行业','下游行业'].indexOf(options.type) !== -1) {
        return `${options.compareStocks[0].secname}${options.type}`
    } else if (['投资关系'].indexOf(options.type) !== -1) {
        return `${options.compareStocks[0].secname}和${options.compareStocks[1]?.secname}的${options.type}`
    } else {
        return `${options.person}的${options.type}`
    }
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let finalData = []
        if (['上游产品', '下游产品','主要产品','上游行业','下游行业'].indexOf(options.type) !== -1) {
            let data = await ajaxCompanyIndustrialChain(options)
            data = JSON.parse(data.data.content)
            switch (options.type) {
                case '上游产品':
                    finalData = data.vUpstreamProducts
                    break;
                case '下游产品':
                    finalData = data.vDownstreamProducts
                    break;
                case '主要产品':
                    finalData = {
                        vMainProducts: data.vMainProducts,
                        sConsumerGroup: data.sConsumerGroup,
                        sSalesMarket: data.sSalesMarket,
                        vBelongedIndustries: data.vBelongedIndustries,
                    }
                    break;
                case '上游行业':
                    finalData = data.vUpstreamIndustries
                    break;
                case '下游行业':
                    finalData = data.vDownstreamIndustries
                    break;   
                default:
                    break;
            }
        }
        if (['十大投资股东','投资关系','高管图谱','任职情况'].indexOf(options.type) !== -1) {
            let data = await ajaxSecurityDetails(options)
            data = JSON.parse(data.data.content)
            switch (options.type) {
                case '十大投资股东':
                    finalData.push(['排名', '股东', '占比增幅'])
                    data.stShareholderGraph.vTopMainHolders[0].vShareholderItems.sort((a, b) => {
                        return b.fPercent - a.fPercent
                    }).slice(0, 10).forEach((item, index) => {
                        finalData.push([index + 1, item.stShareholder.sName, item.fPercent.toFixed(2)])
                    })
                    break;
                case '投资关系':
                    finalData = investRelation(options, data.vSeasonRelatedSecurities[0].stRelatedSecurities.vSecurityShareholderPairs)
                    break;
                case '高管图谱':
                    finalData.push(['名称', '职位'])
                    data.vManagerItems.slice(0, 10).forEach((item) => {
                        finalData.push([item.sName, item.vPostItems.filter(value => value.stSecurity.sDtSecCode === options.compareStocks[0].seccode)[0].vPostNames.join('、')])
                    })
                    break;
                case '任职情况':
                    finalData = takeOffice(options, data.vManagerItems)
                    break; 
                default:
                    break;
            }
        }
        resolve(finalData)
    })
}
function takeOffice(options, data) {
    if (options.person) {
        let finalData = {
            name: options.compareStocks[0].secname,
            children: []
        }
        let newData = data.filter(item => item.sName === options.person)
        finalData.children = newData[0] ? newData[0].vPostItems.map((item) => {
            return {
                name: `${item.stSecurity.sSecName}(${item.vPostNames.join('、')})`
            }
        }) : []
        return finalData
    } else {
        let finalData = data
        return finalData
    }
}
function investRelation(options, data) {
    if (options.compareStocks.length === 2) {
        let newData = []
        let finalData = {
            l: {
                name: "",
                children: [
                    {
                        name: options.compareStocks[0].secname,
                    },
                    {
                        name: options.compareStocks[1].secname,
                    }
                ]
            },
            r: {
                name: "",
                children: []
            }
        }
        data.forEach((item) => {
            item.vSecurities.forEach((value) => {
                newData.push({
                    sDtSecCode: value.sDtSecCode,
                    sSecName: value.sSecName,
                    vShareholders: item.vShareholders
                })
            })
        })
        newData = newData.filter(item => item.sDtSecCode === options.compareStocks[1].seccode)
        finalData.r.children = newData[0] ? newData[0].vShareholders.map((item) => {
            return {
                name: item.sName
            }
        }) : []
        return finalData
    } else {
        let finalData = []
        data.forEach((item) => {
            item.vSecurities.forEach((value) => {
                finalData.push({
                    sDtSecCode: value.sDtSecCode,
                    sSecName: value.sSecName,
                    vShareholders: item.vShareholders
                })
            })
        })
        return finalData
    }
    
}
async function ajaxSecurityDetails(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=investGraph&f=getSecurityDetails&req=SecurityDetailReq&rsp=SecurityDetailRsp",
        JSON.stringify({
            sDtSecCode: options.compareStocks[0].seccode,
            iVersion:1
        })
    );
}
async function ajaxCompanyIndustrialChain(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=investGraph&f=getCompanyIndustrialChain&req=CompanyIndustrialChainReq&rsp=CompanyIndustrialChainRsp",
        JSON.stringify({
            sDtSecCode: options.compareStocks[0].seccode,
        })
    );
}