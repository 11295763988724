<template>
    <div class="video">
        <div class="video_bg" v-loading="loading">
            <!-- <video :src="dataSource.url"  autoplay></video> -->
            <video ref="videoObj" autoplay></video>
        </div>
        <div class="video_msg">
            <p>{{ dataSource.materialName}}</p>
            <div>
                <span>属性：</span>
                <span>视频</span>
            </div>
            <div class="oneRow">
                <div>
                    <span>尺寸：</span>
                    <span>{{ `${width}*${height}`}}</span>
                </div>
                <div>
                    <span>大小：</span>
                    <span>{{ size }}MB</span>
                </div>
            </div>
            <div>
                <span>标签：</span>
                <span>{{ dataSource.materialTag}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        },
        roomSizeScale: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            loading: false,
            size: '',
            width: '1920',
            height: '1080',
        }
    },
    created() {
        if (this.roomSizeScale === '4:3') {
            this.width = '1080'
            this.height = '1920'
        } else {
            this.width = '1920'
            this.height = '1080'
        }
    },
    mounted() {
        this.getVideoSize(this.dataSource.url)
    },
    methods: {
        getVideoSize(url) {
            const _that = this
            _that.loading = true
            fetch(url).then((data) => {
                return data.blob()
            }).then((blob) => {
                _that.$refs.videoObj.src = URL.createObjectURL(blob)
                _that.size = Number(blob.size / 1024 / 1024).toFixed(2)
                _that.loading = false
            })
        },
        getVideoMsg(url) {
            const _that = this
            const img = new Image()
            img.src = url
            img.onload = function() {
                _that.width = img.width
                _that.height = img.height
            }
        },
    }
}
</script>
<style lang="less" scoped>
.video {
    width: 100%;
    // height: 450px;
    display: flex;
    flex-direction: column;
    .video_bg {
        background: #1B1B26;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        > video {
            max-height: 100%;
            max-width: 100%;
        }
    }
    .video_msg {
        flex: 1;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        > p {
            margin-top: 10px;
        }
        > div {
            margin-top: 10px;
        }
        .oneRow {
            display: flex;
            > div {
                margin-right: 14px;
            }
        }
    }
}
</style>