<template>
    <el-dialog
        :visible.sync="visible"
        :title="`${groupId !== undefined ? '编辑' : '新建'}分组`"
        :close-on-click-modal="false"
        width="520px"
        @close="close"
    >
        <div class="create_content">
            <el-form :model="form" ref="form">
                <el-form-item
                    prop="groupName"
                    label=""
                    :rules="[{ required: true, message: '请输入分组名称', trigger: 'blur' }]"
                >
                    <el-input v-model="form.groupName" placeholder="请输入分组名称" maxlength="30" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            
        </div>
        <span slot="footer" class="dialog-footer">
            <template v-if="!groupId">
                <el-button @click="close" size="medium">取消</el-button>
                <el-button @click="submit" type="primary" size="medium">确定</el-button>
            </template>
            <template v-else>
                <el-button @click="del" size="medium" :disabled="!allowDel">删除分组</el-button>
                <el-button @click="submit" type="primary" size="medium">重命名</el-button>
            </template>
        </span>
    </el-dialog>
</template>
<script>
import { validateForm } from "@/utils/utils";
export default {
    props: {
        allowDel: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            visible: false,
            groupId: undefined,
            form: {
                groupName: '',
            },
        }
    },
    methods: {
        open(value) {
            if (value && value instanceof Object && Object.keys(value).length > 0) {
                this.groupId = value.categoryId
                this.form.groupName = value.categoryName
            }
            this.visible = true
        },
        close() {
            this.$refs.form.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        async submit() {
            await validateForm(this.$refs.form);
            if (this.groupId !== undefined) {
                this.API.category.edit({
                    roomId: Number(this.$route.query.id),
                    categoryId: this.groupId,
                    categoryName: this.form.groupName,
                }).then((res) => {
                    if (res?.code === 200) {
                        this.$message.success('分组重命名成功！')
                        this.close()
                        this.$emit('getGroupList')
                    } else {
                        this.$message.error('分组重命名失败！')
                    }
                })
            } else {
                this.API.category.create({
                    categoryName: this.form.groupName,
                    roomId: Number(this.$route.query.id),
                }).then((res) => {
                    if (res?.code === 200) {
                        this.$message.success('分组创建成功！')
                        this.close()
                        this.$emit('getGroupList')
                    } else {
                        this.$message.error('分组创建失败！')
                    }
                    
                })
            }
        },
        del() {
            this.$confirm('确定删除当前选中分组？里面的素材也将被同时删除', '删除分组', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.API.category.delete({
                    categoryId: this.groupId
                }).then((res) => {
                    if (res && res?.code === 200) {
                        this.$message.success('分组删除成功！')
                        this.close()
                        this.$emit('getGroupList', true)
                    } else {
                        this.$message.error('分组删除失败！')
                    }
                })
            }).catch(() => {
                console.log('取消删除')  
            });
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
//   padding: 30px 74px;
  padding: 8px 20px 0px;
  .create_content {
        background: #121212;
        padding: 20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
  }
  .el-form-item {
      margin-bottom: 50px;
  }
  .el-form-item__label {
      color: #fff;
      font-size: 16px;
      text-align: left;
  }
  .el-input__inner {
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.21);
      color: #fff;
  }
  .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C
  }
  .el-input .el-input__count .el-input__count-inner {
      background: unset;
  }
}
/deep/ .el-dialog__footer {
    padding: 0px 20px 20px;
    .dialog-footer {
            padding: 0 20px 20px;
            background: #121212;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .el-button--default {
                border-radius: 4px;
                border: 1px solid #4E8CFF;
                background: #252525;
                color: #4E8CFF;
                margin-right: 30px;
            }
            .el-button--primary {
                border: 1px solid #4E8CFF;
                background: #4E8CFF;
            }
        }
}
</style>