import { convertChartData } from "@/utils/utils.js";
import axios from "axios";
import { getMarketChartData } from "@/businessComp/charts/marketChart/marketChartData.js";
import { getPlateChartData } from "@/businessComp/charts/marketChart/plateChartData.js";
import {
  getAuctionSummaryData,
  getAuctionLimit,
  getAuctionTrend,
} from "@/businessComp/charts/auctionChart/auctionChartData.js";
import { getFinanceCompareData } from "@/businessComp/charts/financeCompareChart/financeCompareData.js";
import {
  getNorthFundFlowChart,
  getCapitalToAShareRate,
  getNorthFundFlowIndustry,
  getNorthFundTop10Stock,
  getNorthFundHistoryDaily,
  getHolderIncomeRank,
  getHoldStockBuildUpList,
  getPointChangeBuildUpList,
  getStockNumHoldList,
  getStockNumBuildUpNew,
  getLongHuBangInfo,
//   getAHExtend,
  getLongHuReal,
  getCapitalMainFlow,
  getCapitalDetail,
} from "@/businessComp/charts/northFundChart/northFundFlowChartData.js";
import { getmarginTradeData } from "@/businessComp/charts/marginTradeChart/marginTradeData.js";
import { getLongHuData } from "@/businessComp/charts/longHuChart/longHuData.js";
import { getEvaluatingDistChartData } from "@/businessComp/charts/evaluatingDistChart/evaluatingDistData.js";
import { getIpoInfoData } from "@/businessComp/charts/ipoInfoChart/ipoInfoChart.js";
import { getCheckMarketData } from "@/businessComp/charts/checkMarketChart/checkMarketData.js"
import { getBigTradeData }from "@/businessComp/charts/bigTradeChart/bigTradeData.js"
import { getResultsContrastData } from  "@/businessComp/charts/resultsContrastChart/resultsContrastData.js"
import { getPlateAreaData } from "@/businessComp/charts/plateAreaChart/plateAreaData.js"
import { getIntelligentDiagnosisData } from "@/businessComp/charts/intelligentDiagnosisChart/intelligentDiagnosisData.js"
import { getChipDistChartData } from "@/businessComp/charts/chipDistChart/chipDistData.js"
import { getInvestmentMapData } from "@/businessComp/charts/investmentMapChart/investmentMapData.js"
import { getCompanyIntroData } from "@/businessComp/charts/companyIntroChart/companyIntroData.js"

import { getDuPonData } from "@/businessComp/charts/duPonChart/duPonData.js"
import { getSharePledgeData } from "@/businessComp/charts/sharePledgeChart/sharePledgeData.js"
import { getStocksFundsData } from "@/businessComp/charts/stocksFundsChart/stocksFundsData.js"
import { getBsSignalData } from "@/businessComp/charts/bsSignalChart/bsSignalData.js"
import { getNuggetsData } from "@/businessComp/charts/nuggetsChart/nuggetsData.js"
import { getKeChuangGraphData } from "@/businessComp/charts/keChuangGraphChart/keChuangGraphData.js"
import { getPlateQuoteData } from "@/businessComp/charts/plateQuoteChart/plateQuoteData.js"
import { getStockMineSweepData } from "@/businessComp/charts/stockMineSweepChart/stockMineSweepData.js"
import { getFinanceAnalysisData } from "@/businessComp/charts/financeAnalysisChart/financeAnalysisData.js"
import { getDiagnosisValueData } from "@/businessComp/charts/diagnosisValueChart/diagnosisValueData.js"
import { getFinancingTrackData } from "@/businessComp/charts/financingTrackChart/financingTrackData.js"
import { getGoodwillAnalysisData } from "@/businessComp/charts/goodwillAnalysisChart/goodwillAnalysisData.js"
import { getGoodwillWarnData } from "@/businessComp/charts/goodwillWarnChart/goodwillWarnData.js"
import { getHistoryBackData } from "@/businessComp/charts/historyBackChart/historyBackData.js"
import { getLongHuStockData } from "@/businessComp/charts/longHuStockChart/longHuStockData.js"
import { getSimilarKData } from "@/businessComp/charts/similarKChart/similarKData.js"
import { getIntelliChooseStockData } from "@/businessComp/charts/intelliChooseStockChart/intelliChooseStockData.js"

// 获取工具数据
export function handleChart(item) {
    let tooFun = {
        "行情": getMarketChartData,
        "板块": getPlateChartData, 
        "个股财报": getFinanceCompareData,
        "财报比对": getFinanceCompareData,
        "融资融券": getmarginTradeData,
        "龙虎榜": getLongHuData,
        "估值交易": getEvaluatingDistChartData,
        "新股中心": getIpoInfoData,
        "市场强度": getCheckMarketData,
        "大宗交易": getBigTradeData,
        "财报比对(图)": getResultsContrastData,
        "板块地域概念": getPlateAreaData,
        "智能诊股": getIntelligentDiagnosisData,
        "筹码分布": getChipDistChartData,
        "投资图谱": getInvestmentMapData,
        "个股百科": getCompanyIntroData,
        "杜邦分析": getDuPonData,
        "个股资金": getStocksFundsData,
        "股权质押(个股)": getSharePledgeData,
        "股权质押(大盘)": getSharePledgeData,
        "多空信号": getBsSignalData,
        "定增掘金": getNuggetsData,
        "科创掘金": getKeChuangGraphData,
        "榜单": getPlateQuoteData,
        "扫雷": getStockMineSweepData,
        "财务业绩": getFinanceAnalysisData,
        "价值评估": getDiagnosisValueData,
        "融资追击": getFinancingTrackData,
        "商誉分析(大盘)": getGoodwillAnalysisData,
        "商誉分析(个股)": getGoodwillWarnData,
        "历史回看": getHistoryBackData,
        "龙虎榜(个股)": getLongHuStockData,
        "相似K线":getSimilarKData,
        "策略选股":getIntelliChooseStockData,

    
    }
    let materialName = item.materialName.split('-')[0]
    return new Promise(async (resolve, reject) => {
        try {
            let remarkData = JSON.parse(item.remark);
            let marketData = []
            let obj = {
                ...remarkData,
            }
            if (materialName === '竞价') {
               if (remarkData.type == "auctionSummary") {
                    marketData = await getAuctionSummaryData(remarkData);
                } else if (
                    ["auctionUpLimit", "auctionDownLimit"].indexOf(remarkData.type) > -1
                ) {
                    marketData = await getAuctionLimit(remarkData);
                } else if (
                    ["auctionUpTrend", "auctionDownTrend"].indexOf(remarkData.type) > -1
                ) {
                    marketData = await getAuctionTrend(remarkData);
                }
                obj.data = marketData
            }
            if (materialName === '北向资金' || materialName === '南向资金') {
                // 流向
                if (remarkData.type === 'flow') {
                    marketData = await getNorthFundFlowChart(remarkData);
                }
                if (remarkData.type === 'shareRate') {
                    marketData = await getCapitalToAShareRate(remarkData);
                }
                if (remarkData.type === 'fundFlowIndustry') {
                    marketData = await getNorthFundFlowIndustry(remarkData);
                }
                if (remarkData.type === 'fundFlowTop10Stock') {
                    marketData = await getNorthFundTop10Stock(remarkData);
                }
                if (remarkData.type === 'historyDaily') {
                    marketData = await getNorthFundHistoryDaily(remarkData);
                }
                if (remarkData.type === 'holderIncomeRank') {
                    marketData = await getHolderIncomeRank(remarkData);
                }
                if (remarkData.type === 'holdRatio') {
                    marketData = await getHoldStockBuildUpList(remarkData);
                }
                if (remarkData.type === 'holdVary') {
                    marketData = await getPointChangeBuildUpList(remarkData);
                }
                if (remarkData.type === 'stockBuildUp') {
                    marketData = await getStockNumBuildUpNew(remarkData);
                }
                if (remarkData.type === 'stockHoldList') {
                    marketData = await getStockNumHoldList(remarkData);
                }
                if (remarkData.type === 'longHuSummary') {
                    marketData = await getLongHuBangInfo(remarkData);
                }
                if (remarkData.type === 'capitalMainFlow') {
                    marketData = await getCapitalMainFlow(remarkData);
                }
                if (remarkData.type === 'capitalDetail') {
                    marketData = await getCapitalDetail(remarkData);
                }
                if (remarkData.type === 'longHuReal') {
                    remarkData = {
                        type: remarkData.type,
                        market: remarkData.market.split('-')[0],
                        sortBy: remarkData.market.split('-')[1] || undefined,
                    }
                    marketData = await getLongHuReal(remarkData);
                }
                obj.data = marketData
            }
            if (tooFun[materialName]) {
                marketData = await tooFun[materialName](remarkData)
                obj.data = marketData
            }
            resolve(obj);
        } catch (error) {
            reject();
        }
    })
}
// 获取通用图表的数据
export function getGeneralChartData(url, materialTag) {
    return new Promise(async (resolve, reject) => {
        let chartData = await axios.get(url);
        try {
            if (chartData.data.dataDescrip == undefined) {
                reject();
            } else {
                chartData = changeChartType(chartData.data, materialTag);
                const data = convertChartData(chartData.dataDescrip);
                const result = {
                    title: chartData.dataDescrip.chartTitle,
                    timeCyc: chartData.dataDescrip.timeCyc,
                    data: data,
                    type: chartData.dataDescrip.chartType,
                };
                resolve(result);
            }
        } 
        catch (e) {
            reject();
        }
    });
}
// 潜规则：通过标题，或者数据量，改变图表类型
export function changeChartType(data, materialTag) {
    if (data.dataDescrip.chartType == "柱形图" && materialTag.indexOf("区间柱图") > -1) {
        data.dataDescrip.chartType = "区间柱图";
    } else if (data.dataDescrip.chartType == "柱形图" && materialTag.indexOf("方块图") > -1) {
        data.dataDescrip.chartType = "方块图";
    } else if (data.dataDescrip.chartType == "柱形图" && data.dataDescrip.originalData.chart1Ydata.length > 1) {
        data.dataDescrip.chartType = "多柱图";
    }
    if (data.dataDescrip.chartType == "饼图" && materialTag.indexOf("玫瑰图") > -1) {
        data.dataDescrip.chartType = "玫瑰图";
    }
    return data;
}