<template>
    <div class="materialNumPic">
        <div class="input">
            <el-input
                v-model="keyword"
                ref="keywordInput"
                placeholder="搜索数图素材"
                @change="searchList"
                suffix-icon="el-icon-search"
                clearable
                @clear="handleClear"
            >
            </el-input>
        </div>
        <div class="img_list">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <ResourceItem
                    :itemData="item"
                    :active="active"
                    @change-active="changeActive"
                    materialSource="数图"
                    :markerVisible="false"
                    :deleteVisible="false"
                    v-for="(item, index) in data"
                    :key="index"
                />
            </div>
            <span>{{ loading ? '正在加载更多' : '暂无更多'}}</span>
        </div>
    </div>
</template>
<script>
import ResourceItem from '../../components/resourceItem/index.vue'

export default {
    data: function() {
        return {
            keyword: '',
            data: [],
            active: '',
            pageNum: 1,
            pageSize: 21,
            endPageNum: 1,
            loading: false,
        }
    },
    components: {
        ResourceItem
    },
    computed: {
        disabled() {
            return this.pageNum >= this.endPageNum;
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeActive(value) {
            this.active = String(value)
        },
        handleClear() {
             this.$refs.keywordInput.blur()
        },
       getList(params) {
            this.loading = true;
            this.API.material.getList({
                materialClass: 0,
                materialType: 1,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                roomId: Number(this.$route.query.id),
                searchKeyword: this.keyword || undefined,
                range: 0,
                ...params
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.data = this.data.concat(res.data.list);
                    this.endPageNum = res.data.pages
                } else {
                    this.$message.error(res.message)
                }
                this.loading = false;
            })
        },
        // 滚动加载
        load() {
            if (!this.disabled) {
                this.pageNum += 1;
                this.getList();
            }
        },
        searchList() {
            this.pageNum = 1
            this.data = []
            this.getList()
        },
    },
}
</script>
<style lang="less" scoped>
.materialNumPic {
    height: 100%;
    .input {
        padding: 0 24px 0 14px;
        box-sizing: border-box;
        /deep/ .el-input__inner {
            background: rgb(32,32,32);
            border-color: rgba(255, 255, 255, 0.08);
            color: #fff;
        }
    }
    .img_list {
        margin-top: 20px;
        padding-top: 2px;
        height: calc(100% - 60px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 0 15px;
            }
        }
        > span {
            color: #fff;
        }
    }
}
</style>