<template>
    <div class="resourceItem">
        <div class="cover">
            <!-- 资讯卡片 -->
            <div :class="['showMsg', String(itemData.id) === active ? 'active' : '']" v-if="itemData.materialType == undefined || itemData.materialType == 3">
                <span :title="itemData.title || itemData.materialName" class="msgTitle">
                    {{ itemData.title || itemData.materialName }}
                </span>
                <div>
                    <div class="msgTag">
                        <ul v-if="!itemData.attitude && !itemData.materialTag"></ul>
                        <ul v-else class="news_tag news_tag_inlist">
                            <li>
                                <span
                                    :style="{ display: itemData.attitude === '极低' || itemData.materialTag === '极低' ? 'block' : 'none'}"
                                >
                                    {{ itemData.attitude || itemData.materialTag }}
                                </span>
                                <i :style="{ display: itemData.attitude === '极低' || itemData.materialTag === '极低' ? 'none' : 'block'}"></i>
                            </li>
                            <li>
                                <span
                                    :style="{ display: itemData.attitude === '低' || itemData.materialTag === '低' ? 'block' : 'none'}"
                                >
                                    {{ itemData.attitude || itemData.materialTag }}
                                </span>
                                <i :style="{ display: itemData.attitude === '低' || itemData.materialTag === '低' ? 'none' : 'block'}"></i>
                            </li>
                            <li>
                                <span
                                    :style="{ display: itemData.attitude === '中性' || itemData.materialTag === '中性' ? 'block' : 'none'}"
                                >
                                    {{ itemData.attitude || itemData.materialTag }}
                                </span>
                                <i :style="{ display: itemData.attitude === '中性' || itemData.materialTag === '中性' ? 'none' : 'block'}"></i>
                            </li>
                            <li>
                                <span
                                    :style="{ display: itemData.attitude === '较好' || itemData.materialTag === '较好' ? 'block' : 'none'}"
                                >
                                    {{ itemData.attitude || itemData.materialTag }}
                                </span>
                                <i :style="{ display: itemData.attitude === '较好' || itemData.materialTag === '较好' ? 'none' : 'block'}"></i>
                            </li>
                            <li>
                                <span
                                    :style="{ display: itemData.attitude === '好' || itemData.materialTag === '好' ? 'block' : 'none'}"
                                >
                                    {{ itemData.attitude || itemData.materialTag }}
                                </span>
                                <i :style="{ display: itemData.attitude === '好' || itemData.materialTag === '好' ? 'none' : 'block'}"></i>
                            </li>
                        </ul>
                    </div>
                    <div class="msgInfo">
                        <span>{{ itemData.dataSource || '灯塔财经'}}</span>
                        <span>{{ $moment(itemData.publishTime || itemData.updateTime).format('YYYY-MM-DD') }}</span>
                    </div>
                </div>
            </div>
            <img :class="String(itemData.id) === active ? 'active' : ''" :src="showImg()" alt="" v-else />
            <!-- 卡片标题 -->
            <div
                class="cover_name"
                :title="itemData.materialName || itemData.title"
                v-if="[1, 5, 6].indexOf(itemData.materialType) !== -1 || materialSource === '本地上传'"
                v-html="itemData.materialName || itemData.title"
            />
            <!-- 卡片预览 -->
            <el-popover
                placement="right"
                :width="[undefined, 1, 3].indexOf(itemData.materialType) !== -1 ? 450 : itemData.materialType === 5 ? 460 : 600"
                trigger="manual"
                @show="changeShowVisible()"
                @hide="changeShowVisible()"
                v-if="popoverVisible"
                popper-class="preview_popover"
                v-model="previewVisible"
            >
                <!-- 图片、logo预览 -->
                <ImageItem v-if="[0, 6].indexOf(itemData.materialType) !== -1 && showVisible" :dataSource="itemData"/>
                <!-- 视频预览 -->
                <VideoItem v-if="itemData.materialType === 2 && showVisible" :dataSource="itemData" :roomSizeScale="roomSizeScale" />
                <!-- 资讯预览 -->
                <Message v-if="(itemData.materialType === undefined || itemData.materialType === 3) && showVisible" :dataSource="itemData" />
                <!-- 数图预览 -->
                <NumFigure v-if="itemData.materialType === 1 && showVisible" :dataSource="itemData" />
                <!-- 工具预览 -->
                <ToolItem v-if="itemData.materialType === 5 && showVisible" :dataSource="itemData" />
                <!-- 遮罩 -->
                <div slot="reference" :class="['marker', numVisible ? '' : 'markerShow']" @click="changePreviewVisible">
                    <!-- 素材类型标识 -->
                    <img :src="showMarkerImg(itemData.materialType)" alt="" v-if="markerVisible">
                    <span v-else></span>
                    <div class="marker_bottom">
                        <!-- 添加 -->
                        <Like
                            v-if="likeVisible"
                            @addMaterial="addMaterial"
                            @deleteMaterial="deleteMaterial"
                            :status="itemData.addFlag !== undefined ? itemData.addFlag : true"
                        />
                        
                        <span class="delete" v-if="materialSource === '本地上传'" @click="deleteMaterial">
                            <i class="el-icon-delete"></i>
                        </span>
                        <el-tooltip content="添加到分组" placement="top">
                            <div class="go_screen" v-if="groupVisible" @click="addGroup">
                                <img src="../../../../../../assets/images/detail/group_active.svg" alt="">
                            </div>
                        </el-tooltip>
                        <el-tooltip content="上屏" placement="top">
                            <div class="go_screen" v-if="screenVisible" @click="goScreen">
                                <img src="../../../../../../assets/images/detail/goScreen.svg" alt="">
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-popover>
        </div>
        <!-- 排序 -->
        <div :class="['positionNum', itemData.positionNum > 99 ? 'little' : '']" :data-id="itemData.materialId" v-if="itemData.positionNum && numVisible">
            {{ itemData.positionNum > 99 ? '99+' : itemData.positionNum }}
        </div>
    </div>
</template>
<script>
import Like from './like.vue'
import Message from './message.vue'
import NumFigure from './numFigure.vue'
import ImageItem from './image.vue'
import VideoItem from './video.vue'
import ToolItem from './tool.vue'
import photo from '../../../../../../assets/images/detail/photo.svg'
import numPhoto from '../../../../../../assets/images/detail/numPhoto.svg'
import Info from '../../../../../../assets/images/detail/info.svg'
import video from '../../../../../../assets/images/detail/video.svg'
import tool from '../../../../../../assets/images/detail/tool.svg'
import line_chart from '../../../../../../assets/images/detail/line_chart.png'
import pie_chart from '../../../../../../assets/images/detail/pie_chart.png'
import rose_chart from '../../../../../../assets/images/detail/rose_chart.png'
import diamond_chart from '../../../../../../assets/images/detail/diamond_chart.png'
import table_chart from '../../../../../../assets/images/detail/table_chart.png'
import vertical_bar_chart from '../../../../../../assets/images/detail/vertical_bar_chart.png'
import across_bar_chart from '../../../../../../assets/images/detail/across_bar_chart.png'
import shutu_chart from '../../../../../../assets/images/detail/shutu_chart.svg'
import { sleep, setRem } from "@/utils/utils.js";

export default {
    props: {
       itemData: {
            type: Object,
            default: () => {}
        }, // 数据源
        active: {
            type: String,
            default: '',
        }, // 当前选中素材
        roomSizeScale: {
            type: String,
            default: '',
        },
        materialSource: {
            type: String,
            default: ''
        }, // 数据页面来源
        markerVisible: {
            type: Boolean,
            default: true
        }, // 数据类型标记
        likeVisible: {
            type: Boolean,
            default: false
        }, // 是否允许添加到我的
        popoverVisible: {
            type: Boolean,
            default: true
        }, // 是否显示预览
        screenVisible: {
            type: Boolean,
            default: true
        },// 是否显示上屏
        groupVisible: {
            type: Boolean,
            default: true
        },
        numVisible: {
            type: Boolean,
            default: false
        }, // 是否显示序号
    },
    components: {
        Like,
        Message,
        NumFigure,
        ImageItem,
        VideoItem,
        ToolItem,
    },
    data: function() {
        return {
            previewVisible: false,
            showVisible: false
        }
    },
    methods: {
        // 关闭预览框
        changePreviewVisible() {
            this.previewVisible = !this.previewVisible
        },
        async changeShowVisible() {
            this.showVisible = !this.showVisible
            // 绑定/解绑事件
            await sleep(200)
            if (this.showVisible) {
                document.addEventListener('click', this.changePreviewVisible)
            } else {
                document.removeEventListener('click', this.changePreviewVisible)
            }
        },
        showMarkerImg(type) {
            switch (type) {
                case 0:
                    return photo
                case 1:
                    return numPhoto
                case 2:
                    return video
                case 3:
                    return Info
                case 5:
                    return tool
                default:
                    break;
            }
        },
        showImg() {
            // 背景-视频|素材-数图
            if (this.itemData.materialType === 2) {
                return `${this.itemData.remark}?x-oss-process=image/resize,h_100,m_lfit`
            }
            if (this.itemData.materialType === 1) {
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('柱形图') !== -1) {
                    return across_bar_chart
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('折线图') !== -1) {
                    return line_chart 
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('饼图') !== -1) {
                    return pie_chart 
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('玫瑰图') !== -1) {
                    return rose_chart 
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('表格') !== -1) {
                    return table_chart
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('多柱图') !== -1) {
                    return vertical_bar_chart
                }
                if (this.itemData.materialTag && this.itemData.materialTag.indexOf('方块图') !== -1) {
                    return diamond_chart
                }
                return shutu_chart
            }
            return `${this.itemData.url}?x-oss-process=image/resize,h_100,m_lfit`
        },
        // 上屏
        goScreen() {
            // 点击上屏，取消预览显示
            this.changePreviewVisible()
            // 素材和我的上屏素材active互斥 
            if (this.materialSource != '背景' && this.materialSource != '直播LOGO' && this.materialSource != '跑马图') {
                this.$bus.$emit('clearMaterialActive')   
            }
            // 当前商品素材id
            this.$emit('change-active', this.itemData.id)
            if (this.materialSource === '资讯') {
                this.$bus.$emit('upScreen', {
                    materialClass: 0,
                    materialName: this.itemData.title,
                    materialType: 3,
                    url: this.itemData.newsUrl,
                    materialId: String(this.itemData.id)
                }, this.materialSource)
            } else {
                this.$bus.$emit('upScreen', this.itemData, this.materialSource)
            }
        },
        getOutMaterialId() {
            if (this.itemData.materialType === undefined) {
                return `news_${this.itemData.newsId}`
            } else if (this.itemData.materialType === 1) {
                return `shutu_${this.itemData.id}`
            } else {
                return this.itemData.outMaterialId
            }
        },
        addGroup() {
            // 取消预览显示
            this.changePreviewVisible()
            let obj = {}
            if (this.itemData.materialType === undefined) {
                // 资讯
                obj = {
                    materialClass: 0,
                    materialName: this.itemData.title,
                    materialType: 3,
                    url: this.itemData.newsUrl,
                    materialId: String(this.itemData.id),
                    materialTag: this.itemData.attitude,
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
            } else {
                obj= {
                    ...this.itemData
                }
            }
            this.$bus.$emit('addMaterial', obj)
        },
        // 删除我的素材
        deleteMaterial(callback) {
            // 点击上屏，取消预览显示
            this.changePreviewVisible()
            // 收藏取消收藏
            if (String(this.itemData.id).indexOf('card') !== -1) {
                this.API.myMaterial.delete({
                    id: this.itemData.id.slice(4),
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.$emit('get-list')
                        callback(true)
                    } else {
                        this.$message.error('删除失败')
                        callback(false)
                    }
                })
            } else {
                // 素材取消收藏
                this.API.myMaterial.delete({
                    materialId: this.getOutMaterialId(),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        callback(true)
                    } else {
                        this.$message.error('取消收藏失败')
                        callback(false)
                    }
                })
            }
        },
        // 添加素材到我的
        addMaterial(callback) {
            // 点击上屏，取消预览显示
            this.changePreviewVisible()
            if (this.itemData.materialType === undefined) {
                // 资讯
                this.API.myMaterial.addMaterial({
                    materialClass: 0,
                    materialName: this.itemData.title,
                    materialType: 3,
                    url: this.itemData.newsUrl,
                    materialId: String(this.itemData.id),
                    materialTag: this.itemData.attitude,
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }).then((response) => {
                    if (response && response instanceof Object && response.code === 200) {
                        callback(true)
                    } else {
                        this.$message.error('添加失败')
                        callback(false)
                    }
                })
            } else if (this.itemData.materialType === 1) {
                // 数图
                this.API.myMaterial.addMaterial({
                    materialClass: 0,
                    materialName: this.itemData.materialName,
                    materialType: 1,
                    url: this.itemData.url,
                    remark: this.itemData.remark,
                    materialTag: this.itemData.materialTag,
                    materialId: String(this.itemData.id),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }).then((response) => {
                    if (response && response instanceof Object && response.code === 200) {
                        callback(true)
                    } else {
                        this.$message.error('添加失败')
                        callback(false)
                    }
                })
            } else {
                this.API.myMaterial.addMaterial({
                    id: this.itemData.id,
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        callback(true)
                    } else {
                        this.$message.error('添加失败')
                        callback(false)
                    }
                })
            }      
        },
    },
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
