import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
//大宗交易数据
export function getBigTradeData(options) {
  return new Promise(async (resolve) => {

    let data = tempHistory["updateDate"];
    if(!data){
      data = await ajaxMarket();
      const btMarketData = JSON.parse(data.data.content).vtMarketBigTradeInfo;
      const updateDate = btMarketData[btMarketData.length-1].sTradingDay;
      tempHistory["updateDate"] = updateDate;
      tempHistory["btMarketData"] = btMarketData
    }

    let finalData;
    if (options.type == "成交量"){
      let data = tempHistory[options.type];
      if(!data){
        data = await ajasDetail(tempHistory["updateDate"]); 
        tempHistory[options.type] = data
      }
      finalData ={
        pageTitle:'大宗交易明细',
        data:handleDetailVolumn(data),
        date:tempHistory["updateDate"]
      };
    } else if (options.type == "溢价率"){
      let data = tempHistory[options.type];
      if(!data){
        data = await ajasDetail(tempHistory["updateDate"]); 
        tempHistory[options.type] = data
      }
      finalData ={
        pageTitle:'大宗交易明细',
        data:handleDetailPremiumRate(data),
        date:tempHistory["updateDate"]
      };
    }else if (options.type == "大宗交易"){
      let data = tempHistory[options.type];
      if(!data){
        data = await ajaxKLine(); 
        tempHistory[options.type] = data
      }

      const dataArr = [tempHistory["btMarketData"],data];
      finalData =handleBigTrade(dataArr);
      
    }
    resolve(finalData);
  });
  
  }

function handleDetailVolumn(data){
  const dayinfo = JSON.parse(data.data.content).vtDayBigTradeInfo;
  //从大到小排序
  dayinfo.sort((a,b)=>{
    return b.iDealVolumn-a.iDealVolumn
  })

  let chartData=[];  
  const titleArr = ["股票名称","收盘价/成交价", "成交量(万股)"];
  chartData.unshift(titleArr);
  //取前十
  dayinfo.slice(0,10).map((item,index)=>{
    chartData.push({
      sSecName:item.sSecName,
      fClose:item.fClose+'/'+item.fDealPrice,
      iDealVolumn:item.iDealVolumn/10000
    })
  })  
  return chartData
}

function handleDetailPremiumRate(data){
  const dayinfo = JSON.parse(data.data.content).vtDayBigTradeInfo;
  //从大到小排序
  // for(var i=0;i<dayinfo.length-1;i++){
  //   for(var j=0;j<dayinfo.length-i-1;j++){
  //     if(dayinfo[j].fPremiumRate<dayinfo[j+1].fPremiumRate){
  //       var count = dayinfo[j];
  //       dayinfo[j] = dayinfo[j+1];
  //       dayinfo[j+1] = count
  //     }

  //   }
  // }
  dayinfo.sort((a,b)=>{
    return b.fPremiumRate-a.fPremiumRate
  })
  let chartData=[];  
  const titleArr = ["股票名称","收盘价/成交价", "溢价率"];
  chartData.unshift(titleArr);
  //取前十
  dayinfo.slice(0,10).map((item,index)=>{
    if(item.fPremiumRate !== 0){
      chartData.push({
        sSecName:item.sSecName,
        fClose:item.fClose+'/'+item.fDealPrice,
        fPremiumRate:item.fPremiumRate+'%'
      })
    } else{
      chartData.push({
        sSecName:item.sSecName,
        fClose:item.fClose+'/'+item.fDealPrice,
        fPremiumRate:item.fPremiumRate
      })     
    }

  }); 
  return chartData;
  
}

function handleBigTrade(dataArr){
  let barlineData = [];
  let tableDate =[];
  const btData = dataArr[0];
  const dayK =JSON.parse(dataArr[1].data.content).vKLineDesc
  //对K线数据排除不在时间范围的数据
  //大宗交易的最大日期
  let sTradingDay = btData[btData.length-1].sTradingDay;
  //取到的数据为数值型，转化为字符型
  //K线的最大日期
  let lYmd = String(dayK[dayK.length-1].lYmd);
  //转化日期格式
  let kTradeDay = moment(lYmd).format('yyyy-MM-DD');
  let dayk;
  let btdata;
  //2组数据取一样的长度
  if (kTradeDay == sTradingDay){
    const datalength = dayK.length;
    dayk = dayK;
    btdata = btData.slice(-datalength);
  }else if (kTradeDay > sTradingDay){
    const datalength = dayK.length-1;
    dayk = dayK.slice(0,datalength);
    btdata = btData.slice(-datalength);
  }

  //柱形图和折线图的数据
  btdata.map((item,index)=>{
    barlineData.push({
      label:item.sTradingDay,
      bar:item.fBigTradeAmt/100000000,
      line:dayk[index].fClose
    })

  })
  //表格内容
  const titleArr = ["交易日期","成交额","溢价成交比例", "折价成交比例"];
  btdata.slice(-5).reverse().map((item,index)=>{
    tableDate.push({
      tradeday:item.sTradingDay,
      fBigTradeAmt:(item.fBigTradeAmt/100000000).toFixed(2)+'亿元',
      fPremiumPct:(item.fPremiumPct).toFixed(2)+'%',
      fDiscountPct:(item.fDiscountPct).toFixed(2)+'%',
    })

  })
  tableDate.unshift(titleArr)
  const result = {
    pageTitle:'大宗交易',
    data:barlineData,
    tabledata:tableDate,
    legend:['大宗交易成交额(亿)','上证指数'],
    date:sTradingDay
  };
  return (result);
}


  //获取大宗交易
async function ajaxMarket(options) {
    return await axios.post("https://comm.wedengta.com/?s=bigTrade&f=getMarketBigTrade&req=MarketBigTradeReq&rsp=MarketBigTradeRsp");
  }

  //获取上证指数
async function ajaxKLine(options) {
    return await axios.post("https://comm.wedengta.com/?s=quote&f=getKLine&req=KLineReq&rsp=KLineRsp",
    JSON.stringify({
      sDtSecCode: "0105000001",
      eKLineType:4,
      iWantnum:60,
    })
    );
  }

  //获取大宗交易的成交量和溢价率
async function ajasDetail(options) {
    return await axios.post("https://comm.wedengta.com/?s=bigTrade&f=getDayBigTrade&req=DayBigTradeReq&rsp=DayBigTradeRsp",
    JSON.stringify({
        sTradingDay: options,
      }));
  }