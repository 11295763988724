<template>
    <div class="right_preview">
        <div class="live_title">
            <div>
                <span>{{ detail.roomName }}</span>
                <span :class="[isConnected ? 'connect' : 'break']">{{ isConnected ? '已连接' : '未连接' }}</span>
            </div>
            <div class="live_operation">
                <div class="left">
                    <div class="brush" :style="{ paddingRight: '10px', cursor: 'pointer' }" @click="changeColorPicker">
                        <span>屏幕画笔</span>
                        <img src="@/assets/images/colorPicket/brush.svg" alt="">
                    </div>
                    <DrawTool @changeColorPicker="changeColorPicker" v-show="currentVisible" />
                </div>
                <button @click="stopRecordingCallback">中断画面</button>
            </div>
        </div>
        <div :class="detail.roomSizeScale === '16:9' ? 'preview_canvas_1' : 'preview_canvas_0'" ref="refPreviewVideoHolder" id="preview">
            <video ref="video" id="previewVideo" class="preview_video" playsinline autoplay></video>
            <div :class="['canvas_area', currentVisible ? '' : 'canvas_holder']">
                 <div class="draw_area" ref="refDrawArea"></div>
            </div>
            <div class="preview_default" v-show="isPreview">
                <img src="@/assets/images/detail/select_preview.png" alt="" @click="openPreviewDialog">
            </div>
        </div>
        <PreviewDialog ref="previewDialog" @openObsDialog="openObsDialog" @openWindowDialog="openWindowDialog" />
        <ObsDialog ref="obsDialog" @previewObs="previewObs" />
        <WindowDialog ref="windowDialog" @clickStartRecording="clickStartRecording" />
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import PreviewDialog from './previewDialog.vue'
import ObsDialog from './obsDialog.vue'
import WindowDialog from './windowDialog.vue'
import debounce from 'lodash.debounce'
//导入画图部分
import CanvasDrawing from 'canvas-drawing'
import DrawTool from './drawTool.vue'
import RecordRTC from '@/utils/RecordRTC.js'
let draw = null
let recorder = null
export default {
    props: {
        isConnected: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            isPreview: true,
            currentVisible: false,
        }
    },
    components: {
        DrawTool,
        PreviewDialog,
        ObsDialog,
        WindowDialog
    },
    created() {
        this.detail = JSON.parse(localStorage.getItem('room_detail'))
        this.setDraw = debounce(this.setDraw, 500)
    },
    async mounted() {
        this.checkWebRtc()
        await sleep(1000)
        this.initDraw()
    },
    methods: {
        // 获取obs画面
        async previewObs() {
            let that = this
            if (!this.isConnected) {
                return that.$message.info('请打开接收端')
            }
            if (window.stream) {
                window.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            function gotStream(stream) {
                that.isPreview = false
                that.$refs.obsDialog.close()
                const videoElement = document.querySelector("#previewVideo");
                videoElement.srcObject = stream;
            }

            function handleError(error) {
                console.log("navigator.MediaDevices.getUserMedia error: ", error.message, error.name);
            }

            const devices = await navigator.mediaDevices.enumerateDevices();
            let obsId = "";
            devices.map((item) => {
                if (item.label == "OBS Virtual Camera") {
                    obsId = item.deviceId;
                }
            });
            if (obsId == "") {
                alert("obs 未打开!");
            }
            const constraints = {
                video: { deviceId: obsId ? { exact: obsId } : undefined },
            };
            navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
        },
        checkWebRtc() {
            if(!navigator.getDisplayMedia && !navigator.mediaDevices?.getDisplayMedia) {
                var error = 'Your browser does NOT support the getDisplayMedia API.';
                alert(error)
            }
        },
        invokeGetDisplayMedia(success, error) {
            let displaymediastreamconstraints = {
                video: {
                    displaySurface: 'monitor', // monitor, window, application, browser
                    logicalSurface: true,
                    cursor: 'always' // never, always, motion
                }
            };
            displaymediastreamconstraints = {
                video: true
            };
        
            if(navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
            }
            else {
                navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
            }
        },
        addStreamStopListener(stream, callback) {
            stream.addEventListener('ended', function() {
                callback();
                callback = function() {};
            }, false);
            stream.addEventListener('inactive', function() {
                callback();
                callback = function() {};
            }, false);
            stream.getTracks().forEach(function(track) {
                track.addEventListener('ended', function() {
                    callback();
                    callback = function() {};
                }, false);
                track.addEventListener('inactive', function() {
                    callback();
                    callback = function() {};
                }, false);
            });
        },
        captureScreen(callback) {
            let that= this
            that.invokeGetDisplayMedia(function(screen) {
                that.addStreamStopListener(screen, function() {
                    that.clickStopRecording()
                });
                callback(screen);
            }, function(error) {
                // 打开窗口选择器，点击取消
                console.log(error)
            });
        },
        clickStartRecording() {
            let that = this
            // 当接收端页面没有打开的时候，阻止
            if (!that.isConnected) {
                return that.$message.info('请打开接收端')
            }
            that.captureScreen(function(screen) {
                that.$refs.video.srcObject = screen;
        
                recorder = RecordRTC(screen, {
                    type: 'video'
                });
        
                recorder.startRecording();
        
                // release screen on stopRecording
                recorder.screen = screen;
                that.isPreview = false
                that.$refs.windowDialog.close()
            });
        },

        // 停止窗口采集
        clickStopRecording() {
            if (recorder) {
                recorder.stopRecording(this.stopRecordingCallback);
            }
        },
        stopRecordingCallback() {
            this.$refs.video.src = this.$refs.video.srcObject = null;
            // this.$refs.video.src = URL.createObjectURL(recorder.getBlob());
            this.isPreview = true;
            if (recorder) {
                recorder.screen.stop();
                recorder.destroy();
                recorder = null;
            }
        },
        
        // 初始化画笔面板
        async initDraw() {
            const options = {
                container: this.$refs.refDrawArea,
                canvasBgColor: 'rgba(0,0,0,0)',
                lineColor: 'red',
                textColor: 'red',
            }
            draw = new CanvasDrawing(options);
            draw.evt.on('drawEnd', this.setDraw)
            draw.evt.on('drawChange', this.setDraw)
            this.global.setDraw(draw)
        },
        setDraw() {
            const _that = this
            draw.generateBase64().then((res) => {
                _that.sendCommand("fullMedia", "show", res);
            })
        },
        // 打开画笔工具
        changeColorPicker() {
            this.currentVisible = !this.currentVisible
        },
        // 发送命令,统一格式为组件名，命令，命令数据
        sendCommand(componentName, componentCommand, componentCommandData) {
            this.global.ws.send(
                    JSON.stringify({
                    componentName: componentName,
                    componentCommand: componentCommand,
                    componentCommandData: componentCommandData,
                })
            );
        },
        openPreviewDialog() {
            this.$refs.previewDialog.open()
        },
        openObsDialog(){
            this.$refs.obsDialog.open()
        },
        openWindowDialog() {
            this.$refs.windowDialog.open()
        }
    },
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>
