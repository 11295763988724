import axios from "axios";

let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢


/*=====================财报-业绩=========================*/
export function getFinanceAnalysisData(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode];
    let finalData = [];
    if (!fullData) {
      fullData = await ajaxFinancialAnalysis(options);
      fullData = JSON.parse(fullData.data.content).mvFinAnalysisDimItem;
      tempHistory[options.seccode] = fullData;
    }
    finalData = getFinalData(fullData, options);
    const legend1Unit = finalData.unit == "" ? "" : `(${finalData.unit})`;
    const result = {
      pageTitle: getTitle(options),
      data: finalData,
      legend1: [options.type.split("-")[1] + legend1Unit, "五年均值"],
      legend2: ["同比(%)", "环比(%)"],
      date: getDate(finalData, options),
      desc: finalData.desc,
    };
    resolve(result);
  });
}
function getFinalData(financeData, options) {
  const seasonData = financeData[options.seasonType];
  const typeArr = options.type.split("-");
  const typeObj = {
    盈利能力: { index: 0, arr: ["ROE", "毛利润率", "净利润率"] },
    偿债能力: { index: 1, arr: ["流动比率", "速动比率", "现金比率", "产权比率"] },
    成长能力: { index: 2, arr: ["营业收入增长率", "营业利润增长率", "现金流"] },
    运营能力: { index: 3, arr: ["存货周转率", "总资产周转率", "应收账款周转率", "营业周期"] },
  };
  //数据
  let targetData = seasonData[typeObj[typeArr[0]].index].vFinAnalysisIndexItem;
  targetData = targetData[typeObj[typeArr[0]].arr.indexOf(typeArr[1])].vFinAnalysisSubIndexItem[0];
  let result = [];
  targetData.vFinAnalysisDataItem.map((item) => {
    let temp = {
      label: item.sYear,
      bar: 1 * (1 * item.sOrgValue).toFixed(1),
      value1: 1 * (1 * item.sOrgValueYoY).toFixed(1), //同比
      value2: 1 * (1 * item.sOrgValueSoS).toFixed(1), //环比
    };
    if (typeArr[1] == "现金流") {
      temp.bar = 1 * (temp.bar / 100000000).toFixed(1);
    }
    result.push(temp);
  });
  //描述
  const descIndexObj = {
    ROE: 0,
    毛利润率: 1,
    净利润率: 2,
    流动比率: 0,
    速动比率: -1,
    现金比率: -1,
    产权比率: 1,
    营业收入增长率: 0,
    营业利润增长率: 1,
    现金流: 3,
    存货周转率: 0,
    总资产周转率: 1,
    应收账款周转率: 2,
    营业周期: 3,
  };
  let desc = seasonData[typeObj[typeArr[0]].index].vDescription[descIndexObj[typeArr[1]]];
  let ave = 1 * targetData.sOrgValueAvg;
  if (typeArr[1] == "现金流") {
    ave = 1 * (ave / 100000000).toFixed(1);
  }
  return {
    ave: ave,
    chartData: result.reverse(),
    unit: getUnit(targetData.sDisplayValueAvg),
    desc: desc,
  };
}
function getUnit(valueTxt) {
  let resultArr = [];
  valueTxt.split("").map((item) => {
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "-"].indexOf(item) == -1) {
      resultArr.push(item);
    }
  });
  return resultArr.join("");
}
function getTitle(options) {
  const bracketTxtArr = options.type.split("-");
  return `${options.secname}${bracketTxtArr[0]}(基于${bracketTxtArr[1]}分析)`;
}
function getDate(finalData, options) {
  const eSeasonTypeObj = {
    1: "一季报",
    2: "中报",
    3: "三季报",
    4: "年报",
  };
  return `数据来自${options.secname}${finalData.chartData.slice(-1)[0].label}年${
    eSeasonTypeObj[options.seasonType]
  }`;
}

//获取财报解析
async function ajaxFinancialAnalysis(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinanceAnalysis&req=GetFinanceAnalysisReq&rsp=GetFinanceAnalysisRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      vSeasonType: [1, 2, 3, 4]
    })
  );
}
