import { render, staticRenderFns } from "./marginTrade.vue?vue&type=template&id=1dc90187&scoped=true&"
import script from "./marginTrade.vue?vue&type=script&lang=js&"
export * from "./marginTrade.vue?vue&type=script&lang=js&"
import style0 from "./marginTrade.vue?vue&type=style&index=0&id=1dc90187&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc90187",
  null
  
)

export default component.exports