<template>
    <el-dialog
        :visible.sync="visible"
        title="续费提示"
        :close-on-click-modal="false"
        width="560px"
        top="30vh"
        @close="close"
    >
        <div class="content">
            您的使用权限已到期，功能无法使用，请前往付费购买！     
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="medium">取消</el-button>
            <el-button @click="pay" type="primary" size="medium">购买</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            this.$bus.$emit('clearMaterialActive')
            this.$bus.$emit('clearBackgroundActive')
            this.visible = false
        },
        pay() {
            this.close()
            this.$bus.$emit('clearMaterialActive')
            this.$bus.$emit('clearBackgroundActive')
            this.$bus.$emit('changePay', true)
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }
}
.content {
    text-align: center;
    color: #fff;
    font-size: 14px;
}
/deep/ .el-dialog__footer {
    // border-top: 2px solid #292B2D;
    padding: 16px 20px 20px;
    .el-button--default {
        border-radius: 4px;
        border: 1px solid #4E8CFF;
        background: #252525;
        color: #4E8CFF;
        margin-right: 60px;
    }
    .el-button--primary {
        border: 1px solid #4E8CFF;
        background: #4E8CFF;
    }
}
</style>