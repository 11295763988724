<template>
    <div class="select_stock">
        <h3>{{ ['板块'].indexOf(data.materialName) !== -1 ? '板块' : '股票名称'}}</h3>
        <el-select
            v-model="stock_code"
            :placeholder="['板块'].indexOf(data.materialName) !== -1 ? '请选择板块' : '请选择股票'"
            filterable
            remote
            size="medium"
            :remote-method="searchStock"
            :loading="loading"
            @change="changeStock"
        >
            <el-option
                v-for="item in stockArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
        </el-select>
    </div>
</template>
<script>
import { getStock } from '@/api/other'
import debounce from 'lodash.debounce'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            stockArr: [],
            stock_code: '',
            loading: false,
        }
    },
    created() {
        this.changeStock = debounce(this.changeStock, 500)
        this.getStockSearchList()
    },
    methods: {
        getStockSearchList() {
            this.API.search.getStockSearchList().then((res) => {
                try {
                    if (res && res?.code === 200 && res.data.length > 0) {
                        this.stockArr = res.data.slice(0, 10).map((item) => {
                            return JSON.parse(item.stockName)
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            })
        },
        closeSelect() {
            Object.assign(this.$data, this.$options.data())
        },
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName || item.sPlateName,
                        value: item.sDtSecCode,
                    }
                })
            }
        },
        searchStock(value) {
            this.loading = true
            getStock({
                key: value,
                type: ['板块'].indexOf(this.data.materialName) !== -1 ? 2 : 0, // 0|只返回A股， 1|A股、港美股， 2|返回a股、港美股、题材、板块、
            }).then((res) => {
                this.loading = false
                if (this.data.materialName === '板块' && res && res instanceof Object && res.vPlateItem && res.vPlateItem.length > 0) {
                    this.handleStockList(res.vPlateItem)
                } else if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                     this.handleStockList(res.vStockItem)
                }
            })
        },
        changeStock(value) {
            let current = this.stockArr.filter(item => item.value === value)[0]
            this.$emit('selectStock', current)
            this.API.search.reportStockSearch({
                stockName: JSON.stringify(current)
            })
        },
    }
}
</script>
<style lang="less" scoped>
.select_stock {
    display: flex;
    flex-direction: column;
    width: 260px;
    > h3 {
        color: #fff;
        margin-bottom: 6px;
    }
    /deep/ .el-select .el-input.is-focus .el-input__inner {
        border-color: rgba(255, 255, 255, 0.08);
    }
    /deep/ .el-input__inner {
        background: #202020;
        border-color: rgba(255, 255, 255, 0.08);
        color: #fff;
    }
}
</style>