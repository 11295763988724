<template>
    <div class="numFigure">
        <div class="numFigure_bg" v-loading="loading">
            <iframe :src="url" frameborder="0" ref="refGeneralChart"></iframe>
        </div>
        <div class="numFigure_msg">
            <p v-html="dataSource.materialName"></p>
            <div>
                <span>属性：</span>
                <span>数图</span>
            </div>
            <div>
                <span>标签：</span>
                <span>{{ dataSource.materialTag}}</span>
            </div>
            <div>
                <span>发布时间：</span>
                <span>{{ $moment(dataSource.createTime).format('YYYY-MM-DD') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralChart from "@/businessComp/charts/generalChart/index.vue";

import { convertChartData, sleep } from "@/utils/utils.js";
import axios from "axios";

export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            loading: false,
            url: `${location.origin}/preview/generalChart`
        }
    },
    components: {
        GeneralChart
    },
    created() {
        this.setGeneralWindowChart()
    },
    methods: {
        //获取通用图表的数据
        getGeneralChartData(url) {
            return new Promise(async (resolve, reject) => {
                let chartData = await axios.get(url);
                try {
                    if (chartData.data.dataDescrip == undefined) {
                        reject();
                    } else {
                        chartData = this.changeChartType(chartData.data);
                        const data = convertChartData(chartData.dataDescrip);
                        const result = {
                            title: chartData.dataDescrip.chartTitle,
                            timeCyc: chartData.dataDescrip.timeCyc,
                            data: data,
                            type: chartData.dataDescrip.chartType,
                        };
                        resolve(result);
                    }
                } 
                catch (e) {
                    reject();
                }
            });
        },
        //潜规则：通过标题，或者数据量，改变图表类型
        changeChartType(data) {
            if (data.dataDescrip.chartType == "柱形图" && this.dataSource.materialTag.indexOf("区间柱图") > -1) {
                data.dataDescrip.chartType = "区间柱图";
            } else if (data.dataDescrip.chartType == "柱形图" && this.dataSource.materialTag.indexOf("方块图") > -1) {
                data.dataDescrip.chartType = "方块图";
            } else if (data.dataDescrip.chartType == "柱形图" && data.dataDescrip.originalData.chart1Ydata.length > 1) {
                data.dataDescrip.chartType = "多柱图";
            }
            if (data.dataDescrip.chartType == "饼图" && this.dataSource.materialTag.indexOf("玫瑰图") > -1) {
                data.dataDescrip.chartType = "玫瑰图";
            }
            return data;
        },
        //设置当前通用图表
        async setGeneralWindowChart() {
            let commandData = "";
            this.loading = true
            commandData = await this.getGeneralChartData(this.dataSource.url).catch(() => {
                this.loading = false
            });
            this.loading = false
            await sleep(1000)
            if (this.$refs.refGeneralChart?.contentWindow) {
                this.$refs.refGeneralChart.contentWindow.show(commandData);
            }
        },
    },
}
</script>
<style lang="less" scoped>
.numFigure {
    // height: 500px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .numFigure_bg {
        width: 100%;
        height: 380px;
        background: #1B1B26;
        > iframe {
            width: 100%;
            height: 100%;
        }
    }
    .numFigure_msg {
        flex: 1;
        color: rgba(255, 255, 255, 0.5);
        > p {
            font-size: 14px;
            margin-top: 10px;
        }
        > div {
            font-size: 14px;
            margin-top: 10px;
        }
    }
    /deep/ .el-loading-mask {
      background: #16181A;
    }
}
</style>