import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
//大宗交易数据
export function getKeChuangGraphData(options) {
    const typeObj = {
        "已申报科创板的公司": "getKCReportCompany", 
        "拟申报科创板的公司": "getKCReportCompany", 
        "三板达标的公司": "getKCReportCompany",        
        "(申报方)的详细介绍": "getKCReportCompanyInfo",        
        "(关联方)的关联情况": "getAGuGraphInfo",        
        "(申报方)的关联图谱": "getKCGraphChirdInfo",        
        "(申报方)的关联关系": "getKCAssociatedCompany",        
        "(关联方)的关联图谱": "getAGuGraphChirdInfo",        
        "(关联方)的关联关系": "getAssociatedCompany"
      };
      const functions = {
        getKCReportCompany,
        getKCReportCompanyInfo,
        getAGuGraphInfo,
        getKCGraphChirdInfo,
        getKCAssociatedCompany,
        getAGuGraphChirdInfo,
        getAssociatedCompany
      };
      return functions[typeObj[options.subType]](options);
}

/*=====================申报情况========================*/
export function getKCReportCompany(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.subType]
        if (!fullData){
            fullData = await ajaxKCReportCompany(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.subType] = fullData;
        }
        const finalData = {
            pageTitle: options.subType,
            data:handleKCReportCompany(fullData,options)
        };
        resolve(finalData);
    });
  }

function handleKCReportCompany(data,options){
    let chartData = [];
    let controlData = [];
    let titleArr=[];
    if (options.subType == "三板达标的公司"){
        data.vtKCReachStaCompanyInfo.map((item,index)=>{
            chartData.push([
                item.sShortName,
                item.vtAssociatedCompany[0]== undefined ?"--":item.vtAssociatedCompany[0]+'等',
                item.sTotalCap+'亿',
                item.sIncome1Year>=10000?(item.sIncome1Year/10000).toFixed(2)+"亿":item.sIncome1Year==0?"--":item.sIncome1Year+"万",
                item.sNetprofit2Year>=10000?(item.sNetprofit2Year/10000).toFixed(2)+'亿':item.sNetprofit2Year==0?"--":item.sNetprofit2Year+"万"
            ]);
            controlData.push({                
                name:item.sShortName,
                companyname:item.vtAssociatedCompany
            })


    
        })
        titleArr = ["公司名称","相关股票","估值","近一年营收","近一年利润"];
    }else{
        data.vtKCReportCompanyInfo.map((item,index)=>{
            chartData.push([
                item.sShortName,
                item.vtAssociatedCompany[0]== undefined ?"--":item.vtAssociatedCompany[0]+'等',
                item.sIndustry==""?"--":item.sIndustry,
                item.sIntermediary,
                item.sStatus
            ]);
            controlData.push({                
                name:item.sShortName,
                companyname:item.vtAssociatedCompany
            })    
        })        
        titleArr = ["公司名称","关联公司","行业","保荐机构","状态"];
    }

    chartData.unshift(titleArr);
    return {chartData:chartData.slice(0,9),controlData: controlData}
  }
/*=====================(申报方)的详细介绍========================*/
export function getKCReportCompanyInfo(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxKCGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleKCReportCompanyInfo(fullData,options)
        };
        resolve(finalData);
    });

}

function handleKCReportCompanyInfo(data,options){
    let chartData = [];
    let titleArr=[];
    chartData.push([data.sCompanyProfile])
    titleArr = ["公司介绍"];
    chartData.unshift(titleArr);
    return chartData
}

/*=====================(关联方)的关联情况========================*/
export function getAGuGraphInfo(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxAGuGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleAGuGraphInfo(fullData,options)
        };
        resolve(finalData);
    });

}

function handleAGuGraphInfo(data,options){
    let chartData = {};
    chartData={
        '收盘价':data.fClosePrice,
        '涨跌幅':data.fUpOrDown+"%",
        '近一月涨跌幅':data.fUpOrDown1Month+"%",
        '关联公司数':data.vtAssociatedCompany.length
    }
    return chartData
}


/*=====================(申报方)的关联图谱========================*/
export function getKCGraphChirdInfo(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxKCGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.compareStocks[0].secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleKCGraphChirdInfo(fullData,options)
        };
        resolve(finalData);
    });
}
function handleKCGraphChirdInfo(data,options){
    let chartData = [];
    data.vtKCGraphChirdInfo1.map((item,index)=>{        
        if(chartData.indexOf({sName:item.sGuDongName})==-1){
            chartData.push({
                sName:item.sGuDongName.length > 22 ? item.sGuDongName.slice(0,22)+'...':item.sGuDongName
            })
        }
    })
    data.vtKCGraphChirdInfo2.map((item,index)=>{
        if(chartData.indexOf({sName:item.sGuDongName})==-1){
            chartData.push({
                sName:item.sGuDongName.length > 22 ? item.sGuDongName.slice(0,22)+'...':item.sGuDongName
            })
        }
    })
    return chartData.slice(0,11)
}

/*=====================(申报方)的关联关系========================*/
export function getKCAssociatedCompany(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxKCGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.compareStocks[0].secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleKCAssociatedCompany(fullData,options)
        };
        resolve(finalData);
    });
}
function handleKCAssociatedCompany(data,options){
    let chartData = [];
    data.vtKCGraphChirdInfo1.slice(0,2).map((item,index)=>{   
            chartData.push({
                sName:item.sGuDongName,
                childData:childData(item.vtAGuCompanyInfo)
            })
       
    })
    return chartData.slice(0,11)
}

function childData(options){    
    let childData = [];
    options.map((item,index)=>{
        childData.push({
            sSecCode:item.sSecCode,
            sSecname:item.sSecname
        })

    })
    return childData.slice(0,7)

}
/*=====================(关联方)的关联图谱========================*/
export function getAGuGraphChirdInfo(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxAGuGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.compareStocks[0].secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleAGuGraphChirdInfo(fullData,options)
        };
        resolve(finalData);
    });
}
function handleAGuGraphChirdInfo(data,options){
    let chartData = [];
    data.vtAGuGraphChirdInfo1.map((item,index)=>{        
        if(chartData.indexOf({sName:item.sGuDongName})==-1){
            chartData.push({
                sName:item.sGuDongName.length > 22 ? item.sGuDongName.slice(0,22)+'...':item.sGuDongName
            })
        }
    })
    data.vtAGuGraphChirdInfo2.map((item,index)=>{
        if(chartData.indexOf({sName:item.sGuDongName})==-1){
            chartData.push({
                sName:item.sGuDongName.length > 22 ? item.sGuDongName.slice(0,22)+'...':item.sGuDongName
            })
        }
    })
    return chartData.slice(0,11)
}


/*=====================(关联方)的关联关系=======================*/
export function getAssociatedCompany(options) {
    return new Promise(async (resolve) => {
        let fullData = tempHistory[options.compareStocks[0].secname+options.subType]
        if (!fullData){
            fullData = await ajaxAGuGraphInfo(options);
            fullData = JSON.parse(fullData.data.content);
            tempHistory[options.compareStocks[0].secname+options.subType] = fullData;
        }
        const finalData = {
            pageTitle: getTitle(options),
            data:handleAssociatedCompany(fullData,options)
        };
        resolve(finalData);
    });
}
function handleAssociatedCompany(data,options){
    let chartData = [];
    data.vtAGuGraphChirdInfo1.slice(0,2).map((item,index)=>{   
            chartData.push({
                sName:item.sGuDongName,
                childData:childDataAs(item.vtKCCompanyStatusInfo)
            })
       
    })
    return chartData.slice(0,11)
}

function childDataAs(options){    
    let childData = [];
    options.map((item,index)=>{
        childData.push({
            sSecname:item.sSecName
        })

    })
    return childData.slice(0,7)

}


function getTitle(options){
    return options.compareStocks[0].secname+options.subType
}

function getCompareTitle(options){
    return options.compareStocks[0].secname+options.subType
}


async function ajaxKCReportCompany(options){
    let require = requireInfo(options)
    const urlObj = {
        "已申报科创板的公司":"https://comm.mdengta.com/?s=KeChuangGraph&f=getKCReportCompanyList&req=KCInfoListReq&rsp=KCReportCompanyListRsp",
        "拟申报科创板的公司":"https://comm.mdengta.com/?s=KeChuangGraph&f=getKCReportCompanyList&req=KCInfoListReq&rsp=KCReportCompanyListRsp",
        "三板达标的公司":"https://comm.mdengta.com/?s=KeChuangGraph&f=getKCReachStaCompanyList&req=KCInfoListReq&rsp=KCReachStaCompanyListRsp"
    }
    return await axios.post(urlObj[options.subType],require)
}

function requireInfo(options){
    const listType={
        "已申报科创板的公司":1,
        "拟申报科创板的公司":2,
        "三板达标的公司":3
    }
    const statusType={
        "已申报科创板的公司":101,
        "拟申报科创板的公司":100
    }

    if(options.subType=='三板达标的公司'){
        return  JSON.stringify({eListType: listType[options.subType],iStartxh:0,iWantnum:10})
    }else{
        return  JSON.stringify({eListType: listType[options.subType],eStatusType:statusType[options.subType],iStartxh:0,iWantnum:10})
    }
}


async function ajaxKCGraphInfo(options){
    return await axios.post("https://comm.mdengta.com/?s=KeChuangGraph&f=getKCGraphInfo&req=CompanyGraphInfoReq&rsp=KCGraphInfoRsp",
    JSON.stringify({
        sShortName: ("compareStocks" in options)?options.compareStocks[0].secname:options.secname,
        eCompanyType:1
    }))    
}

async function ajaxAGuGraphInfo(options){
    return await axios.post("https://comm.mdengta.com/?s=KeChuangGraph&f=getAGuGraphInfo&req=CompanyGraphInfoReq&rsp=AGuGraphInfoRsp",
    JSON.stringify({
        sShortName: ("compareStocks" in options)?options.compareStocks[0].secname:options.secname,
        eCompanyType:2
    }))    
}