<template>
    <div class="marginTrade">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterType()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['新股申购', '等待上市'].indexOf(tool_filter_one) !== -1">
                <h3>新股</h3>
                <el-select v-model="tool_filter_two"  size="medium" placeholder="请选择新股" @change="changeStock()">
                    <el-option
                        v-for="(item, index) in stockArr"
                        :key="index"
                        :label="item.sSecName"
                        :value="`${item.sSecName}-${item.sDtSecCode}`"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="tool_filter_two">
                <h3>申购详情</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changeType()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in typeArr"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            stockArr: [],
            typeArr: [
                {
                    label: '公司简介',
                    value: '公司简介'
                },
                {
                    label: '主营业务',
                    value: '主营业务'
                },
                {
                    label: '基本信息',
                    value: '基本信息'
                },
            ],
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeFilterType() {
            this.tool_filter_two = ''
            this.tool_filter_three = ''
            this.stockArr = []
            if (['新股申购', '等待上市', '上市表现'].indexOf(this.tool_filter_one) !== -1) {
                this.$emit('setCustomWindowChart', {
                    ...this.activeWindowCustomChartMsg,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                    })
                }, 'show', (data) => {
                    let newData = data.data.data
                    this.stockArr = newData.slice(1)
                })
            }
        },
        changeStock() {
            if (!this.tool_filter_three) {
                this.tool_filter_three = '基本信息'
            }
            this.changeType()
        },
        changeType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_three,
                    seccode: this.tool_filter_two.split("-")[1],
                    secname: this.tool_filter_two.split("-")[0],
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}${this.tool_filter_two ? `-${this.tool_filter_two.split("-")[0]}-${this.tool_filter_three}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_three || this.tool_filter_one,
                        seccode: this.tool_filter_two ? this.tool_filter_two.split("-")[1] : undefined,
                        secname: this.tool_filter_two ? this.tool_filter_two.split("-")[0] : undefined,
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            margin-right: 30px;
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>