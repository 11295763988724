import axios from "axios";
import { getUpdateDate } from "@/utils/utils.js";

export function getmarginTradeData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let updateDate = await getDate(options);
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options),
                date: updateDate || options.num || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getDate(options) {
    return new Promise(async (resolve) => {
        let data = ''
        if (['vtSZMarginTrade', 'vtSHMarginTrade', 'vtIndustryBuyValuePlate', 'vtPlateBuyValuePlate'].indexOf(options.type) !== -1) {
            data = await getUpdateDate()
        }
        resolve(data)
    })    
}
function getPageTitle(options) {
    let beginTxt
    if (options.type === 'vtSHMarginTrade') {
        beginTxt = '融资融券余额'
    }
    if (options.type === 'vtSZMarginTrade') {
        beginTxt = '融资买入额'
    }
    if (options.type === 'IndustryPlateDtMargin') {
        beginTxt = '融资买入个股top10'
    }
    if (options.type === 'vtIndustryBuyValue') {
        beginTxt = `融资买入行业板块-${options.plate}`
    }
    if (options.type === 'vtPlateBuyValue') {
        beginTxt = `融资买入概念板块-${options.plate}`
    }
    if (options.type === 'vtIndustryBuyValuePlate') {
        beginTxt = '融资买入行业板块'
    }
    if (options.type === 'vtPlateBuyValuePlate') {
        beginTxt = '融资买入概念板块'
    }
    return beginTxt
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data
        if (options.type === 'IndustryPlateDtMargin') {
            data = await getIndustryPlateDtMargin()
        }
        if (options.type === 'vtSZMarginTrade' || options.type === 'vtSHMarginTrade') {
            data = await getMarginTrade()
        }
        if (options.type === 'vtIndustryBuyValue' || options.type === 'vtPlateBuyValue') {
            data = await getBuyValue(options)
        }
        if (options.type === 'vtIndustryBuyValuePlate' || options.type === 'vtPlateBuyValuePlate') {
            data = await getBuyValuePlate(options)
        }
        resolve(data)
    })
}
// 板块列表
function getBuyValuePlate(options) {
    return new Promise(async (resolve) => {
        let data = await ajaxIndustryPlateBuyValue(options)
        data = JSON.parse(data.data.content)
        resolve(options.type === 'vtIndustryBuyValuePlate' ? data.vtIndustryBuyValue : data.vtPlateBuyValue)
    })
}
// 板块个股
function getBuyValue(options) {
    return new Promise(async (resolve) => {
        let result = [['名称代码', '涨跌幅', '融资买入', '融资买入比']]
        let data = await ajaxPlateDtMargin(options)
        data = JSON.parse(data.data.content).vtIndustryBuyValue
        if (Array.isArray(data) && data.length > 0) {
            let simpleData = await ajaxSecInfo(data.map(item => item.sDtSecCode).join('|'))
            simpleData = JSON.parse(simpleData.data.content).vSecSimpleQuote
            simpleData.forEach((item, index) => {
                const arise = (100 * (item.fNow - item.fClose)) / item.fClose;
                result.push([
                    `<div>${item.sSecName}</div><div>${item.sDtSecCode.substr(4,6)}</div>`,
                    arise.toFixed(2),
                    data[index].sBuyValue,
                    data[index].sBuyPercent
                ])
            })
        }
        resolve(result)
    })
}
function completeData(vtSHMarginTrade, vtSZMarginTrade) {
    let base1,base2;
			if(vtSHMarginTrade.length==vtSZMarginTrade.length){
				return {
					vtSHMarginTrade:vtSHMarginTrade,
					vtSZMarginTrade:vtSZMarginTrade
				};
			}else if(vtSHMarginTrade.length>=vtSZMarginTrade.length){
				base1 = vtSHMarginTrade;
				base2 = vtSZMarginTrade;
			}else{
				base2 = vtSHMarginTrade;
				base1 = vtSZMarginTrade;
			}
			let opDateArray = [];
			for(let i=0;i<base1.length;i++){
				opDateArray.push(base1[i].sOpDate);
			}

			let start_index = 0;
			for (let j = 0; j < opDateArray.length; ++j) {
			    if (base2.sOpDate == base1.sOpDate) {
			        start_index = j;
			        break;
			    }
			}

			let data_start = 0;
			for (let k = start_index;k < opDateArray.length; ++k) {
			    if (base2[data_start] === undefined || base1[k].sOpDate != base2[data_start].sOpDate) {
			        base1.splice(data_start, 1);
			    }
			    data_start++;
			}

			return {
				vtSHMarginTrade:vtSHMarginTrade,
				vtSZMarginTrade:vtSZMarginTrade
			};
}
function setDesc(vtSHMarginTrade,vtSZMarginTrade) {
    let opDate = vtSHMarginTrade[vtSHMarginTrade.length-1].sOpDate;
	let	monthStr = opDate.substr(5,2);
    let dayStr = opDate.substr(8);
    let totalAmount1 = vtSHMarginTrade[vtSHMarginTrade.length-1].fMarginBalance + vtSZMarginTrade[vtSZMarginTrade.length-1].fMarginBalance;
    let totalAmount2 = vtSHMarginTrade[vtSHMarginTrade.length-2].fMarginBalance + vtSZMarginTrade[vtSZMarginTrade.length-2].fMarginBalance;
    let totalBuyAmount1 = vtSHMarginTrade[vtSHMarginTrade.length-1].fBuyValue + vtSZMarginTrade[vtSZMarginTrade.length-1].fBuyValue;
    let totalBuyAmount2 = vtSHMarginTrade[vtSHMarginTrade.length-2].fBuyValue + vtSZMarginTrade[vtSZMarginTrade.length-2].fBuyValue;
    return {
        vtSZMarginTrade: monthStr+'月'+dayStr+'日，融资买入'+getUnit(totalBuyAmount1)+'，较上日'+getCompareStr(totalBuyAmount1,totalBuyAmount2),
        vtSHMarginTrade: monthStr+'月'+dayStr+'日，两市融资融券余额'+getUnit(totalAmount1)+'，较上日'+getCompareStr(totalAmount1,totalAmount2),
    }
}
function getUnit(data) {
    var unit;
    if(data<10000){
        unit = data;
    }else if(data < 100000000){
        unit = (data/1000).toFixed(1)+'万';
    }else{
        unit =(data/100000000).toFixed(1)+'亿';
    }
    return unit;
}
function getCompareStr(data1,data2) {
    var percent,compare;
    if(data1>=data2){
        percent = ((data1-data2) / data1 * 100).toFixed(2)+'%';
        compare = '上涨';
    }else{
        percent = ((data2-data1) / data2 * 100).toFixed(2)+'%';
        compare = '下降';
    }
    return compare + percent;
}
// 融资融券余额 || 融资买入额 
function getMarginTrade(options) {
    return new Promise(async (resolve) => {
        let data = await ajaxHuShenMarginTrade()
        data = JSON.parse(data.data.content)
        let vtSHMarginTrade = data.vtSHMarginTrade
        let vtSZMarginTrade = data.vtSZMarginTrade
        let completeDataObj = completeData(vtSHMarginTrade, vtSZMarginTrade)
        vtSHMarginTrade=completeDataObj.vtSHMarginTrade;
		vtSZMarginTrade=completeDataObj.vtSZMarginTrade;
        let hzBuyChartData = []; // 融资买入额
        let hzMarginBalanceData = []; // 融资融券余额
        for(let i=0;i<vtSHMarginTrade.length;i++){
            hzBuyChartData.push({
                name:vtSHMarginTrade[i].sOpDate,
                value:vtSHMarginTrade[i].fBuyValue+vtSZMarginTrade[i].fBuyValue
            });

            hzMarginBalanceData.push({
                name:vtSHMarginTrade[i].sOpDate,
                value:vtSHMarginTrade[i].fMarginBalance+vtSZMarginTrade[i].fMarginBalance
            });
        }
        let desc = setDesc(vtSHMarginTrade,vtSZMarginTrade)
        resolve({
            desc,
            vtSZMarginTrade: hzBuyChartData,
            vtSHMarginTrade: hzMarginBalanceData,
        })
    })
}
// 个股top10
function getIndustryPlateDtMargin() {
    return new Promise(async (resolve) => {
        let result = [['名称代码', '涨跌幅', '融资买入', '融资买入比']]
        let data = await ajaxIndustryPlateDtMargin()
        data = JSON.parse(data.data.content).vtIndustryBuyValue
        if (Array.isArray(data) && data.length > 0) {
            data = data.slice(0, 10)
            let simpleData = await ajaxSimpleQuote(data.map(item => item.sDtSecCode))
            simpleData = JSON.parse(simpleData.data.content).vSecSimpleQuote
            simpleData.forEach((item, index) => {
                const arise = (100 * (item.fNow - item.fClose)) / item.fClose;
                result.push([
                    `<div>${item.sSecName}</div><div>${item.sDtSecCode.substr(4,6)}</div>`,
                    arise.toFixed(2),
                    data[index].sBuyValue,
                    data[index].sBuyPercent
                ])
            })
        }
        resolve(result)
    })
}
// 板块列表
async function ajaxIndustryPlateBuyValue() {
    return await axios.post(
        "https://comm.wedengta.com/?s=marginTrade&f=getIndustryPlateBuyValue&req=IndustryPlateBuyValueReq&rsp=IndustryPlateBuyValueRsp",
        JSON.stringify({})
      );
}
// 板块个股
async function ajaxPlateDtMargin(options) {
    return await axios.post(
        "https://sec.wedengta.com/getMarginTrade?action=getIndustryPlateDtMargin",
        JSON.stringify({
            sIndustryPlate: options.plate,
            iStartPos: 0,
            iWantNum: 10
        })
      );
}
// 个股列表详情
async function ajaxSecInfo(value) {
    return await axios.get(`https://sec.wedengta.com/getSecInfo?action=squote&seccode=${value}`)
}
// 个股风云榜
async function ajaxIndustryPlateDtMargin() {
    return await axios.post(
        "https://comm.wedengta.com/?s=marginTrade&f=getIndustryPlateDtMargin&req=IndustryPlateDtMarginReq&rsp=IndustryPlateDtMarginRsp",
        JSON.stringify({})
      );
}
// 个股行情简版
async function ajaxSimpleQuote(value) {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
        JSON.stringify({
        vDtSecCode: value
        })
    );
}
// 融资融券余额 融资买入额
async function ajaxHuShenMarginTrade() {
    return await axios.post(
        "https://comm.wedengta.com/?s=marginTrade&f=getHuShenMarginTrade&req=MarginTradeMktReq&rsp=MarginTradeMktRsp",
        JSON.stringify({})
    );
}