import axios from "axios";
import moment from 'moment'
export function getIntelligentDiagnosisData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return options.type
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let finalData = {}
        if (options.type === '主力资金') {
            let data = await ajaxCapitalDDZ(options)
            data = JSON.parse(data.data.content)
            finalData = handleMainCapDetail(data)
        }
        if (options.type === '人气指数') {
            let data = await ajaxPopularityDetail(options)
            data = JSON.parse(data.data.content)
            finalData = handlePopularityDetail(data)
        }
        if (options.type === '机构调研') {
            let data = await ajaxInsResDetail(options)
            data = JSON.parse(data.data.content)
            finalData = handleInsResDetail(data)
        }
        if (['市场表现', '板块潜力'].indexOf(options.type) !== -1) {
            let data= await ajaxMarketTrend(options)
            data = JSON.parse(data.data.content)
            data = completeDapanData(data)
            data = handleSectionData(data)
            data = completeDapanData(data)
            if (options.type === '市场表现') {
                finalData = handleAjaxDapan(data)
            } else {
                finalData = handlePlatePotential(data)
            }
        }
        let value = await ajaxScoreDetail(options)
        value = JSON.parse(value.data.content)
        value = handleScoreDatail(value, options.type)
        resolve({
            ...finalData,
            ...value
        })
    })
}
// 获取评分
function handleScoreDatail(options, type){
    var data = options.vtConsultScore[0];
    let res = {}
    if (type === '人气指数') {
        res = {
            stMktHotScore:'',
            stMktHotScoreDesc:''
        }
        if(data.stMktHotScoreDesc.iScore==0){
            res.stMktHotScore = '市场热度评分：暂无评分';
            res.stMktHotScoreDesc = '';
         }else{
            res.stMktHotScore = `市场热度评分：${data.stMktHotScoreDesc.iScore}分`;
            res.stMktHotScoreDesc = data.stMktHotScoreDesc.sScoreDesc;
         }
    }
    if (type === '主力资金') {
        res = {
            stMainScore: '',
            stMainScoreDesc: '',
        }
        if(data.stMainScoreDesc.iScore==0){
            res.stMainScore = '主力强度评分：暂无评分';
            res.stMainScoreDesc = '';
         }else{
            res.stMainScore = `主力强度评分：${data.stMainScoreDesc.iScore}分`;
            res.stMainScoreDesc = data.stMainScoreDesc.sScoreDesc;
         }
    }
    if (['市场表现', '板块潜力'].indexOf(type) !== -1) {
        res = {
            stTrendScore: '',
            stTrendScoreDesc: '',
        }
        if(data.stTrendScoreDesc.iScore==0){
            res.stTrendScore = '走势分析评分：暂无评分';
            res.stTrendScoreDesc = '';
         }else{
            res.stTrendScore = `走势分析评分：${data.stTrendScoreDesc.iScore}分`;
            res.stTrendScoreDesc = data.stTrendScoreDesc.sScoreDesc;
         }
    }
    return res;
}
function completeDapanData(data) {
    function getFullDate(timestamp) {
        var year = new Date(timestamp).getFullYear() + '';
        var month = new Date(timestamp).getMonth() + '';
        var date = new Date(timestamp).getDate() + '';
        return year + month + date;
    }
    //实际数据中出现了大盘和行业某个日期无数据但个股此日期有数据的情况，所以首先把多余的个股数据删除
    for (var i = 0; i < data.stStockUpdowns.vtUpdowns.length; ++i) {
        var foundFlag = false;
        for (var j = 0; j < data.stIndexUpdowns.vtUpdowns.length; j++) {
            if (getFullDate(data.stIndexUpdowns.vtUpdowns[j].iTradeDate * 1000) == getFullDate(data.stStockUpdowns.vtUpdowns[i].iTradeDate * 1000)) {
                foundFlag = true;
                break;
            }
        }
        if (!foundFlag) {
            data.stStockUpdowns.vtUpdowns.splice(i, 1);
        }
    }


    //获取大盘的日期数组，它是基准
    var dateLine = [];
    for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; ++i) {
        dateLine.push(data.stIndexUpdowns.vtUpdowns[i].iTradeDate);
    }

    //获得开始对比的起点order
    var start_index = 0;
    for (var k = 0; k < dateLine.length; ++k) {
        if (moment(data.stStockUpdowns.vtUpdowns[0].iTradeDate * 1000).format('YYYY-MM-DD') == moment(dateLine[k] * 1000).format('YYYY-MM-DD')) {
            start_index = k;
            break;
        }
    }

    //如果大盘某条日期对应的个股日期对不上，就补齐个股此日期的数据为上一日期的数据
    var data_start = 0;
    var stock_data_start = 0;

    for (var j = start_index; j < dateLine.length; ++j) {
        if (data.stStockUpdowns.vtUpdowns[data_start] == undefined || moment(dateLine[j] * 1000).format('YYYY-MM-DD') != moment(data.stStockUpdowns.vtUpdowns[data_start].iTradeDate * 1000).format('YYYY-MM-DD')) {
            var temp = { "iTradeDate": dateLine[j], "sUpDowns": data.stStockUpdowns.vtUpdowns[data_start - 1].sUpDowns };
            data.stStockUpdowns.vtUpdowns.splice(data_start, 0, temp);
        }
        data_start++;
    }

    //补齐个股数据
    for(var j=0;j<start_index;j++){
          var temp = { "iTradeDate": dateLine[j], "sUpDowns": data.stStockUpdowns.vtUpdowns[0].sUpDowns };
          data.stStockUpdowns.vtUpdowns.splice(stock_data_start, 0, temp);
          stock_data_start++;
    }

    //补齐行业数据
    if (data.stPlateUpdowns.vtUpdowns != "") {
        var start_index = 0;
        for (var k = 0; k < dateLine.length; ++k) {
            if (moment(data.stPlateUpdowns.vtUpdowns[0].iTradeDate * 1000).format('YYYY-MM-DD') == moment(dateLine[k] * 1000).format('YYYY-MM-DD')) {
                start_index = k;
                break;
            }
        }
        var data_start = 0;
        for (var j = start_index; j < dateLine.length; ++j) {
            if (data.stPlateUpdowns.vtUpdowns[data_start] == undefined || moment(dateLine[j] * 1000).format('YYYY-MM-DD') != moment(data.stPlateUpdowns.vtUpdowns[data_start].iTradeDate * 1000).format('YYYY-MM-DD')) {
                var temp = { "iTradeDate": dateLine[j], "sUpDowns": data.stPlateUpdowns.vtUpdowns[data_start - 1].sUpDowns };
                data.stPlateUpdowns.vtUpdowns.splice(data_start, 0, temp);
            }
            data_start++;
        }
    }
    if (data.vPlateUpdowns != undefined) {
        var vPlateLength = data.vPlateUpdowns.length;
        var vPlateUpdowns = data.vPlateUpdowns;
        data.vPlateUpdowns = [];                                           //在概念中存在空数据时的兼容
        for(var i = 0; i < vPlateLength; i++){
            if(vPlateUpdowns[i].vtUpdowns.length!=0){
                data.vPlateUpdowns.push(vPlateUpdowns[i]);
            }
        }

        for (var i = 0; i < data.vPlateUpdowns.length; i++) {
            if (data.vPlateUpdowns[i].vtUpdowns != "") {
                var start_index = 0;
                for (var k = 0; k < dateLine.length; k++) {
                    if (moment(data.vPlateUpdowns[i].vtUpdowns[0].iTradeDate * 1000).format('YYYY-MM-DD') == moment(dateLine[k] * 1000).format('YYYY-MM-DD')) {
                        start_index = k;
                        break;
                    }
                }
                var data_start = 0;
                for (var j = start_index; j < dateLine.length; ++j) {
                    if (data.vPlateUpdowns[i].vtUpdowns[data_start] == undefined || moment(dateLine[j] * 1000).format('YYYY-MM-DD') != moment(data.vPlateUpdowns[i].vtUpdowns[data_start].iTradeDate * 1000).format('YYYY-MM-DD')) {
                        var temp = {
                            "iTradeDate": dateLine[j],
                            "sUpDowns": data.vPlateUpdowns[i].vtUpdowns[data_start - 1].sUpDowns
                        };
                        data.vPlateUpdowns[i].vtUpdowns.splice(data_start, 0, temp);
                    }
                    data_start++;
                }
            }
        }
    }
    return data;
}
function handleSectionData(option) {
    var data = {
        sDesc:'',
        stIndexUpdowns:{vtUpdowns: [],sName:option.stIndexUpdowns.sName},
        stPlateUpdowns:{vtUpdowns: [],sName:option.stPlateUpdowns.sName},
        stStockUpdowns:{vtUpdowns: [],sName:option.stStockUpdowns.sName},
        vPlateUpdowns:new Array(option.vPlateUpdowns.length),
    };
    var length = option.stIndexUpdowns.vtUpdowns.length;
    var endStamp = option.stIndexUpdowns.vtUpdowns[length-1].iTradeDate;//得到最新的时间戳
    var count = 0;
    var startStamp = endStamp-60*60*24*31;
    for(var i=0;i<option.stIndexUpdowns.vtUpdowns.length;i++){
        if(option.stIndexUpdowns.vtUpdowns[i].iTradeDate>=startStamp){
            data.stIndexUpdowns.vtUpdowns.push(option.stIndexUpdowns.vtUpdowns[i]);
        }
    }

    data.stIndexUpdowns.basePercent = data.stIndexUpdowns.vtUpdowns[0].sUpDowns;

    for(var i=0;i<data.stIndexUpdowns.vtUpdowns.length;i++){
        data.stIndexUpdowns.vtUpdowns[i].sUpDowns = (1+parseFloat(data.stIndexUpdowns.vtUpdowns[i].sUpDowns,10))/(1+parseFloat(data.stIndexUpdowns.basePercent,10))-1;
    }


    for(var i=0;i<option.stPlateUpdowns.vtUpdowns.length;i++){
        if(option.stPlateUpdowns.vtUpdowns[i].iTradeDate>=startStamp){
            data.stPlateUpdowns.vtUpdowns.push(option.stPlateUpdowns.vtUpdowns[i]);
        }
    }

    if(data.stPlateUpdowns.vtUpdowns.length!=0){
        data.stPlateUpdowns.basePercent = data.stPlateUpdowns.vtUpdowns[0].sUpDowns;
        for(var i=0;i<data.stPlateUpdowns.vtUpdowns.length;i++){
            data.stPlateUpdowns.vtUpdowns[i].sUpDowns = (1+parseFloat(data.stPlateUpdowns.vtUpdowns[i].sUpDowns,10))/(1+parseFloat(data.stPlateUpdowns.basePercent,10))-1;
        }
    }


    for(var i=0;i<option.stStockUpdowns.vtUpdowns.length;i++){
        if(option.stStockUpdowns.vtUpdowns[i].iTradeDate>=startStamp){
            option.stStockUpdowns.vtUpdowns[i].date= moment(option.stStockUpdowns.vtUpdowns[i].iTradeDate*1000).format('YYYY-MM-DD')
            data.stStockUpdowns.vtUpdowns.push(option.stStockUpdowns.vtUpdowns[i]);
        }
    }

    data.stStockUpdowns.basePercent = data.stStockUpdowns.vtUpdowns[0].sUpDowns;
    for(var i=0;i<data.stStockUpdowns.vtUpdowns.length;i++){
        data.stStockUpdowns.vtUpdowns[i].sUpDowns = (1+parseFloat(data.stStockUpdowns.vtUpdowns[i].sUpDowns,10))/(1+parseFloat(data.stStockUpdowns.basePercent,10))-1;
    }
    for(var i=0;i<option.vPlateUpdowns.length;i++){
        data.vPlateUpdowns[i] = {vtUpdowns:[],sName:option.vPlateUpdowns[i].sName};
        for(var j=0;j<option.vPlateUpdowns[i].vtUpdowns.length;j++){
            if(option.vPlateUpdowns[i].vtUpdowns[j].iTradeDate>=startStamp){
                data.vPlateUpdowns[i].vtUpdowns.push(option.vPlateUpdowns[i].vtUpdowns[j]);
            }
        }
        data.vPlateUpdowns[i].basePercent = data.vPlateUpdowns[i].vtUpdowns[0].sUpDowns;
    }

    for(var i=0;i<option.vPlateUpdowns.length;i++){
        for(var j=0;j<data.vPlateUpdowns[i].vtUpdowns.length;j++){
            data.vPlateUpdowns[i].vtUpdowns[j].sUpDowns = (1+parseFloat(data.vPlateUpdowns[i].vtUpdowns[j].sUpDowns,10))/(1+parseFloat(data.vPlateUpdowns[i].basePercent,10))-1;
        }
    }
    return data;
}
function setMarketDesc(compareWithIndex,compareWithPlate){
    var desc1,desc2;
    if(compareWithIndex){
        desc1='优于';
    }else{
        desc1='弱于';
    }
    if(compareWithPlate){
        desc2='优于';
    }else{
        desc2='弱于';
    }

    return {
        desc1:desc1,
        desc2:desc2
    };
}
function handleAjaxDapan(data) {
    let legendArr = [
        data.stStockUpdowns.sName, // 个股
        data.stPlateUpdowns.sName, // 板块
        data.stIndexUpdowns.sName, // 大盘
    ]
    data.vPlateUpdowns.forEach((item) => {
        legendArr.push(item.sName)
    })
    //设置市场表现的描述文字
    var stockTotal = 0,
        plateTotal = 0,
        indexTotal = 0;

    for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; i++) {
        indexTotal += data.stIndexUpdowns.vtUpdowns[i].sUpDowns / 1;
        if (data.stPlateUpdowns.vtUpdowns[i] != undefined) {
            plateTotal += data.stPlateUpdowns.vtUpdowns[i].sUpDowns / 1;
        }
        stockTotal += data.stStockUpdowns.vtUpdowns[i].sUpDowns / 1;
    }
    var compare1 = stockTotal > indexTotal ? true : false;
    var compare2 = stockTotal > plateTotal ? true : false;
    var descObj = setMarketDesc(compare1, compare2);

    //处理数据格式为图表可用
    var indexUpDowns=[],
        stPlateUpdowns=[],
        stStockUpdowns=[];

    for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; i++) {
        indexUpDowns.push({
            name: moment(data.stIndexUpdowns.vtUpdowns[i].iTradeDate * 1000).format('YYYY-MM-DD'),
            value: data.stIndexUpdowns.vtUpdowns[i].sUpDowns*100
        });
    }
    for (var j = 0; j < data.stPlateUpdowns.vtUpdowns.length; j++) {
        stPlateUpdowns.push({
            name: moment(data.stPlateUpdowns.vtUpdowns[j].iTradeDate * 1000).format('YYYY-MM-DD'),
            value: data.stPlateUpdowns.vtUpdowns[j].sUpDowns*100
        });
    }
    for (var j = 0; j < data.stStockUpdowns.vtUpdowns.length; j++) {
        stStockUpdowns.push({
            name: moment(data.stStockUpdowns.vtUpdowns[j].iTradeDate * 1000).format('YYYY-MM-DD'),
            value: data.stStockUpdowns.vtUpdowns[j].sUpDowns*100
        });
    }

    for (var i = 0; i < data.vPlateUpdowns.length; i++) {
        for (var j = 0; j < data.vPlateUpdowns[i].vtUpdowns.length; j++) {
            data.vPlateUpdowns[i].vtUpdowns[j].name=moment(data.vPlateUpdowns[i].vtUpdowns[j].iTradeDate * 1000).format('YYYY-MM-DD');
            data.vPlateUpdowns[i].vtUpdowns[j].value= data.vPlateUpdowns[i].vtUpdowns[j].sUpDowns*100;
        }
    }

    let chartData=[];
    chartData.push(stStockUpdowns); // 个股
    chartData.push(stPlateUpdowns); // 板块
    chartData.push(indexUpDowns); // 大盘
    for (let i = 0; i < data.vPlateUpdowns.length; i++) {
        chartData.push(data.vPlateUpdowns[i].vtUpdowns);
    }
    return {
        legendArr,
        chartData,
        desc: '近一月个股走势'+descObj.desc1+'大盘走势，'+descObj.desc2+'行业走势'
    }
}
function handlePlatePotential(data) {
    var indexUpDowns = [];
    var stPlateUpdowns = [];
    var chartData = [];
    var legendArr = [
        '上证指数',
        data.stPlateUpdowns.sName
    ]
    for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; i++) {
        indexUpDowns.push({
            name: moment(data.stIndexUpdowns.vtUpdowns[i].iTradeDate * 1000).format('YYYY-MM-DD'),
            value: data.stIndexUpdowns.vtUpdowns[i].sUpDowns*100
        })
    };

    for (var j = 0; j < data.stPlateUpdowns.vtUpdowns.length; j++) {
        stPlateUpdowns.push({
            name: moment(data.stPlateUpdowns.vtUpdowns[j].iTradeDate * 1000).format('YYYY-MM-DD'),
            value: data.stPlateUpdowns.vtUpdowns[j].sUpDowns*100
        })
    }

    //设置市场表现的描述文字
    var plateTotal = 0,
          indexTotal = 0;
    for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; i++) {
        indexTotal += data.stIndexUpdowns.vtUpdowns[i].sUpDowns / 1;
        if (data.stPlateUpdowns.vtUpdowns[i] != undefined) {
            plateTotal += data.stPlateUpdowns.vtUpdowns[i].sUpDowns / 1;
        }
    };
    var compare1 = plateTotal > indexTotal ? true : false;
    var compare2 = false;
    var descObj = setMarketDesc(compare1, compare2);
    chartData.push(indexUpDowns);
    chartData.push(stPlateUpdowns);
    return {
        legendArr,
        chartData,
        desc: '近一月板块走势'+descObj.desc1+'大盘走势'
    }
}
// 主力资金数据处理
function handleMainCapDetail(options) {
    let capitalData = options.vCapitalDDZDesc;
    var chartData = [];
    for (var i = 0; i <capitalData.length; i++) {
        var fZljlr = capitalData[i].fBigInAmt + capitalData[i].fSuperInAmt - capitalData[i].fBigOutAmt - capitalData[i].fSuperOutAmt;
        chartData.push({
            label: moment(capitalData[i].lTime*1000).format('YYYY-MM-DD'),
            value1: (fZljlr/10000).toFixed(2)
        });
    }
    return {
        chartData
    }
}
// 人气指数 数据处理
function handlePopularityDetail(options) {
    if (options.vtPopularity.length === 0) {
        return;
    }
    var vtPopuIndex = options.vtPopularity[0].vtPopuIndex;
    var tempData = [];
    for (var i = 0; i < vtPopuIndex.length; i++) {
        tempData.push({
            name: vtPopuIndex[i].sDate,
            value: Number(vtPopuIndex[i].iIndexVal)
        });
    }

    return {
        chartData: tempData,
        popularityDesc: options.vtPopularity[0].sPopularityDesc
    }
}
// 机构调研 数据处理
function handleInsResDetail(options) {
    let vtInsRes = options.vtInsRes;
    if (vtInsRes.length === 0) {
        return;
    }
    let legend = ['机构调研', '行业平均值']

    let chartData = [{
        label: '2032',
        value1: vtInsRes[0].fVal,
        value2: vtInsRes[0].fIndInsResAvg
    }]

    return {
        insResDescription: `最近三个月，共有${vtInsRes[0].fVal}家机构对该股进行调研，行业排名${vtInsRes[0].iIndustryRank}/${vtInsRes[0].iIndDtNum}`,
        chartData,
        legend
    }
}
// 主力资金
async function ajaxCapitalDDZ(options) {
    return await axios.get("https://sec.wedengta.com/getSecInfo?action=getCapitalDDZ", {
        params: {
            seccode: options.seccode,
            num: 5
        },
      });
  }

  //获取评分
async function ajaxScoreDetail(options) {
    return await axios.post("https://sec.wedengta.com/GetConsultStock?action=getScoreDetail",
    JSON.stringify({
        vtDtSecCode: [options.seccode],
    }));
}
// 人气指数
async function ajaxPopularityDetail(options) {
    return await axios.post("https://sec.wedengta.com/GetConsultStock?action=getPopularityDetail",
    JSON.stringify({
        vtDtSecCode: [options.seccode],
    }));
}
async function ajaxInsResDetail(options) {
    return await axios.post("https://sec.wedengta.com/GetConsultStock?action=getInsResDetail",
    JSON.stringify({
        vtDtSecCode: [options.seccode],
    }));
}
async function ajaxMarketTrend(options) {
    return await axios.get("https://sec.wedengta.com/getSecPortrait?action=MarketTrend", {
        params: {
            seccode: options.seccode,
        },
    });
}
