
 // 场景
const sceneArr =  [
    {
        label: '分时图',
        value: '分时'
    },
    {
        label: '五日图',
        value: '五日',
    },
    {
        label: '月K',
        value: '月K',
    },
    {
        label: '周K',
        value: '周K',
    },
    {
        label: '日K',
        value: '日K',
    },
    {
        label: '1分',
        value: '1分',
    },
    {
        label: '5分',
        value: '5分',
    },
    {
        label: '15分',
        value: '15分',
    },
    {
        label: '30分',
        value: '30分',
    },
    {
        label: '60分',
        value: '60分',
    },
    
]
 // 竞价
const biddingArr = [
    {
        label: '集合竞价',
        value: 'auctionSummary',
    },
    {
        label: '涨停试盘详情',
        value: 'auctionUpLimit',
    },
    {
        label: '跌停试盘详情',
        value: 'auctionDownLimit',
    },
    {
        label: '竞价看多详情',
        value: 'auctionUpTrend',
    },
    {
        label: '竞价看空详情',
        value: 'auctionDownTrend',
    }
];
// 板块
const plateArr = [
    {
        label: '涨幅top10',
        value: '涨幅top10',
    },
    {
        label: '跌幅top10',
        value: '跌幅top10',
    },
    {
        label: '板块潜力',
        value: '板块潜力',
    }
];
 // 北向资金
const northArr = [
    {
        label: '资金流向',
        value: 'flow',
    },
    {
        label: '持股占比',
        value: 'shareRate',
    },
    {
        label: '行业板块排行',
        value: 'fundFlowIndustry',
    },
    {
        label: '十大成交股',
        value: 'fundFlowTop10Stock',
    },
    {
        label: '历史数据（近五日）',
        value: 'historyDaily',
    },
    {
        label: '增仓榜',
        value: 'stockBuildUp',
    },
    {
        label: '持仓榜',
        value: 'stockHoldList',
    },
    {
        label: '资金涨跌排行',
        value: 'longHuReal',
    },
    {
        label: '资金概况',
        value: 'longHuSummary',
    },
    {
        label: '收益排行榜',
        value: 'holderIncomeRank',
    },
    {
        label: '持股占比榜',
        value: 'holdRatio',
    },
    {
        label: '占比异动榜',
        value: 'holdVary',
    },
    {
        label: '主力资金涨跌幅',
        value: 'capitalMainFlow',
    },
    {
        label: '主力资金净流入',
        value: 'capitalDetail',
    },
];
// 南向资金
const southArr = [
    {
        label: '流向',
        value: 'flow',
    },
    {
        label: '十大成交股',
        value: 'fundFlowTop10Stock',
    },
    {
        label: '历史数据（近五日）',
        value: 'historyDaily',
    },
    {
        label: '增仓榜',
        value: 'stockBuildUp',
    },
    {
        label: '持仓榜',
        value: 'stockHoldList',
    },
];
// 个股财报
const individualReportArr = [
    {
        label: '每股指标',
        value: '每股指标',
    },
    {
        label: '盈利能力',
        value: '盈利能力',
    },
    {
        label: '资本结构',
        value: '资本结构'
    },
    {
        label: '偿债能力',
        value: '偿债能力'
    },
    {
        label: '营运能力',
        value: '营运能力'
    },
    {
        label: '成长能力',
        value: '成长能力'
    },
    {
        label: '营业情况',
        value: '营业情况'
    },
    {
        label: '其他经营',
        value: '其他经营收益情况'
    },
    {
        label: '利润情况',
        value: '利润情况'
    },
    {
        label: '净利润详情',
        value: '净利润详情'
    },
    {
        label: '收益详情',
        value: '收益详情'
    },
    {
        label: '资产概况',
        value: '资产概况'
    },
    {
        label: '流动资产',
        value: '流动资产'
    },
    {
        label: '非流动资产',
        value: '非流动资产'
    },
    {
        label: '负债概况',
        value: '负债概况'
    },
    {
        label: '流动负债',
        value: '流动负债'
    },
    {
        label: '非流动负债',
        value: '非流动负债'
    },
    {
        label: '股东收益详情',
        value: '股东收益详情'
    },
    {
        label: '负债和股东权益概况',
        value: '负债和股东权益概况'
    },
    {
        label: '经营活动中产生的现金概况',
        value: '经营活动中产生的现金概况'
    },
    {
        label: '经营活动现金流入详情',
        value: '经营活动现金流入详情'
    },
    {
        label: '经营活动现金流出详情',
        value: '经营活动现金流出详情'
    },
    {
        label: '投资活动中产生的现金概况',
        value: '投资活动中产生的现金概况'
    },
    {
        label: '投资活动现金流入详情',
        value: '投资活动现金流入详情'
    },
    {
        label: '投资活动现金流出详情',
        value: '投资活动现金流出详情'
    },
    {
        label: '筹资活动中产生的现金概况',
        value: '筹资活动中产生的现金概况'
    },
    {
        label: '筹资活动现金流入详情',
        value: '筹资活动现金流入详情'
    },
    {
        label: '筹资活动现金流出详情',
        value: '筹资活动现金流出详情'
    },
    {
        label: '其他现金流变化',
        value: '其他现金流变化'
    },
]
// 财报比对
const reportCompareArr = [
    {
        label: '每股指标',
        value: '每股指标',
    },
    {
        label: '盈利能力',
        value: '盈利能力',
    },
    // {
    //     label: '资本结构',
    //     value: '资本结构'
    // },
    // {
    //     label: '偿债能力',
    //     value: '偿债能力'
    // },
    // {
    //     label: '营运能力',
    //     value: '营运能力'
    // },
    {
        label: '成长能力',
        value: '成长能力'
    },
    {
        label: '营业情况',
        value: '营业情况'
    },
    {
        label: '其他经营',
        value: '其他经营收益情况'
    },
    {
        label: '利润情况',
        value: '利润情况'
    },
    {
        label: '净利润详情',
        value: '净利润详情'
    },
    {
        label: '收益详情',
        value: '收益详情'
    },
    {
        label: '资产概况',
        value: '资产概况'
    },
    {
        label: '流动资产',
        value: '流动资产'
    },
    {
        label: '非流动资产',
        value: '非流动资产'
    },
    {
        label: '负债概况',
        value: '负债概况'
    },
    {
        label: '流动负债',
        value: '流动负债'
    },
    {
        label: '非流动负债',
        value: '非流动负债'
    },
    {
        label: '股东收益详情',
        value: '股东收益详情'
    },
    {
        label: '负债和股东权益概况',
        value: '负债和股东权益概况'
    },
    {
        label: '经营活动中产生的现金概况',
        value: '经营活动中产生的现金概况'
    },
    {
        label: '经营活动现金流入详情',
        value: '经营活动现金流入详情'
    },
    {
        label: '经营活动现金流出详情',
        value: '经营活动现金流出详情'
    },
    {
        label: '投资活动中产生的现金概况',
        value: '投资活动中产生的现金概况'
    },
    {
        label: '投资活动现金流入详情',
        value: '投资活动现金流入详情'
    },
    {
        label: '投资活动现金流出详情',
        value: '投资活动现金流出详情'
    },
    {
        label: '筹资活动中产生的现金概况',
        value: '筹资活动中产生的现金概况'
    },
    {
        label: '筹资活动现金流入详情',
        value: '筹资活动现金流入详情'
    },
    {
        label: '筹资活动现金流出详情',
        value: '筹资活动现金流出详情'
    },
    {
        label: '其他现金流变化',
        value: '其他现金流变化'
    },
]
// 融资融券
const marginTradeArr = [
    {
        label: '融资融券余额',
        value: 'vtSHMarginTrade',
    },
    {
        label: '融资买入额',
        value: 'vtSZMarginTrade',
    },
    {
        label: '融资买入个股top10',
        value: 'IndustryPlateDtMargin',
    },
    {
        label: '融资买入行业板块',
        value: 'vtIndustryBuyValuePlate',
    },
    {
        label: '融资买入概念板块',
        value: 'vtPlateBuyValuePlate',
    }
]
// 龙虎榜
const longHuArr = [
    {
        label: '龙虎榜总榜',
        value: 'vSecList',
    },
    {
        label: '龙虎榜总榜（机构）',
        value: 'vJgqcList',
    },
    {
        label: '龙虎榜总榜（游资）',
        value: 'vYzbyList',
    },
    {
        label: '龙虎榜-游资助手',
        value: 'vYzxsList',
    },
    {
        label: '龙虎榜-超高胜率',
        value: 'vCgslList',
    },
    {
        label: '龙虎榜-资金抢筹',
        value: 'vZjqcList',
    },
    {
        label: '活跃营业部',
        value: 'vSaleOrgList',
    },
]
// 估值交易
const evaluatingDistArr = [
    {
        label: '估值详情',
        value: '估值详情',
    },
    {
        label: 'PE-TTM走势图(倍)',
        value: 'PE-TTM走势图(倍)',
    },
    {
        label: 'PB走势图(倍)',
        value: 'PB走势图(倍)',
    },
    {
        label: '所在行业估值分布',
        value: '所在行业估值分布',
    },
    {
        label: '个股估值分布',
        value: '个股估值分布',
    },
    {
        label: '个股估值回测',
        value: '个股估值回测',
    },
    {
        label: '行业估值分布',
        value: '行业估值分布',
    },
]
// 新股中心
const ipoInfoArr = [
    {
        label: '新股申购',
        value: '新股申购'
    },
    {
        label: '等待上市',
        value: '等待上市'
    },
    {
        label: '上市表现',
        value: '上市表现'
    },
    // {
    //     label: '公司简介',
    //     value: '公司简介'
    // },
    // {
    //     label: '主营业务',
    //     value: '主营业务'
    // },
    // {
    //     label: '基本信息',
    //     value: '基本信息'
    // },
]
// 市场强度
const checkMasterArr = [
    {
        label: '当日大盘预警',
        value: '当日大盘预警',
    },
    {
        label: '当日市场资金',
        value: '当日市场资金',
    },
    {
        label: '当日市场趋势',
        value: '当日市场趋势',
    },
    {
        label: '市场强度',
        value: '市场强度',
    },
    {
        label: '当日涨停强度',
        value: '当日涨停强度',
    },
    {
        label: '当日跌停强度',
        value: '当日跌停强度',
    },
    {
        label: '涨跌停走势',
        value: '涨跌停走势',
    },
    {
        label: '涨停板',
        value: '涨停板',
    },
    {
        label: '连续涨停',
        value: '连续涨停',
    },
    {
        label: '涨停打开',
        value: '涨停打开',
    },
    {
        label: '跌停板',
        value: '跌停板',
    },
    {
        label: '连续跌停',
        value: '连续跌停',
    },
    {
        label: '跌停打开',
        value: '跌停打开',
    },
]
// 大宗交易
const bigTradeArr = [
    {
        label: '大宗交易',
        value: '大宗交易',
    },
    {
        label: '成交量',
        value: '成交量',
    },
    {
        label: '溢价率',
        value: '溢价率',
    },
]
// 财报对比（图）
const resultsContrastArr = [
    {
        label: '综合对比',
        value: '综合对比',
    },
    {
        label: '盈利能力对比',
        value: '盈利能力对比',
        key: 'profitArr',
    },
    {
        label: '偿债能力对比',
        value: '偿债能力对比',
        key: 'repayArr',
    },
    {
        label: '成长能力对比',
        value: '成长能力对比',
        key: 'growArr',
    },
    {
        label: '运营能力对比',
        value: '运营能力对比',
        key: 'operateArr',
    },
]
const plateAreaArr = [
    {
        label: '行业',
        value: '行业',
    },
    {
        label: '概念',
        value: '概念',
    },
    {
        label: '地域',
        value: '地域',
    },
]
const intelligentDiagnosisArr = [
    {
        label: '人气指数',
        value: '人气指数',
    },
    {
        label: '机构调研',
        value: '机构调研',
    },
    {
        label: '主力资金',
        value: '主力资金',
    },
    {
        label: '市场表现',
        value: '市场表现',
    },
    {
        label: '板块潜力',
        value: '板块潜力',
    },
]
const chipDistArr = [
    {
        label: "全市场盈利比例分布",
        value: "全市场盈利比例分布"
    },
    {
        label: "筹码形态分析",
        value: "筹码形态分析",
    },
    {
        label: "个股筹码分布",
        value: "个股筹码分布",
    },
    {
        label: "个股主力筹码分布",
        value: "个股主力筹码分布"
    }
]
const investmentMapArr = [
    {
        label: "十大投资股东",
        value: "十大投资股东",
    },
    {
        label: "投资关系",
        value: "投资关系",
    },
    {
        label: "高管图谱",
        value: "高管图谱",
    },
    {
        label: "任职情况",
        value: "任职情况",
    },
    {
        label: "上游产品",
        value: "上游产品",
    },
    {
        label: "下游产品",
        value: "下游产品",
    },
    {
        label: "主要产品",
        value: "主要产品",
    },
    {
        label: "上游行业",
        value: "上游行业",
    },
    {
        label: "下游行业",
        value: "下游行业",
    },
]
const companyIntroArr = [
    {
        label: "公司介绍",
        value: "公司介绍",
    },
    {
        label: "参股控股公司",
        value: "参股控股公司",
    },
    {
        label: "董事和高管",
        value: "董事和高管",
    },
    {
        label: "主营业务",
        value: "主营业务",
    },
    {
        label: "分红配送",
        value: "分红配送",
    },
    {
        label: "净利润行业对比",
        value: "净利润行业对比",
    },
    {
        label: "营业收入行业对比",
        value: "营业收入行业对比",
    },
    {
        label: "每股收益行业对比",
        value: "每股收益行业对比",
    },
    {
        label: "市盈率行业对比",
        value: "市盈率行业对比",
    },
    {
        label: "股本结构",
        value: "股本结构",
    },
    {
        label: "十大流通股东",
        value: "十大流通股东",
    },
    {
        label: "十大股东",
        value: "十大股东",
    },
    {
        label: "主力持仓",
        value: "主力持仓",
    },
    {
        label: "经营状况概述",
        value: "经营状况概述",
    },
    {
        label: "收入和利润情况",
        value: "收入和利润情况",
    },
    {
        label: "资产负债情况",
        value: "资产负债情况",
    },
    {
        label: "现金净流量",
        value: "现金净流量",
    },
    {
        label: "行业规模",
        value: "行业规模",
    },
    {
        label: "员工人数和营业收入",
        value: "员工人数和营业收入",
    },
    {
        label: "员工分析",
        value: "员工分析",
    },
    {
        label: "人均营业收入",
        value: "人均营业收入",
    },
    {
        label: "普通员工薪酬概况",
        value: "普通员工薪酬概况",
    },
    {
        label: "高管薪酬概况",
        value: "高管薪酬概况",
    },
    {
        label: "员工增长情况",
        value: "员工增长情况",
    },
    {
        label: "人均营业利润",
        value: "人均营业利润",
    },
    {
        label: "博士员工人数",
        value: "博士员工人数",
    },
    {
        label: "硕士员工人数",
        value: "硕士员工人数",
    },
    {
        label: "销售岗员工占比",
        value: "销售岗员工占比",
    },
    {
        label: "生产岗员工占比",
        value: "生产岗员工占比",
    },
    {
        label: "技术岗员工占比",
        value: "技术岗员工占比",
    },
    {
        label: "财务岗员工占比",
        value: "财务岗员工占比",
    },
    {
        label: "行政岗员工占比",
        value: "行政岗员工占比",
    },
    {
        label: "其他岗员工占比",
        value: "其他岗员工占比",
    },
    {
        label: "大专以下员工学历占比",
        value: "大专以下员工学历占比",
    },
    {
        label: "大专员工学历占比",
        value: "大专员工学历占比",
    },
    {
        label: "本科员工学历占比",
        value: "本科员工学历占比",
    },
    {
        label: "硕士员工学历占比",
        value: "硕士员工学历占比",
    },{
        label: "博士员工学历占比",
        value: "博士员工学历占比",
    },
    {
        label: "员工学历构成",
        value: "员工学历构成",
    },
    {
        label: "员工职业构成",
        value: "员工职业构成",
    },
    {
        label: "本科员工比例",
        value: "本科员工比例",
    },
]
const duPonArr = [
    {
        label: 'ROE相关历史数据',
        value: 'ROE相关历史数据',
    },
    {
        label: 'ROE详情',
        value: 'ROE详情',
    },
    {
        label: '排名',
        value: '排名',
    },
    {
        label: '走势详情',
        value: '走势详情',
    },
]
const stocksFundsArr = [
    {
        label: '资金流向',
        value: '资金流向',
    },
    {
        label: '主力增减仓',
        value: '主力增减仓',
    },
    {
        label: '买卖股数',
        value: '买卖股数',
    },
    {
        label: '累计持仓比例与收盘价',
        value: '累计持仓比例与收盘价',
    },
    {
        label: '沪深股通股东信息',
        value: '沪深股通股东信息',
    },
    {
        label: '净流入',
        value: '净流入',
    },
]
const sharePledgeArr = [
    {
        label: '质押比例统计',
        value: '质押比例统计',
    },
    {
        label: '质押比例排名',
        value: '质押比例排名',
    },
    {
        label: '行业数据',
        value: '行业数据',
    },
]
const shareStockPledgeArr = [
    {
        label: '股权质押',
        value: '股权质押',
    },
    {
        label: '重要股东质押',
        value: '重要股东质押',
    },
    {
        label: '重要股东风险预测',
        value: '重要股东风险预测',
    },
]
const bsSignalArr = [
    {
        label: '盘中多空趋势',
        value: '盘中多空趋势',
    },
    {
        label: '行业盘中多空趋势',
        value: '行业盘中多空趋势',
    },
    {
        label: '近期多空趋势',
        value: '近期多空趋势',
    },
    {
        label: '行业近期多空趋势',
        value: '行业近期多空趋势',
    },
]
const nuggetsArr = [
    {
        label: '定增计划',
        value: '定增计划', 
    },
    {
        label: '定增破发',
        value: '定增破发',
    },
    {
        label: '定增优选',
        value: '定增优选',
    },
]
const financeAnalysisArr = [
    {
        label: '盈利能力-ROE',
        value: '盈利能力-ROE', 
    },
    {
        label: '盈利能力-毛利润率',
        value: '盈利能力-毛利润率',
    },
    {
        label: '盈利能力-净利润率',
        value: '盈利能力-净利润率',
    },
    {
        label: '偿债能力-流动比率',
        value: '偿债能力-流动比率',
    },
    {
        label: '偿债能力-速动比率',
        value: '偿债能力-速动比率',
    },
    {
        label: '偿债能力-现金比率',
        value: '偿债能力-现金比率',
    },
    {
        label: '偿债能力-产权比率',
        value: '偿债能力-产权比率',
    },
    {
        label: '成长能力-营业收入增长率',
        value: '成长能力-营业收入增长率',
    },
    {
        label: '成长能力-营业利润增长率',
        value: '成长能力-营业利润增长率',
    },
    {
        label: '成长能力-现金流',
        value: '成长能力-现金流',
    },
    {
        label: '运营能力-存货周转率',
        value: '运营能力-存货周转率',
    },
    {
        label: '运营能力-总资产周转率',
        value: '运营能力-总资产周转率',
    },
    {
        label: '运营能力-应收账款周转率',
        value: '运营能力-应收账款周转率',
    },
    {
        label: '运营能力-营业周期',
        value: '运营能力-营业周期',
    },
]
const keChuangGraphArr = [
    {
        label: '已申报科创板的公司',
        value: '已申报科创板的公司',
    },
    {
        label: '拟申报科创板的公司',
        value: '拟申报科创板的公司',
    },
    {
        label: '三板达标的公司',
        value: '三板达标的公司',
    },
    {
        label: '个股',
        value: '个股',
    },
]
const stockMineSweepArr = [
    {
        label: '风险评估',
        value: '风险评估',
    },
    {
        label: '业绩风险',
        value: '业绩风险',
    },
    {
        label: '质押风险',
        value: '质押风险',
    },
    {
        label: '监管问询',
        value: '监管问询',
    },
    {
        label: '诉讼仲裁',
        value: '诉讼仲裁',
    },
    {
        label: '商誉风险',
        value: '商誉风险',
    },
    {
        label: '股东减持计划',
        value: '股东减持计划',
    },
    {
        label: '会计所评估',
        value: '会计所评估',
    },
    {
        label: '退市风险',
        value: '退市风险',
    },
]
const plateQuoteArr = [
    {
        label: '涨幅榜',
        value: '涨幅榜',
    },
    {
        label: '跌幅榜',
        value: '跌幅榜',
    },
    {
        label: '换手率榜',
        value: '换手率榜',
    },
    {
        label: '涨速榜',
        value: '涨速榜',
    },
    {
        label: '振幅榜',
        value: '振幅榜',
    },
    {
        label: '量比榜',
        value: '量比榜',
    },
    {
        label: '成交额榜',
        value: '成交额榜',
    },
    {
        label: '热门股票(按关注度)',
        value: '热门股票(按关注度)',
    },
    {
        label: '热门股票(按多点信号强度)',
        value: '热门股票(按多点信号强度)',
    },
    {
        label: '上涨潜力排行(按形态预估)',
        value: '上涨潜力排行(按形态预估)',
    },
    {
        label: '上涨潜力排行',
        value: '上涨潜力排行',
    }
]
const diagnosisValueArr = [
    {
        label: '营收',
        value: '营收',
    },
    {
        label: '净利润',
        value: '净利润',
    },
    {
        label: '净资产收益率',
        value: '净资产收益率',
    },
    {
        label: '每股收益',
        value: '每股收益',
    },
    {
        label: '总资产',
        value: '总资产',
    },
    {
        label: '资产负债率',
        value: '资产负债率',
    },
    {
        label: '经营活动现金净额',
        value: '经营活动现金净额',
    },
]
const financingTrackArr = [
    {
        label: '融资买入趋势分析',
        value: '融资买入趋势分析',
    },
    {
        label: '融资融券余额趋势',
        value: '融资融券余额趋势',
    },
    {
        label: '短期持股策略分析',
        value: '短期持股策略分析',
    },
    {
        label: '个股最佳持股策略',
        value: '个股最佳持股策略',
    },
    {
        label: '市场最佳持股策略',
        value: '市场最佳持股策略',
    },
]
const goodwillAnalysisArr = [
    {
        label: '商誉净资产比例统计',
        value: '商誉净资产比例统计',
    },
    {
        label: '商誉总资产比例统计',
        value: '商誉总资产比例统计',
    },
    {
        label: 'A股市场商誉走势',
        value: 'A股市场商誉走势',
    },
    {
        label: '商誉比例排名',
        value: '商誉比例排名',
    },
]
const goodwillWarnArr = [
    {
        label: '最新商誉信息',
        value: '最新商誉信息',
    },
    {
        label: '商誉净资产比例走势',
        value: '商誉净资产比例走势',
    },
    {
        label: '商誉总资产比例走势',
        value: '商誉总资产比例走势',
    },
]
const historyBackArr = [
    {
        label: '月份历史行情回看',
        value: '月份历史行情回看',
    },
    {
        label: '星期历史行情回看',
        value: '星期历史行情回看',
    },
    {
        label: '每年同期历史行情回看',
        value: '每年同期历史行情回看',
    },
    {
        label: '历史阶段必涨股票',
        value: '历史阶段必涨股票',
    },
    {
        label: '行业最佳表现个股',
        value: '行业最佳表现个股',
    },
]
const longHuStockArr = [
    {
        label: '基本信息',
        value: '基本信息',
    },
    {
        label: '买入前五营业部',
        value: '买入前五营业部',
    },
    {
        label: '卖出前五营业部',
        value: '卖出前五营业部',
    },
    {
        label: '历史上榜后表现',
        value: '历史上榜后表现',
    },
]
const similarKArr = [
    {
        label: '模拟走势',
        value: '模拟走势',
    },  
    {
        label: '走势最像的股票',
        value: '走势最像的股票',
    },  
    {
        label: '最相似的历史阶段',
        value: '最相似的历史阶段',
    },   
]
const intelliChooseStockArr = [
    {
        label: '缩量涨停板',
        value: '缩量涨停板',
    },  
    {
        label: '连续涨停策略',
        value: '连续涨停策略',
    },  
    {
        label: '灯塔金股',
        value: '灯塔金股',
    }, 
    {
        label: '公告涨停预测',
        value: '公告涨停预测',
    },  
    {
        label: '主题涨停预测',
        value: '主题涨停预测',
    },  
    {
        label: '券商高预期股',
        value: '券商高预期股',
    }, 
    {
        label: '龙虎榜',
        value: '龙虎榜',
    },  
    {
        label: '超跌反弹',
        value: '超跌反弹',
    },  
    {
        label: '热股推荐',
        value: '热股推荐',
    }, 
    {
        label: '东方金股',
        value: '东方金股',
    },  
    {
        label: '庄家牛股',
        value: '庄家牛股',
    },  
    {
        label: 'MACD金叉股',
        value: 'MACD金叉股',
    }, 
    {
        label: '黄金KDJ',
        value: '黄金KDJ',
    },  
    {
        label: '短期均线金叉',
        value: '短期均线金叉',
    },  
    {
        label: '游资携手',
        value: '游资携手',
    },   
]
const toolObj = {
    definitionList: [
        {
            label: '10%',
            value: 0.1
        },
        {
            label: '30%',
            value: 0.3
        },
        {
            label: '70%',
            value: 0.7
        },
        {
            label: '100%',
            value: 1
        }
    ],
    tooObj: {
        '行情': sceneArr,
        '板块': plateArr,
        '竞价': biddingArr,
        '北向资金': northArr,
        '南向资金': southArr,
        '个股财报': individualReportArr,
        '财报比对': reportCompareArr,
        '融资融券': marginTradeArr,
        '龙虎榜': longHuArr,
        '估值交易': evaluatingDistArr,
        '新股中心': ipoInfoArr,
        '市场强度': checkMasterArr,
        '大宗交易': bigTradeArr,
        '财报比对(图)': resultsContrastArr,
        '板块地域概念': plateAreaArr,
        '智能诊股': intelligentDiagnosisArr,
        '筹码分布': chipDistArr,
        '投资图谱': investmentMapArr,
        '个股百科': companyIntroArr,
        '杜邦分析': duPonArr,
        '个股资金': stocksFundsArr,
        '股权质押(大盘)': sharePledgeArr,
        '股权质押(个股)': shareStockPledgeArr,
        '多空信号': bsSignalArr,
        '定增掘金': nuggetsArr,
        '财务业绩': financeAnalysisArr,
        '科创掘金': keChuangGraphArr,
        '扫雷': stockMineSweepArr,
        '榜单': plateQuoteArr,
        '价值评估': diagnosisValueArr,
        '融资追击': financingTrackArr,
        '商誉分析(大盘)': goodwillAnalysisArr,
        '商誉分析(个股)': goodwillWarnArr,
        '历史回看': historyBackArr,
        '龙虎榜(个股)': longHuStockArr,
        '相似K线':similarKArr,
        '策略选股':intelliChooseStockArr,
    },
    sceneArr,
    biddingArr,
    plateArr,
    northArr,
    southArr,
    reportCompareArr,
    marginTradeArr,
    longHuArr,
    evaluatingDistArr,
    ipoInfoArr,
    checkMasterArr,
    bigTradeArr,
    resultsContrastArr,
    plateAreaArr,
    intelligentDiagnosisArr,
    chipDistArr,
    investmentMapArr,
    companyIntroArr,
    duPonArr,
    stocksFundsArr,
    sharePledgeArr,
    shareStockPledgeArr,
    bsSignalArr,
    nuggetsArr,
    financeAnalysisArr,
    keChuangGraphArr,
    stockMineSweepArr,
    plateQuoteArr,
    diagnosisValueArr,
    goodwillAnalysisArr,
    goodwillWarnArr,
    financingTrackArr,
    historyBackArr,
    longHuStockArr,
    similarKArr,
    intelliChooseStockArr,
    flowArr: [
        {
            label: '当日',
            value: '当日',
        },
        {
            label: '近一月',
            value: '近一月',
        },
        {
            label: '近六月',
            value: '近六月',
        },
        {
            label: '近一年',
            value: '近一年',
        },
        {
            label: '全部',
            value: '全部',
        },
    ],
    shareRateArr: [
        {
            label: 'A股',
            value: 'A股',
        },
        {
            label: '沪市',
            value: '沪市',
        },
        {
            label: '深市',
            value: '深市',
        },
    ],
    fundFlowIndustryArr: [
        {
            label: '概况',
            value: 'summary',
        },
        {
            label: '流入top10',
            value: '流入top10',
        },
        {
            label: '流出top10',
            value: '流出top10',
        },
    ],
    longHuSummaryArr: [
        {
            label: '深股通',
            value: '深股通',
        },
        {
            label: '沪股通',
            value: '沪股通',
        },
    ],
    capitalMainFlowArr: [
        {
            label: '涨幅',
            value: '涨幅'
        },
        {
            label: '跌幅',
            value: '跌幅'
        }
    ],
    capitalDetailArr: [
        {
            label: '净流入',
            value: '净流入'
        },
        {
            label: '净流出',
            value: '净流出'
        }
    ],
    longHuRealArr: [
        {
            label: '实时资金',
            value: 'summary',
        },
        {
            label: '沪股通-涨幅',
            value: '沪股通-涨幅',
        },
        {
            label: '沪股通-跌幅',
            value: '沪股通-跌幅',
        },
        {
            label: '深股通-涨幅',
            value: '深股通-涨幅',
        },
        {
            label: '深股通-跌幅',
            value: '深股通-跌幅',
        },
    ],
    stockBuildUpNorthArr: [
        {
            label: '金额',
            value: '金额',
        },
        {
            label: '股数',
            value: '股数',
        },
    ],
    stockBuildUpSouthArr: [
        {
            label: '金额',
            value: '金额',
        },
        {
            label: '股数',
            value: '股数',
        },
    ],
    stockHoldListNorthArr: [
        {
            label: '北向',
            value: '北向',
        },
    ],
    stockHoldListSouthArr: [
        {
            label: '南向',
            value: '南向',
        },
    ],
    fundFlowTop10StockNorthArr: [
        {
            label: '深股通',
            value: '深股通',
        },
        {
            label: '沪股通',
            value: '沪股通',
        },
    ],
    fundFlowTop10StockSouthArr: [
        {
            label: '港股通(深)',
            value: '港股通(深)',
        },
        {
            label: '港股通(沪)',
            value: '港股通(沪)',
        },
    ],
    historyDailyNorthArr: [
        {
            label: '北向资金',
            value: '北向资金',
        },
        {
            label: '沪股通',
            value: '沪股通',
        },
        {
            label: '深股通',
            value: '深股通',
        },
    ],
    historyDailySouthArr: [
        {
            label: '南向资金',
            value: '南向资金',
        },
        {
            label: '港股通-沪',
            value: '港股通-沪',
        },
        {
            label: '港股通-深',
            value: '港股通-深',
        },
    ],
    profitArr: [{
            label: '净资产收益率(ROE)',
            value: '净资产收益率(ROE)',
        },
        {
            label: '净资产回报率(ROA)',
            value: '净资产回报率(ROA)',
        },
        {
            label: '总资产净利率',
            value: '总资产净利率',
        },
        {
            label: '销售净利率',
            value: '销售净利率',
        },
        {
            label: '销售毛利率',
            value: '销售毛利率',
        }],
    repayArr: [{
        label: '流动比率',
        value: '流动比率',
    },
    {
        label: '速动比率',
        value: '速动比率',
    },
    {
        label: '现金比率',
        value: '现金比率',
    }],
    growArr: [{
        label: '营业总收入增长率',
        value: '营业总收入增长率',
    },
    {
        label: '营业收入在增长率',
        value: '营业收入在增长率',
    },
    {
        label: '营业利润增长率',
        value: '营业利润增长率',
    },
    {
        label: '净利润增长率',
        value: '净利润增长率',
    },
    {
        label: '利润总额增长率',
        value: '利润总额增长率',
    },
    {
        label: '经营性现金流增长率',
        value: '经营性现金流增长率',
    },
    {
        label: '净资产增长率',
        value: '净资产增长率',
    },
    {
        label: '总资产增长率',
        value: '总资产增长率',
    }],
    operateArr: [{
        label: '营业周期',
        value: '营业周期',
    },
    {
        label: '存货周转天数',
        value: '存货周转天数',
    },
    {
        label: '应收账款周转天数',
        value: '应收账款周转天数',
    },
    {
        label: '存货周转率',
        value: '存货周转率',
    },
    {
        label: '流动资产周转率',
        value: '流动资产周转率',
    },
    {
        label: '固定资产周转率',
        value: '固定资产周转率',
    },
    {
        label: '总资产周转率',
        value: '总资产周转率',
    }],
}
export default toolObj