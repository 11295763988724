<template>
    <el-dialog
        :visible.sync="visible"
        title="直播方式选择"
        :close-on-click-modal="false"
        custom-class="preview_dialog"
        width="30vw"
        @close="close"
    >
        <div class="preview_content">
            <div class="way1" @click="openWindowDialog">
                <img src="@/assets/images/detail/windowCollect.png" alt="">
                <h3>窗口采集直播</h3>
                <span>方式：双屏操作</span>
                <span>难度：小</span>
            </div>
            <div class="way2" @click="openObsDialog">
                <div>
                    OBS
                </div>
                <h3>OBS配置直播</h3>
                <span>方式：单屏，下载OBS</span>
                <span>难度：中</span>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            Object.assign(this.$data, this.$options.data())
        },
        openWindowDialog() {
            this.close()
            this.$emit('openWindowDialog')
        },
        openObsDialog() {
            this.close()
            this.$emit('openObsDialog')
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .preview_dialog {
    background: rgb(32,32,32);
    .el-dialog__header {
        border-bottom: 1px solid #262626;
        .el-dialog__title {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .el-dialog__body {
        padding: 8px 60px 24px;
        color: #707070;
        .preview_content {
            display: flex;
            justify-content: space-around;
            > div {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                > h3 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #9E9EA0;
                    margin-top: 4px;
                    margin-bottom: 8px;
                }
            }
            .way1 {
                width: 150px;
                img {
                    height: 118px;
                    width: 121px;
                    margin-left: -20px;
                }
            }
            .way2 {
                > div {
                    width: 88px;
                    height: 88px;
                    text-align: center;
                    line-height: 88px;
                    font-weight: bold;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 4px;
                    background-color: rgb(42, 42, 45);
                    margin-top: 30px;
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>