import axios from "axios";
import moment from 'moment'
let tempHistory = null; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
export function getLongHuStockData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return `${options.secname}${options.type}`
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let finalData = tempHistory
        if (!finalData) {
            finalData = await ajaxLHBStockDetail(options)
            finalData = JSON.parse(finalData.data.content)
            tempHistory = finalData
            finalData = handleAjaxStockDetail(options.type, finalData)
        } else {
            finalData = handleAjaxStockDetail(options.type, finalData)
        }
        resolve(finalData)
    })
}
function getSimpleClass(value){
    return value>=0?'txt_in':'txt_out';
}
function getUnitedTxt(value,suffix){
    if(value>100000000){
        return '<b>'+(value/100000000).toFixed(2)+'</b>亿'+suffix;
    }else if(value>10000){
        return '<b>'+(value/10000).toFixed(2)+'</b>万'+suffix;
    }else{
        return '<b>'+value+'</b>'+suffix;
    }
}
function getUnitedObj(value){
    let txt,unit,
        _value=Math.abs(value);
    if(_value>10000){
        txt=(value/10000).toFixed(2);
        unit='亿';
    }else{
        txt=value.toFixed(2);
        unit='万';
    }
    return {
        txt:txt,
        unit:unit
    };
}
function handleAjaxStockDetail(type, data){

    //处理详情数据展示
    if (type === '基本信息') {
        data.iLHBSecInfo.fChangePctTxt=data.iLHBSecInfo.fChangePct.toFixed(2);
        data.iLHBSecInfo.fCHandPctTxt=data.iLHBSecInfo.fCHandPct.toFixed(2);
        data.iLHBSecInfo.ariseClass=getSimpleClass(data.iLHBSecInfo.fChangePct);
        data.iLHBSecInfo.fDealSumTxt=getUnitedTxt(data.iLHBSecInfo.fDealSum/100,'手');
        data.iLHBSecInfo.fDealAmountTxt=getUnitedTxt(data.iLHBSecInfo.fDealAmount,'元');
        data.iLHBSecInfo.vBeIdeaTxt=data.iLHBSecInfo.vBeIdea.join('、');
        return data.iLHBSecInfo
    } else if (type === '历史上榜后表现') {
        //上榜表现图表
        let onBoardChart=[];
        onBoardChart=data.iLHBSecInfo.vhisKline;
        for (let i = 0; i < onBoardChart.length; i++) {
            onBoardChart[i].name=onBoardChart[i].xLine;
            onBoardChart[i].value1=onBoardChart[i].yLine;
            onBoardChart[i].ariseTxt='上榜后次日涨跌幅:'+onBoardChart[i].fNextChangePct.toFixed(2)+'%';
            if(i==onBoardChart.length-1 && onBoardChart[i].iOnlistFlag){
                onBoardChart[i].ariseTxt='暂无数据,晚点来看吧..';
                onBoardChart[i].fNextChangePct=0;
            }
        }
        return onBoardChart
    } else {
        //上榜罗列
        let onBoardData=data.iLHBSecInfo.vReason;
        for (let i = 0; i < onBoardData.length; i++) {
            onBoardData[i].inDetail=[];
            onBoardData[i].outDetail=[];
            
            onBoardData[i].totalIn=0;       //前10总计买入
            onBoardData[i].totalOut=0;      //前10总计卖出
            onBoardData[i].totalIncome=0;   //前10总计净买入

            //买五直接通过eActType就可以得到了
            for (let j = 0; j < onBoardData[i].vDetail.length; j++) {
                if(onBoardData[i].vDetail[j].eActType==1){
                    onBoardData[i].inDetail.push(onBoardData[i].vDetail[j]);
                }
                onBoardData[i].totalIn+=onBoardData[i].vDetail[j].fBuyAmount;
                onBoardData[i].totalOut+=onBoardData[i].vDetail[j].fSellAmount;
                onBoardData[i].totalIncome=onBoardData[i].totalIn-onBoardData[i].totalOut;
            }

            //卖五的取出规则是那全量数据进行fSellAmout排序然后去前五
            onBoardData[i].outDetail=onBoardData[i].vDetail.sort(function(a,b){
                return b.fSellAmount-a.fSellAmount;
            });
            onBoardData[i].outDetail=onBoardData[i].outDetail.slice(0,5);

            
            //买入total和卖出total变量
            onBoardData[i].buyTotalIn=0;     //前五买入总计买入
            onBoardData[i].buyTotalInPct=0;  //前五买入总计买入占比
            onBoardData[i].buyTotalOut=0;    //前五买入总计卖出
            onBoardData[i].buyTotalOutPct=0; //前五买入总计卖出占比

            onBoardData[i].sellTotalIn=0;    //前五卖出总计买入
            onBoardData[i].sellTotalInPct=0; //前五卖出总计买入占比
            onBoardData[i].sellTotalOut=0;   //前五卖出总计卖出
            onBoardData[i].sellTotalOutPct=0;//前五卖出总计卖出占比


            for (let k = 0; k < onBoardData[i].inDetail.length; k++) {
                onBoardData[i].inDetail[k].fBuyAmountTxt=(onBoardData[i].inDetail[k].fBuyAmount).toFixed(2);
                onBoardData[i].inDetail[k].fSellAmountTxt=(onBoardData[i].inDetail[k].fSellAmount).toFixed(2);
                onBoardData[i].buyTotalIn+=onBoardData[i].inDetail[k].fBuyAmount;
                onBoardData[i].buyTotalInPct+=onBoardData[i].inDetail[k].fBuyProTxt*1;
                onBoardData[i].buyTotalOut+=onBoardData[i].inDetail[k].fSellAmount;
                onBoardData[i].buyTotalOutPct+=onBoardData[i].inDetail[k].fSellProTxt*1;
                if(onBoardData[i].inDetail[k].sOrgClass=='' && onBoardData[i].inDetail[k].sOrgFac==''){
                    onBoardData[i].inDetail[k].infoClass='none';
                }
            }

            
            for (let k = 0; k < onBoardData[i].outDetail.length; k++) {
                onBoardData[i].outDetail[k].fBuyAmountTxt=(onBoardData[i].outDetail[k].fBuyAmount).toFixed(2);
                onBoardData[i].outDetail[k].fSellAmountTxt=(onBoardData[i].outDetail[k].fSellAmount).toFixed(2);
                onBoardData[i].sellTotalIn+=onBoardData[i].outDetail[k].fBuyAmount;
                onBoardData[i].sellTotalInPct+=onBoardData[i].outDetail[k].fBuyProTxt*1;
                onBoardData[i].sellTotalOut+=onBoardData[i].outDetail[k].fSellAmount;
                onBoardData[i].sellTotalOutPct+=onBoardData[i].outDetail[k].fSellProTxt*1;
                if(onBoardData[i].outDetail[k].sOrgClass=='' && onBoardData[i].outDetail[k].sOrgFac==''){
                    onBoardData[i].outDetail[k].infoClass='none';
                }
            }
            onBoardData[i].buyTotalIn=(onBoardData[i].buyTotalIn).toFixed(2);
            onBoardData[i].buyTotalInPct=(onBoardData[i].buyTotalInPct).toFixed(2);
            onBoardData[i].buyTotalOut=(onBoardData[i].buyTotalOut).toFixed(2);
            onBoardData[i].buyTotalOutPct=(onBoardData[i].buyTotalOutPct).toFixed(2);

            onBoardData[i].sellTotalIn=(onBoardData[i].sellTotalIn).toFixed(2);
            onBoardData[i].sellTotalInPct=(onBoardData[i].sellTotalInPct).toFixed(2);
            onBoardData[i].sellTotalOut=(onBoardData[i].sellTotalOut).toFixed(2);
            onBoardData[i].sellTotalOutPct=(onBoardData[i].sellTotalOutPct).toFixed(2);

            onBoardData[i].totalInTxt=getUnitedObj(onBoardData[i].totalIn).txt;
            onBoardData[i].totalInUnit=getUnitedObj(onBoardData[i].totalIn).unit;
            onBoardData[i].totalOutTxt=-1*getUnitedObj(onBoardData[i].totalOut).txt;
            onBoardData[i].totalOutUnit=getUnitedObj(onBoardData[i].totalOut).unit;
            onBoardData[i].totalIncomeTxt=getUnitedObj(onBoardData[i].totalIncome).txt;
            onBoardData[i].totalIncomeUnit=getUnitedObj(onBoardData[i].totalIncome).unit;
            onBoardData[i].totalIncomeClass=getSimpleClass(onBoardData[i].totalIncome);
        }
        return type === '买入前五营业部' ? onBoardData[0].inDetail.sort(function(a,b){
            return b.fBuyAmount-a.fBuyAmount;
        }) : onBoardData[0].outDetail
    }
}
function strToBinary(str) {
    function Str2Bytes(str) {
        let pos = 0;
        let len = str.length;
        if (len % 2 != 0) {
            return null;
        }
        len /= 2;
        let hexA = new Array();
        for (let i = 0; i < len; i++) {
            let s = str.substr(pos, 2);
            let v = parseInt(s, 16);
            hexA.push(v);
            pos += 2;
        }
        return hexA;
    }
    let GUID = Str2Bytes(str);
    for (let j = 0; j < GUID.length; j++) {
        if (GUID[j] > 127) {
            let binary = (GUID[j] - 128).toString(2);
            let complement = ('0b' + binary ^ '0b1111111').toString(2);
            GUID[j] = ~parseInt(complement, 2);
        }
    }
    return GUID;
}
async function ajaxLHBStockDetail(options) {
    let accountInfo = {
        "AID": 1362106,
        "GUID": "06DF8956E15486433132AB599C46D8FE",
        "DUA": "SN=IOSCJPH34_FA&VN=344101317&BN=0&VC=APPLE&MO=iPhone&RL=414_896&CHID=1000&LCID=0&RV=&OS=13.5.1&DV=V1",
        "IMEI": "4DBE6036-F11B-4A54-908A-8FF2B8F6320D",
        "ticket": "8QPxb0fpzsS-EQuhyNlZFCHTQ0Hb!sbm!AZrWxJeLHM.",
        "dtCellphoneState": true,
        "dtnickname": "DT1362106",
        "dtheadimgurl": "",
        "dtMemberType": 1,
        "dtMemberEndTime": 1647075915,
        "iosChecking": 0,
        "dtUserRealName": "",
        "dtUserIDNumber": ""
    }
    return await axios.post(
        "https://comm.wedengta.com/?s=longhubang&f=getLHBStockDetail&req=GetLHBStockDetailReq&rsp=GetLHBStockDetailRsp",
        JSON.stringify({
            stUserInfo: {
                vGUID: strToBinary(accountInfo.GUID),
                sDUA: accountInfo.DUA,
                iAccountId: accountInfo.AID
            },
            sDtSecCode: options.seccode,
            sDay: options.date || moment().subtract(1, "days").format('YYYY-MM-DD')
        })
      );
}
