import axios from "axios";

let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getResultsContrastData(options) {
  return new Promise(async (resolve, reject) => {
    try {
      let result = {}
      if (options.type !== '综合对比') {
          //收集股票seccode
        let seccodeArr = [];
        options.compareStocks.map((item) => {
          seccodeArr.push(item.seccode);
        });
        let finalData = []; //最终数据
        //首先尝试从临时存储中获得数据
        let fullData = tempHistory[seccodeArr[0] + seccodeArr[1]];
        //如果没得到，则开始发请求
        if (!fullData) {
          let seasonData = await ajaxFinReportDateList(seccodeArr);
          const latestSeason = JSON.parse(seasonData.data.content).vReportDate.slice(-1)[0]; //{eSeasonType: 1, sYear: '2022'}
          fullData = await handleTwoStock(seccodeArr, latestSeason);
          tempHistory[seccodeArr[0] + seccodeArr[1]] = fullData;
        }
        finalData = getFinalData(fullData, options)
        
        result = {
          pageTitle: getPageTitle(options),
          data: finalData,
        };
      } else {
        let mDtSecCode = {}
        let vDtSecCode = [];
        options.compareStocks.forEach((item) => {
          mDtSecCode[item.seccode] = item.secname
          vDtSecCode.push(item.seccode)
        });
        let finalData = await ajaxFinanceCompare({
          mDtSecCode,
          vDtSecCode,
        })
        finalData = JSON.parse(finalData.data.content).vFinCompareTotalRankItem
        result = {
          pageTitle: getPageTitle(options),
          data: finalData.map((item) => {
            let obj = {}
            item.vFinCompareDimRankItem.forEach((value) => {
              obj[value.sDimName] = value.iScore
            })
            return obj
          })
        }
      }
      
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

function getPageTitle(options) {
  return `${options.type}${!options.base ? '' : ` — ${options.base}`}`
}
function getValue(data, index1, index2) {
  return data.mItems[index1][index2].sDisplayValue;
}
function getNum(options) {
  let num = 0
  switch (options.base) {
    case '净资产收益率(ROE)':
      num = 7
      break;
    case '净资产回报率(ROA)':
      num = 8
      break;
    case '总资产净利率':
      num = 9
      break;
    case '销售净利率':
      num = 10
      break;
    case '销售毛利率':
      num = 11
      break;
    case '流动比率':
      num = 18
      break;
    case '速动比率':
      num = 19
      break;
    case '现金比率':
      num = 20
      break;
    case '营业总收入增长率':
      num = 28
      break;
    case '营业收入在增长率':
      num = 29
      break;
    case '营业利润增长率':
      num = 30
      break;
    case '净利润增长率':
      num = 31
      break;
    case '利润总额增长率':
      num = 32
      break;
    case '经营性现金流增长率':
      num = 33
      break;
    case '净资产增长率':
      num = 34
      break;
    case '总资产增长率':
      num = 35
      break;
    case '营业周期':
      num = 21
      break;
    case '存货周转天数':
      num = 22
      break;
    case '应收账款周转天数':
      num = 23
      break;
    case '存货周转率':
      num = 24
      break;
    case '流动资产周转率':
      num = 25
      break;
    case '固定资产周转率':
      num = 26
      break;
    case '总资产周转率':
      num = 27
      break;
    default:
      break;
  }
  return num
}
function getFinalData(fullData, options) {
  let result = [];
  if (options.type === '综合对比') {
    fullData.forEach((value) => {
      result.push({
        value1: 2,
        value2: 2,
        value3: 2,
        value4: 2,
      })
    })
  } else {
    let num = getNum(options)
    fullData.forEach((value) => {
      result.push({
        label: options.compareStocks.filter(item => item.seccode === value.sDtSecCode)[0].secname,
        value: getValue(value, 1, num)
      })
    })
  }
  return result
}
async function handleTwoStock(seccodeArr, latestSeason) {
  return new Promise(async (resolve) => {
    const allSeason = [
      {
        sDtSecCode: seccodeArr[0],
        sYear: latestSeason.sYear,
        eSeasonType: latestSeason.eSeasonType,
      },
      {
        sDtSecCode: seccodeArr[1],
        sYear: latestSeason.sYear,
        eSeasonType: latestSeason.eSeasonType,
      },
    ];
    let financeData = await ajaxFinancialData(allSeason);
    financeData = JSON.parse(financeData.data.content).vFinancialData;
    resolve(financeData);
  });
}


//获取财报对比
async function ajaxFinancialData(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinancialData&req=GetFinancialDataReq&rsp=GetFinancialDataRsp",
    JSON.stringify({
      vReqItem: options,
    })
  );
}
//获取有财报数据的日期列表
async function ajaxFinReportDateList(seccodeArr) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinReportDateList&req=GetFinReportDateListReq&rsp=GetFinReportDateListRsp",
    JSON.stringify({
      vDtSecCode: seccodeArr,
    })
  );
}
// 获取财报综合对比
async function ajaxFinanceCompare(seccodeObj) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinanceCompare&req=GetFinanceCompareReq&rsp=GetFinanceCompareRsp",
    JSON.stringify({
      mDtSecCode: seccodeObj.mDtSecCode,
      vDtSecCode: seccodeObj.vDtSecCode
    })
  );
}
