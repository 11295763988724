/*
 * @Author: liuhanchuan
 * @Date: 2021-05-24 11:19:06
 * @Last Modified by: liuhanchuan
 * @Last Modified time: 2022-09-23 10:51:09
 */

<template>
  <div class="rename">
    <span v-if="!visible" @click="beginReName">
      {{ value.categoryName }}
    </span>
    <template v-else>
        <el-input v-if="visible" ref="input" v-model="newValue" size="medium"></el-input>
        <!-- <i class="el-icon-circle-close" @click="closeReName" /> -->
        <i class="el-icon-circle-check" @click="endReName" />
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: '',
    }
  },
  data: function() {
    return {
      visible: false,
      newValue: '',
      item: null,
    }
  },
  methods: {
    initValue() {
      this.visible = true
      this.newValue = this.value.categoryName
    },
   async beginReName() {
     await this.initValue()
     await this.$refs.input.focus()
   },
   clearData() {
      Object.assign(this.$data, this.$options.data())
    },
   endReName() {
     if (this.newValue !== this.value.categoryName) {
        // 编辑分组名称
        if (this.value.categoryId !== undefined) {
            this.API.category.edit({
                roomId: Number(this.$route.query.id),
                categoryId: this.value.categoryId,
                categoryName: this.newValue,
            }).then((res) => {
                if (res?.code === 200) {
                    this.$message({ showClose: true, type: 'success', message: `重命名成功`})
                    this.$emit('getGroupList', () => {
                        this.clearData()
                    })
                } else {
                    this.$message.error('分组重命名失败！')
                }
            })
        } else {
            this.API.category.create({
                categoryName: this.newValue,
                roomId: Number(this.$route.query.id),
            }).then((res) => {
                if (res?.code === 200) {
                    this.$message({ showClose: true, type: 'success', message: `分组创建成功`})
                    this.$emit('getGroupList', () => {
                        this.clearData()
                    })
                } else {
                    this.$message.error('分组创建失败！')
                }
                
            })
        }
     } else {
      this.$emit('getGroupList', () => {
            this.clearData()
        }, false)
     }
   },
   closeReName() {
        this.$emit('getGroupList', () => {
            this.clearData()
        }, false)
   }
  }
}
</script>
<style scoped lang="less">
.rename {
  height: 40px;
  display: flex;
  align-items: center;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
  > i {
    font-size: 20px;
    margin-left: 14px;
    color: #4E8CFF;
    cursor: pointer;
  }
}
</style>
