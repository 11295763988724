<template>
    <div class="marketPlate">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeSceneStock" />
            <div v-show="tool_filter_one">
                <h3>{{ activeWindowCustomChartMsg.materialName === '行情' ? '场景' : '板块'}}</h3>
                <el-select v-model="tool_filter_two"  size="medium" placeholder="请选择" @change="changeFilterTwo()">
                    <el-option
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="tool_filter_two && activeWindowCustomChartMsg.materialName === '行情'">
                <h3>附图1</h3>
                <el-select v-model="tool_filter_three"  size="medium" placeholder="请选择" @change="changeScene()">
                    <el-option
                        v-for="(item, index) in figure(tool_filter_two)"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="tool_filter_two && activeWindowCustomChartMsg.materialName === '行情'">
                <h3>附图2</h3>
                <el-select v-model="tool_filter_four"  size="medium" placeholder="请选择" @change="changeScene()">
                    <el-option
                        v-for="(item, index) in figure(tool_filter_two)"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    components: {
        SelectStock
    },
    methods: {
        figure(chartType) {
            if (['分时', '五日'].indexOf(chartType) !== -1) {
                return [{
                    label: '成交量',
                    value: '成交量'
                },{
                    label: '分时能量',
                    value: '分时能量'
                },{
                    label: '资金博弈',
                    value: '资金博弈'
                },{
                    label: '量比',
                    value: '量比'
                },{
                    label: 'MACD',
                    value: 'MACD'
                }]
            } else {
                return [{
                    label: '成交量',
                    value: '成交量'
                },{
                    label: '主力资金',
                    value: '主力资金'
                },{
                    label: 'MACD',
                    value: 'MACD'
                },{
                    label: 'KDJ',
                    value: 'KDJ'
                },{
                    label: 'RSI',
                    value: 'RSI'
                },{
                    label: 'BOLL',
                    value: 'BOLL'
                },{
                    label: 'PB',
                    value: 'PB'
                },{
                    label: 'PE',
                    value: 'PE'
                },{
                    label: '神奇海波',
                    value: '神奇海波'
                }]
            }
        },
        //  行情&&板块
        changeSceneStock(value) {
            this.tool_filter_one = value
            switch (this.activeWindowCustomChartMsg.materialName) {
                case '行情':
                    this.tool_filter_two = '分时'
                    this.tool_filter_three = '成交量'
                    this.tool_filter_four = 'MACD'
                    break;
                case '板块':
                    this.tool_filter_two = '涨幅top10'
                    this.tool_filter_three = ''
                    this.tool_filter_four = ''
                    break;
                default:
                    this.tool_filter_two = undefined
                    break;
            }
            this.changeScene()
        },
        changeFilterTwo() {
            this.tool_filter_three = '成交量'
            this.tool_filter_four = 'MACD'
            this.changeScene()
        },
        changeScene() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: this.tool_filter_one?.value,
                    secname: this.tool_filter_one?.label,
                    chartType: this.tool_filter_two,
                    figure1: this.tool_filter_three || undefined,
                    figure2: this.tool_filter_four || undefined
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}${this.tool_filter_three ? `-${this.tool_filter_three}` : ''}${this.tool_filter_four ? `-${this.tool_filter_four}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            seccode: this.tool_filter_one?.value,
                            secname: this.tool_filter_one?.label,
                            chartType: this.tool_filter_two,
                            figure1: this.tool_filter_three || undefined,
                            figure2: this.tool_filter_four || undefined
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marketPlate {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            margin-right: 24px;
            > h3 {
                color: #fff;
                margin-bottom: 6px;
            }
        }
        /deep/ .el-select .el-input.is-focus .el-input__inner {
            border-color: rgba(255, 255, 255, 0.08);
        }
        /deep/ .el-input__inner {
            background: #202020;
            border-color: rgba(255, 255, 255, 0.08);
            color: #fff;
        }
    }
}
</style>