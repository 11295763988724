<template>
    <div class="marginTrade">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeStock" />
            <div v-show="tool_filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['月份历史行情回看','星期历史行情回看','行业最佳表现个股'].indexOf(tool_filter_two) !== -1">
                <h3>名称</h3>
                <el-select v-model="tool_filter_three"  size="medium" placeholder="请选择" @change="changeFilterThree()">
                    <el-option
                        v-for="(item, index) in getFilterArr()"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            tool: {},
            monthArr: [{
                label: '一月',
                value: 1,
            },{
                label: '二月',
                value: 2,
            },{
                label: '三月',
                value: 3,
            },{
                label: '四月',
                value: 4,
            },{
                label: '五月',
                value: 5,
            },{
                label: '六月',
                value: 6,
            },{
                label: '七月',
                value: 7,
            },{
                label: '八月',
                value: 8,
            },{
                label: '九月',
                value: 9,
            },{
                label: '十月',
                value: 10,
            },{
                label: '十一月',
                value: 11,
            },{
                label: '十二月',
                value: 12,
            }],
            weekArr: [{
                label: '星期一',
                value: 1,
            },{
                label: '星期二',
                value: 2,
            },{
                label: '星期三',
                value: 3,
            },{
                label: '星期四',
                value: 4,
            },{
                label: '星期五',
                value: 5,
            }],
            category: [{
                label: '月份',
                value: '月份',
            },{
                label: '星期',
                value: '星期',
            },{
                label: '同期',
                value: '同期',
            }],
        }
    },
    components: {
        SelectStock
    },
    created() {
        this.tool = tool
    },
    methods: {
        getFilterArr() {
            if (this.tool_filter_two === '月份历史行情回看') {
                return this.monthArr
            } else if (this.tool_filter_two === '星期历史行情回看') {
                return this.weekArr
            } else if (this.tool_filter_two === '行业最佳表现个股') {
                return this.category
            }
        },
        changeStock(value) {
            this.tool_filter_one = value // obj key is label and value
            this.tool_filter_two = '月份历史行情回看'
            this.tool_filter_three = 1
            this.changeFilterThree()
        },
        changeFilterTwo() {
            if (this.tool_filter_two === '月份历史行情回看' || this.tool_filter_two === '星期历史行情回看') {
                this.tool_filter_three = 1
            } else if (this.tool_filter_two === '行业最佳表现个股') {
                this.tool_filter_three = '月份'
            } else {
                this.tool_filter_three = ''
            }
            this.changeFilterThree()
        },
        changeFilterThree() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: this.tool_filter_one.value,
                    secname: this.tool_filter_one.label,
                    type: this.tool_filter_two,
                    subType: this.tool_filter_three || undefined
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}${this.tool_filter_three ? `-${this.tool_filter_three}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        seccode: this.tool_filter_one.value,
                        secname: this.tool_filter_one.label,
                        type: this.tool_filter_two,
                        subType: this.tool_filter_three || undefined
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                width: 20%;
                box-sizing: border-box;
            }
            &:nth-child(2) {
                width: 40%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            &:last-child {
                width: 20%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>