<template>
    <div class="capitalBidding">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterType()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="tool[`${tool_filter_one}${currentFund(tool_filter_one)}Arr`] && tool[`${tool_filter_one}${currentFund(tool_filter_one)}Arr`].length > 1">
                <h3>筛选条件</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilter()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool[`${tool_filter_one}${currentFund(tool_filter_one)}Arr`]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
        // 北向资金&&南向资金&&竞价
        currentFund(value) {
            if (['fundFlowTop10Stock', 'historyDaily', 'stockBuildUp', 'stockHoldList'].indexOf(value) !== -1) {
                return this.activeWindowCustomChartMsg.materialName === '北向资金' ? 'North' : 'South'
            }
            return ''
        },
        currentFlowDirection(value) {
            if (['fundFlowIndustry', 'stockHoldList', 'capitalDetail', 'capitalMainFlow'].indexOf(this.tool_filter_one) !== -1) {
                return this.tool_filter_two
            }
            if (this.tool_filter_one === 'flow' || this.tool_filter_one === 'stockBuildUp') {
                return value.materialName === '北向资金' ? '北向' : '南向'
            }
            return undefined
        },
        changeFilterType() {
            switch (this.tool_filter_one) {
                case 'flow':
                    this.tool_filter_two = '当日'
                    break;
                case 'shareRate':
                    this.tool_filter_two = 'A股'
                    break;
                case 'fundFlowIndustry':
                    this.tool_filter_two = 'summary'
                    break;
                case 'fundFlowTop10Stock':
                    this.tool_filter_two = this.activeWindowCustomChartMsg.materialName === '北向资金' ? '深股通' : '港股通(深)'
                    break;
                case 'historyDaily':
                    this.tool_filter_two = this.activeWindowCustomChartMsg.materialName === '北向资金' ? '北向资金' : '南向资金'
                    break;
                case 'stockBuildUp':
                    this.tool_filter_two = '金额'
                    break;
                case 'stockHoldList':
                    this.tool_filter_two = this.activeWindowCustomChartMsg.materialName === '北向资金' ? '北向' : '南向'
                    break;
                case 'longHuSummary':
                    this.tool_filter_two = '深股通'
                    break;
                case 'capitalDetail':
                    this.tool_filter_two = '净流入'
                    break;
                case 'capitalMainFlow':
                    this.tool_filter_two = '涨幅'
                    break;
                case 'longHuReal':
                    this.tool_filter_two = 'summary'
                    break;
                default:
                     this.tool_filter_two = undefined
                    break;
            }
            this.changeFilter()
        },
        changeFilter() {
            this.$emit('setCustomWindowChart', {
                    ...this.activeWindowCustomChartMsg,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        flowPeriod: this.tool_filter_one === 'flow' ? this.tool_filter_two : undefined,
                        market: ['flow', 'fundFlowIndustry', 'stockHoldList'].indexOf(this.tool_filter_one) === -1 ? this.tool_filter_two : undefined,
                        flowDirection: this.currentFlowDirection(this.activeWindowCustomChartMsg)
                    })
                }, 'show')
        },
        addGroup() {
            let typeName = this.tool.tooObj[this.activeWindowCustomChartMsg.materialName].filter(item => item.value === this.tool_filter_one)[0].label
            cutLive(document.getElementById('preview'), `${this.activeWindowCustomChartMsg.materialName}-${typeName}${this.tool_filter_two ? `-${this.tool_filter_two}` : ''}`).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: `${this.activeWindowCustomChartMsg.materialName}-${typeName}${this.tool_filter_two ? `-${this.tool_filter_two}` : ''}`,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        flowPeriod: this.tool_filter_one === 'flow' ? this.tool_filter_two : undefined,
                        market: ['flow', 'fundFlowIndustry', 'stockHoldList'].indexOf(this.tool_filter_one) === -1 ? this.tool_filter_two : undefined,
                        flowDirection: this.currentFlowDirection(this.activeWindowCustomChartMsg)
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.capitalBidding {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                width: calc(100% - 320px);
                margin-right: 30px;
            }
            &:last-child {
                width: 320px;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
        }
    }
}
</style>