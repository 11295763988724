<template>
    <div class="detail_container">
        <Menu :isActive="isActive" :selectMenu="selectMenu" />
        <div class="material_class">
            <component
                v-bind:is="currentComponent()"
                :authVisible="authVisible"
                :isActive="isActive"
            >
            </component>
        </div>
        <div class="preview_class">
            <RightPreview :isConnected="isConnected"/>
            <ToolOperation :activeWindowCustomChartMsg="activeWindowCustomChartMsg" :setCustomWindowChart="setCustomWindowChart" />
        </div>
        <PayTipDialog ref="payTip" />
        <AddMaterial ref="addMaterial" />
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { handleChart, getGeneralChartData } from './components/general.js'
import Menu from './Menu/index.vue'
import LiveSet from './liveSet/index.vue'
import MyMaterial from './myMaterial/index.vue'
import CommonMaterial from './commonMaterial/index.vue'
import PublishMaterial from './publishMaterial/index.vue'
import debounce from 'lodash.debounce'
import PayTipDialog from './components/payTipDialog/index.vue';
import AddMaterial from './commonMaterial/AddMaterial.vue'
import RightPreview from './RightPreview/index.vue';
import ToolOperation from './toolOperation/index.vue'
let initTimer;
let orderObj = {
    '行情': 'chartSwitcher|marketChart',
    '板块': 'chartSwitcher|plateChart',
    '北向资金': 'chartSwitcher|northFundChart',
    '南向资金': 'chartSwitcher|northFundChart',
    '竞价': 'chartSwitcher|auctionChart',
    '个股财报': 'chartSwitcher|financeCompareChart',
    '财报比对': 'chartSwitcher|financeCompareChart',
    '融资融券': 'chartSwitcher|marginTradeChart',
    '龙虎榜': 'chartSwitcher|longHuChart',
    '估值交易': 'chartSwitcher|evaluatingDistChart',
    '新股中心': 'chartSwitcher|ipoInfoChart',
    '市场强度': 'chartSwitcher|checkMarketChart',
    '大宗交易': 'chartSwitcher|bigTradeChart',
    '财报比对(图)': 'chartSwitcher|resultsContrastChart',
    '板块地域概念': 'chartSwitcher|plateAreaChart',
    '智能诊股': 'chartSwitcher|intelligentDiagnosisChart',
    "筹码分布": 'chartSwitcher|chipDistChart',
    "投资图谱": 'chartSwitcher|investmentMapChart',
    "个股百科": 'chartSwitcher|companyIntroChart',
    "杜邦分析": 'chartSwitcher|duPonChart',
    "个股资金": 'chartSwitcher|stocksFundsChart',
    "股权质押(个股)": 'chartSwitcher|sharePledgeChart',
    "股权质押(大盘)": 'chartSwitcher|sharePledgeChart',
    "多空信号": 'chartSwitcher|bsSignalChart',
    "定增掘金": 'chartSwitcher|nuggetsChart',
    "财务业绩": 'chartSwitcher|financeAnalysisChart',
    "扫雷": 'chartSwitcher|stockMineSweepChart',
    "榜单": 'chartSwitcher|plateQuoteChart',
    "科创掘金": 'chartSwitcher|keChuangGraphChart',
    "价值评估": 'chartSwitcher|diagnosisValueChart',
    "融资追击": 'chartSwitcher|financingTrackChart',
    "商誉分析(大盘)": 'chartSwitcher|goodwillAnalysisChart',
    "商誉分析(个股)": 'chartSwitcher|goodwillWarnChart',
    "历史回看": 'chartSwitcher|historyBackChart',
    "龙虎榜(个股)": 'chartSwitcher|longHuStockChart',
    "相似K线": 'chartSwitcher|similarKChart',
    "策略选股": 'chartSwitcher|intelliChooseStockChart',

}
export default {
    data: function() {
        return {
            authVisible: true, // 权限没有到期
            isConnected: false, //是否已连接websocket
            backAskGap: 3 * 1000, //心跳间隔。未连接成功前默认会快一些，连接成功后心跳变慢
            backCoolTimeOut: null,
            activeLogoMsg: {},  // 当前所选logo
            activeMsg: {}, // 直播间信息
            activeScreen: {}, // 行情滚动图
            activeBroadcastBgMediaMsg: {}, // 当前所选背景信息
            activeWindowMediaMsg: {}, // 当前所选图片、视频
            activeWindowIframeMsg: {}, // 当前所选咨讯
            activeWindowGeneralChartMsg: {}, // 图表
            activeWindowCustomChartMsg: {}, // 工具
            isActive: 'materialBackground',
            detail: {}, // 直播间信息
        }
    },
    created() {
        this.updataLive = debounce(this.updataLive, 3000)
        this.firstAnswer = debounce(this.firstAnswer, 2000)
        // 监听页面刷新
        window.addEventListener('beforeunload', this.initPage)
        // 获取当前直播间信息
        try {
            let userAuthInfo = JSON.parse(localStorage.getItem('userAuthInfo'))
            if (userAuthInfo instanceof Object && (this.$moment(userAuthInfo.endDate).valueOf() > this.$moment().valueOf())) {
                this.authVisible = true
            } else {
                this.authVisible = false
            }
            if (userAuthInfo instanceof Object && userAuthInfo.isTest) {
                this.sendCommand('watermark', '', false)
            }
            this.detail = JSON.parse(localStorage.getItem('room_detail'))
            // 初始化显示设置的组件
            if (this.detail.backgroundUrl) {
                this.activeBroadcastBgMediaMsg = { url: this.detail.backgroundUrl.split('|').length > 1 ?  this.detail.backgroundUrl.split('|')[1] :  this.detail.backgroundUrl }
            }
            if (this.detail.tools.filter(item => item.toolName === '直播间LOGO').length > 0 && this.detail.tools.filter(item => item.toolName === '直播间LOGO')[0].showStatus) {
                this.activeLogoMsg = JSON.parse(this.detail.tools.filter(item => item.toolName === '直播间LOGO')[0].toolContent)
            }
            if (this.detail.tools.filter(item => item.toolName === '直播间信息').length > 0 && this.detail.tools.filter(item => item.toolName === '直播间信息')[0].showStatus) {
                this.activeMsg = JSON.parse(this.detail.tools.filter(item => item.toolName === '直播间信息')[0].toolContent)
            }
            if (this.detail.tools.filter(item => item.toolName === '行情滚动图').length > 0 && this.detail.tools.filter(item => item.toolName === '行情滚动图')[0].showStatus) {
                this.activeScreen = JSON.parse(this.detail.tools.filter(item => item.toolName === '行情滚动图')[0].toolContent)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async mounted() {
        this.$bus.$on('addMaterial', (value) => {
            this.$refs.addMaterial.open(value)
        })
        // 上屏
        this.$bus.$on('upScreen', (value = {}, source) => {
            if (!this.authVisible) {
                return this.$refs.payTip.open()
            }
            if (source === '直播间LOGO') {
                let newValue = value.tools.filter(item => item.toolName === '直播间LOGO')
                if (newValue.length > 0) {
                     try {
                        this.setLogo(JSON.parse(newValue[0].toolContent), "show")
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            if (source === '直播间信息') {
                 let newValue = value.tools.filter(item => item.toolName === '直播间信息')
                 if (newValue.length > 0) {
                     try {
                        this.setMsg(JSON.parse(newValue[0].toolContent), "show")
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            if (source === '行情滚动图') {
                let newValue = value.tools.filter(item => item.toolName === '行情滚动图')
                if (newValue.length > 0) {
                    try {
                        this.setScreen(JSON.parse(newValue[0].toolContent), "show")
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            if (source === '背景') {
                this.setBroadcastBgMedia(value, 'show')
                // 根据背景动态修改窗口样式
                this.sendCommand("theme", "", value.materialTag.split(' ')[2] === '深色系' ? true : false);
                this.updataLive({
                    backgroundUrl: value.materialType === 0 ? value.url : `${value.remark}|${value.url}`
                })
            }
            if (value.materialClass === 0) {
                // 图片
                if (value.materialType === 0 || value.materialType === 2) {
                    this.setWindowMedia(value, "show")
                }
                // 咨询
                if (value.materialType === 3) {
                    let urlArr = value.url.split('?')
                    this.setWindowIframe({
                        ...value,
                        url: `${location.origin}/news?${urlArr[1]}`
                    }, "show")
                }
                // 数图
                if (value.materialType === 1) {
                    this.setGeneralWindowChart(value, 'show')
                }
                // 工具
                if (value.materialType === 5) {
                    this.setCurrentTool(value)
                }
                // 工具2
                if (value.materialType === 6) {
                    this.setTool(value)
                }
            }
        })
        // 下屏
        this.$bus.$on('downScreen', (source) => {
            if (source === '直播间LOGO') {
                this.logoDownScreen()
            } else if (source === '直播间信息') {
                this.msgDownScreen()
            } else if (source === '行情滚动图') {
                this.screenDownScreen()
            }else {
                this.materialDownScreen()
            }
        })
        // 创建websocket连接
        initTimer = setInterval(() => {
            this.createSocket()
        }, 1000)
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.initPage)
        this.initPage()
    },
    components: {
        LiveSet,
        MyMaterial,
        CommonMaterial,
        PublishMaterial,
        RightPreview,
        PayTipDialog,
        AddMaterial,
        ToolOperation,
        Menu
    },
    methods: {
        // 修改直播间基础配置
        updataLive(params) {
            this.API.live.update({
                id: Number(this.$route.query.id),
                ...params
            })
        },
        selectMenu(index) {
            this.isActive = index
        },
        //发送命令,统一格式为组件名，命令，命令数据
        sendCommand(componentName, componentCommand, componentCommandData) {
            this.global.ws.send(
                JSON.stringify({
                componentName: componentName,
                componentCommand: componentCommand,
                componentCommandData: componentCommandData,
                })
            );
        },

        createSocket() {
            if (this.global.ws && this.global.ws.readyState === 1) {
                if (initTimer) {
                    clearInterval(initTimer)
                }
               //通知接收端已连接
                this.repeatAskFirst();
                //开始接收
                this.startMonite();
            }
        },
        //接收消息
        startMonite() {
            this.global.ws.onmessage = (e) => {
                const data = JSON.parse(e.data);
                //接收端上线后把控制端当前状态全部发过去
                if (data.eventType == "firstAnswer") {
                    this.firstAnswer()
                }
                if (data.eventType == "tong") {
                    clearTimeout(this.backCoolTimeOut);
                    this.backCoolTimeOut = setTimeout(() => {
                        this.isConnected = false;
                    }, 12 * 1000);
                }
            };
        },
        firstAnswer() {
            this.isConnected = true;
            this.backAskGap = 8 * 1000;
            // 发送控制端所有素材
            this.sendBundleCommand();
            //进入心跳询问
            this.repeatAskBackOnline();
        },
        //重复询问接收端是否上线
        async repeatAskFirst() {
            if (this.isConnected) {
                return;
            }
            this.sendCommand("firstAsk");
            await sleep(this.backAskGap);
            this.repeatAskFirst();
        },
        //只要上线了，就间隔几秒询问一次背景是否还活着
        async repeatAskBackOnline() {
            if (!this.isConnected) {
                return;
            }
            this.sendCommand("ping");
            await sleep(this.backAskGap);
            this.repeatAskBackOnline();
        },
         //发送控制端的所有状态
        sendBundleCommand() {
            this.sendScreenMode()
            this.sendBg();
            this.sendLogo();
            this.sendMsg();
            this.sendScreen();
            this.sendWindow();
        },
        //设置屏幕模式，横屏landscape还是竖屏portraitscreen
        sendScreenMode(){
            let mode = this.detail.roomSizeScale === '16:9' ? 'landscape' : 'portraitscreen'
            this.sendCommand("screenMode","", mode);
        },
        // 初始化设置背景
        sendBg() {
            if (Object.keys(this.activeBroadcastBgMediaMsg).length > 0) {
                this.setBroadcastBgMedia(this.activeBroadcastBgMediaMsg, 'show')
            }
        },
        // 初始化设置logo
        sendLogo() {
            if (Object.keys(this.activeLogoMsg).length > 0) {
                this.setLogo(this.activeLogoMsg, 'show')
            }
        },
        // 初始化设置直播间信息
        sendMsg() {
            if (Object.keys(this.activeMsg).length > 0) {
                this.setMsg(this.activeMsg, 'show')
            }
        },
        sendScreen() {
            if (Object.keys(this.activeScreen).length > 0) {
                this.setScreen(this.activeScreen, 'show')
            }
        },
        // 初始化设置窗口
        sendWindow() {
            // 通用图表
            if (Object.keys(this.activeWindowGeneralChartMsg).length > 0) {
                this.setGeneralWindowChart(this.activeWindowGeneralChartMsg, 'show')
            }
            // 工具
            if (Object.keys(this.activeWindowCustomChartMsg).length > 0) {
                this.setCustomWindowChart(this.activeWindowCustomChartMsg, 'show')
            }
            // 图片
            if (Object.keys(this.activeWindowMediaMsg).length > 0) {
                this.setWindowMedia(this.activeWindowMediaMsg, 'show')
            }
            if (Object.keys(this.activeWindowIframeMsg).length > 0) {
                this.setWindowIframe(this.activeWindowIframeMsg, 'show')
            }
        },
        async setCurrentTool(item) {
            await this.otherWindowClose();
            this.activeWindowCustomChartMsg = {...item}
        },
        async setTool(item) {
            console.log(item)
            await this.otherWindowClose();
            this.activeWindowCustomChartMsg = {...item}
            this.setCustomWindowChart(item, "show")
        },
        // 设置工具数据展示
        async setCustomWindowChart(item, componentCommand, callBack) {
            let commandData = ""
            if (componentCommand == "show" || componentCommand == "data") {
                await handleChart(item).then((value) => {
                    commandData = value
                }).catch(() => {
                    commandData = undefined
                    console.error("拉取失败");
                })
            }
            if (callBack) {
                callBack(commandData)
            }
            if (componentCommand === "show" || componentCommand === "hide") {
                this.sendCommand(orderObj[item.materialName.split('-')[0]], componentCommand, commandData);
            }
        },

        //设置当前通用图表
        async setGeneralWindowChart(item, componentCommand) {
            let commandData = "";
            if (componentCommand == "show") {
                commandData = await getGeneralChartData(item.url, item.materialTag).catch(() => {
                    console.error("拉取失败提醒");
                });
            }
            //windowMedia，windowGeneralChart和windowCustomChart是互斥的，只能三选一
            await this.otherWindowClose();

            this.activeWindowGeneralChartMsg = {...item};
            this.sendCommand('chartSwitcher|generalChart', componentCommand, commandData);
        },


        // 设置直播背景
        async setBroadcastBgMedia(item, componentCommand) {
            //如果背景未连接则忽略；如果重复点击也忽略
            this.activeBroadcastBgMediaMsg = {...item};
            // this.sendCommand("theme", "", item.isThemeBlack);
            this.sendCommand('broadcastBgMedia', componentCommand, item.url);
        },
        //设置logo
        async setLogo(item, componentCommand) {
            //如果背景未连接则忽略；如果重复点击也忽略
            this.activeLogoMsg = {...item};
            this.sendCommand('logoSwitcher', componentCommand, item.logoUrl);
        },
        // 设置直播间信息
        async setMsg(item, componentCommand) {
            this.activeMsg = {...item}
            this.sendCommand('multiFuncBar', componentCommand, item)
        },
        // 设置行情滚动图
        async setScreen(item, componentCommand) {
            this.activeScreen = {...item}
            this.sendCommand('marketMarquee', componentCommand, item)
        },
        //设置当前window媒体-包括图片和视频
        async setWindowMedia(item, componentCommand) {
            //windowMedia，windowGeneralChart和windowCustomChart是互斥的，只能三选一
            await this.otherWindowClose();
            this.activeWindowMediaMsg = {...item};
            this.sendCommand('windowMedia', componentCommand, item.url);
        },
        //设置当前window iframe
        async setWindowIframe(item, componentCommand) {
            await this.otherWindowClose();
            this.activeWindowIframeMsg = {...item};
            this.sendCommand('windowIframe', componentCommand, item.url);
        },
        //windowMedia，windowGeneralChart和windowCustomChart是互斥的，只能三选一
        async otherWindowClose() {
           return new Promise(async (resolve, reject) => {
                if (Object.keys(this.activeWindowMediaMsg).length > 0) {
                    this.sendCommand("windowMedia", "hide");
                    this.activeWindowMediaMsg = {};
                    await sleep(400);
                    resolve();
                }
                if (Object.keys(this.activeWindowIframeMsg).length > 0) {
                    this.sendCommand("windowIframe", "hide");
                    this.activeWindowIframeMsg = {};
                    await sleep(400);
                    resolve();
                }
                if (Object.keys(this.activeWindowGeneralChartMsg).length > 0 || Object.keys(this.activeWindowCustomChartMsg).length > 0) {
                    this.sendCommand("chartSwitcher", "hide");
                    this.activeWindowGeneralChartMsg = {};
                    this.activeWindowCustomChartMsg = {};
                    await sleep(400);
                    resolve();
                }
                resolve();
            });
        },
        // logo下屏
        logoDownScreen() {
            if (Object.keys(this.activeLogoMsg).length > 0) {
                this.sendCommand('logoSwitcher', 'hide')
                this.activeLogoMsg = {};
            }
        },
        // 直播间信息下屏
        msgDownScreen() {
            if (Object.keys(this.activeMsg).length > 0) {
                this.sendCommand('multiFuncBar', 'hide')
                this.activeMsg = {};
            }
        },
        screenDownScreen() {
            if (Object.keys(this.activeScreen).length > 0) {
                this.sendCommand('marketMarquee', 'hide')
                this.activeScreen = {};
            }
        },
        // 素材下屏
        materialDownScreen() {
            if (Object.keys(this.activeWindowMediaMsg).length > 0) {
                this.sendCommand('windowMedia', 'hide')
                this.activeWindowMediaMsg = {};
            }
            if (Object.keys(this.activeWindowIframeMsg).length > 0) {
                this.sendCommand('windowIframe', 'hide')
                this.activeWindowIframeMsg = {};
            }
            if (Object.keys(this.activeWindowGeneralChartMsg).length > 0) {
                this.sendCommand('chartSwitcher|generalChart', 'hide')
                this.activeWindowGeneralChartMsg = {};
            }
            if (Object.keys(this.activeWindowCustomChartMsg).length > 0) {
                this.sendCommand(orderObj[this.activeWindowCustomChartMsg.materialName.split('-')[0]], 'hide')
                this.activeWindowCustomChartMsg = {};
            }
            this.$bus.$emit('clearMaterialActive')
        },
        currentComponent() {
            if (['materialBackground', 'component'].indexOf(this.isActive) !== -1) {
                return LiveSet
            }
            if (['materialPhoto', 'materialNumPic', 'materialMessage', 'materialTool'].indexOf(this.isActive) !== -1) {
                return PublishMaterial
            }
            if (this.isActive === 'my_material') {
                return MyMaterial
            }
            if (this.isActive === 'common_material') {
                return CommonMaterial
            }
        },
        initPage() {
            this.isConnected = false
            // websocket连接定时器
            if (initTimer) clearInterval(initTimer)
            // 下屏
            this.logoDownScreen()
            this.msgDownScreen()
            this.materialDownScreen()
            // 停止虚拟摄像头
            this.$bus.$off('upScreen');
            this.$bus.$off('downScreen')
        }
    },
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>

