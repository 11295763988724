<template>
    <div class="colorPicketBox" ref="colorPicketBox">
        <div ref="move">
            <h3>画笔工具</h3>
            <i class="el-icon-close" @click="changeColorPicker"></i>
        </div>
        <div>
            <span>工具</span>
            <el-radio-group v-model="mode" @change="changeMode" size="medium">
                <el-radio-button
                    v-for="(item, index) in tooArr"
                    :label="item.value"
                    :key="index"
                >
                    <img :src="item.img" :alt="item.label" class="modeImg" :title="item.label">
                </el-radio-button>
            </el-radio-group>
        </div>
        <div>
            <span>粗细</span>
            <el-radio-group v-model="size" @change="changeSize" size="medium">
                <el-radio-button
                    v-for="(item, index) in ThicknessArr"
                    :label="item.value"
                    :key="index"
                >
                <span :style="{width: `${item.value + 14}px`, height: `${item.value + 14}px`}" class="sizeSpan" :title="item.label"></span>
                </el-radio-button>
            </el-radio-group>
        </div>
        <div>
            <span>颜色</span>
            <el-radio-group v-model="color" @change="changeColor" size="medium">
                <el-radio-button
                    v-for="(item, index) in colorArr"
                    :label="item.value"
                    :key="index"
                >
                    <span :style="{background: item.value}" class="colorSpan" :title="item.label"></span>
                </el-radio-button>
            </el-radio-group>
        </div>
        <div class="operation">
            <img @click="undo" src="@/assets/images/colorPicket/back.svg" alt="上一步" title="上一步">
            <img @click="redo" src="@/assets/images/colorPicket/back.svg" alt="下一步" title="下一步">
            <img @click="clear" src="@/assets/images/colorPicket/delete.svg" alt="删除" title="删除">
        </div>
    </div>
</template>
<script>
import arrow from '@/assets/images/colorPicket/arrow.svg'
import pencil from '@/assets/images/colorPicket/pencil.svg'
import line from '@/assets/images/colorPicket/line.svg'
import square from '@/assets/images/colorPicket/square.svg'
import fillSquare from '@/assets/images/colorPicket/fillSquare.svg'
import circle from '@/assets/images/colorPicket/circle.svg'
import font from '@/assets/images/colorPicket/font.svg'
export default {
    data: function() {
        return {
            mode: 'pencil',
            size: 2,
            color: 'red',
            tooArr: [
                // {
                //     label: '橡皮擦',
                //     value: 'eraser',
                //     img: '',
                // },
                {
                    label: '铅笔',
                    value: 'pencil',
                    img: pencil,
                },
                {
                    label: '直线',
                    value: 'straightLine',
                    img: line,
                },
                {
                    label: '矩形',
                    value: 'rect',
                    img: square,
                },
                {
                    label: '填充矩形',
                    value: 'fillRect',
                    img: fillSquare,
                },
                {
                    label: '圆形',
                    value: 'circle',
                    img: circle,
                },
                {
                    label: '箭头',
                    value: 'arrow',
                    img: arrow,
                },
                {
                    label: '文字',
                    value: 'text',
                    img: font,
                },
            ],
            ThicknessArr: [
                {
                    label: '小',
                    value: 2,
                },
                {
                    label: '中',
                    value: 4,
                },
                {
                    label: '大',
                    value: 6,
                },
                {
                    label: '特大',
                    value: 8,
                },
            ],
            colorArr: [
                {
                    label: '红',
                    value: 'red',
                },
                {
                    label: '黄',
                    value: 'yellow',
                },
                {
                    label: '蓝',
                    value: 'blue',
                },
                {
                    label: '黑',
                    value: 'black',
                },
                {
                    label: '白',
                    value: 'white',
                },
            ],
        }
    },
    mounted() {
        this.drawTool()
    },
    methods: {
        // 给画笔工具绑定移动事件
        drawTool() {
            let drawDom = this.$refs.colorPicketBox
            let moveDom = this.$refs.move
            this.drag(drawDom, moveDom)
        },
        // 移动
        drag(drawDom, moveDom){
            let oldX, oldY, newX, newY;
            let winWidth = document.documentElement.clientWidth;
            let winHeight = document.documentElement.clientHeight;
            moveDom.onmousedown = function(e) {
                oldX = e.clientX;//记录初始光标相对于视窗坐标
                oldY = e.clientY;
                document.onmousemove = function(e){
                    newX = e.clientX;//获取当前新光标相对于视窗坐标
                    newY = e.clientY;
                    let styleLeft= parseInt(window.getComputedStyle(drawDom, null).getPropertyValue('left')) + newX - oldX
                    let styleTop = parseInt(window.getComputedStyle(drawDom, null).getPropertyValue('top')) + newY - oldY
                    if (styleLeft < 0) {
                        styleLeft = 0
                    }
                    if (styleLeft > winWidth - drawDom.offsetWidth) {
                        styleLeft = winWidth - drawDom.offsetWidth
                    }
                    if (styleTop < 0) {
                        styleTop = 0
                    } 
                    if (styleTop > winHeight - drawDom.offsetHeight) {
                        styleTop = winHeight - drawDom.offsetHeight
                    }
                    drawDom.style.left = styleLeft + 'px';//更新
                    drawDom.style.top = styleTop + 'px';
                    oldX = newX;//新坐标变为老坐标
                    oldY = newY;
                }
                document.onmouseup = function(){
                    document.onmousemove = null;
                    document.onmouseup = null;
                }
            }
        },
        changeColorPicker() {
            this.$emit('changeColorPicker')
        },
        // 清空画笔
        clear() {
            this.global.draw.clear()
        },
        undo() {
            this.global.draw.undo()
        },
        redo() {
            this.global.draw.redo()
        },
        // 选择工具
        changeMode() {
            this.global.draw.setMode(this.mode)
        },
        // 选择大小
        changeSize() {
            // 线条粗细
            this.global.draw.config('lineWidth', this.size)
            // 修改箭头大小
            this.global.draw.config('arrowSize', this.size + 12)
            // 修改文字大小
            this.global.draw.config('textFontSize', this.size + 14)
        },
        changeColor() {
            this.global.draw.config('lineColor', this.color)
            this.global.draw.config('textColor', this.color)
        },
    },
}
</script>
<style lang="less" scoped>
.colorPicketBox {
    position: absolute;
    z-index: 999;
    border-radius: 4px;
    background: #2A2A2D;
    top: 124px;
    left: 640px;
    padding: 10px 12px;
    color: #fff;
    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: move;
        border-bottom: 1px solid #363639;
        margin: 0 -12px;
        padding: 0 12px 10px 12px;
        > h3 {
            font-size: 16px;
            font-weight: bold;
        }
        > i {
            cursor: pointer;
            font-size: 16px;
        }
    }
    > div:not(:first-child) {
        display: flex;
        align-items: center;
        margin-top: 18px;
        > span {
            // font-size: 16px;
           
            margin-right: 12px;
        }
        /deep/ .el-radio-button__inner {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px;
            margin-right: 12px;
            border-radius: 2px;
            border: 0;
            background: unset;
            .colorSpan {
                width: 100%;
                height: 100%;
            }
            .sizeSpan {
                background: #fff;
                border-radius: 50%;
            }
            .modeImg {
                width: 24px;
                height: 24px;
            }
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background: rgba(93,111,144,1);
            // border: 1px solid #4E8CFF;
            box-shadow: unset
        }
    }
    .operation {
        margin-left: 40px;
        > img {
            cursor: pointer;
            width: 24px;
            &:nth-child(2) {
                transform: scaleX(-1);
                margin: 0 22px;
            }
        }
    }
}
</style>