import axios from "axios";

// let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

/*=====================诊断-价值评估=========================*/
export function getDiagnosisValueData(options) {
  return new Promise(async (resolve) => {
    const typeObj = {
      市盈率: 8,
      市净率: 9,
      营收: 1,
      净利润: 2,
      净资产收益率: 3,
      每股收益: 4,
      总资产: 5,
      资产负债率: 6,
      经营活动现金净额: 7,
    };
    // let data = tempHistory["diagnosisValueChart" + options.seccode];
    // if (!data) {
    //   data = await ajaxConsultStock({ seccode: options.seccode, type: typeObj[options.type] });
    //   tempHistory["diagnosisValueChart" + options.seccode] = data;
    // }
    let data = await ajaxConsultStock({ seccode: options.seccode, type: typeObj[options.type] });
    data = JSON.parse(data.data.content).vtFinDateRst[0].stFinDate;
    let finalData = getFinallData(data, options);
    const legends = getLegends(options);
    const date = getDate(finalData);
    const result = {
      pageTitle: `${options.secname}【${options.type}】排名`,
      data: finalData,
      legend: legends,
      desc: data.sFinanceDesc,
      date: date,
    };
    resolve(result);
  });
}
function getFinallData(data, options) {
  let result = [];
  data.vtDateValue.map((item) => {
    data.vtDateValueAvg.map((cell) => {
      if (item.sYearMonth == cell.sYearMonth) {
        let value1 = item.fValue;
        let value2 = cell.fValue;
        if (["营收", "净利润", "总资产", "经营活动现金净额"].indexOf(options.type) > -1) {
          value1 = 1 * (value1 / 100000000).toFixed(2);
          value2 = 1 * (value2 / 100000000).toFixed(2);
        }
        result.push({
          label: item.sYearMonth,
          value1: value1,
          value2: value2,
        });
      }
    });
  });
  return result.reverse();
}
function getDate(data) {
  const latestItem = data.slice(-1)[0].label;
  const year = latestItem.substring(0, 4);
  const month = 1 * latestItem.substr(5, 2);
  const reportObj = {
    3: "一季报",
    6: "中报",
    9: "三季报",
    12: "年报",
  };
  return `数据来自${year}年度${reportObj[month]}`;
}
function getLegends(options) {
  const legendObj = {
    市盈率: "%",
    市净率: "%",
    营收: "亿元",
    净利润: "亿元",
    净资产收益率: "%",
    每股收益: "元",
    总资产: "亿元",
    资产负债率: "亿元",
    经营活动现金净额: "亿元",
  };
  return [`${options.type}(${legendObj[options.type]})`, "行业平均值"];
}

//获取有财报数据的日期列表
async function ajaxConsultStock(options) {
  return await axios.post(
    "https://sec.wedengta.com/GetConsultStock?action=getFinanceDetail",
    JSON.stringify({
      vtFinDateInfo: [{ sDtSecCode: options.seccode, eFinType: options.type }],
    })
  );
}
