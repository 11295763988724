import axios from "axios";
import moment from "moment";

export function getNuggetsData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}

function getPageTitle(options) {
    let title = ""
    if (options.type === '定增优选') {
        switch (options.subType) {
            case 2:
                title = `${options.type}-增发掘金`
                break;
            case 4:
                title = `${options.type}-破发套利`
                break;
            default:
                title = options.type
        }
    } else if (options.type === '定增破发') {
        switch (options.subType) {
            case 1:
                title = '定增解禁'
                break;
            case 3:
                title = '定增破发'
                break;
            default:
                title = `定增破发-${options.sec.secname}`
        }
    } else if (options.type === '定增计划') {
        if (options.sec) {
            title = `定增计划-${options.sec.secname}-${options.subType}`
        } else {
            title = options.subType
        }
    }
    return title
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data = []
        if (options.type === '定增计划') {
            if (options.sec) {
                data = await ajaxAdditionDetail(options)
                data = JSON.parse(data.data.content)
                data = data.vAdditionDesc[0]
            } else {
                data = await ajaxAdditionList(options)
                data = JSON.parse(data.data.content)
                data = data.vAdditionDesc
            }
        } else if (options.type === '定增破发') {
            if (options.sec) {
                data = await ajaxAdditionLiftedDetail(options)
                data = JSON.parse(data.data.content)
                data = data.stAdditionStockLifted
            } else {
                data = await ajaxAdditionLiftedList(options)
                data = JSON.parse(data.data.content)
                data = data.vAdditionStockLifted
            }
        } else if (options.type === '定增优选') {
            data = await additionList(options)
        }
        resolve(data)
    })
}

function additionList(options) {
    return new Promise(async (resolve) => {
        let data = []
        if (options.subType === 2) {
            data = await ajaxAdditionList(options, options.subType)
            data = JSON.parse(data.data.content)
            data = data.vAdditionDesc
        }
        if (options.subType === 4) {
            data = await ajaxAdditionLiftedList(options, options.subType)
            data = JSON.parse(data.data.content)
            data = data.vAdditionStockLifted
        }
        resolve(data)
    })
}

// 个股列表详情
// async function ajaxSecInfo(value) {
//     return await axios.get(`https://sec.wedengta.com/getSecInfo?action=squote&seccode=${value}`)
// }
// 定增计划 2
async function ajaxAdditionList(options, eSortType) {
    return await axios.post(
        "https://news.wedengta.com/getData?action=getAdditionList",
        JSON.stringify({
            sStartId: !eSortType ? "" : undefined,
            sEndId: !eSortType ? "" : undefined,
            vSearchCondition: !eSortType ? [{
                sTopValue: moment().format('YYYY-MM-DD'),
                sBottomValue: moment().subtract(4, 'years').format("YYYY-MM-DD"),
            }, {
                eConditionType: 2,
                vValues: [options.subType],
            }] : undefined,
            eSortType: !eSortType ? 1 : eSortType,
            iStart: !eSortType ? 0 : undefined,
            bCountOnly: !eSortType ? false : undefined
        })
      );
}
// 定增破发 4
async function ajaxAdditionLiftedList(options, eSortType) {
    return await axios.post(
        "https://news.wedengta.com/getData?action=getAdditionLiftedList",
        JSON.stringify({
            iStart: !eSortType ? 0 : undefined,
            vSearchCondition: !eSortType ? [{
                eConditionType: 1,
                sTopValue: moment().format("YYYY-MM-DD"),
                sBottomValue: moment().subtract(1, 'years').format("YYYY-MM-DD"),
            }] : undefined,
            eSortType: !eSortType ? options.subType : eSortType, // 1|已解禁-解禁日期最近 2|未解禁-解禁日期最近 3|已解禁-破发比例最高
        })
      );
}
async function ajaxAdditionDetail(options) {
    return await axios.post(
        "https://news.wedengta.com/getData?action=getAdditionDetail",
        JSON.stringify({
            vDtSecCode: [options.sec.seccode]
        })
      );
}
async function ajaxAdditionLiftedDetail(options) {
    return await axios.post(
        "https://news.wedengta.com/getData?action=getAdditionLiftedDetail",
        JSON.stringify({
            sId: options.sec.seccode
        })
      );
}