<template>
    <div class="materialNumPic">
        <div class="filter">
            <ul>
                <li
                    v-for="item in filterArr"
                    :key="item.value"
                    @click="changeType(item.value)"
                    :class="[type === item.value ? 'active' : '']"
                >
                    {{ item.title }}
                </li>
            </ul>
            <el-input
                v-model="keyword"
                ref="keywordInput"
                placeholder="搜索工具素材"
                @change="searchList"
                suffix-icon="el-icon-search"
                clearable
                @clear="handleClear"
            >
            </el-input>
        </div>
        <div class="img_list">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <ResourceItem
                    :itemData="item"
                    :active="active"
                    @change-active="changeActive"
                    :markerVisible="false"
                    materialSource="工具"
                    v-for="(item, index) in data"
                    :key="index"
                />
            </div>
            <span>{{ loading ? '正在加载更多' : '暂无更多'}}</span>
        </div>
    </div>
</template>
<script>
import ResourceItem from '../../components/resourceItem/index.vue'

export default {
    data: function() {
        return {
            data: [],
            keyword: '',
            active: '',
            pageNum: 1,
            pageSize: 21,
            endPageNum: 1,
            loading: false,
            type: 'all',
            filterArr: [
                {
                   title: '全部',
                   value: 'all', 
                },
                {
                   title: '大盘',
                   value: '大盘', 
                },
                {
                   title: '板块',
                   value: '板块', 
                },
                {
                   title: '财报',
                   value: '财报', 
                },
                {
                   title: '个股',
                   value: '个股', 
                },
                {
                   title: '选股',
                   value: '选股', 
                },
                {
                   title: '榜单',
                   value: '榜单', 
                },
            ],
        }
    },
    components: {
        ResourceItem
    },
    computed: {
        disabled() {
            return this.pageNum >= this.endPageNum;
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        this.$bus.$on('clearMaterialActive', () => {
            this.active = ''
        })
    },
    methods: {
        changeType(value) {
            this.type = value
            this.searchList()
        },
        changeActive(value) {
            this.active = String(value)
        },
       getList(params) {
            this.loading = true;
            this.API.material.getList({
                materialClass: 0,
                materialType: 5,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                roomId: Number(this.$route.query.id),
                searchKeyword: this.keyword || undefined,
                materialTag: this.type === 'all' ? undefined : this.type,
                range: 0,
                ...params
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.data = this.data.concat(res.data.list);
                    // this.data.push({
                    //     addFlag: false,
                    //     createTime: 1653620595000,
                    //     creator: "all",
                    //     id: 24937,
                    //     materialClass: 0,
                    //     materialName: "策略选股",
                    //     materialTag: "个股",
                    //     materialType: 5,
                    //     outMaterialId: "24937",
                    //     outRemark: null,
                    //     remark: null,
                    //     status: 1,
                    //     url: "https://qiweiniu.com/tmp/dtcj-trunk/2d96J_1653986126749.png",
                    //     userId: "all",
                    // })
                    this.endPageNum = res.data.pages
                } else {
                    this.$message.error(res.message)
                }
                this.loading = false;
            })
        },
        // 滚动加载
        load() {
            if (!this.disabled) {
                this.pageNum += 1;
                this.getList();
            }
        },
        searchList() {
            this.pageNum = 1
            this.endPageNum = 1;
            this.data = []
            this.getList()
        },
        handleClear() {
             this.$refs.keywordInput.blur()
        },
    },
}
</script>
<style lang="less" scoped>
.materialNumPic {
    height: 100%;
    // .input {
    //     padding: 0 24px 0 14px;
    //     box-sizing: border-box;
    //     /deep/ .el-input__inner {
    //         background: rgb(32,32,32);
    //         border-color: rgba(255, 255, 255, 0.08);
    //         color: #fff;
    //     }
    // }
    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 14px;
        margin-bottom: 14px;
        > ul {
            display: flex;
            align-items: center;
            flex: 2;
            // margin-bottom: 14px;
            // margin-left: 14px;
            > li {
                margin-right: 12px;
                color: #fff;
                cursor: pointer;
                padding: 2px 6px;
                &:hover {
                    background: rgba(78, 140, 255, 0.2);
                    border-radius: 4px;
                }
            }
            .active {
                background: rgba(78, 140, 255, 0.2);
                border-radius: 4px;
            }
        }
        > div {
            flex: 1;
            /deep/ .el-input__inner {
                background: rgb(32,32,32);
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
    .img_list {
        margin-top: 20px;
        padding-top: 2px;
        height: calc(100% - 60px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 0 15px;
            }
        }
        > span {
            color: #fff;
        }
    }
}
</style>