<template>
    <div class="bsSignal">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeFilterOne" />
            <div class="tool_one_filter" v-show="tool_filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { getCommonStockPlate } from '@/api/other'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_one_plus: '',
            tool_filter_two: '',
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    components: {
        SelectStock
    },
    methods: {
        //  行情&&板块
        async changeFilterOne(value) {
            this.tool_filter_one = value
            this.tool_filter_two = "盘中多空趋势"
            getCommonStockPlate({
                vDtSecCode: [this.tool_filter_one.value]
            }).then((res) => {
                if (res instanceof Object && res.vSecInfo.length > 0) {
                    this.tool_filter_one_plus = res.vSecInfo[0].stPlateInfo || {}
                }
            })
            this.changeFilterTwo()
        },
        changeFilterTwo() {
            if (!this.tool_filter_one) {
                return this.$message.info('请先选择股票！')
            }
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one.value : undefined,
                    secname: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one.label : undefined,
                    industrycode: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one_plus.sDtSecCode : undefined,
                    industryname: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one_plus.sPlateName : undefined,
                    type: this.tool_filter_two
                })
            }, 'show')
        },
        addGroup() {
            cutLive(document.getElementById('preview'), `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}`).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}`,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        seccode: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one.value : undefined,
                        secname: ['盘中多空趋势', '近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one.label : undefined,
                        industrycode: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one_plus.sDtSecCode : undefined,
                        industryname: ['行业盘中多空趋势', '行业近期多空趋势'].indexOf(this.tool_filter_two) !== -1 ? this.tool_filter_one_plus.sPlateName : undefined,
                        type: this.tool_filter_two
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.bsSignal {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        .tool_one_filter {
            margin-left: 40px;
            > h3 {
                color: #fff;
                margin-bottom: 6px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>