<template>
    <div class="message">
        <iframe :src="dataSource.newsUrl || dataSource.url" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        }
    },
}
</script>
<style lang="less" scoped>
.message {
    height: 600px;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.65);
    > iframe {
        width: 100%;
        height: 100%;
    }
}
</style>