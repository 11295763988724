import axios from "axios";
import moment from 'moment'
export function getStocksFundsData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return `${options.secname}${options.type}`
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data
        if (['主力增减仓', '资金流向'].indexOf(options.type) !== -1) {
            data = await ajaxCapitcalFlow(options)
            data = JSON.parse(data.data.content)
            if (options.type === '资金流向') {
                data = fundToday(data)
            } else {
                data = fiveDay(data)
            }
        }
        if (['买卖股数', '累计持仓比例与收盘价', '沪深股通股东信息', '净流入'].indexOf(options.type) !== -1) {
            if (options.type === '买卖股数') {
                data = await ajaxOpenStockInfo(options)
                data = JSON.parse(data.data.content)
                data = stockNetKline(data)
            } else if (options.type === '累计持仓比例与收盘价') {
                data = await ajaxOpenStockInfo(options)
                data = JSON.parse(data.data.content)
                data = holdRatioClosePriceKline(data)
            } else if (options.type === '沪深股通股东信息') {
                data = await ajaxPageStockHolderList(options)
                data = JSON.parse(data.data.content)
                data = data.vHolderInfo.slice(0, 10)
                for(let i=0,j=data.length;i<j;i++){
                    data[i].fHoldNumTxt = validConvert({
                        data:data[i].fHoldNum,
                        suffix:"股",
                        isConvertUnit:true,
                        precision:2,
                    });
                    data[i].fNetHoldNumTxt = validConvert({
                        data:data[i].fNetHoldNum,
                        suffix:"股",
                        isConvertUnit:true,
                        precision:2,
                    });
                }
            } else {
                data = await ajaxOpenStockInfo(options)
                data = JSON.parse(data.data.content)
                data = netBuyInfo(data)
            }
        }
        resolve(data)
    })
}
// 实时成交资金流向
function fundToday(data) {
    if (!data || data.vCapitalFlow.length === 0) {
        return {}
    } else {
        //开始画饼图
        let todayData =  data.vCapitalFlow[0];
        let sumNum=todayData.fBigin+todayData.fSmallin+todayData.fMidin+todayData.fSuperin+todayData.fBigout+todayData.fSmallout+todayData.fMidout+todayData.fSuperout;
        let biginPercent=Math.round((todayData.fBigin+todayData.fSuperin)/sumNum*100);
        let bigoutPercent=Math.round((todayData.fBigout+todayData.fSuperout)/sumNum*100);
        let smallinPercent=Math.round((todayData.fSmallin+todayData.fMidin)/sumNum*100);
        let smalloutPercent=Math.round((todayData.fSmallout+todayData.fMidout)/sumNum*100);
        let pieData= [
            {value:biginPercent, name:'主力流入', itemStyle:{normal:{label:{textStyle:{color:'#e64b4b'}},labelLine:{lineStyle:{color:'#e64b4b'}}}}},
            {value:smallinPercent, name :'散户流入', itemStyle:{normal:{label:{textStyle:{color:'#ff664d'}},labelLine:{lineStyle:{color:'#ff664d'}}}}},
            {value:smalloutPercent, name:'散户流出', itemStyle:{normal:{label:{textStyle:{color:'#4eb76c'}},labelLine:{lineStyle:{color:'#4eb76c'}}}}},
            {value:bigoutPercent, name:'主力流出', itemStyle:{normal:{label:{textStyle:{color:'#30a050'}},labelLine:{lineStyle:{color:'#30a050'}}}}}
        ];
        //流入流出三数字
        let mainFlowIn=((todayData.fBigin+todayData.fSuperin)/10000).toFixed(2); // 主力流入
        let mainFlowOut=((todayData.fBigout+todayData.fSuperout)/10000).toFixed(2); // 主力流出
        let pureFlow=(mainFlowIn-mainFlowOut).toFixed(2); // 净流入
        let huge = ((todayData.fSuperin-todayData.fSuperout)/10000).toFixed(2) // 超大单
        let big = ((todayData.fBigin-todayData.fBigout)/10000).toFixed(2) // 大单
        let middle = ((todayData.fMidin-todayData.fMidout)/10000).toFixed(2) // 中单
        let small = ((todayData.fSmallin-todayData.fSmallout)/10000).toFixed(2) // 小单
        return {
            pieData,
            mainFlowIn,
            mainFlowOut,
            pureFlow,
            huge,
            big,
            middle,
            small
        }
    }
}
//开始五日主力增减仓柱状图
function fiveDay(data) {
     if (!data || data.vCapitalFlow.length === 0) {
        return {}
    } else {
        let bardata = [];
        let vCapitalFlow = data.vCapitalFlow.reverse();
        for(let i=0;i<vCapitalFlow.length;i++){
            bardata.push({
                label: moment(vCapitalFlow[i].lTime*1000).format("MM-DD"),
                value: ((vCapitalFlow[i].fBigin+vCapitalFlow[i].fSuperin-vCapitalFlow[i].fBigout-vCapitalFlow[i].fSuperout)/10000).toFixed(2),
            });
        }
        return bardata
    }
}
// 买卖股数
function stockNetKline(data) {
    if (!data || data.vStockNetKline.length === 0) {
        return {}
    } else {
        data = data.vStockNetKline
        for(let i=0,j=data.length;i<j;i++){
            data[i].name = data[i].sDate;
            data[i].barValue = (data[i].fNetHoldStock).toFixed(2);
        }
        return data.reverse()
    }
}
// 累计持仓比例与收盘价
function holdRatioClosePriceKline(data) {
    if (!data || data.vHoldRatioClosePriceKline.length === 0) {
        return {}
    } else {
        data = data.vHoldRatioClosePriceKline
        for(let i=0,j=data.length;i<j;i++){
            data[i].label = data[i].sDate;
            data[i].line1 = data[i].fHoldRatio;
            data[i].line2 = data[i].fClosePrice;
        }
        return data.reverse()
    }
}
// 净流入记录
function netBuyInfo(data) {
    if (!data || data.vNetBuyInfo.length === 0) {
        return []
    } else {
        data = data.vNetBuyInfo
        for(let i=0,j=data.length;i<j;i++){
            data[i].fNetBuyTxt = validConvert({
                data:data[i].fNetBuy,
                isConvertUnit:true,
                precision:2,
            });
            data[i].fNetHoldStockTxt = validConvert({
                data:data[i].fNetHoldStock,
                suffix:"股",
                isConvertUnit:true,
                precision:2,
            });
            let time = new Date(data[i].sDate).getTime();
            data[i].sDate = moment(time).format('MM-DD');
        }
        return data
    }
}
function validConvert(options) {
    let result=options.data;
    let unit='';
    let isConvertUnit=options.isConvertUnit!=undefined?options.isConvertUnit:false;
    let isPrecisionOnZero=options.isPrecisionOnZero!=undefined?options.isPrecisionOnZero:false;
    let isConvertOnZero=options.isConvertOnZero!=undefined?options.isConvertOnZero:false;
    let isMinus=false;

    //只要看到空就转成--
    if(options.data=='' && options.data.length==0){
        return '--';
    }
    //需要转换的情况
    if(isConvertUnit){
        if(result<0){
            isMinus=true;
            result=Math.abs(result);
        }
        if(result>100000000){
            result=result/100000000;
            unit='亿';
        }else if(result>10000){
            result=result/10000;
            unit='万';
        }
        if(isMinus){
            result*=-1;
        }
    }
    if(options.precision){
        if(!isPrecisionOnZero && options.data==0){
            //
        }else{
            result=result.toFixed(options.precision);
        }
    }
    result=result+unit;
    if(isConvertOnZero && options.data==0 && !isPrecisionOnZero){
        result='--';
    }else if(options.suffix){
        result=result+options.suffix;
    }
    return result;
}
// 买卖股数
async function ajaxCapitcalFlow(options) {
    return await axios.get("https://sec.wedengta.com/getSecInfo?action=CapitcalFlow&count=5", {
        params: {
            seccode: options.seccode,
        },
    });
}

async function ajaxOpenStockInfo(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=hongkongstock&f=getOpenStockInfo&req=GetOpenStockInfoReq&rsp=GetOpenStockInfoRsp",
        JSON.stringify({
            sStockCode: options.seccode,
            sStockName: options.secname,
            eStockType: 1,
        })
      );
}
async function ajaxPageStockHolderList(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=hongkongstock&f=getPageStockHolderList&req=GetPageStockHolderListReq&rsp=GetPageStockHolderListRsp",
        JSON.stringify({
            sStockCode: options.seccode,
            sStockName: options.secname,
            iPageNum: 1,
        })
      );
}
