import axios from "axios";
let weekArr = ['一', '二', '三', '四', '五', '六', '日'];
export function getHistoryBackData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return `${options.secname}${options.type}`
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let finalData = {}
        if (options.type === '月份历史行情回看') {
            let monthArr = ['一月份','二月份','三月份','四月份','五月份','六月份','七月份','八月份','九月份','十月份','十一月份','十二月份']
            finalData = await ajaxHistory(options)
            finalData = JSON.parse(finalData.data.content)
            finalData = {
                data: convertData({
                    data: finalData,
                    objName1: 'stMonthQuota',
                    objName2: 'vMonthInfo',
                    name: 'iYear',
                    value: 'fRisePct'
                }),
                desc: setMonthDesc(finalData),
                date: monthArr[options.subType - 1]
            }
        } else if (options.type === '星期历史行情回看') {
            finalData = await ajaxWeekHistory(options)
            finalData = JSON.parse(finalData.data.content)
            finalData = {
                data: convertData({
                    data: finalData,
                    objName1: 'stWeekQuota',
                    objName2: 'vWeekInfo',
                    name: 'iWeek',
                    value: 'fAvgUpPct'
                }),
                desc: "最近" + finalData.iYearNum + "年的历史行情中，周" + weekArr[Number(options.subType) - 1] + "行情上涨概率为" + finalData.fAvgUpChancePct + "%，平均涨跌幅为" + finalData.fAvgUpPct + "%",
                date: `星期${weekArr[options.subType - 1]}`
            }
        } else if (options.type === '每年同期历史行情回看') {
            finalData = await ajaxSecPeriodHistory(options)
            finalData = JSON.parse(finalData.data.content)
            finalData = {
                data: convertData({
                    data: finalData,
                    objName1: 'stMonthQuota',
                    objName2: 'vMonthInfo',
                    name: 'iYear',
                    value: 'fRisePct'
                }),
                desc: '最近10年的历史行情中，每年同期上涨概率为'+finalData.fRiseChance+'%，平均涨跌幅为'+finalData.fRisePct+'%'
            };
        } else if (options.type === '历史阶段必涨股票') {
            finalData = await ajaxSecMonthTopUp(options)
            finalData = JSON.parse(finalData.data.content)
            finalData = {
                data: finalData.vItem,
                desc: finalData.vItem.length > 0 ? '最近10年的历史行情中，'+finalData.iMonth+'月份的必涨股票' : '最近10年的历史行情中，'+finalData.iMonth+'月份没有必涨股票'
            }
        } else {
            if (options.subType === '月份') {
                finalData = await ajaxPlateMonthTop(options)
                finalData = JSON.parse(finalData.data.content)
                finalData = {
                    data: finalData.vItem,
                    desc: '最近10年的历史行情中，'+finalData.iMonth+'月份的行业最佳表现个股',
                    date: '月份'
                }
            }
            if (options.subType === '星期') {
                finalData = await ajaxPlateWeekTop(options)
                finalData = JSON.parse(finalData.data.content)
                finalData = {
                    data: finalData.vItem,
                    desc: '最近10年的历史行情中，周'+weekArr[finalData.iWeekDay-1]+'的行业最佳表现个股',
                    date: '星期'
                }
            }
            if (options.subType === '同期') {
                finalData = await ajaxPlatePeriodTop(options)
                finalData = JSON.parse(finalData.data.content)
                finalData = {
                    data: finalData.vItem,
                    desc:  '最近10年的历史行情中，每年同期的行业最佳表现个股',
                    date: '同期'
                }
            }
        }
        resolve(finalData)
    })
}
function setMonthDesc(data) {
    let num = data.stMonthQuota.vMonthInfo.length;
    let month = data.iMonth;
    let riseNum = 0;    //上涨数
    let riseChance;     //上涨概率
    let totalUpPct = 0; //总涨幅
    let aveUpPct;       //平均涨跌幅
    for (let i = 0; i < num; i++) {
        if (data.stMonthQuota.vMonthInfo[i].fRisePct > 0) {
            riseNum += 1;
        }
        totalUpPct += data.stMonthQuota.vMonthInfo[i].fRisePct / 1;
    }
    riseChance = (riseNum / num * 100).toFixed(2) ;
    aveUpPct = (totalUpPct / num).toFixed(2);

    //得到上涨概率和平均涨跌幅的最高最低值
    let riseChangeArr=[],
        riseChangeMax,
        riseChangeMin,
        riseChangeTxt='',
        aveUpPctArr=[],
        aveUpPctMax,
        aveUpPctMin,
        aveUpPctTxt='';
    for (let i = 0; i < data.stAvgRiseChanceQuota.vAvgRiseChanceInfo.length; i++) {
        riseChangeArr.push(data.stAvgRiseChanceQuota.vAvgRiseChanceInfo[i].fRiseChance);
    }
    for (let i = 0; i < data.stAvgRiseQuota.vAvgRiseInfo.length; i++) {
        aveUpPctArr.push(data.stAvgRiseQuota.vAvgRiseInfo[i].fRisePct);
    }
    riseChangeMax=riseChangeArr.max().toFixed(2);
    riseChangeMin=riseChangeArr.min().toFixed(2);
    aveUpPctMax=aveUpPctArr.max().toFixed(2);
    aveUpPctMin=aveUpPctArr.min().toFixed(2);
    // console.log(aveUpPct,aveUpPctMax,aveUpPctMin)
    if(riseChance==riseChangeMax){
        riseChangeTxt='(最高)';
    }else if(riseChance==riseChangeMin){
        riseChangeTxt='(最低)';
    }
    if(aveUpPct==aveUpPctMax){
        aveUpPctTxt='(最高)';
    }else if(aveUpPct==aveUpPctMin){
        aveUpPctTxt='(最低)';
    }
    return "最近" + num + "年的历史行情中，" + month + "月份上涨概率为" + riseChance + "%"+riseChangeTxt+"，平均涨跌幅为" + aveUpPct +'%'+aveUpPctTxt
}
function convertData(options) { //options:  data  objName1 objName2   name  value
    let data = [];
    let name;
    let value;
    let lastYear=options.data[options.objName1][options.objName2][0].iYear;

    if (options.name == 'iYear') {
        for (let i = 10; i > 0; i--) { //数据补齐
            name = (lastYear) - (10 - i);
            if (options.data[options.objName1][options.objName2][(10 - i)] === undefined) { //如果第i个木有了
                value = 0;
            } else {
                value = options.data[options.objName1][options.objName2][(10 - i)][options.value];
                if (value === 0) {
                    value = 0.00001;
                }
            }
            data.push({
                name: name,
                value: value,
            });
        }
        data.reverse();
    } else if (options.name == 'iWeek') {
        let tempValue;
        for (let i = 0; i < options.data[options.objName1][options.objName2].length; i++) {
            tempValue = options.data[options.objName1][options.objName2][i][options.value];
            if (tempValue === 0) {
                tempValue = 0.00001;
            }
            data.push({
                name: '周' + weekArr[i],
                value: tempValue
            });
        }
    } else {
        for (let i = 12; i > 0; i--) {
            name = i + '月';
            if (options.data[options.objName1][options.objName2][(12 - i)] === undefined) { //如果第i个木有了
                value = 0;
            } else {
                value = options.data[options.objName1][options.objName2][(12 - i)][options.value];
                if (value === 0) {
                    value = 0.00001;
                }
            }
            data.push({
                name: name,
                value: value
            });
        }
        data.reverse();
    }
    return data;
}
// 月份历史行情回看
async function ajaxHistory(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getHistory',
            month: options.subType, // 1,2,3,4,5,6,7,8,9,10,11,12
            seccode: options.seccode,
        },
    });
}
// 星期历史行情回看
async function ajaxWeekHistory(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getWeekHistory',
            week: options.subType, // 1,2,3,4,5
            seccode: options.seccode,
        }
    });
}
// 每年同期历史行情回看
async function ajaxSecPeriodHistory(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getSecPeriodHistory',
            dt_sec_code: options.seccode
        }
    });
}
// 历史阶段必涨股票
async function ajaxSecMonthTopUp(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getSecMonthTopUp',
        }
    });
}
// 行业月份最佳个股
async function ajaxPlateMonthTop(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getPlateMonthTop',
            dt_sec_code: options.seccode
        }
    });
}
// 行业星期最佳个股
async function ajaxPlateWeekTop(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getPlateWeekTop',
            dt_sec_code: options.seccode
        }
    });
}
// 同期行业最佳个股
async function ajaxPlatePeriodTop(options) {
    return await axios.get("https://sec.wedengta.com/getHistory", {
        params: {
            action: 'getPlatePeriodTop',
            dt_sec_code: options.seccode
        }
    });
}

