import axios from "axios";
import { getUpdateDate } from "@/utils/utils.js";
import moment from "moment";
export function getCheckMarketData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let updateDate = await getDate(options);
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options),
                date: updateDate || '',
                data: finalData,
            };
            resolve(result);
        } catch (error) {
            reject(error)
        }
    })
}
function getDate(options) {
    return new Promise(async (resolve) => {
        let data = ''
        if (['市场强度','当日涨停强度','当日跌停强度','涨跌停走势'].indexOf(options.type) !== -1) {
            data = await getUpdateDate()
        }
        resolve(data)
    })    
}
function getPageTitle(options) {
    let beginTxt
    if (['当日涨停强度','当日跌停强度','涨跌停走势', '当日大盘预警', '当日市场资金', '当日市场趋势'].indexOf(options.type) !== -1) {
        beginTxt = options.type
    } else if (['市场强度'].indexOf(options.type) !== -1) {
        beginTxt = `${options.market}${options.type}`
    } else if (['涨停板','连续涨停','涨停打开','跌停板','连续跌停','跌停打开'].indexOf(options.type) !== -1) {
        beginTxt = `${options.time}${options.type}${options.market}TOP10`
    }
    return beginTxt
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data
        if (['当日涨停强度', '当日跌停强度'].indexOf(options.type) !== -1) {
            data = await getMaxMinStat(options)
        }
        if (['涨跌停走势', '市场强度'].indexOf(options.type) !== -1) {
            data = await getChangeStatDesc(options)
        }
        if (['涨停板','连续涨停','涨停打开','跌停板','连续跌停','跌停打开'].indexOf(options.type) !== -1) {
            data = await getStat(options)
        }
        if (['当日大盘预警'].indexOf(options.type) !== -1) {
            data = await getSecBsInfo(options)
        }
        if (['当日市场资金'].indexOf(options.type) !== -1) {
            data = await getMarketCapital(options)
        }
        if (['当日市场趋势'].indexOf(options.type) !== -1) {
            data = await getMarketTrend(options)
        }
        resolve(data)
    })
}
// 获取涨跌停强度统计数据
function getMaxMinStat(options) {
    return new Promise(async (resolve) => {
        let data = await ajaxMaxMinStat(options)
        data = JSON.parse(data.data.content)
        resolve({
            todayMaxLimit: data.stStatDescToday.vMaxLimit.length,
            todayMinLimit: data.stStatDescToday.vMinLimit.length,
            todayOpenMaxLimit: data.stStatDescToday.vOpenMaxLimit.length,
            todayOpenMinLimit: data.stStatDescToday.vOpenMinLimit.length,
            todayContiMaxLimit: data.stStatDescToday.vContiMaxLimit.length,
            todayContiMinLimit: data.stStatDescToday.vContiMinLimit.length,
            yesterdayMaxLimit: data.stStatDescYesterday.vMaxLimit.length,
            yesterdayMinLimit: data.stStatDescYesterday.vMinLimit.length,
            yesterdayOpenMaxLimit: data.stStatDescYesterday.vOpenMaxLimit.length,
            yesterdayOpenMinLimit: data.stStatDescYesterday.vOpenMinLimit.length,
            yesterdayContiMaxLimit: data.stStatDescYesterday.vContiMaxLimit.length,
            yesterdayContiMinLimit: data.stStatDescYesterday.vContiMinLimit.length,
        })
    })
}
// 获取涨跌停强度详细数据
function getStat(options) {
    const timeObj = {
        "当日": 'stStatDescToday',
        "昨日": 'stStatDescYesterday',
    }
    const typeObj = {
        "涨停板": 'vMaxLimit',
        "跌停板": 'vMinLimit',
        "连续涨停": 'vContiMaxLimit',
        "连续跌停": 'vContiMinLimit',
        "涨停打开": 'vOpenMaxLimit',
        "跌停打开": 'vOpenMinLimit',
    }
    return new Promise(async (resolve) => {
        let result = []
        if (options.market === '封单量') {
            result = [['名称代码', '最新价', '封单量']]
        } else if (options.market === '涨幅' || options.market === '跌幅') {
            result = [['名称代码', '最新价', '涨跌幅']]
        } else if (options.market === '回撤幅度') {
            result = [['名称代码', '最新价', '回撤幅度']]
        } else if (options.market === '拉升幅度') {
            result = [['名称代码', '最新价', '拉升幅度']]
        }
        let data = await ajaxMaxMinStat(options)
        data = JSON.parse(data.data.content)
        
        getTop10Data(options, data[timeObj[options.time]][typeObj[options.type]]).forEach((item, index) => {
            let itemData = getRateByData(item)
            result.push([
                `<div>${item.sSecName}</div><div>${item.sDtSecCode.substr(4,6)}</div>`,  // 股票名称
                itemData.fNow, // 最新价
                options.market === '涨幅' || options.market === '跌幅' ? itemData.sRate : getVolume(options, item), // 涨跌幅、封单量、回撤幅度、拉升幅度
            ])
        })
        resolve(result)
    })
}
// 排序
function getTop10Data(options, data) {
    let newData = []
    if (options.market === '涨幅' || options.market === '跌幅') {
        newData = data.map((item) => {
            return {
                ...item,
                compare1: setCompare1(item)
            }
        }).sort(compare('compare1')).slice(0, 10)
    } else if (options.type === '跌停打开') {
        newData = data.map((item) => {
            return {
                ...item,
                compare2: setCompare2(item)
            }
        }).sort(compare('compare2')).slice(0, 10)
    } else if (options.type === '涨停打开') {
        newData = data.map((item) => {
            return {
                ...item,
                compare3: setCompare3(item)
            }
        }).sort(compare('compare3')).slice(0, 10)
    } else if (options.market === '封单量') {
        newData = data.map((item) => {
            return {
                ...item,
                compare4: setCompare4(item)
            }
        }).sort(compare('compare4')).slice(0, 10)
    } else {
        newData = data.slice(0, 10)
    }
    return newData
}
function compare(p){ //这是比较函数
    return function(m,n){
        var a = m[p];
        var b = n[p];
        return b - a; //降序
    }
}

function setCompare1(item) {
    let fNow = item.fNow*1;
    let fClose = item.fClose;
    if(fNow/1 == 0 || isNaN(fNow)){
       return 0
    } else {
        return Math.abs((fNow-fClose)/fClose)
    }
}
function setCompare2(item) {
    return (item.fNow-item.fMin) / item.fMin * 100
}
function setCompare3(item) {
    return (item.fMax-item.fNow) / item.fMax * 100
}
function setCompare4(item) {
    if (item.fNow > item.fClose){
        return item.vBuyv[0]
    } else {
        return item.vSellv[0]
    }
}

function getVolume(options, liData) {
    if (options.type === '跌停打开') {
        let rate = ((liData.fNow-liData.fMin) / liData.fMin * 100).toFixed(2);
        return "+"+rate+"%"
    } else if (options.type === '涨停打开') {
        let rate = ((liData.fMax-liData.fNow) / liData.fMax * 100).toFixed(2);
        return "-"+rate+"%"
    } else {
        if (liData.fNow > liData.fClose){
            let closeVolume = (100*liData.vBuyv[0]);
            return getShowNumb(closeVolume/100)
        } else {
            let closeVolume = (100*liData.vSellv[0]);
            return getShowNumb(closeVolume/100);
        }
    }
}
function getShowNumb(data) {
    if(data>100000000){
        return (data/100000000).toFixed(2)+"亿手";
    }else if(data>10000){
        return (data/10000).toFixed(2)+"万手";
    }else{
        return data+"手";
    }
}
function getRateByData(liData) {
    let sRate = '';
        let className = '';
        let blockClassName = '';
        let classArrow='';
        let fNow = liData.fNow*1;
        let fClose = liData.fClose;
        let eSecStatus = liData.eSecStatus;
        let iTpFlag = liData.iTpFlag<2?2:liData.iTpFlag;
        //有停牌状态
        if(eSecStatus == "1"){
            sRate = '停牌';
            className = 'num_stop';
            blockClassName='block_stop';
            fNow = fClose.toFixed(iTpFlag);
        //没有停牌状态，但fnow为0，我们认为是集合竞价，显示--，fnow如不为0则正常显示
        }else{
            if(fNow/1 == 0 || isNaN(fNow)){
                sRate = '--';
                className = 'num_stop2';
                blockClassName='block_stop';
                fNow = '--';
            }else{
                let rate = (Math.abs((fNow-fClose)/fClose)*100).toFixed(2);
                if(fClose>fNow){
                    sRate = '-'+rate+'%';
                    className = 'num_fall';
                    blockClassName='block_fall';
                    classArrow='arrow_down';
                }else if(fClose==fNow){
                    sRate = rate+'%';
                    className = 'num_stop2';
                    blockClassName='block_stop';
                }else{
                    sRate = '+'+rate+'%';
                    className = 'num_rise';
                    blockClassName='block_rise';
                    classArrow='arrow_up';
                }
                fNow = fNow.toFixed(iTpFlag);
            }
        }
        return {
            fNow:fNow,
            sRate:sRate,
            className:className,
            blockClassName:blockClassName,
            classArrow:classArrow
        };
}

// 个股市场强度 && 涨跌停走势
function getChangeStatDesc(options) {
    return new Promise(async (resolve) => {
        let data = await ajaxChangeStatDesc(options)
        data = JSON.parse(data.data.content)
        if (['涨跌停走势'].indexOf(options.type) !== -1) {
            data = data.vChangeStatDesc
            resolve(convertToLimit(data))
        } else if (['市场强度'].indexOf(options.type) !== -1) {
            if (options?.market === 'ST') {
                data = data.vStChangeStatDesc
                resolve(convertToSTChartFormat(data[data.length - 1]))
            } else {
                data = data.vChangeStatDesc
                resolve(convertToChartFormat(data[data.length - 1]))
            } 
        }
    })
}
function convertToLimit(data) {
    let result = []
    for (let i = 0; i < data.length; i++) {
        if(data[i].sTime>='0930'){
            result.push({
                name:data[i].sTime.substr(0,2)+':'+data[i].sTime.substr(2),
                value1:data[i].iChangeMax,
                value2:data[i].iChangeMin
            })
        }
    }
    return result;
}
function convertToChartFormat(data) {
    let chartData=[
        {
            name:'跌停',
            value:data.iChangeMin,
            type:0
        },
        {
            name:'-10',
            value:data.iChange09,
            type:0
        },
        {
            name:'-9',
            value:data.iChange98,
            type:0
        },
        {
            name:'-8',
            value:data.iChange87,
            type:0
        },
        {
            name:'-7',
            value:data.iChange76,
            type:0
        },
        {
            name:'-6',
            value:data.iChange65,
            type:0
        },
        {
            name:'-5',
            value:data.iChange54,
            type:0
        },
        {
            name:'-4',
            value:data.iChange43,
            type:0
        },
        {
            name:'-3',
            value:data.iChange32,
            type:0
        },
        {
            name:'-2',
            value:data.iChange21,
            type:0
        },
        {
            name:'-1',
            value:data.iChange10,
            type:0
        },
        {
            name:'平',
            value:data.iChange00,
            type:1
        },
        {
            name:'1',
            value:data.iChange01,
            type:2
        },
        {
            name:'2',
            value:data.iChange12,
            type:2
        },
        {
            name:'3',
            value:data.iChange23,
            type:2
        },
        {
            name:'4',
            value:data.iChange34,
            type:2
        },
        {
            name:'5',
            value:data.iChange45,
            type:2
        },
        {
            name:'6',
            value:data.iChange56,
            type:2
        },
        {
            name:'7',
            value:data.iChange67,
            type:2
        },
        {
            name:'8',
            value:data.iChange78,
            type:2
        },
        {
            name:'9',
            value:data.iChange89,
            type:2
        },
        {
            name:'10',
            value:data.iChange90,
            type:2
        },
        {
            name:'涨停',
            value:data.iChangeMax,
            type:2
        }
    ];
    return chartData.reverse();
}
function convertToSTChartFormat(data) {
    let chartData=[
        {
            name:'跌停',
            value:data.iChangeMin,
            type:0
        },
        {
            name:'-5',
            value:data.iChange54,
            type:0
        },
        {
            name:'-4',
            value:data.iChange43,
            type:0
        },
        {
            name:'-3',
            value:data.iChange32,
            type:0
        },
        {
            name:'-2',
            value:data.iChange21,
            type:0
        },
        {
            name:'-1',
            value:data.iChange10,
            type:0
        },
        {
            name:'平',
            value:data.iChange00,
            type:1
        },
        {
            name:'1',
            value:data.iChange01,
            type:2
        },
        {
            name:'2',
            value:data.iChange12,
            type:2
        },
        {
            name:'3',
            value:data.iChange23,
            type:2
        },
        {
            name:'4',
            value:data.iChange34,
            type:2
        },
        {
            name:'5',
            value:data.iChange45,
            type:2
        },
        {
            name:'涨停',
            value:data.iChangeMax,
            type:2
        }
    ];
    return chartData.reverse();
}
function convertK(data) {
    let newData=[];
    for (let i = 0; i < data.length; i++) {
        //bs信号在最后一位，默认为0，后续合并的时候会修改它
        newData.push([data[i].lYmd,data[i].fOpen,data[i].fClose,data[i].fLow,data[i].fHigh,0]);
    }
    return newData;
}
function combileBsIntoK(bsData, kData) {
    let combinedData=kData;
    for (let i = 0; i < bsData.length; i++) {
        let bsDate=bsData[i].sDate;
        bsDate=bsDate.substr(0,4)+bsDate.substr(5,2)+bsDate.substr(8,2);
        for (let j = 0; j < combinedData.length; j++) {
            if(bsDate==combinedData[j][0]){
                if(bsData[i].iBs==1){
                    combinedData[j][5]=1;
                }else if(bsData[i].iBs==0){
                    combinedData[j][5]=2;
                }
            }
        }
    }
    return combinedData;
}
// 当日大盘预警
function getSecBsInfo() {
    return new Promise(async (resolve) => {
        let data = await ajaxSecBsInfo()
        data = JSON.parse(data.data.content)
        resolve({
            sTypeText: data.sTypeText,
            sDescText: data.sDescText,
            fValue: data.fValue,
        })
    })
}
// 当日市场趋势
function getMarketTrend() {
    return new Promise(async (resolve) => {
        let data = await ajaxMarketAlertDetail()
        data = JSON.parse(data.data.content)
        // let marketAlert = []
        let aIndex = []
        data.vDtMarketAlert.forEach((item) => {
            // if (item.iMakertAlert!=0) {
            //     marketAlert.push({
            //         value:item.iMakertAlert,
            //         iTime:new Date(item.sDate).getTime()
            //     });
            // }
            if (item.fAIndex!=0) {
                aIndex.push({
                    value:item.fAIndex,
                    iTime:new Date(item.sDate).getTime()
                });
            }
        })
        // marketAlert = marketAlert.reverse()
        aIndex = aIndex.reverse()
        if(aIndex[aIndex.length-1].value==0){
            aIndex.splice(-1);
        }
        let kData = await ajaxKLine()
        kData = JSON.parse(kData.data.content).vKLineDesc
        let stockKArray = convertK(kData)
        if (stockKArray.length > 3) {
            let bsData = await ajaxkMarketCapital(stockKArray[stockKArray.length-1][0]+'')
            bsData = JSON.parse(bsData.data.content).vSecBsInfo
            stockKArray = combileBsIntoK(bsData, stockKArray)
        }
        resolve({
            // marketAlert,
            kLine: stockKArray,
            aIndex: {
                data: aIndex,
                desc: '历史峰值: '+(data.stMaxDtAIndex.fMaxValue).toFixed(2)+'('+data.stMaxDtAIndex.sMaxDate.replace(/-/g,'.')+')'
            }
        })
    })
}
// 当日市场资金
function getMarketCapital() {
    return new Promise(async (resolve) => {
        let data = await ajaxMarketAlertDetail()
        data = JSON.parse(data.data.content)
        let aMarginTrade = []
        let aAHExtend = []
        data.vDtMarketAlert.forEach((item) => {
            if (item.fMarginTrade != 0) {
                aMarginTrade.push({
                    value:item.fMarginTrade,
                    iTime:new Date(item.sDate).getTime()
                })
            }
            if (item.fAHExtend != 0) {
                aAHExtend.push({
                    value:item.fAHExtend,
                    iTime:new Date(item.sDate).getTime()
                });
            }
        })
        aMarginTrade = aMarginTrade.reverse()
        if(aMarginTrade[aMarginTrade.length-1].value==0) {
            aMarginTrade.splice(-1);
        }
        aAHExtend = aAHExtend.reverse()
        resolve({
            aMarginTrade: {
                data: aMarginTrade,
                desc: '历史峰值: '+(data.stMaxMarginTrade.fMaxValue).toFixed(2)+'亿('+data.stMaxMarginTrade.sMaxDate.replace(/-/g,'.')+')'
            },
            aAHExtend: {
                data: aAHExtend,
                desc: '历史峰值: 净流入'+data.stMaxAHExtend.fMaxValue+'亿('+data.stMaxAHExtend.sMaxDate.replace(/-/g,'.')+')'
            }
        })
    })
}
// 市场涨跌分析
async function ajaxChangeStatDesc() {
    return await axios.post(
        "https://comm.wedengta.com/?s=statinfo&f=getChangeStatDesc&req=ChangeStatReq&rsp=ChangeStatRsp",
        JSON.stringify({
            iStart: 0,
            iWantNum: 250,
            isNeedAll: true
        })
      );
}
// 涨跌停强度
async function ajaxMaxMinStat() {
    return await axios.post(
        "https://comm.wedengta.com/?s=statinfo&f=getMaxMinStat&req=MaxMinStatReq&rsp=MaxMinStatRsp",
        JSON.stringify({})
    );
}
// 大盘预警
async function ajaxSecBsInfo() {
    return await axios.post(
        "https://comm.wedengta.com/?s=singal&f=getSecBsInfo&req=GetSecBsInfoReq&rsp=GetSecBsInfoRsp",
        JSON.stringify({
            sDtSecCode: '0105000001',
            sDate: '',
            iSize: 60,
        })
    );
}

async function ajaxkMarketCapital(date) {
    return await axios.post(
        "https://comm.wedengta.com/?s=singal&f=getSecBsInfo&req=GetSecBsInfoReq&rsp=GetSecBsInfoRsp",
        JSON.stringify({
            sDtSecCode: '2005888001',
            sDate: date,
            iSize: 60,
        })
    );                     
}
async function ajaxKLine() {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getKLine&req=KLineReq&rsp=KLineRsp",
        JSON.stringify({
            sDtSecCode: '2005888001',
            eKLineType: 4,
            iWantnum: 90,
        })
    );     
}
 async function ajaxMarketAlertDetail() {
    return await axios.post(
        "https://comm.wedengta.com/?s=marketAlert&f=getMarketAlertDetail&req=MarketAlertReq&rsp=MarketAlertRsp",
        JSON.stringify({
            iStart: 0,
            iSize: 300
        })
    );
 }