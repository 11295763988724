import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
export function getStockMineSweepData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let fullData = tempHistory[options.seccode]
            if (!fullData){
                fullData = await ajaxStockMineSweepInfo(options);
                fullData = JSON.parse(fullData.data.content);
                tempHistory[options.seccode] = fullData;
            }
            const finalData = {
                pageTitle: getTitle(options),
                data:handleStockMineSweep(fullData,options)
            };
            resolve(finalData); 
        } catch (error) {
            reject(error)
        }
    })
}

function getTitle(options){
    return options.secname+options.type
}

function handleStockMineSweep(data,options){    
    let chartData = [];
    let rangeObj={
        "风险评估":data.stTotalRisk,
        "财务风险":data.stFinanceRisk,
        "业绩风险":data.stPerformanceRisk,
        "质押风险":data.stPledgeRisk,
        "监管问询":data.stDisClosureRisk,
        "诉讼仲裁":data.stLitigateRisk,
        "商誉风险":data.stGoolwillRisk,
        "股东减持计划":data.stShareholderReduRisk,
        "会计所评估":data.stAuditRisk,
        "退市风险":data.stDelistRiskRisk
    }
    if(options.type=='风险评估'){
        chartData.push({
            sRiskLevelDesc:rangeObj[options.type].sRiskLevelDesc,
            vRiskItem:rangeObj[options.type].vRiskItem.slice(0,3),
            iMineNum:rangeObj[options.type].iMineNum,
            iRiskLevel:rangeObj[options.type].iRiskLevel,
            noData:'暂无数据'
        })
    }else if(options.type=='业绩风险'){
        chartData.push({
            sNoticeDay:rangeObj[options.type].sNoticeDay,
            sNoticeResult:rangeObj[options.type].sNoticeResult,
            sNoticeDesc:rangeObj[options.type].sNoticeDesc,
            iReportType:rangeObj[options.type].iReportType,
            noData:'暂无数据'
        })      
    }else if(options.type=='质押风险'){
        let item=[{
            name:"fOffsetStockNum",
            value:rangeObj[options.type].fOffsetStockNum/rangeObj[options.type].fTotalPledgeStockNum*100
        },{
            name:"fEarlyWarningStockNum",
            value:rangeObj[options.type].fEarlyWarningStockNum/rangeObj[options.type].fTotalPledgeStockNum*100
        },{
            name:"fUntouchWarningStockNum",
            value:rangeObj[options.type].fUntouchWarningStockNum/rangeObj[options.type].fTotalPledgeStockNum*100
        }]
        chartData.push({
            fTotalPledgeRatio:rangeObj[options.type].fTotalPledgeRatio,
            fTotalEquityRatio:rangeObj[options.type].fTotalEquityRatio,
            fTotalPledgeStockNum:rangeObj[options.type].fTotalPledgeStockNum,
            fOffsetStockNum:rangeObj[options.type].fOffsetStockNum,
            fEarlyWarningStockNum:rangeObj[options.type].fEarlyWarningStockNum,
            fUntouchWarningStockNum:rangeObj[options.type].fUntouchWarningStockNum,
            sRiskDesc:rangeObj[options.type].sRiskDesc,
            fRiskScore:rangeObj[options.type].fRiskScore,
            item:item,
            noData:'股东暂无股权质押行为'
        })      
    }else if(options.type=='监管问询'){
        chartData.push({
            vItem:rangeObj[options.type].vItem,
            sRiskDesc:rangeObj[options.type].sRiskDesc,
            iDisClosureNum:rangeObj[options.type].iDisClosureNum,
            noData:'公司暂未接到监管层问询'
        })      
    }else if(options.type=='诉讼仲裁'){
        chartData.push({
            vItem:rangeObj[options.type].vItem,
            sRiskDesc:rangeObj[options.type].sRiskDesc,
            iLitigateCasesNum:rangeObj[options.type].iLitigateCasesNum,
            noData:'近两年,公司没有涉及任何诉讼'
        })      
    }else if(options.type=='商誉风险'){
        chartData.push({
            fGoodwillValue: rangeObj[options.type].fGoodwillValue,
            fNetAssetsValue: rangeObj[options.type].fNetAssetsValue,
            fTotalAssetsValue: rangeObj[options.type].fTotalAssetsValue,
            fTotalMarketValue: rangeObj[options.type].fTotalMarketValue,
            sRiskDesc: rangeObj[options.type].sRiskDesc,
            noData:'暂时不存在商誉减值风险'
        })      
    }else if(options.type=='股东减持计划'){
        chartData.push({
            fHalfYearAccuReduAvgPriv: rangeObj[options.type].fHalfYearAccuReduAvgPriv,
            fHalfYearAccuReduRatio: rangeObj[options.type].fHalfYearAccuReduRatio,
            fHalfYearAccuReduStockNum: rangeObj[options.type].fHalfYearAccuReduStockNum,
            fReduMaxStockNum: rangeObj[options.type].fReduMaxStockNum,
            fReduMaxStockRatio: rangeObj[options.type].fReduMaxStockRatio,
            fTotalShareCapital: rangeObj[options.type].fTotalShareCapital,
            sReduDate: rangeObj[options.type].sReduDate,
            sReduEndDate: rangeObj[options.type].sReduEndDate,
            sReduStartDate: rangeObj[options.type].sReduStartDate,
            noData:'股东暂无减持计划'
        })      
    }else if(options.type=='会计所评估'){
        chartData.push({            
            sAuditResultDesc: rangeObj[options.type].sAuditResultDesc,
            sAuditResultSummary: rangeObj[options.type].sAuditResultSummary,
            noData:'标准无保留意见'
        })      
    }else if(options.type=='退市风险'){
        chartData.push({            
            iDelistCond: rangeObj[options.type].iDelistCond,
            sRiskDesc: rangeObj[options.type].sRiskDesc,
            vNetAssetsItem: rangeObj[options.type].vNetAssetsItem,
            vNetProfitItem: rangeObj[options.type].vNetProfitItem,
            vRevenueItem: rangeObj[options.type].vRevenueItem,
            noData:'暂未出现退市风险'
        })      
    }


    return chartData
}


async function ajaxStockMineSweepInfo(options){
    return await axios.post("https://comm.wedengta.com/?s=commredis&f=getStockMineSweepInfo&req=GetStockMineSweepInfoReq&rsp=GetStockMineSweepRedisRsp&commredis=0",
    JSON.stringify({
        sDtSecCode: options.seccode
    }))    
}