import axios from "axios";
import { getUpdateDate } from "@/utils/utils.js";

let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getEvaluatingDistChartData(options) {
  const typeObj = {
    估值详情: "getSecLastPEPB",
    "PE-TTM走势图(倍)": "getSecHisPEPB",
    "PB走势图(倍)": "getSecHisPEPB",
    所在行业估值分布: "getIndusSecPBROE",
    个股估值分布: "getValueModelDetail",
    个股估值回测: "getValueModelDetail",
    行业估值分布: "getValueIndustryDetail",
  };
  const functions = {
    getSecLastPEPB,
    getSecHisPEPB,
    getIndusSecPBROE,
    getValueModelDetail,
    getValueIndustryDetail,
  };
  return functions[typeObj[options.type]](options);
}

/*=====================估值详情=========================*/
export function getSecLastPEPB(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode + options.type];
    if (!fullData) {
      fullData = await ajaxSecLastPEPB(options);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.seccode + options.type] = fullData;
    }
    const finalData = {
      total: (fullData.fLastMValue / 100000000).toFixed(2) + "亿元",
      pe: fullData.fLastPE.toFixed(2) + "倍",
      pb: fullData.fLastPB.toFixed(2) + "倍",
    };
    const result = {
      pageTitle: getTitle(options),
      data: finalData,
    };
    resolve(result);
  });
}
function getTitle(options) {
  return options.secname + options.type;
}

//估值详情
async function ajaxSecLastPEPB(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=PortfolioSecNews&f=getSecLastPEPB&req=GetSecLastPEPBReq&rsp=GetSecLastPEPBRsp",
    JSON.stringify({
      sDtCode: options.seccode,
    })
  );
}

/*=====================pe，pb走势图=========================*/
export function getSecHisPEPB(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode + options.type];
    if (!fullData) {
      fullData = await ajaxSecHisPEPB(options);
      fullData = JSON.parse(fullData.data.content);
      //高于历史20%的日子
      let overData = await ajaxSecLastPEPB(options);
      overData = JSON.parse(overData.data.content);
      //把数据存起来
      fullData.overData = overData;
      tempHistory[options.seccode + options.type] = fullData;
    }
    let finalData = [];
    fullData.vHisVal.map((item) => {
      finalData.push({
        label: item.sDate.substr(0, 10),
        value1: item.fVal,
      });
    });
    const legendObj = {
      "PE-TTM走势图(倍)": "PE-TTM",
      "PB走势图(倍)": "PB",
    };
    const result = {
      pageTitle: getTitle(options),
      data: finalData.reverse(),
      desc: getDesc(finalData[finalData.length - 1].value1, fullData.overData, options),
      legend: [legendObj[options.type]],
    };
    resolve(result);
  });
}
function getDesc(nowValue, overData, options) {
  const descTargetObj = {
    "PE-TTM走势图(倍)": ["PE-TTM", "fPEOverHis"],
    "PB走势图(倍)": ["PB", "fPBOverHis"],
  };
  const descTarget = descTargetObj[options.type][0];
  const over = (overData[descTargetObj[options.type][1]] * 100).toFixed(0);
  return `当前${descTarget}为${nowValue.toFixed(2)}高于历史${over}%的日子`;
}
//pe，pb走势图
async function ajaxSecHisPEPB(options) {
  const valTypeObj = {
    "PE-TTM走势图(倍)": 0, // 0 pe,   1  pb
    "PB走势图(倍)": 1,
  };
  return await axios.post(
    "https://comm.wedengta.com/?s=PortfolioSecNews&f=getSecHisPEPB&req=GetSecHisPEPBReq&rsp=GetSecHisPEPBRsp",
    JSON.stringify({
      sDtCode: options.seccode,
      eValType: valTypeObj[options.type],
      eDuration: 1, //last one year
    })
  );
}

/*=====================行业估值分布=========================*/
export function getIndusSecPBROE(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode + options.type];
    if (!fullData) {
      fullData = await ajaxIndusSecPBROE(options);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.seccode + options.type] = fullData;
    }
    let finalData = [];
    fullData.vIndusSecPBROE.map((item) => {
      let temp = {
        label: item.fROE,
        dot: item.fPB,
        isHighlight: item.sDtCode == options.seccode,
        isAboveLine: item.fPB >= item.fROE * fullData.dLineSlope + fullData.dLineOrigin,
        tip: item.sSecName,
      };
      finalData.push(temp);
    });
    finalData = finalData.filter((item) => {
      return item.dot <= 10 && item.label >= 0;
    });
    let isHaveHighlight = false;
    finalData.map((item) => {
      if (item.isHighlight) {
        isHaveHighlight = true;
      }
    });
    isHaveHighlight = false;
    const noDataDesc = isHaveHighlight ? "" : `${options.secname}（${options.seccode}）远离该行业大部分个股，暂不展示`;
    const result = {
      pageTitle: getIndusTitle(options, fullData),
      data: {
        lineOrigin: fullData.dLineOrigin,
        lineSlope: fullData.dLineSlope,
        dataArr: finalData,
      },
      noDataDesc: noDataDesc,
      legend: ["低估区", "高估区", "PB-ROE均衡线"],
    };
    resolve(result);
  });
}
function getIndusTitle(options, fullData) {
  return `${options.secname}所在板块(${fullData.sIndusName})的行业估值分布图`;
}
async function ajaxIndusSecPBROE(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=PortfolioSecNews&f=getIndusSecPBROE&req=GetIndusSecPBROEReq&rsp=GetIndusSecPBROERsp",
    JSON.stringify({
      sDtCode: options.seccode,
    })
  );
}

/*=====================个股估值分布=========================*/
export function getValueModelDetail(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode + options.type + options.period + options.mode];
    if (!fullData) {
      fullData = await ajaxValueModelDetail(options);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.seccode + options.type + options.period + options.mode] = fullData;
    }
    // fNow或fAvg存在冲突的时候，优先使用fNow，fAvg
    if (fullData.fAvg == fullData.fNow) {
      fullData.fNow = fullData.fAvg;
    }

    //打分数据
    let scoreData = tempHistory[options.seccode + "scoreData"];
    if (!scoreData) {
      scoreData = await ajaxValueElasticityScore(options);
      scoreData = JSON.parse(scoreData.data.content).fScore_Integrity;
      tempHistory[options.seccode + "scoreData"] = scoreData;
    }

    //通过fMin和fMax，为vtNum数组里的元素创造name，value，percent
    const section = (fullData.fMax - fullData.fMin) / (fullData.vtNum.length - 1);
    let xAiasArr = [];
    for (var i = 0; i < fullData.vtNum.length; i++) {
      xAiasArr.push({
        name: 1 * (fullData.fMin + section * i).toFixed(2),
        value: fullData.vtNum[i],
        percent: ((getSum(fullData.vtNum.slice(0, i + 1)) / getSum(fullData.vtNum)) * 100).toFixed(2),
      });
    }

    //把均值和当前值写入第一个数据里
    xAiasArr[0].fNowIndex = getDataIndex(xAiasArr, fullData.fNow);
    xAiasArr[0].fNow = 1 * fullData.fNow.toFixed(2);
    xAiasArr[0].fAvgIndex = getDataIndex(xAiasArr, fullData.fAvg);
    xAiasArr[0].fAvg = 1 * fullData.fAvg.toFixed(2);
    xAiasArr[0].mode = options.mode;

    //xAiasArr里的name也需要改写
    xAiasArr[xAiasArr[0].fAvgIndex].name = xAiasArr[0].fAvg;
    xAiasArr[xAiasArr[0].fNowIndex].name = xAiasArr[0].fNow;

    //均线文字
    const descTitle = getDescTitle(options, xAiasArr);
    const risk = getRiskObj(xAiasArr[0].fNow, fullData.vtRiskThreshold);
    const riskSure = getRiskSureObj(scoreData);
    const descTxt = getDescTxt(options.mode, xAiasArr, risk.riskTxt);
    const traceDesc = getTraceDesc(options, xAiasArr);
    const trace = getTrace(fullData.vtValueBackTrace);

    const result = {
      pageTitle: getValueDetailTitle(options),
      data: xAiasArr,
      descTitle: descTitle,
      risk: risk,
      riskSure: riskSure,
      descTxt: descTxt,
      traceDesc: traceDesc,
      trace: trace,
    };
    resolve(result);
  });
}
function getTraceDesc(options, xAiasArr) {
  return `针对历史数据回测分析，若${options.mode}=${xAiasArr[0].fNow}，则后续走势规律有：`;
}

function getTrace(traceData) {
  let traceTable = [];
  traceTable.push(["时间周期", "3个月", "6个月", "1年", "2年"]);
  let percentArr = ["上涨概率"];
  let ariseArr = ["涨跌幅"];
  traceData.map((item) => {
    percentArr.push(item.fIncPro);
    ariseArr.push(item.fIncAvg);
  });
  traceTable.push(percentArr);
  traceTable.push(ariseArr);
  return traceTable;
}
function getDescTxt(mode, xAiasArr, riskTxt) {
  const percent = xAiasArr[xAiasArr[0].fNowIndex].percent;
  const now = xAiasArr[0].fNow;
  return `在上图选定的统计周期内，${percent}%的时间段${mode}<=${now}；结合历史估值数据统计分析，当前估值风险${riskTxt}`;
}
function getRiskSureObj(data) {
  const riskSureArr = [
    {
      riskSureTxt: "高",
      riskSureClass: "eva_very_sure",
    },
    {
      riskSureTxt: "较高",
      riskSureClass: "eva_normal_sure",
    },
    {
      riskSureTxt: "不适用",
      riskSureClass: "eva_not_sure",
    },
    {
      riskSureTxt: "--",
      riskSureClass: "eva_empty",
    },
  ];
  let resultIndex = 0;
  if (data >= 70) {
    resultIndex = 0;
  } else if (data > 60 && data < 70) {
    resultIndex = 1;
  } else if (data > 0) {
    resultIndex = 2;
  } else {
    resultIndex = 3;
  }
  return riskSureArr[resultIndex];
}
function getRiskObj(name, riskThreshold) {
  if (riskThreshold[5] == riskThreshold[4] && riskThreshold[4] == riskThreshold[3]) {
    riskThreshold[4] -= 0.1;
    riskThreshold[3] -= 0.2;
  } else if (riskThreshold[5] == riskThreshold[4]) {
    riskThreshold[4] -= 0.1;
  }
  const riskArr = [
    {
      riskTxt: "很低",
      riskClass: "eva_risk_very_low",
    },
    {
      riskTxt: "低",
      riskClass: "eva_risk_low",
    },
    {
      riskTxt: "较低",
      riskClass: "eva_risk_litter_low",
    },
    {
      riskTxt: "中性",
      riskClass: "eva_risk_middle",
    },
    {
      riskTxt: "较高",
      riskClass: "eva_risk_litter_high",
    },
    {
      riskTxt: "高",
      riskClass: "eva_risk_high",
    },
    {
      riskTxt: "很高",
      riskClass: "eva_risk_very_high",
    },
  ];
  let resultIndex;
  if (name < riskThreshold[0]) {
    resultIndex = 0;
  } else if (name >= riskThreshold[0] && name < riskThreshold[1]) {
    resultIndex = 1;
  } else if (name >= riskThreshold[1] && name < riskThreshold[2]) {
    resultIndex = 2;
  } else if (name >= riskThreshold[2] && name < riskThreshold[3]) {
    resultIndex = 3;
  } else if (name >= riskThreshold[3] && name < riskThreshold[4]) {
    resultIndex = 4;
  } else if (name >= riskThreshold[4] && name < riskThreshold[5]) {
    resultIndex = 5;
  } else if (name >= riskThreshold[5]) {
    resultIndex = 6;
  }
  return riskArr[resultIndex];
}

function getDescTitle(options, xAiasArr) {
  const now = xAiasArr[0].fNow;
  const avg = xAiasArr[0].fAvg;
  const gap = 1 * ((100 * (now * 1 - avg)) / avg).toFixed(2);
  const firstHalf = `当前${options.mode}=${now}，`;
  let lastHalf = "";
  if (gap == 0) {
    lastHalf = "和历史均值持平";
  } else {
    lastHalf = `比历史均值${gap > 0 ? "高" : "低"}${Math.abs(gap)}%`;
  }
  return firstHalf + lastHalf;
}
function getDataIndex(chartData, fNow) {
  let gapArr = [];
  for (var i = 0; i < chartData.length; i++) {
    gapArr.push(Math.abs(fNow - chartData[i].name));
  }
  const minValue = gapArr.min();
  return gapArr.indexOf(minValue);
}
function getSum(arr) {
  var sum = 0;
  for (var i = 0; i < arr.length; i++) {
    sum += arr[i] * 1;
  }
  return sum;
}
function getValueDetailTitle(options) {
  if (options.type == "个股估值分布") {
    return `${options.secname}估值分布(${options.period}\/${options.mode}模型)`;
  } else if (options.type == "个股估值回测") {
    return `${options.secname}估值回测(${options.period}\/${options.mode}模型)`;
  } else if (options.type == "行业估值分布") {
    return `${options.secname}行业估值分布(${options.period}\/${options.mode}模型)`;
  }
}

const typeObj = {
  PB: 2,
  综合估值系数: 3,
  "扣非PE-TTM": 1,
};
const periodObj = {
  "3年": 1,
  "5年": 2,
  "10年": 3,
  上市以来: 4,
};

async function ajaxValueModelDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=valueModel&f=getValueModelDetail&req=ValueModelReq&rsp=ValueModelRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      eType: typeObj[options.mode],
      ePeriod: periodObj[options.period],
    })
  );
}
async function ajaxValueElasticityScore(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=ValueElasticity&f=getValueElasticityScore&req=ValueElasticityScoreReq&rsp=ValueElasticityScoreRsp",
    JSON.stringify({
      sSecDtCode: options.seccode,
    })
  );
}

/*=====================行业估值分布=========================*/
export function getValueIndustryDetail(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.seccode + options.type + options.period + options.mode];
    if (!fullData) {
      let plateData = await ajaxSimpleQuote(options);
      plateData = JSON.parse(plateData.data.content).vSecSimpleQuote[0].stSecAttr.stPlateInfo.sDtSecCode;
      options.plateSeccode = plateData;
      fullData = await ajaxValueIndustryDetail(options);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.seccode + options.type + options.period + options.mode] = fullData;
    }

    //得到显示用的表格数据
    let finalData = [];
    const titleArr = ["公司名称", options.mode, "历史分布百分比"];
    const aveArr = [
      "行业平均",
      fullData.stValueCompanyAvg.fModelNowVal.toFixed(2),
      fullData.stValueCompanyAvg.fDistPer.toFixed(2) + "%",
    ];
    let bodyArr = [];
    const top10Obj = getTop10(fullData.vtValueCompany, options.seccode);
    top10Obj.top10.map((item) => {
      bodyArr.push([`${item.order}.${item.sSecName}`, item.fModelNowVal.toFixed(2), item.fDistPer.toFixed(2) + "%"]);
    });
    finalData.push(titleArr);
    finalData.push(aveArr);
    finalData = finalData.concat(bodyArr);

    const result = {
      pageTitle: getValueDetailTitle(options),
      data: finalData,
      highlightIndex: top10Obj.highlightIndex,
    };
    resolve(result);
  });
}
function getTop10(dataArr, seccode) {
  let result = [];
  //编上原始序号
  dataArr.map((item, index) => {
    let temp = item;
    temp.order = index + 1;
    result.push(temp);
  });
  //找到自己的index
  let highlightIndex = 0;
  result.map((item) => {
    if (item.sDtSecCode == seccode) {
      highlightIndex = item.order - 1;
    }
  });
  if (highlightIndex > 10) {
    const self = result[highlightIndex];
    result.splice(9, 0, self);
    highlightIndex = 9;
  }
  return {
    highlightIndex: highlightIndex,
    top10: result.slice(0, 10),
  };
}

async function ajaxSimpleQuote(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
    JSON.stringify({
      vDtSecCode: [options.seccode],
    })
  );
}
async function ajaxValueIndustryDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=valueModel&f=getValueIndustryDetail&req=ValueIndustryReq&rsp=ValueIndustryRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      sPlateCode: options.plateSeccode,
      eType: typeObj[options.mode],
      ePeriod: periodObj[options.period],
    })
  );
}

Array.prototype.max = function () {
  return Math.max.apply({}, this);
};
Array.prototype.min = function () {
  return Math.min.apply({}, this);
};
