import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
let similarKData
let predictData
let selfSimilarKData
let commonSelfData
export function getSimilarKData(options) {
    return new Promise(async (resolve, reject) => {
        try { 
            let fullData = await Promise.all([ajaxSimilarK(options),ajaxSimilarKPredict(options), ajaxSimilarK2(options)]); 
            console.log(fullData,'fullData');      
            similarKData = JSON.parse(fullData[0].data.content);
            predictData = JSON.parse(fullData[1].data.content);
            selfSimilarKData = JSON.parse(fullData[2].data.content);
            commonSelfData = selfSimilarKData.stSimilarKLineValue;           
            const finalData = {
                pageTitle: getTitle(options),
                data:handleAjaxSimilarK({
                    similarKData:similarKData,
                    predictData:predictData
                },options)
            };
            console.log(finalData,'finalData');
            resolve(finalData); 
        } catch (error) {
            reject(error)
        }
    })
}

function getTitle(options){
    return options.secname+options.type
}

function handleAjaxSimilarK(data,options){
    
    // console.log('预测数据',data.predictData);
    
    let predictLineData= handlePredictData(data.predictData);
    let RangeData=handlePredictRangeData(data.predictData)
    let predictRangeData=RangeData[0];     
    let predictMax = RangeData[1]; 
    let predictMin = RangeData[2]; 
    var _data = data.similarKData.stSimilarKLineValue;
    // console.log('相似原始数据',_data);

    let chartData
    let chartDesc
    let kData
    let similarsecname
    if(options.type=='模拟走势'){
        const listType={
            "二维模型":1,
            "四维模型":2,
            "六维模型":3,
            "30天":1,
            "60天":2,
            "50周":3,
            "沪深市场":1,
            "相同行业":2,
            "同档市值":3,
            "仅自己":4,
            "历史以来":1,
            "最近3年":2,
            "最近5年":3,
        }
        //处理base块
        kData=handleCommonBlock(_data.stSourceSimilarKLineRecord);
        var similarTypeTxtArr=['说明：采用股价和量能作为模型的分析因子','说明：在二维模型基础上增加趋势相关因子','说明：在四维模型基础上增加主力相关因子'];
        var scopeTypeTxtArr=['历史以来','最近3年内','最近5年内'];
        var timeTypeTxtArr=['沪深市场','相同行业','同档市值'];
        chartData ={kData:kData,predictLineData:predictLineData,predictRangeData:predictRangeData,predictMax:predictMax,predictMin:predictMin}
        chartDesc=similarTypeTxtArr[listType[options.similarType]-1]+',选取'+scopeTypeTxtArr[listType[options.scopeType]-1]+timeTypeTxtArr[listType[options.timeType]-1]+'的股票进行对比分析'
                                     
    }else if (options.type=='走势最像的股票'){
        //处理other similar块
        chartData=handleCommonBlock(_data.vSimilarKLineRecords[0]);
        similarsecname =_data.vSimilarKLineRecords[0].sSecName
        chartDesc="相似度:"+_data.vSimilarKLineRecords[0].sSimilarValue+"%"
    }else if (options.type=='最相似的历史阶段'){    
        //处理self similar块
        if(options.scopeType===1){ //沪深
            chartData=handleCommonBlock(_data.vSelfSimilarKLineRecord[0]);
            similarsecname =_data.vSelfSimilarKLineRecord[0].sSecName
            chartDesc="相似度:"+_data.vSelfSimilarKLineRecord[0].sSimilarValue+"%"
        }else{
            chartData=handleCommonBlock(commonSelfData.vSelfSimilarKLineRecord[0]);
            similarsecname =_data.vSelfSimilarKLineRecord[0].sSecName
            chartDesc="相似度:"+_data.vSelfSimilarKLineRecord[0].sSimilarValue+"%"
        }
    }
    return  {chartData:chartData,chartDesc:chartDesc,similarsecname:similarsecname}
}

function handlePredictData(data){
    var resultArr=[];
    for (var i = 0; i < data.stKLinePredictInfo.vtClose.length; i++) {
        resultArr.push({
            lYmd:100+i,
            dot:data.stKLinePredictInfo.vtClose[i],
        });
    }
    // console.log('预测线数据',resultArr);
    return resultArr;
}

function handlePredictRangeData(data){
    //找到6条topbest数组最多的那一条，以那一条为基准取值
    var topBestMaxLength=getTopBestMaxLength(data);
    var singleRangeArr=[];
    var rangeArr=[];

    for (var i = 0; i < topBestMaxLength; i++) {
        for (var j = 0; j < data.vKLineTopBest.length; j++) {
            if(data.vKLineTopBest[j].vtClose[i]!==undefined){
                singleRangeArr.push(data.vKLineTopBest[j].vtClose[i]);
            }
        }
        rangeArr.push({
            lYmd:100+i,
            high:singleRangeArr.max(),
            low:singleRangeArr.min()
        });
        singleRangeArr=[];
    }
    // console.log('预测范围数据',rangeArr);

    var minMaxArr=[];
    for (var i = 0; i < rangeArr.length; i++) {
        minMaxArr.push(rangeArr[i].high);
        minMaxArr.push(rangeArr[i].low);
    }
    let predictMax = minMaxArr.max();
    let predictMin = minMaxArr.min();

    return [rangeArr,predictMax,predictMin];
}

function getTopBestMaxLength(data){
    var topBestLengthArr=[];
    for (var j = 0; j < data.vKLineTopBest.length; j++) {
        topBestLengthArr.push(data.vKLineTopBest[j].vtClose.length);
    }
    return topBestLengthArr.max();
}

//通用处理块
function  handleCommonBlock(data){
    var _data=data;
    // console.log(data,'data');
    var dataArray=convertK(_data.vSimilarKLineDesc);
    let kdate=dataArray.map((item)=>{
        return {
            label: item[0],
            k: item.slice(1, 5)
        }
    })
    let subdata=dataArray.map((item)=>{
        return {
            label: item[0],
            volume: item[item.length - 1]
        }
    })

    return {kdate:kdate,subdata:subdata}
}

function convertK(data){
    var kData=[];
    for (var i = 0; i < data.length; i++) {
        kData.push([data[i].lYmd,data[i].fOpen,data[i].fClose,data[i].fLow,data[i].fHigh,getKSignedVolume(data[i].lVolume, data[i].fOpen, data[i].fClose)])
    }
    return kData
}

// K线的量获取正负号
function getKSignedVolume(volume, fOpen, fClose) {
    let sign;
    if(fOpen<=fClose){
        sign=1
    }else if(fOpen>fClose){
        sign=-1
    }
    return volume*sign;
  }

async function ajaxSimilarK(options){    
    const listType={
        "二维模型":1,
        "四维模型":2,
        "六维模型":3,
        "30天":1,
        "60天":2,
        "50周":3,
        "沪深市场":1,
        "相同行业":2,
        "同档市值":3,
        "仅自己":4,
        "历史以来":1,
        "最近3年":2,
        "最近5年":3,
    }
    return await axios.post("https://comm.wedengta.com/?s=similarKLine&f=getStrategySimilarKLine&req=StrategySimilarKLineReq&rsp=StrategySimilarKLineRsp",
    JSON.stringify({
        sDtSecCode: options.seccode,
        eSimilarType:listType[options.similarType],            //1二维   2四维  3六维
        eSimilarPeriod:listType[options.similarPeriod],        //1 30天   2 60天 3 50周
        eSimilarComparedScopeType:listType[options.scopeType], //1全a股  2全行业  3同档市值  4仅自己
        eSimilarTimeType:listType[options.timeType]            //1历史以来 3最近5年  2最近3年
    }))    
}

async function ajaxSimilarK2(options){    
    const listType={
        "二维模型":1,
        "四维模型":2,
        "六维模型":3,
        "30天":1,
        "60天":2,
        "50周":3,
        "沪深市场":1,
        "相同行业":2,
        "同档市值":3,
        "仅自己":4,
        "历史以来":1,
        "最近3年":2,
        "最近5年":3,
    }
    return await axios.post("https://comm.wedengta.com/?s=similarKLine&f=getStrategySimilarKLine&req=StrategySimilarKLineReq&rsp=StrategySimilarKLineRsp",
    JSON.stringify({
        sDtSecCode: options.seccode,
        eSimilarType:listType[options.similarType],            //1二维   2四维  3六维
        eSimilarPeriod:listType[options.similarPeriod],        //1 30天   2 60天 3 50周
        eSimilarComparedScopeType:1 //1全a股  2全行业  3同档市值  4仅自己
    }))    
}

async function ajaxSimilarKPredict(options){     
    const listType={
        "二维模型":1,
        "四维模型":2,
        "六维模型":3,
        "30天":1,
        "60天":2,
        "50周":3,
        "沪深市场":1,
        "相同行业":2,
        "同档市值":3,
        "仅自己":4,
        "历史以来":1,
        "最近3年":2,
        "最近5年":3,
    }
    return await axios.post("https://sec.wedengta.com/getSimilarKLine?action=getKLinePredict",
    JSON.stringify({
        sAppId: '',
        stKey:{
            // stUserInfo:{"vGUID":[-51,43,-37,-70,28,-125,-33,14,-31,-91,65,44,23,42,59,-29],"sDUA":"SN=IOSCJPH33_FA&VN=338033014&BN=0&VC=APPLE&MO=iPhone&RL=414_896&CHID=1000&LCID=0&RV=&OS=12.1.4&DV=V1","iAccountId":1362106,"sIMEI":"4DBE6036-F11B-4A54-908A-8FF2B8F6320D"},
            sDtSecCode:options.seccode,
            eSimilarType:listType[options.similarType],            //1二维   2四维  3六维
            eSimilarPeriod:listType[options.similarPeriod],        //1 30天   2 60天 3 50周
            eSimilarComparedScopeType:listType[options.scopeType], //1全a股  2全行业  3同档市值  4仅自己
            eSimilarTimeType:listType[options.timeType]            //1历史以来 3最近5年  2最近3年
        }
    }))    
}