<template>
    <div class="toolOperation" v-if="Object.keys(activeWindowCustomChartMsg).length > 0">
        <component
            v-bind:is="currentTool()"
            :activeWindowCustomChartMsg="activeWindowCustomChartMsg"
            @setCustomWindowChart="setCustomWindowChart"
        >
        </component>
    </div>
</template>
<script>
import Power from './power.vue'
import CapitalBidding from './capitalBidding.vue'
import MarketPlate from './marketPlate.vue'
import MarginTrade from './marginTrade.vue'
import LongHu from './longHu.vue'
import EvaluatingDist from './evaluatingDist.vue'
import Ipoinfo from './ipoinfo.vue'
import CheckMaster from './checkMaster.vue'
import BigTrade from './bigTrade.vue'
import ResultsContrast from './resultsContrast.vue'
import PlateArea from './plateArea.vue'
import IntelligentDiagnosis from './intelligentDiagnosis.vue'
import ChipDist from './chipDist.vue'
import InvestmentMap from './investmentMap.vue'
import CompanyIntro from './companyIntro.vue'
import Dupon from './duPon.vue'
import SharePledge from './sharePledge.vue'
import BsSignal from './bsSignal.vue'
import Nuggets from './nuggets.vue'
import FinanceAnalysis from './financeAnalysis.vue'
import KeChuangGraph from './keChuangGraph.vue'
import FinancingTrack from './financingTrack.vue'
import GoodwillAnalysis from './goodwillAnalysis.vue'
import HistoryBack from './historyBack.vue'
import LongHuStock from './longHuStock.vue'
import SimilarK from './similarK.vue'
import IntelliChooseStock from './intelliChooseStock.vue'
export default {
   props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        },
        setCustomWindowChart: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        Power,
        CapitalBidding,
        MarketPlate,
        MarginTrade,
        LongHu,
        EvaluatingDist,
        Ipoinfo,
        CheckMaster,
        BigTrade,
        ResultsContrast,
        PlateArea,
        IntelligentDiagnosis,
        ChipDist,
        InvestmentMap,
        CompanyIntro,
        Dupon,
        SharePledge,
        BsSignal,
        Nuggets,
        FinanceAnalysis,
        KeChuangGraph,
        FinancingTrack,
        GoodwillAnalysis,
        HistoryBack,
        LongHuStock,
        SimilarK,
        IntelliChooseStock,
    },
    methods: {
        currentTool() {
            let orderObj = {
                '行情': MarketPlate,
                '板块': MarketPlate,
                '北向资金': CapitalBidding,
                '南向资金': CapitalBidding,
                '竞价': CapitalBidding,
                '个股财报': Power,
                '财报比对': Power,
                '融资融券': MarginTrade,
                '龙虎榜': LongHu,
                '估值交易': EvaluatingDist,
                '新股中心': Ipoinfo,
                '市场强度': CheckMaster,
                '大宗交易': BigTrade,
                '财报比对(图)': ResultsContrast,
                '板块地域概念': PlateArea,
                '智能诊股': IntelligentDiagnosis,
                "筹码分布": ChipDist,
                "投资图谱": InvestmentMap,
                "个股百科": CompanyIntro,
                "杜邦分析": Dupon,
                "个股资金": IntelligentDiagnosis,
                "股权质押(个股)": SharePledge,
                "股权质押(大盘)": BigTrade,
                "多空信号": BsSignal,
                "定增掘金": Nuggets,
                "财务业绩": FinanceAnalysis,
                "扫雷": IntelligentDiagnosis,
                "榜单": BigTrade,
                "科创掘金": KeChuangGraph,
                "价值评估": IntelligentDiagnosis,
                "融资追击": FinancingTrack,
                "商誉分析(大盘)": GoodwillAnalysis,
                "商誉分析(个股)": IntelligentDiagnosis,
                "历史回看": HistoryBack,
                "龙虎榜(个股)": LongHuStock,
                "相似K线":SimilarK,
                "策略选股":IntelliChooseStock,
            }
            return orderObj[this.activeWindowCustomChartMsg.materialName]
        },
    },
}
</script>
<style lang="less" scoped>
.toolOperation {
    align-self: flex-start;
    display: flex;
    width: 100%;
}
</style>