<template>
  <div :class="`market_marquee_holder ${theme} }`">
    <div class="left_text" v-if="time1 && time2">
      <span>{{ time1 }}</span>
      <span>{{ time2 }}</span>
    </div>
    <div class="top_swiper" id="topSwiper" v-show="barTitle || barCareerNo || barRiskFree">
      <div class="swiper-wrapper">
        <div class="swiper-slide bar_title" v-if="barTitle">{{ barTitle }}</div>
        <div class="swiper-slide bar_career_no" v-if="barCareerNo">{{ barCareerNo }}</div>
        <div class="swiper-slide bar_risk_free" v-if="barRiskFree">{{ barRiskFree }}</div>
      </div>
    </div>
    <div class="marquee market_marquee hidden" id="marketMarquee">
      <div class="marquee-inner">
        <div class="marquee-content">
          <div :class="`single_market ${item.className}`" v-for="(item, index) in marketList" :key="index">
            <span>{{ item.name }}</span>
            <b>{{ item.fNow }}</b>
            <b>{{ item.sRate }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { sleep, setRem, randomNum, getRateByData } from "@/utils/utils.js";
import InfiniteMarquee from "infinite-marquee";
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
export default {
  props: ['defaultData'],
  data() {
    return {
      marketList: [], //当前市场行情
      isMarqueeInited: false, //默认marquee没有init
      isSwiperInited: false, //默认swiper没有init
      theme: "",
      barTitle: "", //标题
      barRiskFree: "", //免责
      barCareerNo: "", //职业编号
      time1: "",
      time2: "",
    };
  },
  async created() {
    this.theme = this.defaultData?.theme || "",
    this.barTitle = this.defaultData?.barTitle || "", //标题
    this.barRiskFree = this.defaultData?.barRiskFree || "", //免责
    this.barCareerNo = this.defaultData?.barCareerNo || "", //职业编号
    this.time1 = this.defaultData?.time1 || "",
    this.time2 = this.defaultData?.time2 || "",
    await this.start();
    this.$nextTick(() => {
      $("#marketMarquee").removeClass("hidden");
      this.initMarketMarquee();
      this.initSwiper();
    })
  },
  methods: {
    show(options) {
      if (options.time) {
        this.time1 = options.time.split("|")[0];
        this.time2 = options.time.split("|")[1];
      }
      this.theme = options.theme;
      this.barTitle = options.barTitle;
      this.barCareerNo = options.barCareerNo;
      this.barRiskFree = options.barRiskFree;
      $("#marketMarquee").removeClass("hidden");
      this.initMarketMarquee();
      this.initSwiper();
    },
    start() {
      return new Promise(async (resolve) => {
        const params = JSON.stringify({
          vDtSecCode: [
            "0105000001", //上证
            "0005399001", //深证成指
            "0005399006", //创业板指
            "2005888001", //灯塔指数
            "0005399005", //中小板指
            "0005399300", //沪深300
            "0105000016", //上证50
            "0105000905", //中证500
            "0105000010", //上证180
            "0105000009", //上证380
            "0005399106", //深证综指
            "0005399004", //深证100R
            "0105000003", //B股指数
            "0005399003", //成分B指
            "0105000011", //基金指数
          ],
        });
        let marketData = await axios.post(
          "https://comm.wedengta.com?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp&encode=1",
          params
        );
        marketData.data.vSecSimpleQuote.map((item) => {
          const tempObj = getRateByData(item);
          this.marketList.push({
            name: item.sSecName,
            fNow: item.fNow,
            sRate: tempObj.sRate,
            className: tempObj.className,
          });
        });
        resolve()
      })
    },
    initMarketMarquee() {
      if (this.isMarqueeInited) {
        return;
      }
      this.isMarqueeInited = true;
      new InfiniteMarquee({
        el: document.querySelector("#marketMarquee"),
        direction: "left",
        duration: 100,
        css: true,
      });
    },
    async initSwiper() {
      if (this.isSwiperInited) {
        return;
      }
      this.isSwiperInited = true;
      let swipeArr = [];
      if (this.barTitle != "") {
        swipeArr.push(this.barTitle);
      }
      if (this.barCareerNo != "") {
        swipeArr.push(this.barCareerNo);
      }
      if (this.barRiskFree != "") {
        swipeArr.push(this.barRiskFree);
      }
      if (swipeArr.length > 1) {
        this.$nextTick(() => {
          new Swiper("#topSwiper", {
            direction: "vertical",
            speed: 600,
            autoplay: {
              delay: 2800, //1秒切换一次
            },
            loop: true,
          });
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
@import "infinite-marquee/assets/css/infinite-marquee";
</style>
