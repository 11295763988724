<template>
    <div class="power_stock">
        <h3>{{ title }}</h3>
        <el-select
            v-model="stock_code"
            placeholder="请选择股票"
            filterable
            remote
            size="medium"
            :remote-method="searchStock"
            :loading="loading"
            @change="changeStock"
        >
            <el-option
                v-for="item in stockArr"
                :key="item.seccode"
                :label="item.secname"
                :value="item.seccode"
            >
            </el-option>
        </el-select>
    </div>
</template>
<script>
import { getStock } from '@/api/other'
import debounce from 'lodash.debounce'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: '股票',
        },
    },
    data: function() {
        return {
            stockArr: [],
            stock_code: '',
            loading: false,
        }
    },
     created() {
        this.changeStock = debounce(this.changeStock, 500)
        this.getStockSearchList()
    },
    methods: {
        getStockSearchList() {
            this.API.search.getStockSearchList().then((res) => {
                try {
                    if (res && res?.code === 200 && res.data.length > 0) {
                        this.stockArr = res.data.slice(0, 10).map((item) => {
                            let obj = JSON.parse(item.stockName)
                            return {
                                secname: obj.label,
                                seccode: obj.value,
                            }
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            })
        },
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        secname: item.sCHNShortName || item.sPlateName,
                        seccode: item.sDtSecCode,
                    }
                })
            }
        },
        searchStock(value) {
            this.loading = true
            getStock({
                key: value,
                type: 0, // 0|只返回A股， 1|A股、港美股， 2|返回a股、港美股、题材、板块、
            }).then((res) => {
                this.loading = false
                if (res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                    this.handleStockList(res.vStockItem)
                }
            })
        },
        changeStock(value) {
            let current = this.stockArr.filter(item => item.seccode === value)[0]
            this.$emit('individualStock', current)
            this.$emit('compareStockOne', current)
            this.$emit('compareStockTwo', current)
            this.API.search.reportStockSearch({
                stockName: JSON.stringify({
                    label: current.secname,
                    value: current.seccode
                })
            })
        },
    }
}
</script>
<style lang="less" scoped>
.power_stock {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;
    > h3 {
        color: #fff;
        margin-bottom: 8px;
    }
    /deep/ .el-select .el-input.is-focus .el-input__inner {
        border-color: rgba(255, 255, 255, 0.08);
    }
    /deep/ .el-input__inner {
        background: #202020;
        border-color: rgba(255, 255, 255, 0.08);
        color: #fff;
    }
}
</style>