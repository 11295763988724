<template>
    <div class="card_item">
        <div class="cover">
            <el-switch v-model="visible" @change="changeScreen" :disabled="switch_disabled" />
            <img :src="img_url" alt="" />
        </div>
        <div class="cover_name">
            <span>{{ name }}</span>
             <!-- 编辑 -->
            <div class="edit" @click="edit()" v-if="editVisible">
                <i class="el-icon-edit"></i>
                <span>编辑</span>
            </div>
        </div>
        <template v-if="name === '直播间LOGO'">
            <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
            <CropperDialog ref="cropper" :fixed="true" :nameControl="false" @receiveData="receiveData" />
        </template>
        <BarDialog ref="bar" v-if="name === '直播间信息'" :switch_visible="visible" @upDate="getRoomDetail" @downScreen="downScreen" />
        <MarketDialog ref="market" v-if="name === '行情滚动图'" @upDate="getRoomDetail" @downScreen="downScreen" />
    </div>
</template>
<script>
import debounce from 'lodash.debounce'
import CropperDialog from '@/components/cropperDialog'
import MarketDialog from './marketDialog.vue'
import BarDialog from './barDialog.vue'
import { sleep } from "@/utils/utils";

import LiveLogo from '../../../../../../../assets/images/detail/live_logo.svg'
import LiveTitle from '../../../../../../../assets/images/detail/live_title.svg'
import LiveMarket from '../../../../../../../assets/images/detail/live_market.svg'


export default {
    props: {
        name: {
            type: String,
            default: '',
        },
        authVisible: {
            type: Boolean,
            default: true,
        },
        editVisible: {
            type: Boolean,
            default: false
        }, // 是否显示编辑按钮
    },
    data: function() {
        return {
            room_detail: {},
            img_url: LiveLogo,
            visible: false,
            switch_disabled: true
        }
    },
    components: {
        CropperDialog,
        BarDialog,
        MarketDialog
    },
    async created() {
        this.getRoomDetail = debounce(this.getRoomDetail, 500)
        this.changeShowStatus = debounce(this.changeShowStatus, 500)
        this.room_detail = JSON.parse(localStorage.getItem('room_detail'))
        let value = this.room_detail.tools.filter(item => item.toolName === this.name)
        if (value.length > 0 && value[0].showStatus) {
            this.visible = true
            this.$bus.$emit('upScreen', this.room_detail, this.name)
        }
    },
    watch: {
        room_detail: function() {
           this.showImg()
           this.changeSwitchDisabled()
        }
    },
    methods: {
        // 判断当前组件是否可以上屏
        changeSwitchDisabled() {
            let value = Object.keys(this.room_detail).length > 0 ? this.room_detail.tools.filter(item => item.toolName === this.name) : [];
            if (value.length > 0) {
                this.switch_disabled = false
            }
        },
        showImg() {
            if (this.name === '直播间LOGO') {
                let tool_logo_arr = Object.keys(this.room_detail).length > 0 ? this.room_detail.tools.filter(item => item.toolName === '直播间LOGO') : [];
                if (tool_logo_arr.length > 0) {
                    try {
                        this.img_url = JSON.parse(tool_logo_arr[0].toolContent).logoUrl
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            if (this.name === '直播间信息') {
                this.img_url = LiveTitle
            }
            if (this.name === '行情滚动图') {
                this.img_url = LiveMarket
            }
        },
        edit() {
            if (this.name === '直播间LOGO') {
                this.uploadImg()
            }
            if (this.name === '直播间信息') {
                this.editBar()
            }
            if (this.name === '行情滚动图') {
                this.editMarket()
            }
        },
        changeScreen(bool) {
            if (this.authVisible) {
                if (bool) {
                    this.$bus.$emit('upScreen', this.room_detail, this.name)
                } else {
                    this.$bus.$emit('downScreen', this.name)
                }
                this.changeShowStatus(bool)
            } else {
                if (bool) {
                    this.$bus.$emit('upScreen', this.room_detail, this.name)
                    this.visible = false
                } else {
                    this.$bus.$emit('downScreen', this.name)
                    this.changeShowStatus(bool)
                }
            }
        },
        async downScreen() {
            if (this.visible) {
                this.$bus.$emit('downScreen', this.name)
                await sleep(1000)  
                this.$bus.$emit('upScreen', this.room_detail, this.name)
            }
        },
        changeShowStatus(bool) {
            const toolCode = this.getToolCode()
            let value = Object.keys(this.room_detail).length > 0 ? this.room_detail.tools.filter(item => item.toolName === this.name) : [];
            this.API.live.addMaterial({
                id: Number(this.$route.query.id),
                liveRoomTools: {
                    roomId: Number(this.$route.query.id),
                    toolCode,
                    toolName: this.name,
                    showStatus: bool ? 1 : 0,
                    toolContent: value.length > 0 ? value[0].toolContent : undefined,
                    id: value.length > 0 ? value[0].id : undefined
                },
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.getRoomDetail()
                }
            })
        },
        getToolCode() {
            switch (this.name) {
                case '直播间LOGO':
                    return 'liveLogo'
                case '直播间信息':
                    return 'liveMsg'
                case '行情滚动图':
                    return 'liveMarket'
                default:
                    return ''
            }
        },
        // 查询房间信息
        getRoomDetail() {
            this.API.live.getRoomDetail({
                roomId: Number(this.$route.query.id)
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.room_detail = res.data
                    localStorage.setItem('room_detail', JSON.stringify(res.data))
                }
            })
        },
        // 编辑直播间信息
        editBar() {
            let newValue = this.room_detail.tools.filter(item => item.toolName === '直播间信息')[0]
            if (newValue) {
                this.$refs.bar.open(JSON.parse(newValue.toolContent))
            } else {
                this.$refs.bar.open()
            }
        },
        // 编辑行情滚动图
        editMarket() {
            let newValue = this.room_detail.tools.filter(item => item.toolName === '行情滚动图')[0]
            if (newValue) {
                this.$refs.market.open(JSON.parse(newValue.toolContent))
            } else {
                this.$refs.market.open()
            }
        },
        // 直播间LOGO
        uploadImg() {
            this.$refs.fileInput.click()
        },
        batchImport(e) {
            const [file] = e.target.files;
            const checkSize = this.imgBeforeUpload(file)
            if (checkSize) {
                this.$refs.cropper.openDialog(file)
            }
             // 清空input的值
            this.$refs.fileInput.value = ''
        },
        //图片上传前，检查大小是否超出
        imgBeforeUpload(file) {
            const isFileSizeFit = file.size / 1024 / 1024 < 5;
            if (!isFileSizeFit) {
                this.$message.error("图片大小不能超过 5MB!");
            }

            return isFileSizeFit;
        },
        // 直播间logo信息修改
        receiveData(value) {
            this.API.live.addMaterial({
                id: Number(this.$route.query.id),
                liveRoomTools: {
                    roomId: Number(this.$route.query.id),
                    toolName: '直播间LOGO',
                    toolCode: 'liveLogo',
                    toolContent: JSON.stringify({
                        logoUrl: value.url,
                        logoName: value.name,
                    }),
                    showStatus: this.visible ? 1 : 0,
                },
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('编辑成功')
                    this.downScreen()
                    this.getRoomDetail()
                } else {
                    this.$message.error('编辑失败')
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.card_item {
    width: 216px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    .cover {
        height: 122px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        background: rgb(27, 27, 38);
        > img {
            height: 100%;
            width: auto;
        }
        .el-switch {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    .cover_name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px 10px;
        > span {
            flex: 1;
            margin-right: 16px;
            color: #262525;
            font-size: 14px;
            font-weight: bold;
        }
        .edit {
            cursor: pointer;
            color: #4E8CFF;
            &:hover {
                color: #66b1ff;
            }
            > i {
                margin-right: 2px;
            }
        }
    }
}
</style>