import axios from "axios";
import moment from "moment";
import { getUpdateDate } from "@/utils/utils.js";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
//新股中心数据
export function getIpoInfoData(options) {
    return new Promise(async (resolve) => {
      let finalData; 
      if (options.type == "新股申购") { 
        let data = tempHistory[options.type];
        if(!data){
          data = await ajaxipoStocks(options); 
          tempHistory[options.type] = data
        };
        // const data = await ajaxipoStocks(options);      
        finalData = handlePurchaseData(data);
      } else if (options.type == "等待上市") {  
        let data = tempHistory[options.type];
        if(!data){
          data = await ajaxipoStocks(options); 
          tempHistory[options.type] = data
        };   
        finalData = handleWaitData(data);
      } else if (options.type == "上市表现") { 
        let data = tempHistory[options.type];
        if(!data){
          data = await ajaxipoStocks(options); 
          tempHistory[options.type] = data
        };
        finalData = handleExpressionData(data);
      } else if (options.type == "公司简介"){
        let data = tempHistory[options.type+options.seccode];
        if(!data){
          data = await ajastocksInfo(options); 
          tempHistory[options.type] = data
        };  
        finalData = {
          pageTitle: getTitle(options),
          data: handleCompanyDescData(data),
        };
      } else if (options.type == "主营业务"){
        let data = tempHistory[options.type+options.seccode];
        if(!data){
          data = await ajastocksInfo(options); 
          tempHistory[options.type] = data
        };
        // const data = await ajastocksInfo(options);
        finalData = {
          pageTitle: getTitle(options),
          data: handleMainBusiData(data),
        };
      } else if (options.type == "基本信息"){
        let data = tempHistory[options.type+options.seccode];
        if(!data){
          data = await ajastocksInfo(options); 
          tempHistory[options.type] = data
        };
        // const data = await ajastocksInfo(options);
        finalData = {
          pageTitle: getTitle(options),
          data: handleBasicInfoData(data),
        };

      }      
      resolve(finalData);
    });
  }

function getTitle(options) {
  return options.secname + options.type;
}

function handlePurchaseData(data){
    const stocksData = JSON.parse(data.data.content).vInfo;

    const now_day = moment().format('YYYY-MM-DD') 
    const titleArr = ["股票名称","申购上限","发行价格", "申购日期"]; 
    let chartData = [];
    //数据筛选  日期大于等于今天
    stocksData.map((item, index)=>{     
        if (now_day<=item.sIPODate && item.eMarketType != 4){            
        chartData.push({
            sSecName:item.sSecName,            
            fAplCeil:item.fAplCeil/10000,
            fIssPrice:item.fIssPrice,
            sIPODate:item.sIPODate,
            // sIPOCode:item.sIPOCode,
            sDtSecCode:item.sDtSecCode
            })
        }
    });     
    
    chartData = chartData.slice(-10).reverse()
    for (var i=0;i<chartData.length;i++){
      if (chartData[i].fAplCeil== 0) {
         chartData[i].fAplCeil ='-----'
      } else{
          chartData[i].fAplCeil +='万股'
      }
      if (chartData[i].fIssPrice==0){
          chartData[i].fIssPrice ="-----"
      }
  }
    chartData.unshift(titleArr);
    const result = {
        pageTitle: "近期可申购新股",
        data: chartData,
      };
    return result;
}
  
function handleWaitData(data){
    const stocksData = JSON.parse(data.data.content).vInfo;
    const now_day = moment().format('YYYY-MM-DD')

    const titleArr = ["股票名称","发行价","中签率", "申购日期"];
    let chartData = [];    
    chartData.push(titleArr);
    //数据筛选  日期大于今天且排除北交所
    stocksData.map((item, index)=>{        

        if (now_day>item.sIPODate && item.eMarketType != 4){            
        chartData.push({
            sSecName:item.sSecName,
            fIssPrice:item.fIssPrice,
            fAplRatio:item.fAplRatio+'%',
            sIPODate:item.sIPODate,
            sDtSecCode:item.sDtSecCode
            })
        }
    });
    chartData = chartData.slice(0,11)
    const result = {
        pageTitle: "近期新股中签情况",
        data: chartData,
      };
    return result;
}

function handleExpressionData(data){
    const stocksData = JSON.parse(data.data.content).vInfo;

    const now_day = moment().format('YYYY-MM-DD')

    const titleArr = ["股票名称","连续涨停","每签获利", "上市日期"];
    let chartData = [];  
    //数据筛选  日期大于等于今天且排除北交所
    stocksData.map((item, index)=>{ 
        if (now_day>=item.sListDate && item.eMarketType != 4 && item.sListDate !=''){            
        chartData.push({
            sSecName:item.sSecName,
            iTopIncreaseDay:item.iTopIncreaseDay,
            fIncomePerSign:item.fIncomePerSign,          
            sListDate:item.sListDate,            
            iIncreseStatus:item.iIncreseStatus,
            sDtSecCode:item.sDtSecCode
            })
        }
    });
    chartData = chartData.slice(0,10)
    for (var i=0;i<chartData.length;i++){
        if (chartData[i].iTopIncreaseDay== 0) {
           chartData[i].iTopIncreaseDay ='-----'
        } else{
            chartData[i].iTopIncreaseDay +='天'
        }
        if (chartData[i].iIncreseStatus==1){
            chartData[i].iTopIncreaseDay +="(未开板)"
        }
        if (chartData[i].fIncomePerSign==0){
          chartData[i].fIncomePerSign ="-----"
        }
        delete chartData[i].iIncreseStatus
    }      
    chartData.unshift(titleArr);
    const result = {
        pageTitle: "近期上市新股表现",
        data: chartData,
      };
    return result;
}

function handleCompanyDescData(data){
  const companydesc = JSON.parse(data.data.content).stInfo.sCompanyDesc;
  const titleArr = ["公司简介"];
  let chartData = [];
  chartData.push(titleArr);
  chartData.push({companydesc:companydesc});
  return chartData;
}

function handleMainBusiData(data){
  const mainbusi = JSON.parse(data.data.content).stInfo.sMainBusi;
  const titleArr = ["主营业务"];
  let chartData = [];
  chartData.push(titleArr);
  chartData.push({mainbusi:mainbusi});
  return chartData;
}

function handleBasicInfoData(data){
  const basicinfo = JSON.parse(data.data.content).stInfo;
  const titleArr = ["基本信息"];
  for(const key in basicinfo){
    if (key =="fAplRatio" && basicinfo[key] !=""){
      basicinfo[key]=basicinfo[key]+'%'
    } else if (key == "fIssPrice" && basicinfo[key] !=""){
      basicinfo[key]=basicinfo[key]+'元'
    } else if ( key == "fShareNum" && basicinfo[key] !=""){
      basicinfo[key]=basicinfo[key]+'万股'
    } else if (key == "fPerCeil" && basicinfo[key] !=""){
      basicinfo[key]=basicinfo[key]+'倍'
    } else if (key =="fOnlineShareNum" && basicinfo[key] !="") {
      basicinfo[key]=basicinfo[key].toFixed(2)+'万股'
    } else if (key =="fAplCeil" && basicinfo[key] !="") {
      basicinfo[key]=basicinfo[key]+'股'
    } else if (key =="fAplFundCeil" && basicinfo[key] !=""){
      if (basicinfo[key]>=10000){
        basicinfo[key]=(basicinfo[key]/10000).toFixed(2)+'万元'
      }else{
        basicinfo[key]=basicinfo[key]+'元'
      }      
    }else if (basicinfo[key] ==""){
      basicinfo[key]="--"
    }

  }
  let chartData = [];
  chartData.push(titleArr);
  chartData.push({a:"股票代码",b:basicinfo.sDtSecCode.slice(4),c:"申购日期",d:basicinfo.sIPODate});
  chartData.push({a:"申购代码",b:basicinfo.sIPOCode,c:"缴款日期",d:basicinfo.sLotDeclDate});
  chartData.push({a:"中签率",b:basicinfo.fAplRatio,c:"上市日期",d:basicinfo.sListDate});
  chartData.push({a:"发行价格",b:basicinfo.fIssPrice,c:"发行市盈率",d:basicinfo.fPerCeil});
  chartData.push({a:"发行数量",b:basicinfo.fShareNum,c:"网上发行数量",d:basicinfo.fOnlineShareNum});
  chartData.push({a:"申购数量上限",b:basicinfo.fAplCeil,c:"申购资金上限",d:basicinfo.fAplFundCeil});
  return chartData;

}


  //获取新股中心的股票
async function ajaxipoStocks(options) {
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getIPOBasicInfo&req=GetIPOBasicInfoReq&rsp=GetIPOBasicInfoRsp");
  }


  //获取新股申购的股票信息
async function ajastocksInfo(options) {
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getIPODetailInfo&req=GetIPODetailInfoReq&rsp=GetIPODetailInfoRsp",
    JSON.stringify({
        sDtSecCode: options.seccode,
      }));
  }