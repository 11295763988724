<template>
    <div class="marginTrade">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterType()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['vtIndustryBuyValuePlate', 'vtPlateBuyValuePlate'].indexOf(tool_filter_one) !== -1">
                <h3>板块</h3>
                <el-radio-group v-model="tool_filter_two" size="small" @change="changePlateType()">
                    <el-radio-button
                        :label="item"
                        v-for="(item, index) in plateArr"
                        :key="index"
                    >
                        {{ item.sPlate }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool: {},
            plateArr: []
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeFilterType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                })
            }, 'show', (data) => {
                this.plateArr = data.data.data
            })
        },
        changePlateType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one === 'vtIndustryBuyValuePlate' ? 'vtIndustryBuyValue' : 'vtPlateBuyValue',
                    plate: this.tool_filter_two?.sPlate,
                    num: this.tool_filter_two?.sBuyValue,
                })
            }, 'show')
        },
        addGroup() {
            let type = this.tool.tooObj[this.activeWindowCustomChartMsg.materialName].filter(item => item.value === this.tool_filter_one)[0].label
            let name = `${this.activeWindowCustomChartMsg.materialName}-${type}${this.tool_filter_two ? `-${this.tool_filter_two.sPlate}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            type: this.tool_filter_two ? this.tool_filter_one === 'vtIndustryBuyValuePlate' ? 'vtIndustryBuyValue' : 'vtPlateBuyValue' : this.tool_filter_one,
                            plate: this.tool_filter_two ? this.tool_filter_two.sPlate : undefined,
                            num: this.tool_filter_two ? this.tool_filter_two.sBuyValue : undefined,
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                flex: 1;
                margin-right: 30px;
            }
            &:last-child {
                width: 46%;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
        }
    }
}
</style>