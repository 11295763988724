import axios from "axios";
import moment from "moment";
import { getUpdateDate } from "@/utils/utils.js";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
export function getGoodwillWarnData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let fullData = tempHistory[options.seccode]
            if (!fullData){
                fullData = await ajaxGoodwillWarn(options);
                fullData = JSON.parse(fullData.data.content);
                tempHistory[options.seccode] = fullData;
            }
            // console.log(fullData,'fullData');
            const finalData = {
                pageTitle: getTitle(options),
                data:handleGoodwillWarnData(fullData,options),
                date:fullData.sDate
            };
            // console.log(finalData,'finalData');
            resolve(finalData); 
        } catch (error) {
            reject(error)
        }
    })
}


function getTitle(options){
    return options.secname+options.type
}

function handleGoodwillWarnData(data,options){ 
    let returnData=[]
    if(options.type=='最新商誉信息'){ 
        returnData.push({a:"商誉(万元)",b:(data.fGoodwillSum/10000).toFixed(2)})
        returnData.push({a:"商誉总资产占比",b:(data.fTotalAssetRatio*100).toFixed(2)+'%'})
        returnData.push({a:"占比行业排名(总资产)",b:data.iTotalAssetRatioRank+'/'+data.iIndustryCompanyNum})
        returnData.push({a:"商誉净资产占比",b:(data.fNetAssetRatio*100).toFixed(2)+'%'})
        returnData.push({a:"占比行业排名(净资产)",b:data.iNetAssetRatioRank+'/'+data.iIndustryCompanyNum})
    }else if(options.type=='商誉净资产比例走势'){    
        let chartData = [];
        let mGoodwillSum = data.mGoodwillSum
        let mIndustryNetRatioAve = data.mIndustryNetRatioAve
        let mNetAssetRatio = data.mNetAssetRatio
        let label = Object.keys(mGoodwillSum)
        let value1 = Object.values(mGoodwillSum)
        let value2 = Object.values(mNetAssetRatio)
        let length = value2.length
        let value3 = Object.values(mIndustryNetRatioAve).slice(-length)
        console.log(value3,'value3');
        label.map((item,index)=>{
            chartData.push({
                label:label[index],
                line1:value1[index]/10000,
                line2:value2[index],
                line3:value3[index]
            })  
        })
        returnData = {chartData:chartData, legend:["个股商誉值(万)","商誉资产占比","行业平均占比"]}    
    }else if(options.type=='商誉总资产比例走势'){    
        let chartData = [];        
        let mGoodwillSum = data.mGoodwillSum        
        let mTotalAssetRatio = data.mTotalAssetRatio        
        let mIndustryTotalRatioAve = data.mIndustryTotalRatioAve        
        let label = Object.keys(mGoodwillSum)
        let value1 = Object.values(mGoodwillSum)
        let value2 = Object.values(mTotalAssetRatio)
        let length = value2.length
        let value3 = Object.values(mIndustryTotalRatioAve).slice(-length)
        label.map((item,index)=>{
            chartData.push({
                label:label[index],
                line1:value1[index]/10000,
                line2:value2[index],
                line3:value3[index]
            })  
        })     
        returnData = {chartData:chartData,legend:["个股商誉值(万)","商誉资产占比","行业平均占比"]}
    }
    return returnData

}

  //获取个股商誉信息
async function ajaxGoodwillWarn(options) {
    return await axios.post("https://comm.wedengta.com/?s=GoodwillWarn&f=getStockGoodwillDetail&req=StockGoodwillReq&rsp=StockGoodwillRsp",
    JSON.stringify({
        sSecDtCode: options.seccode,
      }));
  }