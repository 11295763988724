import axios from "axios";
import moment from "moment";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢
//大宗交易数据
export function getBsSignalData(options) {
    const typeObj = {
        "盘中多空趋势": "getDailySecBsInfo",            
        "近期多空趋势": "getSecBsInfo",
        "行业近期多空趋势":"getSecBsInfo",
        "行业盘中多空趋势":"getDailySecBsInfo"
      };
      const functions = {
        getDailySecBsInfo,
        getSecBsInfo
      };
      return functions[typeObj[options.type]](options);

}

export function getSecBsInfo(options) {
    return new Promise(async (resolve) => {
        let requestName
        if(options.type.indexOf("行业")>-1){
            requestName=options.industryname
        }else{
            requestName=options.secname
        }
        let fullData3 = tempHistory[requestName+options.type+'K线']
        if (!fullData3){
            fullData3 = await ajaxKLine(options);
            fullData3 = JSON.parse(fullData3.data.content);
            tempHistory[requestName+options.type+'K线'] = fullData3;
        }  
        let fullData = tempHistory[requestName+options.type+'多空信号']
        if (!fullData){
            fullData = await ajaxSecBsInfo(options,fullData3.vKLineDesc[0].lYmd);
            fullData = JSON.parse(fullData.data.content);
            console.log(fullData,'fullData');
            tempHistory[requestName+options.type+'多空信号'] = fullData;
        }
        let fullData2 = tempHistory[requestName+options.type+'海波信号']
        if (!fullData2){
            fullData2 = await ajaxSecWaveInfo(options,fullData3.vKLineDesc[0].lYmd);
            fullData2 = JSON.parse(fullData2.data.content);
            tempHistory[requestName+options.type+'海波信号'] = fullData2;
            console.log(fullData2,'fullData2');
        }
        const finalData = {
            pageTitle: (options.type.indexOf("行业")==-1)?getTitle(options):getIndustryTitle(options),
            data:handleSecBsInfo(fullData3,fullData,fullData2,options)
        };
        console.log(finalData);
        resolve(finalData);
    });
}


export function getDailySecBsInfo(options) {
    return new Promise(async (resolve) => {
            let fullData3 = await ajaxKLine(options);
            fullData3 = JSON.parse(fullData3.data.content);

            console.log(fullData3.vKLineDesc[0].lYmd,'iTime');
            let fullData = await ajaxDailySecBsInfo(options,fullData3.vKLineDesc[0].lYmd);
            fullData = JSON.parse(fullData.data.content);

            let fullData2 = await ajaxDailySecWaveInfo(options,fullData3.vKLineDesc[0].lYmd);
            fullData2 = JSON.parse(fullData2.data.content);

        const finalData = {
            pageTitle: (options.type.indexOf("行业")==-1)?getTitle(options):getIndustryTitle(options),
            data:handleSecBsInfo(fullData3,fullData,fullData2,options)
        };
        resolve(finalData);
    });
}

function convertK(data,options) {
    let newData=[];
    for (let i = 0; i < data.length; i++) {
        //bs信号在倒数第二位，海波信号在最后一位，默认为0，后续合并的时候会修改它,
        if(options.type=='近期多空趋势' || options.type=='行业近期多空趋势'){
            newData.push([data[i].lYmd,data[i].fOpen,data[i].fClose,data[i].fLow,data[i].fHigh,0,0]);
        }else if (options.type=='盘中多空趋势'|| options.type=='行业盘中多空趋势'){
            newData.push([timeChange(data[i].lYmd,data[i].lMinute),data[i].fOpen,data[i].fClose,data[i].fLow,data[i].fHigh,0,0]);

        }

    }
    return newData;
}

function timeChange(lYmd,lMinute){
    let hh= parseInt((lMinute) /60)
    let mm= (lMinute) % 60
    if(hh<10){
        hh='0'+hh
    }
    if(mm<10){
        mm='0'+mm
    }
    let minute=hh+':'+mm
    let dd=lYmd.toString().slice(-2)
    let str=lYmd.toString()
    str=Number(str.slice(0,str.length-2))
    if(str<10){
        str='0'+str
    }

    let mdate=str+'-'+dd+' '+minute
    
    return mdate

}
function combileBsIntoK(bsData, kData) {
    let combinedData=kData;
    console.log(bsData,'bsData');
    for (let i = 0; i < bsData.length; i++) {
        let bsDate=bsData[i].sDate;
        bsDate=bsDate.substr(0,4)+bsDate.substr(5,2)+bsDate.substr(8,2);
        for (let j = 0; j < combinedData.length; j++) {
            if(bsDate==combinedData[j][0]){
                if(bsData[i].iBs==1){
                    combinedData[j][5]=1;
                }else if(bsData[i].iBs==0){
                    combinedData[j][5]=2;
                }
            }
        }
    }
    return combinedData;
}

function combileBsIntoKMin(bsData, kData) {
    let combinedData=kData;
    for (let i = 0; i < bsData.length; i++) {
        let bsDateMin=bsData[i].sHourMinute;
        let bsDate=bsData[i].sDate.substr(5,2)+'-'+bsData[i].sDate.substr(8,2);
        for (let j = 0; j < combinedData.length; j++) {
            // console.log('bsDate',bsDate,combinedData[j][0]); 
            if(bsDate+" "+bsDateMin==combinedData[j][0]){
                if(bsData[i].iBs==1){
                    combinedData[j][5]=1;
                }else if(bsData[i].iBs==0){
                    combinedData[j][5]=2;
                }
            }
        }
    }
    return combinedData;
}
function combileWaveIntoK(wdata, kData) {
    let combinedData=kData;
    for (let i = 0; i < wdata.length; i++) {
        let wadata=wdata[i].sDate        
        wadata=wadata.substr(0,4)+wadata.substr(5,2)+wadata.substr(8,2);
        for (let j = 0; j < combinedData.length; j++) {
            // console.log('wadata',wadata,combinedData[j][0]); 
            if(wadata==combinedData[j][0]){
                if(wdata[i].iWave==1){
                    combinedData[j][6]=1;
                }else if(wdata[i].iWave==-1){
                    combinedData[j][6]=-1;
                }
            }
        }
    }
    return combinedData;
}

function combileWaveIntoKMin(wdata, kData) {
    let combinedData=kData;
    for (let i = 0; i < wdata.length; i++) {
        let wadataMin=wdata[i].sHourMinute  
        let wadata=wdata[i].sDate.substr(5,2)+wdata[i].sDate.substr(8,2);     
        for (let j = 0; j < combinedData.length; j++) {
            console.log('wadata',timeChange(wadata,wadataMin),combinedData[j][0],timeChange(wadata,wadataMin)==combinedData[j][0]); 
            if(timeChange(wadata,wadataMin)==combinedData[j][0]){
                if(wdata[i].iWave==1){
                    combinedData[j][6]=1;
                }else if(wdata[i].iWave==-1){
                    combinedData[j][6]=-1;
                }
            }
        }
    }
    return combinedData;
}

function handleSecBsInfo(kData,bsData,wdata,options){
    let stockKArray = convertK(kData.vKLineDesc,options) //获取K线数据
    if(options.type=='近期多空趋势' || options.type=='行业近期多空趋势'){
        stockKArray = combileBsIntoK(bsData.vSecBsInfo, stockKArray) //叠加BS数据
        stockKArray = combileWaveIntoK(wdata.vSecWaveInfo, stockKArray) //叠加Wave数据
    }else if (options.type=='盘中多空趋势'|| options.type=='行业盘中多空趋势'){
        stockKArray = combileBsIntoKMin(bsData.vSecBsInfo, stockKArray) //叠加BS数据
        stockKArray = combileWaveIntoKMin(wdata.vSecWaveInfo, stockKArray) //叠加Wave数据
    }
    //多空的描述
    let latestDK,latestPrice;
    let fData=stockKArray;
    fData=fData.reverse()
    let lastPrice=fData[0][2];
    let dkDesc
    if(options.type.indexOf("近期多空趋势")>-1){
        let magicTxt
        let isHaveBS=false;
        let isHaveDK=false;
        for (var i = 0; i < fData.length; i++) {
            //得到是否有多空
            if(fData[i][5]!=0){
                latestDK=fData[i][5];
                latestPrice=fData[i][2];
                isHaveBS=true;
                break;
            }
        }
        for(var j = 0; j < fData.length; j++){ 
            //海波信号及描述
            if(fData[j][6]!=0){
                magicTxt=getMagicTxt(j,fData[j][6]);
                isHaveDK=true;
                break;
            }
        }
        if(!isHaveDK || !isHaveBS){
            dkDesc="多空信号或神奇海波数据过少，暂不支持近期的多空趋势分析"
        }else {
            dkDesc='最近一次发出的是'+getDKTxt(latestDK)+',累计涨跌幅达'+getAriseTxt(latestPrice,lastPrice)+'；'+'结合神奇海波形态分析,'+magicTxt;
        }
    } else if (options.type.indexOf("盘中多空趋势")>-1){
        let magicTxt
        let isHaveBS=false;
        let isHaveDK=false;
        let positiveNum=0
        let negetiveNum=0;
        for(var j = 0; j < fData.length; j++){ 
            if(fData[j][6].value==1){
                positiveNum+=1;
            }else if(fData[j][6].value==-1){
                negetiveNum+=1;
            }
        }
        let txt1='最近4小时已出现'+positiveNum+'次正向波(红),'+negetiveNum+'次负向波(绿);';
        for (var i = 0; i < fData.length; i++) {
            //得到是否有多空
            if(fData[i][5]!=0){
                latestDK=fData[i][5];
                latestPrice=fData[i][2];
                isHaveBS=true;
                break;
            }
        }
        for(var k = 0; k < fData.length; k++){ 
            //海波信号及描述
            if(fData[k][6]!=0){
                magicTxt=getMagicTxtMin(k,fData[k][6]);
                isHaveDK=true;
                break;
            }
        }
        if(!isHaveDK || !isHaveBS){
            dkDesc="多空信号或神奇海波数据过少，暂不支持近期的多空趋势分析"
        }else {
            dkDesc=txt1+'结合神奇海波形态分析,'+magicTxt;
        }      

    }   

    let sKArray = stockKArray.map((item) => {
        return {
          label: item[0],
          k: item.slice(1, 5),
          iBs: item[item.length - 2],
        };
      });
    let sWaveArray = stockKArray.map((item) => {
        return {
          label: item[0],
          iWave: item[item.length - 1],
        };
      });
    return {stockKArray:sKArray,stockWaveArray:sWaveArray,dkDesc:dkDesc}
}

function getDKTxt(latestDK){
    if (latestDK==1){
        let desc = "多点信号(D)"
        return desc
    }else if (latestDK==2){
        let desc = "空点信号(K)"
        return desc
    }

}

function getAriseTxt(latestPrice,lastPrice){
    let chg = ((lastPrice-latestPrice)/latestPrice*100).toFixed(2)
    if ((lastPrice-latestPrice)>0){
        chg = "+"+chg+'%'
    }else if ((lastPrice-latestPrice)<0){
        chg = chg+'%'
    }
    return chg
}

function getMagicTxt(index,value){
    var magicTxt;
    var dkTxt=value==1?'多':'空';
    if([0].indexOf(index)>-1){
        magicTxt='近期趋势转为看'+dkTxt+',信号较为强烈！';
    }else if([1,2].indexOf(index)>-1){
        magicTxt='近期趋势维持看'+dkTxt+',信号保持稳定！';
    }else if([3,4].indexOf(index)>-1){
        magicTxt='近期趋势维持看'+dkTxt+',信号相对减弱！';
    }else{
        magicTxt='近期趋势暂不明朗,信号混沌不清！';
    }
    return magicTxt;
}


function getMagicTxtMin(index,value){
    var magicTxt;
    var dkTxt=value==1?'多':'空';
    if([0].indexOf(index)>-1){
        magicTxt='日内短线趋势转为看'+dkTxt+',信号较为强烈！';
    }else if([1,2].indexOf(index)>-1){
        magicTxt='日内短线趋势维持看'+dkTxt+',信号保持稳定！';
    }else if([3,4].indexOf(index)>-1){
        magicTxt='日内短线趋势维持看'+dkTxt+',信号相对减弱！';
    }else{
        magicTxt='日内短线趋势暂不明朗,信号混沌不清！';
    }
    return magicTxt;
}


function getTitle(options){
    return options.secname+options.type
}

function getIndustryTitle(options){
    return options.industryname+options.type
}


//获取个股K线数据  行业
async function ajaxKLine(options){
    let codeObj ={
        "近期多空趋势":options.seccode,
        "行业近期多空趋势":options.industrycode,
        "盘中多空趋势":options.seccode,
        "行业盘中多空趋势":options.industrycode
    }
    let lineObj ={
        "近期多空趋势":4,
        "行业近期多空趋势":4,
        "盘中多空趋势":0,
        "行业盘中多空趋势":0      
    }
    let numObj ={
        "近期多空趋势":60,
        "行业近期多空趋势":60,
        "盘中多空趋势":48,
        "行业盘中多空趋势":48      
    }
    return await axios.post("https://comm.wedengta.com/?s=quote&f=getKLine&req=KLineReq&rsp=KLineRsp",
    JSON.stringify({
        sDtSecCode: codeObj[options.type],
        eKLineType:lineObj[options.type],
        iWantnum:numObj[options.type]
    }))    
}

//获取行业名称和代码
async function ajaxSecBaseInfo(options){
    return await axios.post("https://comm.wedengta.com/?s=base&f=getSecBaseInfo&req=SecBaseInfoReq&rsp=SecBaseInfoRsp",
    JSON.stringify({
        vDtSecCode:[options.seccode]
    }))    
}

//获取个股多空信号  行业
async function ajaxSecBsInfo(options,iTime){
    let typeObj ={
        "近期多空趋势":options.seccode,
        "行业近期多空趋势":options.industrycode
    }
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getSecBsInfo&req=GetSecBsInfoReq&rsp=GetSecBsInfoRsp",
    JSON.stringify({
        sDtSecCode: typeObj[options.type],
        sDate:iTime,//日期从K线的最后一天获取
        iSize:60
    }))    
}


//获取个股海波信号  行业
async function ajaxSecWaveInfo(options,iTime){
    let typeObj ={
        "近期多空趋势":options.seccode,
        "行业近期多空趋势":options.industrycode
    }
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getSecWaveInfo&req=GetSecWaveInfoReq&rsp=GetSecWaveInfoRsp",
    JSON.stringify({
        sDtSecCode: typeObj[options.type],
        sDate:iTime,//日期从K线的最后一天获取
        iSize:60,
        iFrom:0
    }))    
}


//获取盘中多空信号  行业
async function ajaxDailySecBsInfo(options,iTime){
    let typeObj ={
        "盘中多空趋势":options.seccode,
        "行业盘中多空趋势":options.industrycode
    }
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getDailySecBsInfo&req=GetDailySecBsInfoReq&rsp=GetDailySecBsInfoRsp",
    JSON.stringify({
        sDtSecCode: typeObj[options.type],
        sDate:iTime,//日期从K线的最后一天获取
        iFrom:0
    }))    
}


//获取盘中海波信号  行业
async function ajaxDailySecWaveInfo(options,iTime){
    console.log(iTime,'盘中时间');
    let typeObj ={
        "盘中多空趋势":options.seccode,
        "行业盘中多空趋势":options.industrycode
    }
    return await axios.post("https://comm.wedengta.com/?s=singal&f=getDailySecWaveInfo&req=GetDailySecWaveInfoReq&rsp=GetDailySecWaveInfoRsp",
    JSON.stringify({
        sDtSecCode: typeObj[options.type],
        sDate:iTime,//日期从K线的最后一天获取
        iFrom:0
    }))    
}







