<template>
    <div class="marginTrade">
        <button v-show="tool_filter_one" @click="addGroup">添加到常用</button>
        <div>
            <div>
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterOne()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="tool_filter_one">
                <h3>类型</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterTwo()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterOneObj[tool_filter_one]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="stockArr.length > 0 && ['定增计划', '定增破发'].indexOf(tool_filter_one) !== -1">
                <h3>股票</h3>
                <el-select v-model="tool_filter_three"  size="medium" placeholder="请选择" @change="changeFilterThree()">
                    <el-option
                        v-for="(item, index) in stockArr"
                        :key="index"
                        :label="item.sSecName"
                        :value="`${item.sSecName}|${tool_filter_one === '定增计划' ? item.sDtSecCode : item.sId}`"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-show="['定增计划'].indexOf(tool_filter_one) !== -1 && tool_filter_three">
                <h3>类型</h3>
                <el-radio-group v-model="tool_filter_four" size="medium" @change="changeFilterFour()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterOneObj['定增计划详情']"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            stockArr: [],
            filterOneObj: {
                "定增计划": [
                    {
                        'label': '定增预案',
                        'value': '定增预案',
                    },
                    {
                        'label': '定增获批',
                        'value': '定增获批',
                    },
                    {
                        'label': '定增完成',
                        'value': '定增完成',
                    },
                ],
                "定增破发": [
                    {
                        'label': '解禁日期最近',
                        'value': 1,
                    },
                    {
                        'label': '破发比例最高',
                        'value': 3,
                    },
                ],
                "定增优选": [
                    {
                        'label': '增发掘金',
                        'value': 2,
                    },
                    {
                        'label': '破发套利',
                        'value': 4,
                    },
                ],
                "定增计划详情": [
                    {
                        'label': '详情',
                        'value': '详情',
                    },
                    {
                        'label': '发行对象',
                        'value': '发行对象',
                    },
                    {
                        'label': '补充说明',
                        'value': '补充说明',
                    },
                ]
            },
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeFilterOne() {
            this.stockArr = []
            this.tool_filter_two = this.filterOneObj[this.tool_filter_one][0].value
            this.changeFilterTwo()
        },
        changeFilterTwo() {
            this.tool_filter_three = ''
            this.tool_filter_four = ''
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    subType: this.tool_filter_two
                })
            }, 'show', (data) => {
                this.stockArr = data.data.data
            })
        },
        changeFilterThree() {
            this.tool_filter_four = '详情'
            this.changeFilterFour()
        },
        changeFilterFour() {
             this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    type: this.tool_filter_one,
                    sec: {
                        'secname': this.tool_filter_three.split("|")[0],
                        'seccode': this.tool_filter_three.split("|")[1],
                    },
                    subType: this.tool_filter_four
                })
            }, 'show')
        },
        addGroup() {
            let type = this.filterOneObj[this.tool_filter_one].filter(item => item.value === this.tool_filter_two)[0].label
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}-${type}${this.tool_filter_three ? `-${this.tool_filter_three.split("|")[0]}` : ''}${this.tool_filter_four ? `-${this.tool_filter_four}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        type: this.tool_filter_one,
                        sec: this.tool_filter_three ? {
                            'secname': this.tool_filter_three.split("|")[0],
                            'seccode': this.tool_filter_three.split("|")[1],
                        } : undefined,
                        subType: this.tool_filter_four ? this.tool_filter_four : this.tool_filter_two
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                // width: 14%;
                padding-right: 30px;
                box-sizing: border-box;
            }
            &:nth-child(2) {
                // width: 36%;
                padding-right: 30px;
                box-sizing: border-box;
            }
            &:nth-child(3) {
                // width: 20%;
                padding-right: 30px;
                box-sizing: border-box;
            }
            &:last-child {
                // width: 30%;
                box-sizing: border-box;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>