<template>
    <div class="bsSignal">
        <button
            v-show="['已申报科创板的公司', '拟申报科创板的公司', '三板达标的公司'].indexOf(tool_filter_one) !== -1 || this.tool_filter_two"
            @click="addGroup">添加到常用</button>
        <div>
            <div class="tool_one_filter">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_one" size="medium" @change="changeFilterOne">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeFilterTwo" v-if="tool_filter_one === '个股'" />
            <div class="tool_two_filter" v-show="tool_filter_two">
                <h3>详情</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changeFilterThree">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in filterThreeArr"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            filterThreeArr: [
                {
                    label: '(申报方)的详细介绍',
                    value: '(申报方)的详细介绍',
                },
                {
                    label: '(申报方)的关联图谱',
                    value: '(申报方)的关联图谱',
                },
                {
                    label: '(申报方)的关联关系',
                    value: '(申报方)的关联关系',
                },
                {
                    label: '(关联方)的关联情况',
                    value: '(关联方)的关联情况',
                },
                {
                    label: '(关联方)的关联图谱',
                    value: '(关联方)的关联图谱',
                },
                {
                    label: '(关联方)的关联关系',
                    value: '(关联方)的关联关系',
                },
            ],
            tool: {},
        }
    },
    created() {
        this.tool = tool
    },
    components: {
        SelectStock
    },
    methods: {
        //  行情&&板块
        async changeFilterOne() {
            this.tool_filter_two = ''
            this.tool_filter_three = ''
            if (this.tool_filter_one !== '个股') {
                this.$emit('setCustomWindowChart', {
                    ...this.activeWindowCustomChartMsg,
                    remark: JSON.stringify({
                         subType: this.tool_filter_one
                    })
                }, 'show')
            }
        },
        changeFilterTwo(value) {
           this.tool_filter_two = value 
           this.tool_filter_three = '(申报方)的详细介绍'
           this.changeFilterThree()
        },
        changeFilterThree() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                   subType: this.tool_filter_three,
                   compareStocks: [{
                        seccode: this.tool_filter_two.value,
                        secname: this.tool_filter_two.label,
                   }]
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one}${this.tool_filter_two ? `-${this.tool_filter_two.label}-${this.tool_filter_three}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                            subType: this.tool_filter_three || this.tool_filter_one,
                            compareStocks: this.tool_filter_two ? [{
                                    seccode: this.tool_filter_two.value,
                                    secname: this.tool_filter_two.label,
                            }] : undefined
                        }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.bsSignal {
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        .tool_one_filter {
            width: 18%;
            box-sizing: border-box;
            margin-right: 30px;
        }
        .tool_two_filter {
            flex: 1;
            margin-left: 30px;
        }
        > div {
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.08);
            }
            /deep/ .el-input__inner {
                background: #202020;
                border-color: rgba(255, 255, 255, 0.08);
                color: #fff;
            }
        }
    }
}
</style>