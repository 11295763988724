<template>
    <el-dialog
        :visible.sync="visible"
        title="直播间信息"
        :close-on-click-modal="false"
        custom-class="live_msg"
        width="40vw"
        top="10vh"
        @close="close"
    >
        <div class="create_content">
            <div :class="['preview', form.theme]">
                <img :src="showLogo(form.logo)" alt="">
                <div class="live_title">{{ form.title || '直播标题' }}</div>
                <div class="live_date">{{ $moment().format('YYYY-MM-DD') }}</div>
                <div class="live_time">{{ form.liveTime || '开播时间' }}</div>
                <div class="risk_free">{{ form.riskFree || '风险提示' }}</div>
                <div class="marquee_text">
                    <span>{{ form.marqueeText || '执业信息' }}</span>
                </div>
            </div>
            <el-form :model="form" ref="form" label-width="94px">
                <el-form-item
                    prop="theme"
                    label="选择配色"
                    :rules="[{ required: true, message: '请选择配色', trigger: 'blur' }]"
                >
                    <el-radio-group v-model="form.theme">
                        <el-radio label="theme1">配色一</el-radio>
                        <el-radio label="theme2">配色二</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="图标：" :rules="[{ required: true }]">
                    <div class="upload_samll delete">
                        <div :style="{ borderColor: checkIcon ? '#FA5555' : ''}" @click="uploadIcon()">
                            <img :src="form.logo || '../../../../../../../assets/images/detail/image_add.svg'" alt="" :style="{ width: form.logo ? '' : '36px', height: form.logo ? '' : '36px' }">
                            <span v-show="form.logo" @click.stop="">
                                <i class="el-icon-delete" @click="deleteImg()"></i>
                            </span>
                        </div>
                        <span style="color: #FA5555; position: absolute; fontSize: 12px; lineHeight: 1; marginLeft: 0;bottom: -16px" v-show="checkIcon">请上传头像</span>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="title"
                    label="直播标题"
                    :rules="[{ required: true, message: '请输入直播标题', trigger: 'blur' }]"
                >
                    <el-input v-model="form.title" placeholder="请输入直播标题" maxlength="18" show-word-limit></el-input>
                </el-form-item>
                <el-form-item
                    prop="liveTime"
                    label="开播时间"
                    :rules="[{ required: true, message: '请输入开播时间', trigger: 'blur' }]"
                >
                    <el-input v-model="form.liveTime" placeholder="请输入开播时间" maxlength="18" show-word-limit></el-input>
                </el-form-item>
                <el-form-item
                    prop="marqueeText"
                    label="执业信息"
                    :rules="[{ required: true, message: '请输入执业信息', trigger: 'blur' }]"
                >
                    <el-input
                        type="textarea"
                        placeholder="请输入执业信息"
                        v-model="form.marqueeText"
                        :rows="4"
                        show-word-limit
                        maxlength="80"
                    />
                </el-form-item>
                <el-form-item
                    prop="riskFree"
                    label="风险提示"
                    :rules="[{ required: true, message: '请输入风险提示', trigger: 'blur' }]"
                >
                    <el-input
                        type="textarea"
                        placeholder="请输入风险提示"
                        v-model="form.riskFree"
                        :rows="4"
                        show-word-limit
                        maxlength="50"
                    />
                </el-form-item>
            </el-form>
            <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
            <CropperDialog ref="cropper" :fixed="true" :nameControl="false" :fixedNumber="[1,1]" :fixedControl="false" @receiveData="receiveData" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="medium">取 消</el-button>
            <el-button @click="submit" type="primary" size="medium">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { validateForm } from "@/utils/utils";
import CropperDialog from '@/components/cropperDialog'
import image_add from '@/assets/images/detail/image_add.svg'
export default {
    props: {
        switch_visible: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            visible: false,
            checkIcon: false,
            form: {
                theme: 'theme1',
                logo: '',
                title: '',
                liveTime: '',
                marqueeText: '',
                riskFree: '',
            },
        }
    },
    components: {
        CropperDialog
    },
    methods: {
        showLogo(value) {
            return value ? value : image_add
        },
        uploadIcon() {
             this.$refs.fileInput.click()
        },
        batchImport(e) {
            const [file] = e.target.files;
            const checkSize = this.imgBeforeUpload(file)
            if (checkSize) {
                this.$refs.cropper.openDialog(file)
            }
             // 清空input的值
            this.$refs.fileInput.value = ''
        },
        //图片上传前，检查大小是否超出
        imgBeforeUpload(file) {
            const isFileSizeFit = file.size / 1024 / 1024 < 5;
            if (!isFileSizeFit) {
                this.$message.error("图片大小不能超过 5MB!");
            }

            return isFileSizeFit;
        },
        // 直播间logo信息修改
        receiveData(value) {
          this.form.logo = value.url
          this.checkIcon = false
        },
        deleteImg() {
            this.form.logo = ''
        },
        open(value) {
            this.visible = true
            if (value) {
                this.form = value
            }
        },
        close() {
            this.$refs.form.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        async submit() {
            await validateForm(this.$refs.form);
            if (!this.form.logo) {
                return this.checkIcon = true
            }
            this.API.live.addMaterial({
                id: Number(this.$route.query.id),
                liveRoomTools: {
                    roomId: Number(this.$route.query.id),
                    toolName: '直播间信息',
                    toolCode: 'liveMsg',
                    toolContent: JSON.stringify(this.form),
                    showStatus: this.switch_visible ? 1 : 0,
                },
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('编辑成功')
                    this.$emit('downScreen')
                    this.$emit('upDate')
                    this.close()
                } else {
                    this.$message.error('编辑失败')
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .live_msg {
    background: rgb(32,32,32);
    .el-dialog__header {
        display: flex;
        justify-Content: center;
        align-items: center;
        .el-dialog__title {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .el-dialog__body {
        padding: 8px 20px 0px;
        .create_content {
            display: flex;
            flex-direction: column;
            background: #121212;
            padding: 20px 20px 0;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .theme2 {
                background: url('../../../../../../../assets/images/detail/bar_bg_portrait2.png') no-repeat;
                .live_title,
                .live_date,
                .risk_free {
                    color: #57121d;
                }
            }
            .theme1 {
                background: url('../../../../../../../assets/images/detail/bar_bg_portrait.png') no-repeat;
                .live_title,
                .live_date,
                .risk_free {
                    color: #073287;
                }
            }
            .preview {
                width: 100%;
                height: 120px;
                background-size: contain;
                margin-bottom: 24px;
                margin-top: 20px;
                position: relative;
                > img {
                    height: 110px;
                    width: 110px;
                    position: absolute;
                    top: -26px;
                    left: 16px;
                }
                .live_title {
                    position: absolute;
                    left: 136px;
                    top: 34px;
                    font-size: 36px;
                    font-weight: bold;
                }
                .live_date {
                    position: absolute;
                    font-size: 18px;
                    font-weight: bold;
                    top: 26px;
                    right: 8px;
                }
                .live_time {
                    position: absolute;
                    color: #fff;
                    line-height: 26px;
                    left: 140px;
                }
                .risk_free {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 256px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 15px;
                    text-align: center;
                }
                .marquee_text {
                    position: absolute;
                    left: 6px;
                    bottom: 0;
                    width: 510px;
                    color: #fff;
                    height: 30px;
                    overflow: hidden;
                    > span {
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .el-form-item {
            margin-bottom: 24px;
        }
        .el-form-item__label {
            color: #fff;
            font-size: 16px;
            text-align: left;
        }
        .el-input__inner, .el-textarea__inner {
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.21);
            color: #fff;
        }
        .el-form-item.is-error .el-input__inner {
            border-color: #F56C6C
        }
        .el-input .el-input__count .el-input__count-inner, .el-input__count {
            background: unset;
        }
    }
    .el-dialog__footer {
        padding: 0 20px 20px;
        .dialog-footer {
            padding: 0 20px 20px;
            background: #121212;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .el-button--default {
                border-radius: 4px;
                border: 1px solid #4E8CFF;
                background: #252525;
                color: #4E8CFF;
                margin-right: 60px;
            }
            .el-button--primary {
                border: 1px solid #4E8CFF;
                background: #4E8CFF;
            }
        }
    }
}
.upload_samll {
    display: flex;
    align-items: flex-end;
    > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f8ff;
        border: 1px solid #f5f8ff;
        box-sizing: content-box;
        height: 64px;
        width: 64px;
        cursor: pointer;
        > img {
            max-width: 100%;
            max-height: 100%;
        }
        > span {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(29, 29, 29, 0.7);
            opacity: 0;
            color: #fff;
            cursor: pointer;
            font-size: 22px;
            > i:hover {
                color: #FF9226;
            }
        }
    }
    > span {
        margin-left: 16px;
        font-size: 12px;
        color: #3472E8;
        cursor: pointer;
        &:hover {
            color: #1a61e9;
        }
    }
}
.delete {
    > div:hover {
        > span {
            opacity: 1;
        }
    }
}
</style>