<template>
    <div class="liveSet">
        <keep-alive>
            <component v-bind:is="currentComponent()" :authVisible="authVisible"></component>
        </keep-alive>
    </div>
</template>
<script>
import ScreenComponents from './screenComponents/index.vue'
import MaterialBackground from './materialBackground/index.vue'
export default {
    props: {
        authVisible: {
            type: Boolean,
            default: true,
        },
        isActive: {
            type: String,
        }
    },
    components: {
        ScreenComponents,
        MaterialBackground
    },
    methods: {
        currentComponent() {
            switch (this.isActive) {
                case 'component':
                    return 'ScreenComponents'
                    break;
                case 'materialBackground':
                    return 'MaterialBackground'
                    break;
            }
        }
    }
}
</script>
<style lang="less" scoped>
 .liveSet {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    padding: 20px 0 0px 0;
    box-sizing: border-box;
 }
</style>