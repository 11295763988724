<template>
    <div class="marginTrade">
        <button v-show="tool_filter_two" @click="addGroup">添加到常用</button>
        <div>
            <SelectStock :data="activeWindowCustomChartMsg" @selectStock="changeStock" />
            <div v-show="tool_filter_one">
                <h3>名称</h3>
                <el-radio-group v-model="tool_filter_two" size="medium" @change="changeFilterType()">
                    <el-radio-button
                        :label="item.value"
                        v-for="(item, index) in tool.tooObj[activeWindowCustomChartMsg.materialName]"
                        :key="index"
                    >
                        {{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['个股估值分布', '个股估值回测', '行业估值分布'].indexOf(tool_filter_two) !== -1">
                <h3>周期</h3>
                <el-radio-group v-model="tool_filter_three" size="medium" @change="changeType()">
                    <el-radio-button
                        :label="item"
                        v-for="(item, index) in cycleArr"
                        :key="index"
                    >
                        {{ item }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div v-show="['个股估值分布', '个股估值回测', '行业估值分布'].indexOf(tool_filter_two) !== -1">
                <h3>模型</h3>
                <el-radio-group v-model="tool_filter_four" size="medium" @change="changeType()">
                    <el-radio-button
                        :label="item"
                        v-for="(item, index) in moduleArr"
                        :key="index"
                    >
                        {{ item }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import tool from '../components/tool.js'
import SelectStock from '../components/SelectStock/index.vue'
import { cutLive } from "@/utils/utils.js";

export default {
    props: {
        activeWindowCustomChartMsg: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            tool_filter_one: '',
            tool_filter_two: '',
            tool_filter_three: '',
            tool_filter_four: '',
            tool: {},
            cycleArr: ['3年','5年','10年','上市以来'],
            moduleArr: ['PB', '综合估值系数', '扣非PE-TTM'],
        }
    },
    components: {
        SelectStock
    },
    created() {
        this.tool = tool
    },
    methods: {
        changeStock(value) {
            this.tool_filter_one = value // obj key is label and value
            this.tool_filter_two = '估值详情'
            this.changeType()
        },
        changeFilterType() {
            if (!this.tool_filter_one) {
                this.tool_filter_two = ''
                return this.$message.info('请先选择股票')
            }
            if (['估值详情', 'PE-TTM走势图(倍)', 'PB走势图(倍)', '所在行业估值分布'].indexOf(this.tool_filter_two) !== -1) {
                this.tool_filter_three = ''
                this.tool_filter_four = ''
                this.changeType()
            }
            if (['个股估值分布', '个股估值回测', '行业估值分布'].indexOf(this.tool_filter_two) !== -1) {
                this.tool_filter_three = '3年'
                this.tool_filter_four = 'PB'
                this.changeType()
            }
        },
        changeType() {
            this.$emit('setCustomWindowChart', {
                ...this.activeWindowCustomChartMsg,
                remark: JSON.stringify({
                    seccode: this.tool_filter_one.value,
                    secname: this.tool_filter_one.label,
                    type: this.tool_filter_two,
                    period: this.tool_filter_three || undefined,
                    mode: this.tool_filter_four || undefined
                })
            }, 'show')
        },
        addGroup() {
            let name = `${this.activeWindowCustomChartMsg.materialName}-${this.tool_filter_one.label}-${this.tool_filter_two}${this.tool_filter_three ? `-${this.tool_filter_three}` : ''}${this.tool_filter_four ? `-${this.tool_filter_four}` : ''}`
            cutLive(document.getElementById('preview'), name).then((res = {}) => {
                let obj = {
                    materialClass: 0,
                    materialName: name,
                    materialType: 6,
                    url: res.downUrl,
                    materialId: String(this.activeWindowCustomChartMsg.id),
                    materialTag: this.activeWindowCustomChartMsg.materialTag,
                    remark: JSON.stringify({
                        seccode: this.tool_filter_one.value,
                        secname: this.tool_filter_one.label,
                        type: this.tool_filter_two,
                        period: this.tool_filter_three || undefined,
                        mode: this.tool_filter_four || undefined
                    }),
                    roomId: this.$route.query.id ? Number(this.$route.query.id) : undefined,
                }
                this.$bus.$emit('addMaterial', obj)
            }).catch(() => {
                this.$message.error('素材添加分组失败！')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.marginTrade {
    width: 100%;
    > button {
        background: #16181A;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-bottom: 8px;
        &::before {
            content: " ";
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
            background-image: url('../../../../../assets/images/detail/group.svg');
        }
        &:hover {
            color: #fff;
            &::before {
                background-image: url('../../../../../assets/images/detail/group_active.svg');
            }
        }
    }
    > div {
        display: flex;
        width: 100%;
        > div {
            &:first-child {
                width: 20%;
                box-sizing: border-box;
            }
            &:nth-child(2) {
                width: 40%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            &:nth-child(3) {
                width: 20%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            &:last-child {
                width: 20%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            > h3 {
                color: #fff;
                margin-bottom: 8px;
            }
            /deep/ .el-radio-group {
                display: flex;
                flex-wrap: wrap;
                .el-radio-button__inner {
                    background: #2E2F30;
                    color: rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    border: #2E2F30;
                    margin-bottom: 8px;
                    margin-right: 8px;
                }
            }
            /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: #767676;
                border: #767676;
                color: #fff;
                box-shadow: unset
            }
        }
    }
}
</style>