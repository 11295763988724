import axios from "axios";
import moment from "moment";

import { getUpdateDate } from "@/utils/utils.js";

export function getNorthFundChartData(options) {
  const typeObj = {
    flow: "getNorthFundFlowChart", //北向资金流向
    shareRate: "getCapitalToAShareRate", //北向占比
    fundFlowIndustry: "getNorthFundFlowIndustry", //北向资金summary,和流入流出top10
    fundFlowTop10Stock: "getNorthFundTop10Stock", //北向流入流出top10个股
    historyDaily: "getNorthFundHistoryDaily", //北向，南向资金历史daily
    holderIncomeRank: "getHolderIncomeRank", //北上股东收益排行榜
    holdRatio: "getHoldStockBuildUpList", //北上持股占比
    holdVary: "getPointChangeBuildUpList", //占比异动
    stockBuildUp: "getStockNumBuildUpNew", //北向资金增仓榜
    stockHoldList: "getStockNumHoldList", //北向资金持仓榜
    longHuSummary: "getLongHuBangInfo", //沪股通资金概况
    longHuReal: "getLongHuReal", //沪股通资金实时
    capitalMainFlow: "getCapitalMainFlow", // 主力资金涨跌幅
    capitalDetail: "getCapitalDetail", // 主力资金净流入/净流出
  };
  const functions = {
    getNorthFundFlowChart,
    getCapitalToAShareRate,
    getNorthFundFlowIndustry,
    getNorthFundTop10Stock,
    getNorthFundHistoryDaily,
    getHolderIncomeRank,
    getHoldStockBuildUpList,
    getPointChangeBuildUpList,
    getStockNumBuildUpNew,
    getStockNumHoldList,
    getLongHuBangInfo,
    getLongHuReal,
    getCapitalMainFlow,
    getCapitalDetail,
  };
  return functions[typeObj[options.type]](options);
}

/*=====================北向资金流向=========================*/
export function getNorthFundFlowChart(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();

    const data = await ajaxNorthFundFlow(options);
    let finalData = JSON.parse(data.data.content);
    if (options.flowPeriod != "当日") {
      finalData.vIncomeKline = finalData.vIncomeKline.reverse();
    } else {
      finalData.vIncomeKline = getDayData(finalData.vIncomeKline, options.flowDirection);
    }
    finalData.vIncomeKline.map((item) => {
      item.value1 = item.fShIncome;
      item.value2 = item.fSzIncome;
      item.value3 = item.fIncome;
      item.label = item.sXdt;
    });
    const legendObj = {
      北向: ["沪股通", "深股通", "北向资金"],
      南向: ["港股通(沪)", "港股通(深)", "南向资金"],
    };
    const result = {
      pageTitle: options.flowPeriod + options.flowDirection + "资金流向",
      legend: legendObj[options.flowDirection],
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}

//当日数据存在重复和漏的情况，需要处理一遍
function getDayData(data, flowDirection) {
  let result = [];
  let fixedMinutes = getFixedMinute(flowDirection);
  const lastMinute = getMinute(data[data.length - 1].sXdt);
  fixedMinutes.map((item) => {
    if (item <= lastMinute) {
      let isFound = false;
      data.map((cell) => {
        if (item == getMinute(cell.sXdt) && !isFound) {
          isFound = true;
          cell.sXdt = cell.sXdt.substring(0, 5);
          result.push(cell);
        }
      });
      if (!isFound) {
        try {
          // let lastItem = JSON.parse(JSON.stringify(data[]));
          let lastItem = JSON.parse(JSON.stringify(result[result.length - 1]));
          lastItem.sXdt = getMinuteString(item);
          result.push(lastItem);
        } catch (error) {
          console.log(error);
        }
      }
    }
  });
  return result;
}
function getFixedMinute(flowDirection) {
  let fixedMinutes = [];
  if (flowDirection == "北向") {
    // 9:30-11:30
    for (var i = 9 * 60 + 30; i < 11 * 60 + 30; i++) {
      fixedMinutes.push(i);
    }
    // 13:00-15:00
    for (var i = 13 * 60; i < 15 * 60 + 1; i++) {
      fixedMinutes.push(i);
    }
  } else {
    // 9:00-12:00, 3个小时+3小时10分，一共6小时10分 =6*60+10=370
    for (var i = 9 * 60; i < 12 * 60; i++) {
      fixedMinutes.push(i);
    }
    // 13:00-16:10
    for (var i = 13 * 60; i < 16 * 60 + 10; i++) {
      fixedMinutes.push(i);
    }
  }
  return fixedMinutes;
}
//09:30:00 转成 540 的格式
function getMinute(dateString) {
  return dateString.substring(0, 2) * 60 + dateString.substring(3, 5) * 1;
}
// 540转成 09:30 的格式
function getMinuteString(dateMin) {
  return zeroPad(Math.floor(dateMin / 60)) + ":" + zeroPad(dateMin % 60);
}
function zeroPad(num) {
  return num < 10 ? "0" + num : num;
}

//获取北向资金流向
async function ajaxNorthFundFlow(options) {
  const dateTypeObj = {
    当日: "1D",
    近一月: "1M",
    近六月: "6M",
    近一年: "1Y",
    全部: "ALL",
  };
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getCapitalFlow&req=GetCapitalFlowReq&rsp=GetCapitalFlowRsp",
    JSON.stringify({
      eStockType: options.flowDirection == "北向" ? 1 : 2, //股票类型：1-北上；2-南下
      sDateType: dateTypeObj[options.flowPeriod], //日期类型：1D-天，1W-周，1M-月，3M-季，6M-半年，1Y-1年,ALL-全部
    })
  );
}

/*=====================占比=========================*/
export function getCapitalToAShareRate(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    const data = await ajaxCapitalToAShareRate(options);
    let finalData = JSON.parse(data.data.content).vHoldRatioClosePriceKline.reverse();
    finalData.map((item) => {
      item.label = item.sDate;
      item.line1 = item.fHoldRatio;
      item.line2 = item.fClosePrice;
    });
    const legendObj = {
      A股: "沪深300",
      沪市: "上证指数",
      深市: "深证成指",
    };
    const pageTitleObj = {
      A股: "北向资金持股占A股流通市值",
      沪市: "北向资金持股占沪市流通市值",
      深市: "北向资金持股占深市流通市值",
    };
    const result = {
      pageTitle: pageTitleObj[options.market],
      legend: ["占股市比重", legendObj[options.market]],
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}

//获取北向资金占比
async function ajaxCapitalToAShareRate(options) {
  const marketObj = {
    A股: 1,
    沪市: 11,
    深市: 12,
  };
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getCapitalToAShareRate&req=GetCapitalToAShareRateReq&rsp=GetCapitalToAShareRateRsp",
    JSON.stringify({
      eStockType: marketObj[options.market], //1 沪深(默认) ，11沪股通 ，12深股通
      iStartxh: 0, //从新到旧
      iWantnum: 250, //要250个，和app保持一致
    })
  );
}

/*=====================北上流入summary,流入top10，流出top10=========================*/
export function getNorthFundFlowIndustry(options) {
  if (options.flowDirection == "summary") {
    return getNorthFundFlowSummary(options);
  } else {
    return getNorthFundTop10Industry(options);
  }
}
export function getNorthFundFlowSummary(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxFlowSum();
    data = JSON.parse(data.data.content);

    let sum = data.fIncome;
    sum = (sum / 100000000).toFixed(2);

    let finalData = [];
    data.vPlantIncome.map((item) => {
      finalData.push({
        label: getShortLabel(item.sPlantName, 4),
        value: 1 * (item.fIncome / 100000000).toFixed(2),
      });
    });
    const result = {
      pageTitle: "北向资金行业概况",
      date: data.sDate,
      sum: sum,
      data: finalData,
    };
    resolve(result);
  });
}
function getShortLabel(label, maxLength) {
  if (label.length > maxLength) {
    return label.substring(0, maxLength - 1) + "...";
  } else {
    return label;
  }
}
export function getNorthFundTop10Industry(options) {
  return new Promise(async (resolve) => {
    const dataArr = await Promise.all([
      ajaxFlowSum(), //流入流出总值
      ajaxFlowTop10(), //流入/流出前10
    ]);

    const data1 = JSON.parse(dataArr[0].data.content);
    let sum = data1.fIncome;
    sum = (sum / 100000000).toFixed(2);

    const data2 = JSON.parse(dataArr[1].data.content);
    let finalData = data2.vPlantIncome;

    if (options.flowDirection == "流入top10") {
      finalData = finalData.slice(0, 10);
    } else if (options.flowDirection == "流出top10") {
      finalData = finalData.slice(-10).reverse();
    }
    finalData.map((item) => {
      item.label = item.sPlantName;
      item.value1 = 1 * (item.fIncome / 100000000).toFixed(2);
      item.value2 = 1 * (item.fIncome / 100000000).toFixed(2);
    });
    const pageTitleObj = {
      流入top10: "北向资金行业净流入top10",
      流出top10: "北向资金行业净流出top10",
    };
    const result = {
      pageTitle: pageTitleObj[options.flowDirection],
      date: data2.sDate,
      title: ["行业", "", options.flowDirection == "流入top10" ? "净流入" : "净流出"],
      data: finalData,
      value1Suffix: "",
      value2Suffix: "亿",
    };
    resolve(result);
  });
}

async function ajaxFlowSum() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getMarketSectionInfo&req=GetMarketSectionInfoReq&rsp=GetMarketSectionInfoRsp",
    JSON.stringify({
      sDateType: "1D",
      eStockType: 1,
    })
  );
}
async function ajaxFlowTop10() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getMarketSectionList&req=GetMarketSectionListReq&rsp=GetMarketSectionListRsp",
    JSON.stringify({
      sDateType: "1D",
      eStockType: 1,
    })
  );
}

/*=====================沪股通，深股通，港股通（深），港股通（沪）十大成交股=========================*/
export function getNorthFundTop10Stock(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxLongHuBangTop10(options);
    data = JSON.parse(data.data.content);
    let finalData = [];
    let _data;
    if (options.market == "深股通") {
      _data = data.shenGuTongInfo.vDealDetail;
    } else if (options.market == "沪股通") {
      _data = data.huGuTongInfo.vDealDetail;
    } else if (options.market == "港股通(深)") {
      _data = data.shenGuTongInfo.vDealDetail;
    } else if (options.market == "港股通(沪)") {
      _data = data.huGuTongInfo.vDealDetail;
    }

    _data.map((item) => {
      finalData.push({
        label: item.sStockName,
        value1: 1 * (item.fDealAmount / 100000000).toFixed(2),
        value2: 1 * (item.fIncome / 100000000).toFixed(2),
      });
    });
    const pageTitleObj = {
      深股通: "北向资金深股通十大成交股",
      沪股通: "北向资金沪股通十大成交股",
      "港股通(深)": "南向资金港股通(深)十大成交股",
      "港股通(沪)": "南向资金港股通(沪)十大成交股",
    };
    const result = {
      pageTitle: pageTitleObj[options.market],
      date: data.vDateWeek[0].sDate,
      title: ["名称代码", "成交额", "净买入"],
      data: finalData,
      value1Suffix: "亿",
      value2Suffix: "亿",
    };
    resolve(result);
  });
}
async function ajaxLongHuBangTop10(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getLongHuBangInfo&req=GetLongHuBangInfoReq&rsp=GetLongHuBangInfoRsp",
    JSON.stringify({
      eStockType: ["深股通", "沪股通"].indexOf(options.market) > -1 ? 1 : 2,
      sDateType: "1D",
    })
  );
}

/*=====================北向资金历史数据=========================*/
export function getNorthFundHistoryDaily(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxCapitalHistoryDaily(options);
    const updateDate = await getUpdateDate();
    data = JSON.parse(data.data.content).vCapitalHistoryDaily.reverse();
    let finalData = [];
    let sum = 0;
    data.map((item) => {
      finalData.push({
        label: item.sDate,
        buy: item.fBuyAmount,
        sell: item.fSellAmount,
        inCome: item.fIncome,
      });
      sum += item.fIncome;
    });
    const pageTitleObj = {
      北向资金: "近五日北向资金合计流入",
      沪股通: "近五日北向资金(沪股通)合计流入",
      深股通: "近五日北向资金(深股通)合计流入",
      南向资金: "近五日南向资金合计流入",
      "港股通-沪": "近五日南向资金(沪)合计流入",
      "港股通-深": "近五日南向资金(深)合计流入",
    };
    const result = {
      pageTitle: pageTitleObj[options.market],
      legend: ["净流入(亿元)", "买入额(亿元)", "卖出额(亿元)"],
      data: finalData,
      date: updateDate,
      sum: 1 * sum.toFixed(2),
    };
    resolve(result);
  });
}
async function ajaxCapitalHistoryDaily(options) {
  const stockTypeObj = {
    北向资金: 1,
    南向资金: 2,
    沪股通: 11,
    深股通: 12,
    "港股通-沪": 21,
    "港股通-深": 22,
  };
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getCapitalHistoryDaily&req=GetCapitalHistoryDailyReq&rsp=GetCapitalHistoryDailyRsp",
    JSON.stringify({
      eStockType: stockTypeObj[options.market],
      iTop5: 1,
    })
  );
}

/*=====================北向股东收益排行=========================*/
export function getHolderIncomeRank(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    let data = await ajaxPageHolderIncomeChart();
    data = JSON.parse(data.data.content);
    let finalData = [];
    data.vHolderIncomeOrder.slice(0, 10).map((item) => {
      finalData.push({
        label: item.sHolderName,
        value: item.fIncomeRatio,
      });
    });
    const result = {
      pageTitle: "北上股东收益排行榜",
      title: ["序号", "个股", "收益"],
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}
async function ajaxPageHolderIncomeChart() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getPageHolderIncomeChart&req=GetPageHolderIncomeChartReq&rsp=GetPageHolderIncomeChartRsp",
    JSON.stringify({
      eStockType: 1, //北上
      iPageNum: 1,
    })
  );
}

/*=====================持股占比榜=========================*/
export function getHoldStockBuildUpList() {
  return new Promise(async (resolve) => {
    let data = await ajaxHoldStockBuildUpList();
    data = JSON.parse(data.data.content);
    let finalData = [];
    data.vHoldStockUpInfo.slice(0, 10).map((item) => {
      finalData.push({
        label: item.sStockName,
        value: item.fUpHoldRatio,
      });
    });
    const result = {
      pageTitle: "北上资金持股占比榜",
      date: data.sDate,
      title: ["序号", "个股", "持股占比"],
      data: finalData,
    };
    resolve(result);
  });
}

async function ajaxHoldStockBuildUpList() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getHoldStockBuildUpList&req=GetHoldStockBuildUpListReq&rsp=GetHoldStockBuildUpListRsp",
    JSON.stringify({
      sDateType: "1D",
      eStockType: 1,
      iPageNum: 1,
    })
  );
}

/*=====================占比异动榜=========================*/
export function getPointChangeBuildUpList() {
  return new Promise(async (resolve) => {
    let data = await ajaxPointChangeBuildUpList();
    data = JSON.parse(data.data.content);
    let finalData = [];
    data.vPointChangeUpInfo.slice(0, 10).map((item) => {
      finalData.push({
        label: item.sStockName,
        value: item.fUpPointChange,
      });
    });
    const result = {
      pageTitle: "北上资金占比异动榜",
      date: data.sDate,
      title: ["序号", "个股", "异动幅度"],
      data: finalData,
    };
    resolve(result);
  });
}

async function ajaxPointChangeBuildUpList() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getPointChangeBuildUpList&req=GetPointChangeBuildUpListReq&rsp=GetPointChangeBuildUpListRsp",
    JSON.stringify({
      sDateType: "1D",
      eStockType: 1,
      iPageNum: 1,
    })
  );
}

/*=====================北向资金增仓榜=========================*/
export function getStockNumBuildUpNew(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxStockNumBuildUpNew(options);
    data = JSON.parse(data.data.content);
    let finalData = [];
    data.vStockUpInfo.slice(0, 10).map((item) => {
      finalData.push({
        label: item.sStockName,
        value1: 1 * (item.fUpAmout / 100000000).toFixed(2),
        value2: 1 * (item.fUpNum / 10000).toFixed(2),
      });
    });
    const result = {
      pageTitle: options.flowDirection + "资金" + options.market + "增仓榜",
      date: data.sDate,
      title: ["个股名称", "增持市值", "增持股数"],
      data: finalData,
      value1Suffix: "亿",
      value2Suffix: "万股",
    };
    resolve(result);
  });
}

async function ajaxStockNumBuildUpNew(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getStockNumBuildUpNew&req=GetStockNumBuildUpNewReq&rsp=GetStockNumBuildUpNewRsp",
    JSON.stringify({
      eStockType: options.flowDirection == "北向" ? 1 : 2, //：1-北上；2-南下
      sDateType: "1D", //1D-天，1W-周，1M-月，3M
      iUpMarketValueDir: 1, //1从高到低；-1，从低到高
      iUpStockNumDir: options.market === '股数' ? 1 : 0,
    })
  );
}

/*=====================北向资金持仓榜=========================*/
export function getStockNumHoldList(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxStockNumHoldList(options);
    data = JSON.parse(data.data.content);
    let finalData = [];
    data.vStockNumHoldList.slice(0, 10).map((item) => {
      finalData.push({
        label: item.sStockName,
        value1: 1 * (item.fHoldMarketValue / 100000000).toFixed(2),
        value2: 1 * (item.fHoldNum / 100000000).toFixed(2),
      });
    });
    const result = {
      pageTitle: options.flowDirection + "资金持仓榜",
      date: data.sDate,
      title: ["个股名称", "持仓市值", "持仓数"],
      data: finalData,
      value1Suffix: "亿",
      value2Suffix: "亿股",
    };
    resolve(result);
  });
}
async function ajaxStockNumHoldList(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getStockNumHoldList&req=GetStockNumHoldListReq&rsp=GetStockNumHoldListRsp",
    JSON.stringify({
      eStockType: options.flowDirection == "北向" ? 1 : 2, //：1-北上；2-南下
      iHoldMarketValueDir: 1, //1从高到低；-1，从低到高
    })
  );
}

/*=====================沪股通资金概况=========================*/
export function getLongHuBangInfo(options) {
  return new Promise(async (resolve) => {
    let data = await ajaxLongHuBangInfo();
    data = JSON.parse(data.data.content);
    let finalData = [];
    if (options.market == "深股通") {
      finalData = data.shenGuTongInfo.vHKAHoldChange.reverse();
    } else {
      finalData = data.huGuTongInfo.vHKAHoldChange.reverse();
    }
    const legendObj = {
      沪股通: ["沪股通当日资金流入(亿元)", "上证指数"],
      深股通: ["深股通当日资金流入(亿元)", "深证成指"],
    };
    const pageTitleObj = {
      深股通: "深股通资金概况",
      沪股通: "沪股通资金概况",
    };
    finalData.map((item) => {
      item.label = item.sDate;
      item.line = item.fPoint;
      item.bar = item.fNetBuy;
    });
    let summary = {};
    if (options.market == "深股通") {
      summary = { ...data.shenGuTongInfo };
    } else {
      summary = { ...data.huGuTongInfo };
    }
    summary.fBuyAmout = (summary.fBuyAmout / 100).toFixed(2);
    summary.fBuyNum = (summary.fBuyNum / 10000).toFixed(2);
    summary.fDailyAmt = (summary.fDailyAmt / 100).toFixed(2);
    summary.fSellAmout = (summary.fSellAmout / 100).toFixed(2);
    summary.fSellNum = (summary.fSellNum / 10000).toFixed(2);
    summary.fTotleAmout = (summary.fTotleAmout / 100).toFixed(2);
    summary.fTotleNum = (summary.fTotleNum / 10000).toFixed(2);
    const result = {
      pageTitle: pageTitleObj[options.market],
      date: data.vDateWeek[0].sDate,
      legend: legendObj[options.market],
      data: finalData,
      summary: summary,
    };
    resolve(result);
  });
}
async function ajaxLongHuBangInfo() {
  return await axios.post(
    "https://comm.wedengta.com/?s=hongkongstock&f=getLongHuBangInfo&req=GetLongHuBangInfoReq&rsp=GetLongHuBangInfoRsp",
    JSON.stringify({
      eStockType: 1, //：1-北上；2-南下
      sDate: "",
    })
  );
}

/*=====================沪股通实时资金，流入流出=========================*/
export function getLongHuReal(options) {
  if (options.market == "summary") {
    return getAHExtend(options);
  } else {
    return getLongHuList(options);
  }
}
export function getAHExtend(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    let data = await ajaxAHExtend();
    data = JSON.parse(data.data.content).stAHExtendInfo;
    let finalData = [
      ["名称", "资金"],
      ["沪股通流入", 1 * data.fSHHKQuota.toFixed(2)],
      ["深股通流入", 1 * data.fSZ2HKFlowInto.toFixed(2)],
      ["沪股通剩余", 1 * data.fSHHKFlowInto.toFixed(2)],
      ["深股通剩余", 1 * data.fSZ2HKCurQuota.toFixed(2)],
    ];
    const result = {
      pageTitle: "沪股通/深股通实时资金",
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}
async function ajaxAHExtend() {
  return await axios.post("https://comm.wedengta.com/?s=quote&f=getAHExtend&req=AHExtendReq&rsp=AHExtendRsp");
}

export function getLongHuList(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();
    let data = await ajaxPlateQuoteEx(options);
    data = JSON.parse(data.data.content).vPlateQuoteDesc;
    let finalData = [["股票名称", "最新价", "涨跌幅"]];
    data.map((item) => {
      const arise = (100 * (item.fNow - item.fClose)) / item.fClose;
      finalData.push([item.sSecName, item.fNow, arise.toFixed(2)]);
    });

    const result = {
      pageTitle: `${options.market}${options.sortBy}top10`,
      data: finalData,
      date: updateDate,
    };
    resolve(result);
  });
}
async function ajaxPlateQuoteEx(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getPlateQuoteEx&req=PlateQuoteReq&rsp=PlateQuoteRsp",
    JSON.stringify({
      ePlateQuoteReqType: options.type == "沪股通" ? 12 : 17, //17深股通 12 沪股通
      ePlateQuoteSortType: options.sortBy == "涨幅" ? 2 : 1, //1 跌幅幅， 2 涨幅
      iWantnum: 10,
    })
  );
}

/*=====================资金涨幅、跌幅=========================*/
export function getCapitalMainFlow(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();

    let simpleQuoteData = await ajaxSimpleQuote();
    simpleQuoteData = JSON.parse(simpleQuoteData.data.content).vSecSimpleQuote;
    let simpleData = {};
    simpleQuoteData.forEach((item) => {
      const arise = (100 * (item.fNow - item.fClose)) / item.fClose;
      simpleData[item.sSecName] = `${arise.toFixed(2)}%`;
    });

    let codeData = await ajaxPlateQuote(options);
    codeData = JSON.parse(codeData.data.content).vPlateQuoteDesc;
    let data = await ajaxCapitalMainFlow(codeData.map((item) => item.sDtSecCode));
    data = JSON.parse(data.data.content).vCapitalMainFlowDesc;
    let finalData = [];
    data.forEach((item) => {
      finalData.push({
        label: item.sSecName,
        value1: item.fChangeRate.toFixed(2),
        value2: 1 * (item.fZljlr / 10000).toFixed(2),
      });
    });
    const result = {
      pageTitle: `主力资金${options.flowDirection}top10`,
      date: updateDate,
      title: ["股票名称", "涨跌幅", "主力净流入"],
      data: finalData.sort((a, b) => {
        return Math.abs(b.value1) - Math.abs(a.value1);
      }),
      simpleData,
      value1Suffix: "%",
      value2Suffix: "万",
    };
    resolve(result);
  });
}
async function ajaxPlateQuote(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getPlateQuote&req=PlateQuoteReq&rsp=PlateQuoteRsp",
    JSON.stringify({
      ePlateQuoteReqType: 1,
      ePlateQuoteSortType: options.flowDirection == "涨幅" ? 1 : 2, //2 跌幅， 1 涨幅
      iWantnum: 10,
      iColype: 14,
    })
  );
}
async function ajaxCapitalMainFlow(value) {
  // fZljlr 净流入 fChangeRate 涨幅百分比
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getCapitalMainFlow&req=CapitalMainFlowReq&rsp=CapitalMainFlowRsp",
    JSON.stringify({
      vDtSecCode: value || [],
      iStartxh: 0,
      iWantnum: 1,
    })
  );
}
/*=====================资金流入、流出=========================*/
export function getCapitalDetail(options) {
  return new Promise(async (resolve) => {
    const updateDate = await getUpdateDate();

    let simpleQuoteData = await ajaxCapitalMainFlow([
      "2005888001",
      "0105000001",
      "0005399001",
      "0005399006",
      "0005399005",
    ]);
    simpleQuoteData = JSON.parse(simpleQuoteData.data.content).vCapitalMainFlowDesc;
    let simpleData = {};
    simpleQuoteData.forEach((item) => {
      simpleData[item.sSecName] = 1 * (item.fZljlr / 100000000).toFixed(2) + "亿";
    });

    let data = await ajaxCapitalDetail(options);
    data = JSON.parse(data.data.content).vCapitalDetailDesc;
    let finalData = [];
    data.forEach((item) => {
      finalData.push({
        label: item.sSecName,
        value1: 1 * (item.fZljlr / 100000000).toFixed(2),
        value2: item.fChangeRate.toFixed(2),
      });
    });
    const result = {
      pageTitle: `主力资金${options.flowDirection}top10`,
      date: updateDate,
      title: ["股票名称", "主力净流入", "涨跌幅"],
      data: finalData,
      simpleData,
      value1Suffix: "亿",
      value2Suffix: "%",
    };
    resolve(result);
  });
}
async function ajaxCapitalDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getCapitalDetail&req=CapitalDetailReq&rsp=CapitalDetailRsp",
    JSON.stringify({
      eSetType: 6,
      iColype: 44,
      iStartxh: 0,
      iNum: 10,
      eSortType: options.flowDirection == "净流入" ? 1 : 2, // 1是流入，2是流出
      eDataType: 1,
    })
  );
}
// 个股行情简版
async function ajaxSimpleQuote() {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
    JSON.stringify({
      vDtSecCode: ["2005888001", "0105000001", "0005399001", "0005399006", "0005399005"],
    })
  );
}
