import axios from "axios";

export function getLongHuData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    if (options.type === 'vSecList') {
        return '龙虎榜总榜'
    }
    if (options.type === 'vJgqcList') {
        return '龙虎榜总榜（机构）'
    }
    if (options.type === 'vYzbyList') {
        return '龙虎榜总榜（游资）'
    }
    if (options.type === 'vYzxsList') {
        return '龙虎榜-游资助手'
    }
    if (options.type === 'vCgslList') {
        return '龙虎榜-超高胜率'
    }
    if (options.type === 'vZjqcList') {
        return '龙虎榜-资金抢筹'
    }
    if (options.type === 'vSaleOrgList') {
        return '活跃营业部'
    }
    if (options.type === 'vLHBReasonDepDetail' || options.type === 'vthreeDayKline') {
        return `${options.sSaleDepName}`
    }
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data
        if (['vthreeDayKline', 'vLHBReasonDepDetail'].indexOf(options.type) !== -1) {
            data = await ajaxLHBSaleDepDetail(options)
            data = JSON.parse(data.data.content)
            data = {
                data: data[options.type],
                date: data.sDay,
                iLHBDepInfo: data.iLHBDepInfo || ''
            }
        } else if (['vYzxsList', 'vCgslList', 'vZjqcList', 'vSaleOrgList'].indexOf(options.type) !== -1) {
            data = await ajaxLHBList()
            data = JSON.parse(data.data.content)
            data = {
                data: data[options.type],
                date: data.sDay,
            }
        } else if (['vSecList', 'vJgqcList', 'vYzbyList'].indexOf(options.type) !== -1) {
            let data1 = await ajaxLHBList()
            data1 = JSON.parse(data1.data.content)
            data = await ajaxLHBListDetail({ sDay: data1.sDay })
            data = JSON.parse(data.data.content)
            data = {
                date: data1.sDay,
                data: data[options.type].slice(0, 10),
            }
        }
        resolve(data)
    })
}
async function ajaxLHBList() {
    return await axios.post(
        "https://comm.wedengta.com/?s=longhubang&f=getLHBList&req=GetLHBListReq&rsp=GetLHBListRsp",
        JSON.stringify({})
      );
}
async function ajaxLHBListDetail(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=longhubang&f=getLHBListDetail&req=GetLHBListDetailReq&rsp=GetLHBListDetailRsp",
        JSON.stringify({
            iSubList: 1,
            sDay: options.sDay
        })
      );
}
async function ajaxLHBSaleDepDetail(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=longhubang&f=getLHBSaleDepDetail&req=GetLHBSaleDepDetailReq&rsp=GetLHBSaleDepDetailRsp",
        JSON.stringify({
            sSaleDepName: options.sSaleDepName,
            sDay: options.sDay
        })
      );
}