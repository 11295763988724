import axios from "axios";

export function getFinancingTrackData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return options.type
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data = {}
        if (['融资买入趋势分析','融资融券余额趋势'].indexOf(options.type) !== -1) {
            data = await ajaxStockMarginTrade(options)
            data = JSON.parse(data.data.content)
            data.vtStockDateMarginTrade = data.vtStockDateMarginTrade.reverse().slice(0, 70);
            if (options.type === '融资买入趋势分析') {
                let trendData = [];
                for (let i = 0; i < data.vtStockDateMarginTrade.length; i++) {
                    trendData[i] = {
                        name: data.vtStockDateMarginTrade[i].sOpDate,
                        value: data.vtStockDateMarginTrade[i].fBuyValue / 100000000,
                        lineValue: data.vtStockDateMarginTrade[i].fBuyPercent
                    };
                }
                if (trendData.length > 60) {
                    trendData = trendData.splice(trendData.length - 60, trendData.length);
                }
                data.data = trendData
                data.desc = setFinTrendDesc(data)
            } else {
                let restData = [];
                for (let i = 0; i < data.vtStockDateMarginTrade.length; i++) {
                    restData[i] = {
                        name: data.vtStockDateMarginTrade[i].sOpDate,
                        value: data.vtStockDateMarginTrade[i].fMarginBalance / 100000000
                    };
                }
                //得到涨跌幅数据
                let restAriseArr = getAriseValue(restData);
                //涨跌幅数据合并到原始数据中
                for (let i = 0; i < restData.length; i++) {
                    restData[i].lineValue = restAriseArr[i];
                }
                //将数据砍成60
                if (restData.length > 60) {
                    restData = restData.splice(restData.length - 60, restData.length);
                }
                data.data = restData
                data.desc = setFinRestDesc(data)
            }
        } else if (options.type === '短期持股策略分析') {
            data = await ajaxBackTraceAnalysis(options)
            data = JSON.parse(data.data.content).vtStockBackTraceRst
            let newData = formatAnalysis(data)
            data = {
                data: newData,
                desc: newData.length > 0 ? setBackTraceDesc(newData) : ''
            }
        } else if (options.type === '市场最佳持股策略') {
            data = await ajaxBuySignal(options)
            data = JSON.parse(data.data.content)
            let seccodeArr = [].concat(getSeccodeArr(data.vtWinBuySignalRst))
            let newData = await ajaxSimpleQuote(seccodeArr)
            newData = JSON.parse(newData.data.content).vSecSimpleQuote
            for (let l = 0; l < data.vtWinBuySignalRst.length; l++) {
                for (let m = 0; m < newData.length; m++) {
                    if (data.vtWinBuySignalRst[l].stMarginTradeDt.sDtSecCode == newData[m].sDtSecCode) {
                        data.vtWinBuySignalRst[l].stMarginTradeDt.sSecName = newData[m].sSecName;
                    }
                }
            }
        } else if (options.type === '个股最佳持股策略') {
            data = await ajaxStockHoldBest(options)
            data = JSON.parse(data.data.content)
            var nowIncRate;
            var nowIncRateClass;
            if (data.stRstNowBest.fIncRateAvg >= 0) {
                nowIncRate = '+' + (data.stRstNowBest.fIncRateAvg).toFixed(2);
                nowIncRateClass = 'num_rise';
            } else {
                nowIncRate = (data.stRstNowBest.fIncRateAvg).toFixed(2);
                nowIncRateClass = 'num_fall';
            }

            var hisIncRate;
            var hisIncRateClass;
            if (data.stRstHistoryBest.fIncRateAvg >= 0) {
                hisIncRate = '+' + (data.stRstHistoryBest.fIncRateAvg).toFixed(2);
                hisIncRateClass = 'num_rise';
            } else {
                hisIncRate = (data.stRstHistoryBest.fIncRateAvg).toFixed(2);
                hisIncRateClass = 'num_fall';
            }

            var nowHoldDays = data.stRstNowBest.iHoldDays;
            data = options.subType === '当前最佳' ? {
                buyPercent: validNum((data.fBuyPercent).toFixed(2), '%'),
                iHoldDays: validNum(nowHoldDays, '天'),
                fWinPer: validNum(data.stRstNowBest.fWinPer, '%'),
                fIncRateAvg: validNum(nowIncRate, '%'),
                fIncRateAvgClass: nowIncRateClass
            } : {
                buyPercent: data.sHistoryBuyPct,
                iHoldDays: validNum(data.stRstHistoryBest.iHoldDays, '天'),
                fWinPer: validNum(data.stRstHistoryBest.fWinPer, '%'),
                fIncRateAvg: validNum(hisIncRate, '%'),
                fIncRateAvgClass: hisIncRateClass
            }
        }
        resolve(data)
    })
}
function validNum(num, suffix) {
    var resultString;
    if (num == 0 || num == undefined) {
        resultString = '--';
    } else {
        resultString = num + suffix;
    }
    return resultString;
}
function getAriseValue(data) {
    let ariseArr = [];
    let _data = data;
    for (let i = 0; i < _data.length; i++) {
        if (i === 0) {
            ariseArr.push(0);
        } else {
            ariseArr.push((_data[i].value - _data[i - 1].value) / _data[i - 1].value * 100);
        }
    }
    return ariseArr;
}
function formatAnalysis(data) {
    let chartData = [];
    for (let i = 0; i < data.length; i++) {
        chartData.push({
            name: data[i].iHoldDays,
            value: data[i].fIncRateAvg,
            lineValue: data[i].fWinPer
        });
    }
    return chartData;
}
function strToBinary(str) {
    function Str2Bytes(str) {
        let pos = 0;
        let len = str.length;
        if (len % 2 != 0) {
            return null;
        }
        len /= 2;
        let hexA = new Array();
        for (let i = 0; i < len; i++) {
            let s = str.substr(pos, 2);
            let v = parseInt(s, 16);
            hexA.push(v);
            pos += 2;
        }
        return hexA;
    }
    let GUID = Str2Bytes(str);
    for (let j = 0; j < GUID.length; j++) {
        if (GUID[j] > 127) {
            let binary = (GUID[j] - 128).toString(2);
            let complement = ('0b' + binary ^ '0b1111111').toString(2);
            GUID[j] = ~parseInt(complement, 2);
        }
    }
    return GUID;
}
function getSeccodeArr(data) {
    var secArr = [];
    for (var i = 0; i < data.length; i++) {
        secArr.push(data[i].stMarginTradeDt.sDtSecCode);
    }
    return secArr;
}
function getAriseDesc(latest, yesterday) {
    var ariseTxt;
    // console.log(latest,yesterday);
    var rate = (latest - yesterday) / yesterday * 100;
    if (isNaN(rate)) {
        rate = 0;
    }
    if (rate >= 0) {
        ariseTxt = '上升';
    } else {
        ariseTxt = '下降';
    }
    return ariseTxt + Math.abs(rate).toFixed(2) + '%';
}
// 短期持股
function setBackTraceDesc(analysisData) {
    var lineValueArr = [],
        lineValueMax, lineValueMaxIndex;
    for (var i = 0; i < analysisData.length; i++) {
        lineValueArr.push(analysisData[i].lineValue);
    }
    lineValueMax = lineValueArr.max();
    lineValueMaxIndex = lineValueArr.indexOf(lineValueMax);
    var descTxt = '针对该股融资买入和历史行情数据回测分析可得：持股' + (lineValueMaxIndex + 1) + '天的胜率达' + analysisData[lineValueMaxIndex].lineValue + '%，获利空间为' + analysisData[lineValueMaxIndex].value + '%';
    return descTxt
}
//融资买入分析文字
function setFinTrendDesc(data) {
    var descArr = [];
    var dateLatestDataTxt = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 1].sBuyValue; //倒数第一天数据
    var dateLatestData = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 1].fBuyValue; //倒数第一天数据
    var dateYestData = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 2].fBuyValue; //倒数第二天数据
    var ariseDesc1 = getAriseDesc(dateLatestData, dateYestData);

    var buyPercentLatest = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 1].fBuyPercent; //倒数第一天数据
    var buyPercentYesterday = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 2].fBuyPercent; //倒数第二天数据
    var ariseDesc2 = getAriseDesc(buyPercentLatest, buyPercentYesterday);

    descArr = [
        '当日融资买入额' + dateLatestDataTxt + ',',
    ];

    //如果没有排名位置就不写
    if (data.stBuyValueRank.iPosition !== 0 && data.stBuyValueRank.iTotal !== 0) {
        descArr.push('行业排名' + data.stBuyValueRank.iPosition + '/' + data.stBuyValueRank.iTotal + ',');
    }

    descArr.push('较前一交易日' + ariseDesc1 + ';');

    if (data.fBuyPercent !== 0) {
        descArr.push('当日融资买入比' + data.fBuyPercent + '%,');
        descArr.push('较前一交易日' + ariseDesc2);
    }
    return descArr.join('')
}
//融资余额分析文字
function setFinRestDesc(data) {
    // console.log(data);
    var descArr = [];
    var dateLatestDataTxt = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 1].sMarginBalance; //倒数第一天数据
    var dateLatestData = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 1].fMarginBalance; //倒数第一天数据
    var dateYestData = data.vtStockDateMarginTrade[data.vtStockDateMarginTrade.length - 2].fMarginBalance; //倒数第二天数据
    var ariseDesc1 = getAriseDesc(dateLatestData, dateYestData);

    descArr = [
        '当日融资融券余额' + dateLatestDataTxt + ',',
    ];

    //如果没有排名位置就不写
    if (data.stBalanceRank.iPosition !== 0 && data.stBalanceRank.iTotal !== 0) {
        descArr.push('行业排名' + data.stBalanceRank.iPosition + '/' + data.stBalanceRank.iTotal + ',');
    }

    descArr.push('较前一交易日' + ariseDesc1 + ';');
    return descArr.join('')
}


// 市场最佳持股策略 第一步
async function ajaxBuySignal() {
    let accountInfo = {
        "AID": 1362106,
        "GUID": "06DF8956E15486433132AB599C46D8FE",
        "DUA": "SN=IOSCJPH34_FA&VN=344101317&BN=0&VC=APPLE&MO=iPhone&RL=414_896&CHID=1000&LCID=0&RV=&OS=13.5.1&DV=V1",
        "IMEI": "4DBE6036-F11B-4A54-908A-8FF2B8F6320D",
        "ticket": "8QPxb0fpzsS-EQuhyNlZFCHTQ0Hb!sbm!AZrWxJeLHM.",
        "dtCellphoneState": true,
        "dtnickname": "DT1362106",
        "dtheadimgurl": "",
        "dtMemberType": 1,
        "dtMemberEndTime": 1647075915,
        "iosChecking": 0,
        "dtUserRealName": "",
        "dtUserIDNumber": ""
    } 
    return await axios.post(
        "https://sec.wedengta.com/getMarginTrade?action=getBuySignal",
        JSON.stringify({
            stUserInfo: {
                vGUID: strToBinary(accountInfo.GUID),
                sDUA: accountInfo.DUA,
                iAccountId: accountInfo.AID
            }
        })
      );
}
// 市场最佳持股策略 第二步
async function ajaxSimpleQuote(seccodeArr) {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
        JSON.stringify({
            vDtSecCode: seccodeArr
        })
    )
}
async function ajaxStockMarginTrade(options) {
    return await axios.post(
        "https://sec.wedengta.com/getMarginTrade?action=getStockMarginTrade",
        JSON.stringify({
            sDtSecCode:options.seccode
        })
    )
}
// 短期持股分析图
async function ajaxBackTraceAnalysis(options) {
    return await axios.post(
        "https://sec.wedengta.com/getMarginTrade?action=getBackTraceAnalysis",
        JSON.stringify({
            sDtSecCode: options.seccode,
            sAppId: '0'
        })
    )
}
// 个股最佳持股策略
async function ajaxStockHoldBest(options) {
    return await axios.post(
        "https://sec.wedengta.com/getMarginTrade?action=getStockHoldBest",
        JSON.stringify({
            sDtSecCode: options.seccode,
            sAppId: '0'
        })
    )
}