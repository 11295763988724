import axios from "axios";

let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function getChipDistChartData(options) {
  const typeObj = {
    全市场盈利比例分布: "getMarketEarnPercentDist",
    筹码形态分析: "getFromTypeList",
    个股筹码分布: "getChipDist",
    个股主力筹码分布: "getChipDist"
  };
  const functions = {
    getMarketEarnPercentDist,
    getFromTypeList,
    getChipDist,
  };
  return functions[typeObj[options.type]](options);
}

/*=====================全市场盈利比例分布=========================*/
export function getMarketEarnPercentDist(options) {
  return new Promise(async (resolve) => {
    let finalData = [];
    let fullData = {}
    if (options.subType == "all") {
      fullData = await ajaxMarketEarnPercentDist(options);
      fullData = JSON.parse(fullData.data.content);
      finalData = [
        {
          name: "0-20%",
          value: 0,
        },
        {
          name: "20-50%",
          value: 0,
        },
        {
          name: "50%-80%",
          value: 0,
        },
        {
          name: "80-100%",
          value: 0,
        },
      ];
      finalData = finalData.map((item, index) => {
        item.value = fullData.vtStockNum[index];
        return item;
      });
    } else {
      fullData = await ajaxMarketEarnPercent(options);
      fullData = JSON.parse(fullData.data.content).vtStockEarnPer;
      finalData.push(["股票名称", "获利持仓占比", "今日占比变动"]);
      fullData.map((item) => {
        finalData.push([
          item.sSecName,
          (item.fEarningPer * 100).toFixed(2) + "%",
          (item.fTodayEarningPerChange * 100).toFixed(2) + "%",
        ]);
      });
    }

    const result = {
      pageTitle: getTitle(options),
      data: finalData,
      desc: getDistDesc(options, fullData),
    };
    resolve(result);
  });
}
function getDistDesc(options, fullData) {
  if (options.subType == "all") {
    return fullData.vtEarnDesc;
  }
}
function getTitle(options) {
  if (options.subType == "all") {
    return "全市场盈利比例分布";
  } else {
    const ratioObj = {
      part1: "0-20%",
      part2: "20-50%",
      part3: "50-80%",
      part4: "80-100%",
    };
    return `今日盈利占比${ratioObj[options.subType]}的股票`;
  }
}

//获利占比
async function ajaxMarketEarnPercentDist(options) {
  return await axios.post(
    "https://sec.wedengta.com/getChipDist?action=getMarketEarnPercentDist",
    JSON.stringify({
      iType: 1,
    })
  );
}
async function ajaxMarketEarnPercent(options) {
  const rangeObj = {
    part1: 1,
    part2: 2,
    part3: 3,
    part4: 4,
  };
  return await axios.post(
    "https://sec.wedengta.com/getChipDist?action=getMarketEarnPercent",
    JSON.stringify({
      iStartxh: 0,
      iWantnum: 10,
      iSortType: 1,
      iPerRange: rangeObj[options.subType],
    })
  );
}

/*=====================筹码形态分析=========================*/
export function getFromTypeList(options) {
  return new Promise(async (resolve) => {
    let finalData = []
    let fullData;
    fullData = await ajaxFromTypeList(options);
    fullData = JSON.parse(fullData.data.content);
    if (options.subType.indexOf("筹码形态") > -1) {
      finalData = fullData.vChipDistFormInfo.length;
    } else {
      let top10 = fullData.vChipDistFormInfo.slice(0, 10);
      let seccodeArr = [];
      top10.map((item) => {
        seccodeArr.push(item.sDtSecCode);
      });
      let seccodesData = await ajaxSimpleQuote(seccodeArr);
      seccodesData = JSON.parse(seccodesData.data.content).vSecSimpleQuote;
      top10 = top10.map((item) => {
        seccodesData.map((cell) => {
          if (item.sDtSecCode == cell.sDtSecCode) {
            item.fNow = cell.fNow;
            const absRate = (Math.abs((cell.fNow - cell.fClose) / cell.fClose) * 100).toFixed(2);
            const isRatePositive = cell.fNow >= cell.fClose;
            item.sRate = `${isRatePositive ? "" : "-"}${absRate}%`;
            item.fPeakChipPer = item.fPeakChipPer + "%";
          }
        });
        return item;
      });
      finalData.push(["股票名称", "最新价", "涨跌幅", "峰内筹码比例"]);
      top10.map((item) => {
        finalData.push([item.sSecName, item.fNow, item.sRate, item.fPeakChipPer]);
      });
    }
    const result = {
      pageTitle: options.subType,
      data: finalData,
    };
    resolve(result);
  });
}

async function ajaxFromTypeList(options) {
  const eChipDistFormTypeObj = {
    底部单峰密集型股票: 2,
    底部单峰密集型筹码形态: 2,
    中部单峰密集型股票: 3,
    中部单峰密集型筹码形态: 3,
    顶部单峰密集型股票: 4,
    顶部单峰密集型筹码形态: 4,
    双峰密集型股票: 5,
    双峰密集型筹码形态: 5,
    发散型股票: 1,
    发散型筹码形态: 1
  };
  return await axios.post(
    "https://comm.wedengta.com/?s=chipdistdata&f=getFromTypeList&req=GetFromTypeListReq&rsp=GetFromTypeListRsp",
    JSON.stringify({
      eChipDistFormType: eChipDistFormTypeObj[options.subType.trim()],
    })
  );
}
async function ajaxSimpleQuote(seccodes) {
  return await axios.post(
    "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
    JSON.stringify({
      vDtSecCode: seccodes,
    })
  );
}

/*=====================个股筹码分布=========================*/
export function getChipDist(options) {
  return new Promise(async (resolve) => {
    let fullData = await ajaxHisChipDistDetail(options);
    fullData = JSON.parse(fullData.data.content);
    if (options.type === '个股筹码分布') {
      let shapeData = await ajaxChipDistFormDetail(options)
      shapeData = JSON.parse(shapeData.data.content).vtChipDistFormInfo
      fullData = {
        chipDesc: fullData.sChangeDesc,
        chipDist: handleChipDist(fullData.vtChipDistRsp.reverse()),
        chipShape: shapeData.length > 0 ? getShapeTxt(shapeData[0].eChipDistFormType): '',
      }
    }
    if (options.type === '个股主力筹码分布') {
      fullData = {
        mainChipDesc: fullData.sMainChgDesc,
        chipDistMain: handleChipDistMain(fullData.vtChipDistRsp.reverse())
      }
    }
    return resolve({
      ...fullData,
      pageTitle: `${options.secname}${options.type}`
    })
  })
}
function getShapeTxt(type){
  var shapeArr=['发散型','底部单峰密集型','中部单峰密集型','顶部单峰密集型','双峰密集型'];
  return shapeArr[type-1];
}
function handleChipDist(data) {
  //获得所有帧里的最大值和最小值
  let minMax = getMinMax(data, 'vStockChipDist');
   //数据格式转换供图表用
  for (var i = 0; i < data.length; i++) {
    data[i].dataConverted = convertData({
        data: data[i].vStockChipDist,
        name: 'fPrice',
        value: 'fPer',
        avePrice: data[i].fAvgCost,
        closePrice: data[i].fClosePrice,
        pressPrice: data[i].fPressPrice,
        supportPrice: data[i].fSupportPrice,
        xMax: minMax.xMax,
        yMax: minMax.yMax,
        yMin: minMax.yMin
    });
  }
  return data[data.length - 1]
}
function handleChipDistMain(data) {
  data = data.filter(item => item.vtMainChipDist.length > 0)
  //获得所有帧里的最大值和最小值
  let minMax = getMinMax(data, 'vtMainChipDist');
   //数据格式转换供图表用
  for (var i = 0; i < data.length; i++) {
    data[i].dataConvertedMain = convertData({
        data: data[i].vtMainChipDist,
        name: 'fPrice',
        value: 'fPer',
        closePrice: data[i].fClosePrice.toFixed(1),
        mainAvgCost: data[i].fMainAvgCost.toFixed(1),
        xMax: minMax.xMax,
        yMax: minMax.yMax,
        yMin: minMax.yMin
    });
  }
  return data[data.length - 1]
}
function convertData(options) { //options:  data  name  value
  var data = []; //返回的数据
  for (var i = 0; i < options.data.length; i++) {
      data.push({
          name: options.data[i][options.name],
          value: options.data[i][options.value] / 100
      });
  }
  //把特异数据写入第一个数组中
  data[0].closePrice = options.closePrice;
  data[0].avePrice = options.avePrice;
  data[0].pressPrice = options.pressPrice;
  data[0].supportPrice = options.supportPrice;
  data[0].mainAvgCost = options.mainAvgCost;
  data[0].xMax = options.xMax / 100;
  data[0].yMax = options.yMax;
  data[0].yMin = options.yMin;
  return data;
}
function getMinMax(data, distName) {
  var yValueArr = [],
      xValueArr = [];
  for (var i = 0; i < data.length; i++) {
      if (distName == 'vtMainChipDist') {
          yValueArr.push(data[i].fMainAvgCost);
      } else {
          yValueArr.push(data[i].fAvgCost);
          yValueArr.push(data[i].fPressPrice);
          yValueArr.push(data[i].fSupportPrice);
      }
      for (var j = 0; j < data[i][distName].length; j++) {
          xValueArr.push(data[i][distName][j].fPer);
          yValueArr.push(data[i][distName][j].fPrice);
      }
  }
  return {
      xMax: xValueArr.max(),
      yMax: yValueArr.max(),
      yMin: yValueArr.min()
  };
}
 
async function ajaxHisChipDistDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=chipDist&f=getHisChipDistDetail&req=HisChipDistReq&rsp=HisChipDistRsp",
    JSON.stringify({
      sDtSecCode: options.seccode,
      iStartxh: 0,
      iWantnum: 30
    })
  );
}
async function ajaxChipDistFormDetail(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=chipdistdata&f=getChipDistFormDetail&req=ChipDistFormReq&rsp=ChipDistFormRsp",
    JSON.stringify({
      sAppId: '',
      vtDtSecCode:[options.seccode]
    })
  );
}
