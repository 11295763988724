import axios from "axios";

export function getSharePledgeData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return options.type
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let data
        if (options.type === '质押比例统计') {
            data = await ajaxSecPledgeRatioStat(options)
            data = JSON.parse(data.data.content)
            data = {
                date: data.sTime,
                data: ratioStat(data.mPledgeRatioStat)
            }
        }
        if (options.type === '质押比例排名') {
            data = await ajaxSecPledgeRatioSort(options)
            data = JSON.parse(data.data.content)
            data = ratioRanking(data.vSecPledgeData)
        }
        if (options.type === '行业数据') {
            data = await ajaxIndustryPledgeData(options)
            data = JSON.parse(data.data.content)
            data = ratioData(data.vIndustryPledgeData)
        }
        if (['股权质押', '重要股东质押', '重要股东风险预测'].indexOf(options.type) !== -1) {
            data = await ajaxSecPledgeInfo(options)
            data = JSON.parse(data.data.content)
            if (options.type === '股权质押') {
                if (data.mTotalPledgeInfo instanceof Object && Object.keys(data.mTotalPledgeInfo).length > 0) {
                    let newData = await ajaxPledgeRatioTrendChart(options)
                    newData = JSON.parse(newData.data.content)
                    data = stockRatio(data, newData.mDate2Ratio)
                } else {
                    data = '该股票尚无重要股东质押记录'
                }
            }
            if (options.type === '重要股东风险预测') {
                if (data.vUserSecPledgeInfoItem instanceof Array && data.vUserSecPledgeInfoItem.length > 0) {
                    let newData = await ajaxSimpleQuote(options)
                    newData = JSON.parse(newData.data.content)
                    data = shareholderRisk(options, data.vUserSecPledgeInfoItem, newData.vSecSimpleQuote)
                } else {
                    data = '该股票尚无重要股东质押记录'
                }
            }
            if (options.type === '重要股东质押') {
                if (data.mTotalPledgeInfo instanceof Object && Object.keys(data.mTotalPledgeInfo).length > 0) {
                    data = importantRatio(data.mTotalPledgeInfo)
                } else {
                    data = '该股票尚无重要股东质押记录'
                }
            }
        }
        resolve(data)
    })
}
function validConvert(options) {
    let result=options.data;
    let unit='';
    let isConvertUnit=options.isConvertUnit!=undefined?options.isConvertUnit:false;
    let isPrecisionOnZero=options.isPrecisionOnZero!=undefined?options.isPrecisionOnZero:false;
    let isConvertOnZero=options.isConvertOnZero!=undefined?options.isConvertOnZero:false;
    let isMinus=false;

    //只要看到空就转成--
    if(options.data=='' && options.data.length==0){
        return '--';
    }
    //需要转换的情况
    if(isConvertUnit){
        if(result<0){
            isMinus=true;
            result=Math.abs(result);
        }
        if(result>100000000){
            result=result/100000000;
            unit='亿';
        }else if(result>10000){
            result=result/10000;
            unit='万';
        }
        if(isMinus){
            result*=-1;
        }
    }
    if(options.precision){
        if(!isPrecisionOnZero && options.data==0){
            //
        }else{
            result=result.toFixed(options.precision);
        }
    }
    result=result+unit;
    if(isConvertOnZero && options.data==0 && !isPrecisionOnZero){
        result='--';
    }else if(options.suffix){
        result=result+options.suffix;
    }
    return result;
}
function ratioStat(data = {}) {
    return [
        {
            name: "<10%",
            value: data['0'] || 0,
        },
        {
            name: "10%~30%",
            value: data['1'] || 0,
        },
        {
            name: "30%-50%",
            value: data['2'] || 0,
        },
        {
            name: "≥50%",
            value: data['3'] || 0,
        },
    ];
}
function ratioRanking(data = []) {
    let fullData = [['股票名称', '质押比例/股数', '质押笔数', '无限售股份质押数','有限售股份质押数']]
    data.forEach((item) => {
        fullData.push([
            `<div>${item.sSecName}</div><div>${item.sIndustryName}</div>`,
            `${item.fPledgeRatio}%/${validConvert({
                data: item.fPledgeStockCount * 10000,
                precision:2,   //精度，可选，如不填则不输出精度
                isConvertUnit:true,  //是否转换单位万亿，默认为false
                isConvertOnZero:false  //如果为0，是否输出--，默认为false
            })}`,
            item.iPledgeDeals,
            validConvert({
                data: item.fUnlimitPledgeStockCount * 10000,
                precision:2,   //精度，可选，如不填则不输出精度
                isConvertUnit:true,  //是否转换单位万亿，默认为false
                isConvertOnZero:false  //如果为0，是否输出--，默认为false
            }),
            validConvert({
                data: item.fLimitPledgeStockCount * 10000,
                precision:2,   //精度，可选，如不填则不输出精度
                isConvertUnit:true,  //是否转换单位万亿，默认为false
                isConvertOnZero:false  //如果为0，是否输出--，默认为false
            }),
        ])
    })
    return fullData
}
function ratioData(data = []) {
    let fullData = [['行业', '平均质押比例', '质押公司数', '质押总笔数']]
    data.sort((a, b) => { return b.fPledgeRatioAve - a.fPledgeRatioAve}).slice(0, 10).forEach((item) => {
        fullData.push([
            item.sIndustryName,
            `${item.fPledgeRatioAve.toFixed(2)}%`,
            item.iCompanyCount,
            item.iTotalPledgeDeals
        ])
    })
    return fullData
}
function getBarPointClass(value){
    let barClass;
    if(value<=20){
        barClass='bar_point1';
    }else if(value>20 && value<=40){
        barClass='bar_point2';
    }else if(value>40 && value<=60){
        barClass='bar_point3';
    }else if(value>60 && value<=80){
        barClass='bar_point4';
    }else if(value>80){
        barClass='bar_point5';
    }
    return barClass;
}
function stockRatio(data1 = {}, data2 = {}) {
    return {
        level: getBarPointClass(data1.fTotalRiskLevel || 0),
        data: Object.keys(data2).map((value) => {
            return {
                label: value.split(' ')[0],
                value1: data2[value]
            }
        })
    }
}
function combineAddDuplicate(options){
    let data=JSON.parse(JSON.stringify(options.data));
    let duplicateKey=options.duplicateKey;  //重复的字段
    let addKey=options.addKey;              //需要相加的字段
    let addKey2=options.addKey2;              //需要相加的字段

    let map={};
    for (let i = 0; i < data.length; i++) {
        if(!map[data[i][duplicateKey]]){
            map[data[i][duplicateKey]]=data[i];
        }else{
            map[data[i][duplicateKey]][addKey]+=data[i][addKey];
            if(addKey2!=undefined){
                map[data[i][duplicateKey]][addKey2]+=data[i][addKey2];
            }
        }
    }
    
    let uniqueArr=[];
    for (let item in map) {
        uniqueArr.push(map[item]);
    }
    return uniqueArr;
}
function getDownTxt(price, nowPrice){
    let percent=((nowPrice-price)/nowPrice*100).toFixed(1);
    let upDownTxt=percent>0?'下跌':'上涨';
    return upDownTxt+Math.abs(percent)+'%';
}
function getDownArr(data, nowPrice){
    let priceArr=[];
    for (let i = 0; i < data.length; i++) {
        priceArr.push(data[i].name);
    }
    let priceMax=priceArr.max();
    let priceMin=priceArr.min();

    let gap=(priceMax-priceMin)/10;

    let resultArr=[];
    for (let i = 0; i < 11; i++) {
        resultArr.push({
            price:priceMax-gap*i,
            txt: getDownTxt(priceMax-gap*i, nowPrice)
        });
    }
    return resultArr;
}
function unitConverter(value){
    if(value>100000000){
        return (value/100000000).toFixed(1)+'亿';
    }else{
        return (value/10000).toFixed(1)+'万';
    }
}
function showDynamicDetail(options, data, downObj){
    var downPrice=downObj.price;
    var priceKey= options.subType === '平仓价' ? 'fOffsetprice':'fAlarmprice';
    var priceTxt= options.subType;
    
    var belowData=data.filter(function(item){
        if(item[priceKey]>=downPrice){
            return true;
        }else{
            return false;
        }
    });
    var belowDataCombined=combineAddDuplicate({
        data:belowData,
        duplicateKey:'sFpshname',
        addKey:'lInvolvedsum',
        addKey2:'fPctofpledger'
    });

    belowDataCombined=belowDataCombined.sort(function(a,b){
        return b.lInvolvedsum-a.lInvolvedsum;
    });

    for (var i = 0; i < belowDataCombined.length; i++) {
        belowDataCombined[i].lInvolvedsumTxt=unitConverter(belowDataCombined[i].lInvolvedsum);
        belowDataCombined[i].fPctofpledgerTxt=(100*belowDataCombined[i].fPctofpledger).toFixed(1);
    }

    var stockNumSum=0;
    for (var i = 0; i < belowDataCombined.length; i++) {
        stockNumSum+=belowDataCombined[i].lInvolvedsum;
    }
    stockNumSum=unitConverter(stockNumSum);
    return {
        desc: `共计会有${stockNumSum}股质押股票低于等于${priceTxt}`,
        belowDataCombined,
    }
}
function shareholderRisk(options, data1, data2) {
    let nowPrice = data2[0].fNow
    let currentData = []
    if (options.subType === '预警价') {
        currentData = combineAddDuplicate({
            data: data1,
            duplicateKey:'fAlarmprice',
            addKey:'lInvolvedsum'
        })
        currentData=currentData.sort(function(a,b){
            return a.fAlarmprice-b.fAlarmprice;
        });
        for (let i = 0; i < currentData.length; i++) {
            currentData[i].name=currentData[i].fAlarmprice;
            currentData[i].value=currentData[i].lInvolvedsum/10000;
        }
    }
    if (options.subType === '平仓价') {
        currentData = combineAddDuplicate({
            data: data1,
            duplicateKey:'fOffsetprice',
            addKey:'lInvolvedsum'
        });
        currentData=currentData.sort(function(a,b){
            return a.fOffsetprice-b.fOffsetprice;
        });
        for (let i = 0; i < currentData.length; i++) {
            currentData[i].name=currentData[i].fOffsetprice;
            currentData[i].value=currentData[i].lInvolvedsum/10000;
        }
    }
    let downArr = getDownArr(currentData, nowPrice)
    let dynamicDetail = showDynamicDetail(options, data1, downArr[downArr.length - 1])
    return {
        nowPrice,
        downPrice: downArr[downArr.length - 1].price,
        downTxt: downArr[downArr.length - 1].txt,
        data: currentData,
        dynamicDetail,
    }
}
function importantRatio(data) {
    let fullData = [['股东名称', '质押股数', '占股东所持有股票比例']]
    Object.keys(data).forEach((value) => {
        fullData.push([
            data[value].sFpshname,
            validConvert({
                data: data[value].lAccufpshares * 10000,
                precision:2,   //精度，可选，如不填则不输出精度
                isConvertUnit:true,  //是否转换单位万亿，默认为false
                isConvertOnZero:false  //如果为0，是否输出--，默认为false
            }),
            `${(data[value].fAccupctofpled * 100).toFixed(1)}%`
        ])
    })
    return fullData
}
async function ajaxCapitcalFlow(options) {
    return await axios.get("https://sec.wedengta.com/getSecInfo?action=CapitcalFlow&count=5", {
        params: {
            seccode: options.seccode,
        },
    });
}
// 质押比例统计
async function ajaxSecPledgeRatioStat() {
    return await axios.post(
        "https://comm.wedengta.com/?s=SecPledge&f=getSecPledgeRatioStat&req=PledgeRatioStatReq&rsp=PledgeRatioStatRsp",
        JSON.stringify({
            iTimeStamp:Math.floor(new Date().getTime()/1000)
        })
    );
}
async function ajaxSecPledgeRatioSort() {
    return await axios.post(
        "https://comm.wedengta.com/?s=SecPledge&f=getSecPledgeRatioSort&req=PledgeRatioSortReq&rsp=PledgeRatioSortRsp",
        JSON.stringify({
            iTimeStamp:Math.floor(new Date().getTime()/1000),
            iDisplayCount:10,
            eDisplayOrder:1,
            vSecPortfolioDtCode: [],
        })
    );
}
async function ajaxIndustryPledgeData() {
    return await axios.post(
        "https://comm.wedengta.com/?s=SecPledge&f=getIndustryPledgeData&req=IndustryPledgeReq&rsp=IndustryPledgeRsp",
        JSON.stringify({
            iTimeStamp:Math.floor(new Date().getTime()/1000)
        })
    );
}
async function ajaxSecPledgeInfo(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=PortfolioSecNews&f=getSecPledgeInfo&req=GetSecPledgeInfoReq&rsp=GetSecPledgeInfoRsp",
        JSON.stringify({
            sDtCode: options.seccode
        })
    );
}
// 股权质押比例(占总股本)
async function ajaxPledgeRatioTrendChart(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=SecPledge&f=getPledgeRatioTrendChart&req=PledgeRatioTrendChartReq&rsp=PledgeRatioTrendChartRsp",
        JSON.stringify({
            sSecDtCode: options.seccode
        })
    );
}
// 当前股价
async function ajaxSimpleQuote(options) {
    return await axios.post(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp",
        JSON.stringify({
            vDtSecCode: [options.seccode]
        })
    );
}
