import axios from "axios";
export function getGoodwillAnalysisData(options) {
    return new Promise(async (resolve, reject) => {
        try {
            let finalData = await getPageData(options)
            const result = {
                pageTitle: getPageTitle(options) || '',
                data: finalData,
            };
            resolve(result); 
        } catch (error) {
            reject(error)
        }
    })
}
function getPageTitle(options) {
    return options.type
}
function getPageData(options) {
    return new Promise(async (resolve) => {
        let finalData = {}
        if (options.type === '商誉比例排名') {
            finalData = await ajaxGoodwillRatioRank(options.subType === '净资产' ? 1 : 2)
            finalData = JSON.parse(finalData.data.content)
            let newFinalData = [['股票名称','商誉比例','商誉金额','净资产','总资产','所属行业']]
            finalData.vGoodwillRatioRank.forEach((item) => {
                newFinalData.push([
                    item.sSecName,
                    `${item.fGoodwillRatio.toFixed(2)}%`,
                    getUnit(item.fGoodwillSum),
                    getUnit(item.fNetAsset),
                    getUnit(item.fTotalAsset),
                    item.sIndustryName
                ])
            })
            finalData = newFinalData
        } else {
            finalData = await ajaxGoodwillWarnIndex()
            finalData = JSON.parse(finalData.data.content)
            switch (options.type) {
                case "商誉净资产比例统计":
                    finalData = {
                        date: finalData.stNetAssetRatioStat.sDate,
                        data: getChartData(finalData.stNetAssetRatioStat.mNetAssetRatioData)
                    }
                    break;
                case "商誉总资产比例统计":
                    finalData = {
                        date: finalData.stTotalAsseRatioStat.sDate,
                        data: getChartData(finalData.stTotalAsseRatioStat.mTotalAssetRatioData)
                    }
                    break;
                default:
                    let goodWillTrendChartData=[];
                    Object.keys(finalData.mGoodwillTrend).forEach((key) => {
                        goodWillTrendChartData.push({
                            name: key,
                            value1: finalData.mGoodwillTrend[key]
                        });
                    })
                    finalData = goodWillTrendChartData
            }
        }
        resolve(finalData)
    })
}
function getUnit(data) {
    var unit;
    if(data<10000){
        unit = data;
    }else if(data < 100000000){
        unit = (data/1000).toFixed(1)+'万';
    }else{
        unit =(data/100000000).toFixed(1)+'亿';
    }
    return unit;
}
function getChartData(data){
    var resultArr=[
        {
            'name':'>100%',
            'value':0
        },
        {
            'name':'50～100%',
            'value':0
        },
        {
            'name':'30～50%',
            'value':0
        },
        {
            'name':'10～30%',
            'value':0
        },
        {
            'name':'<10%',
            'value':0
        }
    ];
    Object.keys(data).forEach((key) => {
        resultArr[key - 1].value = data[key]
    })
    return resultArr;
}
async function ajaxGoodwillWarnIndex() {
    return await axios.post("https://comm.wedengta.com/?s=GoodwillWarn&f=getGoodwillWarnIndex&req=GoodwillWarnIndexReq&rsp=GoodwillWarnIndexRsp",
    JSON.stringify({}));
}
async function ajaxGoodwillRatioRank(assetType) {
    return await axios.post("https://comm.wedengta.com/?s=GoodwillWarn&f=getGoodwillRatioRank&req=GoodwillRatioRankReq&rsp=GoodwillRatioRankRsp",
    JSON.stringify({
        eAssetType: assetType,  // 1净资产  2总资产
        eStockType: 1,  //1 全市场   2自选股
        vPortfolio: [],  //自选股数组
        iDisplayCount: 10,
        eSortType: 2    // 1升序 2降序
    }));
}

